import { SET_BROKER_LOAN_APPLICATION_ID } from './actions/types'

export interface State {
  blaId: string | undefined
  fromBroker: string
}

export default (state: State = initialState, action: any) => {
  if (action.type === SET_BROKER_LOAN_APPLICATION_ID) {
    return {
      ...state,
      blaId: action.blaId,
      fromBroker: action.fromBroker,
    }
  } else {
    return state
  }
}

export const initialState: State = {
  blaId: undefined,
  fromBroker: '',
}
