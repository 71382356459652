import { RadioElement } from '../../../../../shared/RadioGroup/Models'

const options: RadioElement[] = [
  {
    id: 'less-than-five',
    label: 'LESS_THAN_5_TIMES',
  },
  {
    id: 'five-ten',
    label: '5_10_TIMES',
  },
  {
    id: 'ten-twenty',
    label: '10-20_TIMES',
  },
]

export default options
