import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import InvestmentExperience from './InvestmentExperience'
import PEPCheck from './PEPCheck'
import FinancialSituation from './FinancialSituation'
import GoalOfInvestment from './GoalOfInvestment'
import Steps from '../../../shared/Steps'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import FixuraInput from '../../../shared/FixuraInput'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../../store'
import postAML from '../../../../store/user/investor/aml/actions/postAML'
import getAML from '../../../../store/user/investor/aml/actions/getAML'
import { scroll, ScrollElement } from '../../../../utils/scroll'
import Indicator from '../../../shared/Indicator'

interface Props extends WithTranslation {
  postAmlData: (data, forceAmlFillIn) => void
  getAmlData: () => void
  is_fetching: boolean
  error: any
  force_aml_fill_in: boolean
  amlData: any
}

const AmlPresentation = (props: Props) => {
  const [fields, setFields] = useState({})
  const [errors, setErrors] = useState({})
  const localHandleChange = (
    field: string,
    e: React.FormEvent<HTMLInputElement>
  ) => handleChange(field, fields, setFields, e)
  const {
    t,
    postAmlData,
    is_fetching,
    force_aml_fill_in,
    getAmlData,
    amlData,
    error,
  } = props

  useEffect(() => {
    getAmlData()
  }, [getAmlData])

  useEffect(() => {
    amlData.data.is_pep = amlData.data.is_pep == true //eslint-disable-line

    if (error && error.response && error.response.status) {
      scroll('SERVER_ERROR')
    }

    setFields(amlData.data)
  }, [amlData.data, error])

  const submitData = () => {
    if (fields['IBAN']) {
      fields['IBAN'] = fields['IBAN'].split(' ').join('')
    }

    if (handleValidation(fields, setErrors, t)) {
      postAmlData(fields, force_aml_fill_in)
    }
  }

  return (
    <Container className="aml__wrapper">
      {force_aml_fill_in ? (
        <Row>
          <Indicator isVisible={is_fetching} />
          <Col className="text-center step-top-pad">
            <small className="error">{t('FORCE_AML_FILL_IN')}</small>
          </Col>
        </Row>
      ) : (
        <Row className="step-top-pad">
          <Indicator isVisible={is_fetching} />
          <Steps
            stepIndex={3}
            steps={[
              t('IDENTITY_VERIFICATION'),
              t('INVESTOR_ON_BOARDING_STEP_PHONE'),
              t('INVESTOR_ON_BOARDING_STEP_AML'),
            ]}
          />
        </Row>
      )}

      {error && error.response && error.response.status ? (
        <Row>
          <ScrollElement name="SERVER_ERROR" />
          <Col className="text-center step-top-pad">
            <small className="error">{t('SERVER_ERROR')}</small>
          </Col>
        </Row>
      ) : (
        ''
      )}
      <Row>
        <Col className="text-center" lg={{ size: 8, offset: 2 }}>
          <h1 className="font-50">{t('JUST_FEW_QUESTIONS')}</h1>
        </Col>
      </Row>
      <Row className={force_aml_fill_in ? 'hide' : 'mb-5'}>
        <Col className="text-center" lg={{ size: 8, offset: 2 }}>
          <ScrollElement name="IBAN" />
          <div className="font-20-body">{t('PROVIDE_BANK_ACCOUNT_NUMBER')}</div>
        </Col>
      </Row>
      <Row className={force_aml_fill_in ? 'hide' : 'input-distance'}>
        <Col md="7" className="m-auto">
          <FixuraInput
            name="IBAN"
            className="input-characteristics"
            placeholder={t('BANK_ACCOUNT_NUMBER_(IBAN)')}
            onChange={localHandleChange.bind(undefined, 'IBAN')}
            errorMessage={errors['IBAN']}
            hasError={errors.hasOwnProperty('IBAN')}
            defaultValue={fields['IBAN']}
          />
        </Col>
      </Row>
      <Row>
        <PEPCheck setFields={setFields} errors={errors} fields={fields} />
        <InvestmentExperience
          setFields={setFields}
          fields={fields}
          errors={errors}
        />
        <FinancialSituation
          setFields={setFields}
          fields={fields}
          errors={errors}
        />
        <GoalOfInvestment
          setFields={setFields}
          fields={fields}
          errors={errors}
        />
      </Row>
      <Row>
        <Col className="text-center" lg={{ size: 8, offset: 2 }}>
          <div className="font-20-body">{t('THANKS_FOR_INFO')}</div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center" lg={{ size: 8, offset: 2 }}>
          <div className="font-20-body">
            {t('CLICK_TO_SIGN_INVESTMENT_CONTRACT')}
          </div>
        </Col>
      </Row>
      {force_aml_fill_in ? (
        <Row>
          <Col className="text-center" lg={{ size: 8, offset: 2 }}>
            <Button className="continue-distance" onClick={submitData}>
              {t('CONTINUE')}
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col className="text-center" lg={{ size: 8, offset: 2 }}>
            <Button className="continue-distance" onClick={submitData}>
              {t('CONTINUE_TO_AGREEMENT')}
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  )
}

function handleChange(
  field,
  fields,
  setFields,
  e: React.FormEvent<HTMLInputElement>
) {
  fields[field] = e.currentTarget.value
  setFields(fields)
}

function handleValidation(fields, setErrors, t) {
  const errors = {}
  let formIsValid = true

  if (!fields['IBAN']) {
    if (formIsValid) {
      scroll('IBAN')
    }
    formIsValid = false
    errors['IBAN'] = t('CANNOT_BE_EMPTY')
  }

  if (!fields.hasOwnProperty('is_pep') || fields['is_pep'] === null) {
    if (formIsValid) {
      scroll('is_pep')
    }
    formIsValid = false
    errors['is_pep'] = t('CANNOT_BE_EMPTY')
  }
  if (
    isEmptyOrFalse(fields, 'is_familiar_with_publicly_traded_stocks') &&
    isEmptyOrFalse(fields, 'is_familiar_with_bonds') &&
    isEmptyOrFalse(fields, 'is_familiar_with_money_market_instruments') &&
    isEmptyOrFalse(fields, 'is_familiar_with_derivatives') &&
    isEmptyOrFalse(fields, 'is_familiar_with_investment_funds') &&
    isEmptyOrFalse(fields, 'has_no_experience_in_investment_vehicles')
  ) {
    if (isEmpty(fields, 'investment_funds_specification')) {
      if (formIsValid) {
        scroll('investment_funds_specification')
      }
      formIsValid = false
      errors['investment_funds_specification'] = t(
        'AT_LEAST_ONE_CHECKBOX_MUST_BE_CHECKED'
      )
    }
  }

  if (
    isEmptyOrFalse(fields, 'is_familiar_with_investments_on_my_own') &&
    isEmptyOrFalse(fields, 'is_familiar_with_investment_advice') &&
    isEmptyOrFalse(fields, 'is_familiar_with_asset_management') &&
    isEmptyOrFalse(fields, 'is_familiar_with_electronic_trading') &&
    isEmptyOrFalse(fields, 'is_familiar_with_insurance_investments') &&
    isEmptyOrFalse(fields, 'has_no_experience_in_investment_services')
  ) {
    if (isEmpty(fields, 'insurance_investments_specification')) {
      if (formIsValid) {
        scroll('insurance_investments_specification')
      }
      formIsValid = false
      errors['insurance_investments_specification'] = t(
        'AT_LEAST_ONE_CHECKBOX_MUST_BE_CHECKED'
      )
    }
  }

  if (
    isEmptyOrFalse(fields, 'is_employee') &&
    isEmptyOrFalse(fields, 'is_entrepreneur') &&
    isEmptyOrFalse(fields, 'is_student') &&
    isEmptyOrFalse(fields, 'is_retired') &&
    isEmptyOrFalse(fields, 'has_other_financial_situation')
  ) {
    if (formIsValid) {
      scroll('professional_situation')
    }
    formIsValid = false
    errors['professional_situation'] = t(
      'AT_LEAST_ONE_CHECKBOX_MUST_BE_CHECKED'
    )
  }

  if (
    fields.hasOwnProperty('has_other_financial_situation') &&
    fields['has_other_financial_situation']
  ) {
    if (isEmpty(fields, 'financial_situation')) {
      if (formIsValid) {
        scroll('profession')
      }
      formIsValid = false
      errors['financial_situation'] = t('CANNOT_BE_EMPTY')
    }
  } else {
    fields['financial_situation'] = null
    fields['has_other_financial_situation'] = false
  }

  if (isEmpty(fields, 'profession')) {
    if (formIsValid) {
      scroll('profession')
    }
    formIsValid = false
    errors['profession'] = t('CANNOT_BE_EMPTY')
  }

  if (isEmpty(fields, 'monthly_gross_income_amount')) {
    if (formIsValid) {
      scroll('monthly_gross_income_amount')
    }
    formIsValid = false
    errors['monthly_gross_income_amount'] = t('CANNOT_BE_EMPTY')
  }

  if (isEmpty(fields, 'total_assets_amount')) {
    if (formIsValid) {
      scroll('total_assets_amount')
    }
    formIsValid = false
    errors['total_assets_amount'] = t('CANNOT_BE_EMPTY')
  }

  if (
    isEmptyOrFalse(fields, 'origin_of_invested_assets_is_salary') &&
    isEmptyOrFalse(fields, 'origin_of_invested_assets_is_investments') &&
    isEmptyOrFalse(fields, 'origin_of_invested_assets_is_inheritance') &&
    isEmptyOrFalse(fields, 'origin_of_invested_assets_is_other')
  ) {
    if (formIsValid) {
      scroll('investment_capital_origin')
    }
    formIsValid = false
    errors['investment_capital_origin'] = t(
      'AT_LEAST_ONE_CHECKBOX_MUST_BE_CHECKED'
    )
  }

  if (
    isEmptyOrFalse(fields, 'goal_of_investment_is_generate_returns') &&
    isEmptyOrFalse(fields, 'goal_of_investment_is_savings') &&
    isEmptyOrFalse(fields, 'goal_of_investment_is_pension') &&
    isEmptyOrFalse(fields, 'goal_of_investment_is_other')
  ) {
    if (formIsValid) {
      scroll('purpose_of_investment')
    }
    formIsValid = false
    errors['purpose_of_investment'] = t('AT_LEAST_ONE_CHECKBOX_MUST_BE_CHECKED')
  }

  if (
    fields.hasOwnProperty('is_familiar_with_insurance_investments') &&
    fields['is_familiar_with_insurance_investments']
  ) {
    if (isEmpty(fields, 'insurance_investments_specification')) {
      if (formIsValid) {
        scroll('insurance_investments_specification')
      }
      formIsValid = false
      errors['insurance_investments_specification'] = t('CANNOT_BE_EMPTY')
    }
  } else {
    fields['insurance_investments_specification'] = null
    fields['is_familiar_with_insurance_investments'] = false
  }

  if (
    fields.hasOwnProperty('is_familiar_with_investment_funds') &&
    fields['is_familiar_with_investment_funds']
  ) {
    if (isEmpty(fields, 'investment_funds_specification')) {
      if (formIsValid) {
        scroll('investment_funds_specification')
      }
      formIsValid = false
      errors['investment_funds_specification'] = t('CANNOT_BE_EMPTY')
    } else {
    }
  } else {
    fields['investment_funds_specification'] = null
    fields['is_familiar_with_investment_funds'] = false
  }

  if (
    fields.hasOwnProperty('origin_of_invested_assets_is_other') &&
    fields['origin_of_invested_assets_is_other']
  ) {
    if (isEmpty(fields, 'origin_of_invested_assets_specification')) {
      if (formIsValid) {
        scroll('origin_of_invested_assets_specification')
      }
      formIsValid = false
      errors['origin_of_invested_assets_specification'] = t('CANNOT_BE_EMPTY')
    }
  } else {
    fields['origin_of_invested_assets_specification'] = null
    fields['origin_of_invested_assets_is_other'] = false
  }

  if (
    fields.hasOwnProperty('goal_of_investment_is_other') &&
    fields['goal_of_investment_is_other']
  ) {
    if (isEmpty(fields, 'goal_of_investment_specification')) {
      if (formIsValid) {
        scroll('goal_of_investment_specification')
      }
      formIsValid = false
      errors['goal_of_investment_specification'] = t('CANNOT_BE_EMPTY')
    }
  } else {
    fields['goal_of_investment_specification'] = null
    fields['goal_of_investment_is_other'] = false
  }

  if (isEmpty(fields, 'annual_investment_count')) {
    if (formIsValid) {
      scroll('annual_investment_count')
    }
    formIsValid = false
    errors['annual_investment_count'] = t('CANNOT_BE_EMPTY')
  }

  if (isEmpty(fields, 'annual_investment_amount')) {
    if (formIsValid) {
      scroll('annual_investment_amount')
    }
    formIsValid = false
    errors['annual_investment_amount'] = t('CANNOT_BE_EMPTY')
  }

  if (isEmpty(fields, 'risk_profile')) {
    if (formIsValid) {
      scroll('risk_profile')
    }
    formIsValid = false
    errors['risk_profile'] = t('CANNOT_BE_EMPTY')
  }

  setErrors(errors)

  return formIsValid
}

const isEmpty = (fields, field) =>
  !fields.hasOwnProperty(field) ||
  fields[field] === '' ||
  fields[field] === null
const isEmptyOrFalse = (fields, field) =>
  isEmpty(fields, field) || !fields[field]

interface MapStateToProps {
  is_fetching: boolean
  error: any
  force_aml_fill_in: boolean
  amlData: any
}

interface MapDispatchToProps {
  postAmlData: (data, forceAmlFillIn) => void
  getAmlData: () => void
}

const mapStateToProps = (state: State): MapStateToProps => ({
  is_fetching: state.user.investor.aml.is_fetching,
  force_aml_fill_in: state.user.profile.data.force_aml_fill_in,
  error: state.user.investor.aml.error,
  amlData: state.user.investor.aml,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MapDispatchToProps => ({
  postAmlData: (data, forceAmlFillIn) =>
    dispatch(postAML(data, forceAmlFillIn)),
  getAmlData: () => dispatch(getAML()),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MapStateToProps, MapDispatchToProps, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(AmlPresentation)
