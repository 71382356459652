import {
  createFetchSignicatAuthUrlRequestAction,
  createFetchSignicatAuthUrlSuccessAction,
  createFetchSignicatAuthUrlFailureAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import Signicat from '../../../../../api/auth/Signicat'
import { State } from '../../../../index'
import RedirectData from '../../../../../models/Auth/Signicat/RedirectData'

export default (
  method: string,
  goal: string
): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const body: RedirectData = {
    method,
    goal,
    language: 'fi',
    accepted_tc: getState().auth.register.terms_and_conditions.data.version,
    affiliate_name: getState().marketing.affiliate.borrower_affiliate_name
      ? getState().marketing.affiliate.borrower_affiliate_name
      : getState().marketing.affiliate.investor_affiliate_name,
    unique_affiliate_id: getState().marketing.affiliate.borrower_unique_code
      ? getState().marketing.affiliate.borrower_unique_code
      : getState().marketing.affiliate.investor_unique_code,
    blaId: getState().broker.loanApplication.blaId,
  }

  dispatch(createFetchSignicatAuthUrlRequestAction())
  try {
    const signicat = new Signicat()
    const response: AxiosResponse = await signicat.getRedirectUrl(body)
    dispatch(createFetchSignicatAuthUrlSuccessAction(response.data.data))
    window.location.href = response.data.data.redirect_url
  } catch (error) {
    dispatch(createFetchSignicatAuthUrlFailureAction(error))
  }
}
