import React from 'react'
import { cx, unitType } from './utils'

interface Props {
  day: any
  calendar: any
  startDate: any
  range: any
  minDate: any
  maxDate: any
  onDayMouseEnter: (day: any) => void
  onDayClick: (day: any) => void
}

export class DayCell extends React.Component<Props> {
  handleOnMouseEnter = () => {
    const { onDayMouseEnter, day } = this.props
    if (onDayMouseEnter) {
      onDayMouseEnter(day)
    }
  }
  handleOnClick = () => {
    const { onDayClick, day } = this.props
    if (onDayClick) {
      onDayClick(day)
    }
  }

  render() {
    const { day } = this.props
    const className = cx({ ...this.props })
    return (
      <td
        className={className}
        onClick={this.handleOnClick}
        onMouseEnter={this.handleOnMouseEnter}
      >
        {day.format('DD')}
      </td>
    )
  }
}

interface WeekCellProps {
  week: any
}

export class WeekCell extends React.Component<WeekCellProps> {
  render() {
    const { week } = this.props
    return <td className="week">{week}</td>
  }
}

export const cellMapper = {
  [unitType.DAY]: DayCell,
  [unitType.WEEK]: WeekCell,
}
