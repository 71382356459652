import React from 'react'
import { Link } from 'react-router-dom'
import LoginType from './LoginType'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import ic_lock from 'assets/images/ic_lock.svg'

interface ComponentProps {
  type: LoginType
}

interface Props extends WithTranslation, ComponentProps {}

function getRouteByType(type: LoginType) {
  switch (type) {
    case LoginType.LOGIN_EMAIL:
      return '/login/email'
    case LoginType.LOGIN_PHONE_NUMBER:
      return '/login/sms'
    case LoginType.LOGIN_SCA:
      return '/login/bank'
    default:
      return '/login'
  }
}

const LoginLink = (props: Props) => {
  const { t, type } = props
  return (
    <div className="login-link d-flex text-left px-15">
      <img src={ic_lock} className="mr-2" alt={t('LOGIN')} />
      <Link to={getRouteByType(type)}>{t(type)}</Link>
    </div>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(LoginLink)
