import React, { useState, useEffect } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../../store'
import { Button, Container } from 'reactstrap'
import Indicator from '../../../shared/Indicator'
import ProfileData, { Company } from '../../../../models/Auth/ProfileData'
import Contract from '../../../shared/Contract'
import { postSignContract } from '../../../../store/user/investor/contract/actions/postSignContract'
import { getContract } from '../../../../store/user/investor/contract/actions/getContract'
import browserHistory from 'browserHistory'
import { postSignTermsAndConditionsPreRelease } from 'store/auth/register/terms_and_conditions/actions/postSignTermsAndConditionsPreRelease'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { postTermsAndCondtionAndPriceList } from 'store/user/investor/termsAndCondtionAndPriceList/actions/postTermsAndCondtionAndPriceList'

interface Props extends WithTranslation, MapStateToProps, MapDispatchToProps {}

const SignCompnayContract = (props: Props) => {
  const {
    t,
    isFetching,
    data,
    signContract,
    getContract,
    contractHtml,
    company,
    SignTermsAndConditionsPreRelease,
    postTermsAndCondtionAndPriceList,
    isTermsAndConditionAndPriceListAccepted,
  } = props
  const [fields, setFields] = useState({ acceptRisk: false, acceptLose: false })
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (data.id) getContract(company?.deposits[0].mambu_deposit_id)
  }, [company, data, getContract])

  const signContractAndTermsAndConditions = (
    depositId?: string,
    companyId?: number
  ) => {
    SignTermsAndConditionsPreRelease(true)
    signContract(depositId, companyId)
  }

  const toggle1stMarch2021TermsAndConditions = () => {
    history.push(`${location.pathname}/terms-and-conditions-and-price-list`)
  }

  return (
    <Container className="contract__wrapper">
      <div className="text-center pt-5 sign-investor-contract">
        <h1 className="font-50 step-big-title">{t('SIGN_CONTRACT')}</h1>
        <Indicator isVisible={isFetching} />
        <Contract html={contractHtml} />
        <div className="sign-investor-contract__center">
          <div className="check-boxes__wrapper mt-5">
            <div className="checkbox__wrapper">
              <input
                type="checkbox"
                className="accept-risk"
                checked={fields.acceptRisk}
                onChange={() =>
                  setFields({ ...fields, acceptRisk: !fields.acceptRisk })
                }
              />
              <span className="ml-2 text-left">
                {t('SIGN_CONTRACT_INVESTMENT_TEXT')}
              </span>
            </div>
            <div className="checkbox__wrapper">
              <input
                className="accept-risk"
                type="checkbox"
                disabled={false}
                checked={isTermsAndConditionAndPriceListAccepted}
                onChange={postTermsAndCondtionAndPriceList}
              />
              <div className="ml-2 text-left">
                {`${t('SIGN_CONTRACT_UPCOMING_TEXT_TERMS_AND_CONDITIONS')}`}
                <Button
                  onClick={toggle1stMarch2021TermsAndConditions}
                  color="pep-link"
                  className="link-h-20 height-terms text-left terms-and-conditions-btn"
                >
                  <div>
                    {`${t(
                      'SIGN_CONTRACT_UPCOMING_TERMS_AND_CONDITIONS_AND_PRICE_LIST'
                    )}`}
                    ,&nbsp;
                  </div>
                </Button>
                {`${t(
                  'SIGN_CONTRACT_UPCOMING_TEXT_TERMS_AND_CONDITIONS_MORE_INFO'
                )}`}
              </div>
            </div>
            <div className="checkbox__wrapper">
              <span className="ml-4 text-left">
                {t('INVESTOR_AUTO_INVEST_ACTIVATE_AUTOMACTICALLY')}
                <a className="footer-bold-text" href="mailto:invest@fixura.com">
                  invest@fixura.com
                </a>
              </span>
            </div>
          </div>
        </div>
        <Button
          disabled={
            !(fields.acceptRisk && isTermsAndConditionAndPriceListAccepted)
          }
          onClick={() =>
            signContractAndTermsAndConditions(
              company?.deposits[0].mambu_deposit_id,
              company?.id
            )
          }
          type="submit"
          className="my-5"
        >
          {t('SIGN_CONTRACT_START_INVESTING')}
        </Button>
      </div>
    </Container>
  )
}

interface MapStateToProps {
  isFetching: boolean
  data: ProfileData
  contractHtml?: string
  error: any
  company?: Company
  isTermsAndConditionAndPriceListAccepted: boolean
}

interface MapDispatchToProps {
  signContract: (depositId?: string, companyId?: number) => void
  getContract: (depositId?: string) => void
  SignTermsAndConditionsPreRelease: (isInvestor?: boolean) => void
  postTermsAndCondtionAndPriceList: () => void
}

const mapStateToProps = (state: State): MapStateToProps => {
  const companyId = browserHistory.location.pathname.split('/')[3]
  const investor = state.user.investor
  const company = state.user.profile.data.companies.find(
    (company) => company.id === parseInt(companyId)
  )
  return {
    isFetching: investor.contract.is_fetching || state.user.profile.is_fetching,
    data: state.user.profile.data,
    contractHtml: investor.contract.html,
    error: investor.contract.error,
    company,
    isTermsAndConditionAndPriceListAccepted:
      state.user.investor.termsAndCondtionAndPriceList
        .isTermsAndConditionAndPriceListAccepted,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MapDispatchToProps => ({
  signContract: (depositId, companyId) =>
    dispatch(postSignContract(depositId, companyId)),
  getContract: (depositId) => dispatch(getContract(depositId)),
  SignTermsAndConditionsPreRelease: (isInvestor?: boolean) =>
    dispatch(postSignTermsAndConditionsPreRelease(isInvestor)),
  postTermsAndCondtionAndPriceList: () =>
    dispatch(postTermsAndCondtionAndPriceList()),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MapStateToProps, MapDispatchToProps, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(SignCompnayContract)
