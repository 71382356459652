import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
  getOnFidoRetryRedirectUrlFailureAction,
  getOnFidoRetryRedirectUrlRequestAction,
  getOnFidoRetryRedirectUrlSuccessAction,
} from './creators'
import InvestorAPI from '../../../../../api/user/InvestorAPI'

export const getRetryRedirectLink = (
  state: string
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(getOnFidoRetryRedirectUrlRequestAction())
  try {
    const investorAPI = new InvestorAPI()
    const response = await investorAPI.getRetryRedirectLink(state)
    window.location.href = response.data.data.redirect_url
    dispatch(getOnFidoRetryRedirectUrlSuccessAction(response.data.data.state))
  } catch (error) {
    dispatch(getOnFidoRetryRedirectUrlFailureAction(error))
  }
}
