import { compose } from 'redux'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { withTranslation } from 'react-i18next'
import MappedDispatchModel from './MappedDispatch.model'
import MappedStateModel from './MappedState.model'
import toggleAutoInvestStatus from '../../../../store/user/investor/auto_invest/actions/toggleAutoInvestStatus'
import { State } from '../../../../store'
import Presentation from './Presentation'
import { Company } from 'models/Auth/ProfileData'
import browserHistory from 'browserHistory'

const mapStateToProps = (state: State): MappedStateModel => {
  const companyId = browserHistory.location.pathname.split('/')[3]
  const companies = state.user.profile.data.companies
  const data = state.user.profile.data
  const company: Company | undefined = companyId
    ? companies.find((company) => company.id === parseInt(companyId))
    : undefined

  if (company) {
    return {
      depositId: company ? company.deposits[0]?.mambu_deposit_id : '',
    }
  }
  return {
    depositId: data ? data.deposits[0]?.mambu_deposit_id : '',
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  toggleAutoInvest: (depositId: string, companyId) =>
    dispatch(toggleAutoInvestStatus(depositId, companyId)),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, MappedDispatchModel, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Presentation)
