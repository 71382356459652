import { combineReducers } from 'redux'
import sca_methods, {
  initalState as scaMethodsInitialState,
  State as ScaMethodsState,
} from './sca_methods'
import terms_and_conditions, {
  initialState as termsAndConditionsInitialState,
  State as TermsAndConditionsState,
} from './terms_and_conditions'
import signicat, {
  initialState as signicatInitialState,
  State as SignicatState,
} from './signicat'
import sms, { initialState as smsInitialState, State as SmsState } from './sms'
import onfido, {
  initialState as onfidoInitialState,
  State as OnfidoState,
} from './onfido'

export interface State {
  sca_methods: ScaMethodsState
  terms_and_conditions: TermsAndConditionsState
  signicat: SignicatState
  sms: SmsState
  onfido: OnfidoState
}

export default combineReducers<State>({
  sca_methods,
  terms_and_conditions,
  signicat,
  sms,
  onfido,
})

export const initialState: State = {
  sca_methods: scaMethodsInitialState,
  terms_and_conditions: termsAndConditionsInitialState,
  signicat: signicatInitialState,
  sms: smsInitialState,
  onfido: onfidoInitialState,
}
