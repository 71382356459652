import React from 'react'
import { Th } from './Table'
import { dayjs } from './utils'

interface Props {
  calendar: any
  colSpan: number
  className?: string
  handleSelected: any
  locale: any
  showDropdowns: boolean
}

export default class CalendarTitle extends React.Component<Props> {
  readonly monthSelect: any
  readonly yearSelect: any

  constructor(props: Props) {
    super(props)
    this.monthSelect = React.createRef()
    this.yearSelect = React.createRef()
  }

  static defaultProps = {
    className: 'month',
  }
  handleChange = () => {
    // tslint:disable-next-line:no-this-assignment
    const {
      monthSelect: { current: monthSelect },
      yearSelect: { current: yearSelect },
    } = this
    const options = [
      parseInt(yearSelect.value, 10),
      parseInt(monthSelect.value, 10) + 1,
    ]
    // @ts-ignore
    const newCalendar = dayjs(options)

    const { handleSelected } = this.props
    if (handleSelected) {
      handleSelected(newCalendar)
    }
  }

  render() {
    const { calendar, colSpan, className, locale, showDropdowns } = this.props
    const month = calendar.month()
    const year = calendar.year()
    const titleProps = { colSpan, className }
    let label = calendar.format('MMM YYYY')
    if (showDropdowns) {
      const { monthNames, yearNames } = locale
      label = [
        <select
          className="monthselect"
          key={0}
          ref={this.monthSelect}
          value={month}
          onChange={this.handleChange}
        >
          {monthNames.map((name: any, key: any) => (
            <option key={key} value={key}>
              {name}
            </option>
          ))}
        </select>,
        <select
          className="yearselect"
          key={1}
          ref={this.yearSelect}
          value={year}
          onChange={this.handleChange}
        >
          {yearNames.map((name: any, key: any) => (
            <option key={key} value={name}>
              {name}
            </option>
          ))}
        </select>,
      ]
    }

    return <Th {...titleProps}>{label}</Th>
  }
}
