import { Redirect, Route } from 'react-router'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { setRedirectPathAfterLogin } from '../../utils/auth'

const routesToStoreForRedirectAfterAuthentication = [
  '/borrower/onboarding',
  '/onboarding/investor',
  '/borrower/loan-offer/accept',
]

interface IProps {
  isAuthorized: boolean
  pathname?: string
}
const PrivateRoutePresentation: React.FunctionComponent<IProps> = ({
  children,
  isAuthorized,
  pathname = '',
  ...rest
}) => {
  const location = useLocation()
  const preserveHash = location.hash && pathname.indexOf('#') < 0

  if (routesToStoreForRedirectAfterAuthentication.includes(location.pathname)) {
    setRedirectPathAfterLogin(`${location.pathname}${location.search}`)
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthorized ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `${pathname}${preserveHash ? location.hash : ''}`,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoutePresentation
