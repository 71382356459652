import React from 'react'
import { Col, Row } from 'reactstrap'
import CheckBox from '../shared/CheckBox'
import { useTranslation } from 'react-i18next'
import UpdateUser from '../../models/Auth/User/UpdateUser'
import { State } from '../../store'
import { ThunkDispatch } from 'redux-thunk'
import updateUserProfile from '../../store/user/profile/actions/updateUserProfile'
import { connect } from 'react-redux'

interface Props extends MappedState, MappedDispatch {}

const MarketingConsent = (props: Props) => {
  const {
    isCheckedSMSOffers,
    isCheckedEmailOffers,
    toggleOffersCheckboxes,
  } = props
  const { t } = useTranslation()

  function toggleGetNewsByEmail() {
    toggleOffersCheckboxes({
      is_checked_email_offers: !isCheckedEmailOffers,
      is_checked_sms_offers: isCheckedSMSOffers,
    })
  }

  function toggleGetNewsBySMS() {
    toggleOffersCheckboxes({
      is_checked_email_offers: isCheckedEmailOffers,
      is_checked_sms_offers: !isCheckedSMSOffers,
    })
  }

  return (
    <Row>
      <Col className="my-2" md={12}>
        <div className="marketing__title py-2">
          {t('PROFILE_MARKETING_CONSENT')}
        </div>
        <CheckBox
          isChecked={isCheckedEmailOffers}
          onToggle={toggleGetNewsByEmail}
        >
          {t('PROFILE_MARKETING_CONSENT_EMAIL')}
        </CheckBox>
        <CheckBox isChecked={isCheckedSMSOffers} onToggle={toggleGetNewsBySMS}>
          {t('PROFILE_MARKETING_CONSENT_SMS')}
        </CheckBox>
      </Col>
    </Row>
  )
}

interface MappedState {
  isCheckedEmailOffers: boolean
  isCheckedSMSOffers: boolean
}

interface MappedDispatch {
  toggleOffersCheckboxes: (data: UpdateUser) => void
}

const mapStateToProps = (state: State): MappedState => ({
  isCheckedEmailOffers: state.user.profile.data.is_checked_email_offers,
  isCheckedSMSOffers: state.user.profile.data.is_checked_sms_offers,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  toggleOffersCheckboxes: (data: UpdateUser) =>
    dispatch(updateUserProfile(data)),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(MarketingConsent)
