import {
  createCompanyAgreementsFailureAction,
  createCompanyAgreementsRequestAction,
  createCompanyAgreementsSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import TermsAndConditionsAPI from '../../../../../api/general/TermsAndConditionsAPI'

export default (companyId: number): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch
) => {
  dispatch(createCompanyAgreementsRequestAction())
  try {
    const termsAndConditionsApi = new TermsAndConditionsAPI()
    const response: AxiosResponse = await termsAndConditionsApi.getCompanyTermsAndConditionsAndContractStatus(
      companyId
    )
    dispatch(createCompanyAgreementsSuccessAction(response.data.data))
  } catch (error) {
    dispatch(createCompanyAgreementsFailureAction(error))
  }
}
