import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import {
  createUpdateLoanApplicationFailureAction,
  createUpdateLoanApplicationRequestAction,
  createUpdateLoanApplicationSuccessAction,
} from './creators'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import UpdateLoanOfferRequest from '../../../../../models/Borrower/UpdateLoanOfferRequest'
import { AxiosResponse } from 'axios'
import { State } from '../../../../index'

const updateLoanApplication = (
  data: UpdateLoanOfferRequest
): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  dispatch(createUpdateLoanApplicationRequestAction())

  try {
    const borrowerAPI = new BorrowerAPI()
    const response: AxiosResponse = await borrowerAPI.updateLoanApplication(
      getState().user.borrower.loan_application.data.id,
      data
    )
    dispatch(createUpdateLoanApplicationSuccessAction(response.data.data))
  } catch (error) {
    dispatch(createUpdateLoanApplicationFailureAction(error))
  }
}

export default updateLoanApplication
