import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Row } from 'reactstrap'
import { connect } from 'react-redux'
import postForeignInvestorProvideDetailsData from '../../../../store/auth/register/onfido/actions/postForeignInvestorProvideDetails'
import Indicator from '../../../shared/Indicator'
import Steps from './../Steps'
import FixuraInput from '../../../shared/FixuraInput'
import PostForeignInvestorProvideDetails from '../../../../models/Auth/ForeignInvestorProfileData'
import { State } from '../../../../store'
import { unprocessableEntityErrorHandler } from '../../../../utils'
import { ThunkDispatch } from 'redux-thunk'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import isOverEighteen from '../../../../utils/isOverEighteen'
import CountryInput from '../../../shared/CountryInput'
import RadioGroup from '../../../shared/RadioGroup'
import { RadioElement } from '../../../shared/RadioGroup/Models'
import Columns from '../../../shared/Wrapper/Columns'
import { createToggleTermsAndConditionsAction } from '../../../../store/auth/register/terms_and_conditions/actions/creators'

interface Props extends MappedState, MappedDispatch {}

const ForeignInvestorProvideDetails = (props: Props) => {
  const {
    isFetching,
    postForeignInvestorProvideDetails,
    error,
    isTermsAndConditionsAccepted,
    toggleTermsAndConditionsAction,
  } = props

  const { t } = useTranslation()
  const location = useLocation()

  const localUnprocessableEntityErrorHandler = unprocessableEntityErrorHandler.bind(
    undefined,
    [
      'given_name',
      'family_name',
      'birthdate',
      'line1',
      'postcode',
      'city',
      'country',
      'nationality',
      'taxation_country',
      'is_american',
      'passport_number',
      'passport_issued_country',
      'passport_expiry_date',
      'tax_identification_number',
    ],
    error
  )
  const [fields, setFields] = useState({
    given_name: '',
    family_name: '',
    birthdate: '',
    line1: '',
    line2: '',
    postcode: '',
    city: '',
    country: 'FI',
    nationality: 'FI',
    taxation_country: 'FI',
    passport_number: '',
    passport_issued_country: 'FI',
    passport_expiry_date: '',
    is_checked_terms_and_conditions: isTermsAndConditionsAccepted,
    tax_identification_number: '',
  })

  const [errors, setErrors] = useState(localUnprocessableEntityErrorHandler())
  useEffect(() => {
    setErrors(localUnprocessableEntityErrorHandler())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const localHandleChange = (
    field: string,
    e: React.FormEvent<HTMLInputElement>
  ) => handleChange(field, fields, setFields, e)
  const submitData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleValidation(fields, setErrors, t, isTermsAndConditionsAccepted)) {
      postForeignInvestorProvideDetails(fields)
    }
  }

  const toggleTermsAndConditions = () => {
    toggleTermsAndConditionsAction()
  }

  const IsAmericanCheckElements: RadioElement[] = [
    {
      id: 'yes',
      label: t('YES'),
    },
    {
      id: 'no',
      label: t('NO'),
    },
  ]

  const extractIsAmericanYesNoFromFields = (is_american?: boolean) => {
    if (is_american === true) {
      return 'yes'
    } else if (is_american === false) {
      return 'no'
    }
    return undefined
  }

  const passFields = (id: string) => {
    const newFields = { ...fields, is_american: id === 'yes' }
    setFields(newFields)
    localHandleChange.bind(undefined, 'is_american')
  }

  return (
    <section id="container" className="container borrower-onboarding">
      <Indicator isVisible={isFetching} />
      <Steps currentStepIndex={1} />
      <div className="phone">
        <div className={'d-block'}>
          <h1 className="font-50 step-big-title">
            {t('PROVIDE_DETAILS_TITLE')}!
          </h1>
          <p className="text-center pb-4 mb-4">
            {t('FOREIGN_INVESTOR_PROVIDE_DETAILS_TEXT')}
          </p>
          <Form onSubmit={submitData}>
            <FormGroup className="my-5">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <FixuraInput
                    className="w-100"
                    type="text"
                    placeholder={t('FIRST_NAME')}
                    onChange={localHandleChange.bind(undefined, 'given_name')}
                    hasError={errors.hasOwnProperty('given_name')}
                    errorMessage={errors['given_name']}
                    defaultValue={fields.given_name}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="my-5">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <FixuraInput
                    className="w-100"
                    type="text"
                    placeholder={t('LAST_NAME')}
                    onChange={localHandleChange.bind(undefined, 'family_name')}
                    hasError={errors.hasOwnProperty('family_name')}
                    errorMessage={errors['family_name']}
                    defaultValue={fields.family_name}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="my-5 date-of-birth">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <label>{t('DATE_OF_BIRTH')}</label>
                  <FixuraInput
                    className="w-100"
                    type="date"
                    onChange={localHandleChange.bind(undefined, 'birthdate')}
                    hasError={errors.hasOwnProperty('birthdate')}
                    errorMessage={errors['birthdate']}
                    defaultValue={fields.birthdate}
                    onBlur={() => isOverEighteen(fields.birthdate)}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="my-5">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <FixuraInput
                    className="w-100"
                    type="text"
                    placeholder={t('STREET')}
                    onChange={localHandleChange.bind(undefined, 'line1')}
                    hasError={errors.hasOwnProperty('line1')}
                    errorMessage={errors['line1']}
                    defaultValue={fields.line1}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="my-5">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <FixuraInput
                    className="w-100"
                    type="text"
                    placeholder={t('ZIP_CODE')}
                    onChange={localHandleChange.bind(undefined, 'postcode')}
                    hasError={errors.hasOwnProperty('postcode')}
                    errorMessage={errors['postcode']}
                    defaultValue={fields.postcode}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="my-5">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <FixuraInput
                    className="w-100"
                    type="text"
                    placeholder={t('CITY')}
                    onChange={localHandleChange.bind(undefined, 'city')}
                    hasError={errors.hasOwnProperty('city')}
                    errorMessage={errors['city']}
                    defaultValue={fields.city}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <label>{t('COUNTRY')}</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CountryInput
                    onChange={(country: string) => {
                      setFields({ ...fields, country })
                    }}
                    hasError={errors.hasOwnProperty('country')}
                    errorMessage={errors['country']}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="my-5">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <label>{t('NATIONALITY')}</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CountryInput
                    onChange={(nationality: string) => {
                      setFields({ ...fields, nationality })
                    }}
                    hasError={errors.hasOwnProperty('nationality')}
                    errorMessage={errors['nationality']}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="my-5">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <label>{t('TAXATION_COUNTRY')}</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CountryInput
                    onChange={(taxation_country: string) => {
                      setFields({ ...fields, taxation_country })
                    }}
                    hasError={errors.hasOwnProperty('taxation_country')}
                    errorMessage={errors['taxation_country']}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row className="mb-1">
                <Col lg={{ size: 6, offset: 3 }}>
                  <div>{t('AMERICAN_CITIZEN')}</div>
                </Col>
              </Row>
              <div className="center__field mb-2">
                <Row className="row-bottom-distance text-center">
                  <RadioGroup
                    elements={IsAmericanCheckElements}
                    selectedElementId={extractIsAmericanYesNoFromFields(
                      fields['is_american']
                    )}
                    onChange={passFields}
                    ElementWrapper={Columns}
                  />
                </Row>
              </div>
              <div className="text-center">
                <small className="validation-error">
                  {errors['is_american']}
                </small>
              </div>
              {fields['is_american'] && (
                <>
                  <FormGroup className="my-5">
                    <Row>
                      <Col lg={{ size: 6, offset: 3 }}>
                        <FixuraInput
                          className="w-100"
                          type="text"
                          placeholder={t('TIN')}
                          onChange={localHandleChange.bind(
                            undefined,
                            'tax_identification_number'
                          )}
                          hasError={errors.hasOwnProperty(
                            'tax_identification_number'
                          )}
                          errorMessage={errors['tax_identification_number']}
                          defaultValue={fields.tax_identification_number}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
            </FormGroup>
            <FormGroup className="my-5">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <FixuraInput
                    className="w-100"
                    type="text"
                    placeholder={t('PASSPORT_NUMBER')}
                    onChange={localHandleChange.bind(
                      undefined,
                      'passport_number'
                    )}
                    hasError={errors.hasOwnProperty('passport_number')}
                    errorMessage={errors['passport_number']}
                    defaultValue={fields.passport_number}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="my-5">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <label>{t('PASSPORT_ISSUED_COUNTRY')}</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CountryInput
                    onChange={(passport_issued_country: string) => {
                      setFields({ ...fields, passport_issued_country })
                    }}
                    hasError={errors.hasOwnProperty('passport_issued_country')}
                    errorMessage={errors['passport_issued_country']}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="my-5 date-of-birth">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <label>{t('PASSPORT_EXPIRY_DATE')}</label>
                  <FixuraInput
                    className="w-100"
                    type="date"
                    onChange={localHandleChange.bind(
                      undefined,
                      'passport_expiry_date'
                    )}
                    hasError={errors.hasOwnProperty('passport_expiry_date')}
                    errorMessage={errors['passport_expiry_date']}
                    defaultValue={fields.passport_expiry_date}
                    onBlur={() => isOverEighteen(fields.passport_expiry_date)}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <div className="d-flex text-left">
                    <div>
                      <input
                        className="terms-and-conditions__checkbox__color"
                        type="checkbox"
                        disabled={false}
                        checked={
                          isTermsAndConditionsAccepted ||
                          fields.is_checked_terms_and_conditions
                        }
                        onChange={toggleTermsAndConditions}
                      />
                    </div>
                    <div className="ml-3 accept-term">
                      <Link to={`${location.pathname}/terms-and-conditions`}>
                        {t('TERMS_AND_CONDITIONS_PRICE_LIST_PRIVACY_POLICY')}
                      </Link>
                      <div onClick={toggleTermsAndConditions}>
                        {t(
                          'TERMS_AND_CONDITIONS_PRICE_LIST_PRIVACY_POLICY_READ_AND_ACCEPTED'
                        )}
                      </div>
                      {errors.hasOwnProperty(
                        'is_checked_terms_and_conditions'
                      ) && (
                        <div>
                          <small className="text-danger">
                            {errors['is_checked_terms_and_conditions']}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <Row>
              <Col className="text-center">
                <Button type="submit" className="mt-5 min-w-10">
                  {t('CONTINUE')}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </section>
  )
}

function handleChange(
  field,
  fields,
  setFields,
  e: React.FormEvent<HTMLInputElement>
) {
  fields[field] = e.currentTarget.value
  setFields(fields)
}

function handleValidation(fields, setErrors, t, isTermsAndConditionsAccepted) {
  const errors = {}
  let formIsValid = true

  //field validation
  if (!fields['given_name']) {
    formIsValid = false
    errors['given_name'] = t('CANNOT_BE_EMPTY')
  }

  if (!fields['family_name']) {
    formIsValid = false
    errors['family_name'] = t('CANNOT_BE_EMPTY')
  }

  if (!fields['birthdate']) {
    formIsValid = false
    errors['birthdate'] = t('CANNOT_BE_EMPTY')
  }

  //Fixme TODO safari
  /*   if (fields['birthdate'] && !isOverEighteen(fields.birthdate)) {
    formIsValid = false
    errors['birthdate'] = t('IS_UNDER_EIGHTEEN')
  } */

  if (!fields['line1']) {
    formIsValid = false
    errors['line1'] = t('CANNOT_BE_EMPTY')
  }

  if (!fields['postcode']) {
    formIsValid = false
    errors['postcode'] = t('CANNOT_BE_EMPTY')
  }

  if (!fields['city']) {
    formIsValid = false
    errors['city'] = t('CANNOT_BE_EMPTY')
  }

  if (!fields.hasOwnProperty('is_american') || fields['is_american'] === null) {
    formIsValid = false
    errors['is_american'] = t('CANNOT_BE_EMPTY')
  }

  if (fields['is_american'] && !fields['tax_identification_number']) {
    formIsValid = false
    errors['tax_identification_number'] = t('CANNOT_BE_EMPTY')
  }

  if (!fields['passport_number']) {
    formIsValid = false
    errors['passport_number'] = t('CANNOT_BE_EMPTY')
  }

  if (!fields['passport_issued_country']) {
    formIsValid = false
    errors['passport_issued_country'] = t('CANNOT_BE_EMPTY')
  }

  if (!fields['passport_expiry_date']) {
    formIsValid = false
    errors['passport_expiry_date'] = t('CANNOT_BE_EMPTY')
  }
  // eslint-disable-next-line
  if (
    !fields['is_checked_terms_and_conditions'] &&
    !isTermsAndConditionsAccepted
  ) {
    errors['is_checked_terms_and_conditions'] = t('PLEASE_CHECK_TERMS')
    formIsValid = false
  }

  setErrors(errors)
  return formIsValid
}

interface MappedState {
  isFetching: boolean
  error: any
  isTermsAndConditionsAccepted: boolean
}

interface MappedDispatch {
  postForeignInvestorProvideDetails: (
    data: PostForeignInvestorProvideDetails
  ) => void
  toggleTermsAndConditionsAction: () => void
}

const mapStateToProps = (state: State): MappedState => ({
  isFetching: state.auth.register.onfido.is_fetching,
  error: state.auth.register.onfido.error,
  isTermsAndConditionsAccepted:
    state.auth.register.terms_and_conditions.is_accepted,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  postForeignInvestorProvideDetails: (data) =>
    dispatch(postForeignInvestorProvideDetailsData(data)),
  toggleTermsAndConditionsAction: () =>
    dispatch(createToggleTermsAndConditionsAction()),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ForeignInvestorProvideDetails)
