import * as React from 'react'

interface Props {
  spinnerHeight?: number
  spinnerWidth?: number
}

const Spinner = (props: Props) => {
  const sWidth = props.spinnerWidth || 100
  const sHeight = props.spinnerHeight || 100

  return (
    <div id="loading-bar-spinner" className="spinner">
      <div
        className="spinner-icon"
        style={{ width: sWidth, height: sHeight }}
      ></div>
    </div>
  )
}

export default Spinner
