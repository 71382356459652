import React from 'react'

interface Props {
  isChecked: boolean
  onToggle: () => void
}

const CheckBox = ({
  isChecked,
  onToggle,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <div className="d-flex my-2 pl-3 align-items-center">
      <input
        type="checkbox"
        className="checkbox__input"
        checked={isChecked}
        onChange={onToggle}
      />
      <span className="ml-2">{children}</span>
    </div>
  )
}

export default CheckBox
