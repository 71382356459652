import { connect } from 'react-redux'
import { State } from '../../store'
import { getFixuraToken } from '../../utils/auth'
import PrivateRoutePresentation from './PrivateRoutePresentation'

export default connect((state: State) => {
  const { profile } = state.user
  return {
    isAuthorized:
      profile.data.id !== 0 || profile.is_fetching || !!getFixuraToken(),
    pathname: '/register',
  }
})(PrivateRoutePresentation)
