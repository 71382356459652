import { compose } from 'redux'
import { connect } from 'react-redux'
import Presentation from './Presentation'
import { State } from '../../../../store'
import { withTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import browserHistory from 'browserHistory'

const mapStateToProps = (state: State): MappedStateModel => {
  const { data } = state.legacy.borrower.loan
  const selectedLoanId = browserHistory.location.pathname.split('/').pop()
  if (selectedLoanId) {
    // @ts-ignore
    const loan = data.find((loan) => loan.Reference === selectedLoanId)
    if (loan) {
      return {
        remainingDebt: loan.RemainingDebt,
        loanAccountState: loan.loanAccountState,
        interestRate: String(loan.InterestRate),
        loanAmount: loan.LoanAmount,
        monthlyPayment: String(loan.LoanPlan[0].TotalAmount),
        dueDate: new Date(loan.LoanPlan[0].DueDate).getDate(),
        islegacy: loan.LoanPlan[0] ? true : false,
      }
    }
  }
  return {
    isHidden: true,
  }
}

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, any, any, any>(mapStateToProps)
)

export default enhancer(Presentation)
