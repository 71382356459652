import { Route } from 'react-router'
import React from 'react'
import Dashboard from '../components/Dashboard'
import Profile from '../components/Profile'
import Investor from '../components/Investor'
import Borrower from './Borrower'
import WithdrawModal from '../components/Investor/CollectFunds/WithdrawModal'
import AutoinvestModal from '../components/Investor/PortfolioSettings/AutoinvestModal'
import AddCompanyModal from 'components/Investor/AddCompanyModal'

const DashboardView = () => {
  return (
    <div id="container">
      <Route exact path="/dashboard" component={Dashboard} />
      <Route path="/dashboard/borrower" component={Borrower} />

      <Route exact path="/dashboard/investor">
        <Investor />
      </Route>

      <Route exact path="/dashboard/company/:companyId">
        <Investor />
      </Route>

      <Route exact path="/dashboard/company/:companyId/investor">
        <Investor />
      </Route>

      <Route path="/dashboard/investor/fixed-rate-portfolio/:userId?">
        <Investor />
      </Route>

      <Route path="/dashboard/company/fixed-rate-portfolio/:userId?">
        <Investor />
      </Route>

      <Route path="/dashboard/investor/fair-credit-portfolio">
        <Investor />
      </Route>

      <Route
        path="/dashboard/investor/fair-credit-portfolio/withdraw"
        component={WithdrawModal}
      />

      <Route
        path="/dashboard/investor/fair-credit-portfolio/autoinvest"
        component={AutoinvestModal}
      />

      <Route path="/dashboard/company/:companyId/investor/fair-credit-portfolio">
        <Investor />
      </Route>

      <Route
        path="/dashboard/company/:companyId/investor/fair-credit-portfolio/withdraw"
        component={WithdrawModal}
      />

      <Route
        path="/dashboard/company/:companyId/investor/fair-credit-portfolio/autoinvest"
        component={AutoinvestModal}
      />

      <Route
        path="/dashboard/add-company/:companyId?"
        component={AddCompanyModal}
      />

      <Route
        path="/dashboard/:userId?/add-company/:companyId?"
        component={AddCompanyModal}
      />

      <Route
        path="/dashboard/:companyId/investor/add-company/:companyId?"
        component={AddCompanyModal}
      />

      <Route path="/dashboard/profile" component={Profile} />
    </div>
  )
}

export default DashboardView
