import API from '../API'

export default class BorrowerAPI extends API {
  public getLegacyLoans() {
    return this.get(`api/legacy/loans`)
  }

  public getLegacyInvoice(id: number | string, legacy_user_id?: number) {
    const params = { legacy_user_id, id }
    return this.get(`api/legacy/invoice?invoiceId`, params)
  }
}
