import { connect } from 'react-redux'
import Presentation from './Presentation'
import MappedStateModel from './MappedState.model'
import { State } from '../../../../store'

const mapStateToProps = (state: State): MappedStateModel => {
  const loanAccountState = state.user.borrower.loan.data?.loanAccountState
  const dueData = state.user.borrower.loan.data?.next_instalment?.due_date
  return {
    dueData,
    loanAccountState,
  }
}

export default connect(mapStateToProps)(Presentation)
