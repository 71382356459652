import { connect } from 'react-redux'
import { State } from '../../../../store'
import PrivateRoutePresentation from '../../PrivateRoutePresentation'

export default connect((state: State) => ({
  isAuthorized:
    state.user.profile.data.force_aml_fill_in ||
    state.user.profile.data.deposits.length === 0,
  pathname: '/onboarding/investor/contract',
}))(PrivateRoutePresentation)
