import {
  createForeignInvestorProvideDetailsFailureAction,
  createForeignInvestorProvideDetailsRequestAction,
  createForeignInvestorProvideDetailsSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import OnFidoRegisterAPI from '../../../../../api/auth/OnFidoRegisterAPI'
import PostForeignInvestorProvideDetails from '../../../../../models/Auth/ForeignInvestorProfileData'
import { State } from '../../../../index'

export default (data): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  dispatch(createForeignInvestorProvideDetailsRequestAction(data))
  try {
    const { request_body } = getState().auth.register.onfido
    const onFidoApi = new OnFidoRegisterAPI()
    const { language } = getState().user.profile.data
    const terms_and_conditions = getState().auth.register.terms_and_conditions
      .is_accepted
    const body: PostForeignInvestorProvideDetails = {
      given_name: request_body && request_body && request_body.given_name,
      family_name: request_body && request_body.family_name,
      birthdate: request_body && request_body.birthdate,
      line1: request_body && request_body.line1,
      line2: request_body && request_body.line2,
      city: request_body && request_body.city,
      postcode: request_body && request_body.postcode,
      country: request_body && request_body.country,
      nationality: request_body && request_body.nationality,
      taxation_country: request_body && request_body.taxation_country,
      passport_number: request_body && request_body.passport_number,
      passport_issued_country:
        request_body && request_body.passport_issued_country,
      passport_expiry_date: request_body && request_body.passport_expiry_date,
      is_american: request_body && request_body.is_american,
      tax_identification_number:
        request_body && request_body.tax_identification_number,
      is_checked_terms_and_conditions: terms_and_conditions,
      language,
      method: 'onfido',
    }
    const response: AxiosResponse = await onFidoApi.postForeignInvestorProvideDetailsData(
      body ? body : data
    )
    window.location.href = response.data.data.redirect_url
    dispatch(
      createForeignInvestorProvideDetailsSuccessAction(response.data.data.state)
    )
  } catch (error) {
    dispatch(createForeignInvestorProvideDetailsFailureAction(error))
  }
}
