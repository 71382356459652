import React, { useEffect } from 'react'
import Indicator from '../../shared/Indicator'
import getActiveLoanApplication from '../../../store/user/borrower/loan_application/actions/getActiveLoanApplication'
import { ThunkDispatch } from 'redux-thunk'
import postTinkCode from '../../../store/user/borrower/loan_application/actions/postTinkCode'
import { Action } from 'redux'
import { connect } from 'react-redux'
import { State } from '../../../store'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router'

interface Props extends MappedState, MappedDispatch {}

const TinkCallback = (props: Props) => {
  const { loanApplicationId, getActiveLoanApplication, postTinkCode } = props
  const { search } = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (loanApplicationId === 0) {
      getActiveLoanApplication()
    } else {
      const qs = queryString.parse(search)
      const { code } = qs
      if (code && typeof code === 'string') {
        postTinkCode(code)
      } else {
        history.push('/dashboard/borrower/loan-application')
      }
    }
    // eslint-disable-next-line
  }, [loanApplicationId])
  return <Indicator isVisible={true} />
}

interface MappedState {
  loanApplicationId: number
}

interface MappedDispatch {
  getActiveLoanApplication: () => void
  postTinkCode: (code: string) => void
}

const mapStateToProps = (state: State): MappedState => ({
  loanApplicationId: state.user.borrower.loan_application.data.id,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<State, void, Action>
): MappedDispatch => ({
  getActiveLoanApplication: () => dispatch(getActiveLoanApplication()),
  postTinkCode: (code: string) => dispatch(postTinkCode(code)),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(TinkCallback)
