import React, { useState } from 'react'
import { Col, Container, Row, Toast, ToastBody, Button } from 'reactstrap'
import { RadioElement } from '../../../../shared/RadioGroup/Models'
import Columns from '../../../../shared/Wrapper/Columns'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import RadioGroup from '../../../../shared/RadioGroup'
import { ScrollElement } from '../../../../../utils/scroll'
import { store } from '../../../../../store'

interface ComponentProps {
  setFields: (fields) => void
  fields: any
  errors: any
}

interface Props extends ComponentProps {}

const extractPepYesNoFromFields = (is_pep?: boolean) => {
  if (is_pep === true) {
    return 'yes'
  } else if (is_pep === false) {
    return 'no'
  }
  return undefined
}

const PEPCheck = (props: Props) => {
  const { setFields, fields } = props
  const [show, setShow] = useState(false)
  const { t } = useTranslation()
  const passFields = (id: string) => {
    const newFields = { ...fields, is_pep: id === 'yes' }
    setFields(newFields)
  }
  const toggle = () => setShow(!show)
  const { language } = store.getState().user.profile.data
  const isSwedish = language === 'se'

  const PEPCheckElements: RadioElement[] = [
    {
      id: 'yes',
      label: t('YES'),
    },
    {
      id: 'no',
      label: t('NO'),
    },
  ]

  return (
    <Container className="aml__wrapper">
      <ScrollElement name="is_pep" />
      {show && (
        <div>
          <Toast isOpen={show} className="pep-check__container">
            <ToastBody className="pep-check__body">
              <div className="p-3 pep-check__btn">
                <p className="font-20-body">
                  {t('POLITICAL_EXPOSED_PERSON_TITILE')}
                </p>
              </div>
              <p>{t('POLITICAL_EXPOSED_PERSON_MAIN')}</p>
              <ul>
                <li>{t('POLITICAL_EXPOSED_PERSON_MAIN_LINE_1')}</li>
                <li>{t('POLITICAL_EXPOSED_PERSON_MAIN_LINE_2')}</li>
                <li>{t('POLITICAL_EXPOSED_PERSON_MAIN_LINE_3')}</li>
                <li>{t('POLITICAL_EXPOSED_PERSON_MAIN_LINE_4')}</li>
                <li>{t('POLITICAL_EXPOSED_PERSON_MAIN_LINE_5')}</li>
                <li>{t('POLITICAL_EXPOSED_PERSON_MAIN_LINE_6')}</li>
                <li>{t('POLITICAL_EXPOSED_PERSON_MAIN_LINE_7')}</li>
                <li>{t('POLITICAL_EXPOSED_PERSON_MAIN_LINE_8')}</li>
                <li>{t('POLITICAL_EXPOSED_PERSON_MAIN_LINE_9')}</li>
                <li>{t('POLITICAL_EXPOSED_PERSON_MAIN_LINE_10')}</li>
              </ul>
              <p>{t('POLITICAL_EXPOSED_PERSON_SECOND_MAIN')}</p>
              <p>{t('POLITICAL_EXPOSED_PERSON_SECOND_MAIN_LINE')}</p>
              <ul>
                <li>{t('POLITICAL_EXPOSED_PERSON_SECOND_MAIN_LINE_1')}</li>
                <li>{t('POLITICAL_EXPOSED_PERSON_SECOND_MAIN_LINE_2')}</li>
              </ul>
            </ToastBody>

            <div className="p-3 pep-check__btn">
              <Button onClick={toggle} color="link">
                {t('CLOSE')}
              </Button>
            </div>
          </Toast>
          <div className="modal-backdrop fade show" />
        </div>
      )}
      <Row className="mb-5">
        <Col className="text-center" lg={{ size: 8, offset: 2 }}>
          {isSwedish ? (
            <div className="font-20-body">
              {`${t('PEP_CHECK_QUESTION')}`}
              {`${t('PEP_CHECK_QUESTION_1')}`}
              {/* TODO check me css change ? black color */}
              <Button onClick={toggle} color="pep-link" className="link-h-20">
                <div className="font-20-body link-pep">
                  {`${t('POLITICAL_EXPOSED_PERSON')}`}?
                </div>
              </Button>
            </div>
          ) : (
            <div className="font-20-body">
              {`${t('PEP_CHECK_QUESTION')}`}
              {/* TODO check me css change comma black color */}
              <Button onClick={toggle} color="pep-link" className="link-h-20">
                <div className="font-20-body link-pep">
                  {`${t('POLITICAL_EXPOSED_PERSON')}`},
                </div>
              </Button>
              {`${t('PEP_CHECK_QUESTION_1')}`}
            </div>
          )}
        </Col>
      </Row>
      <Row className="row-bottom-distance text-center">
        <RadioGroup
          elements={PEPCheckElements}
          selectedElementId={extractPepYesNoFromFields(fields['is_pep'])}
          onChange={passFields}
          ElementWrapper={Columns}
        />
      </Row>
    </Container>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>()

export default enhancer(PEPCheck)
