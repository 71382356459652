import React from 'react'
import { DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { INVEST_IN_SAVLEND_LINK } from '../../constants'

interface IProps {
  url: string
  label: string
}

const NavMenuSavelendItem: React.FunctionComponent<IProps> = ({
  url,
  label,
}) => {
  const { t } = useTranslation()

  function redirectToSavelendSite() {
    window.location.assign(INVEST_IN_SAVLEND_LINK)
  }

  return (
    <DropdownItem onClick={() => redirectToSavelendSite()}>
      <Link
        onClick={(e) => {
          e.preventDefault()
        }}
        to={url}
      >
        {t(label)}
      </Link>
    </DropdownItem>
  )
}

export default NavMenuSavelendItem
