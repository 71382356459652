import {
  ACCEPT_TERMS_AND_CONDITIONS,
  GET_TERMS_AND_CONDITIONS_FAILURE,
  GET_TERMS_AND_CONDITIONS_REQUEST,
  GET_TERMS_AND_CONDITIONS_SUCCESS,
  REFUSE_TERMS_AND_CONDITIONS,
  TOGGLE_TERMS_AND_CONDITIONS,
  SIGN_TERMS_AND_CONDITIONS_REQUEST,
  SIGN_TERMS_AND_CONDITIONS_SUCCESS,
  SIGN_TERMS_AND_CONDITIONS_FAILURE,
  SIGN_COMPANY_TERMS_AND_CONDITIONS_REQUEST,
  SIGN_COMPANY_TERMS_AND_CONDITIONS_SUCCESS,
  SIGN_COMPANY_TERMS_AND_CONDITIONS_FAILURE,
  SET_SHOULD_AGREE_WITH_TERMS_AND_CONDITIONS,
} from './actions/types'
import TermsAndConditions from '../../../../models/General/TermsAndConditions'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TERMS_AND_CONDITIONS_REQUEST:
      return { ...state, is_fetching: true }
    case GET_TERMS_AND_CONDITIONS_SUCCESS:
      return { ...initialState, data: action.data }
    case GET_TERMS_AND_CONDITIONS_FAILURE:
      return { ...initialState, error: action.error }
    case ACCEPT_TERMS_AND_CONDITIONS:
      return { ...state, is_accepted: true }
    case REFUSE_TERMS_AND_CONDITIONS:
      return { ...state, is_accepted: false }
    case TOGGLE_TERMS_AND_CONDITIONS:
      return { ...state, is_accepted: !state.is_accepted }
    case SIGN_TERMS_AND_CONDITIONS_REQUEST:
      return { ...state, is_fetching: false }
    case SIGN_TERMS_AND_CONDITIONS_SUCCESS:
      return { ...state, is_fetching: false }
    case SIGN_TERMS_AND_CONDITIONS_FAILURE:
      return { ...state, error: action.error, is_fetching: false }
    case SIGN_COMPANY_TERMS_AND_CONDITIONS_REQUEST:
      return { ...state, is_fetching: false }
    case SIGN_COMPANY_TERMS_AND_CONDITIONS_SUCCESS:
      return { ...state, is_fetching: false }
    case SIGN_COMPANY_TERMS_AND_CONDITIONS_FAILURE:
      return { ...state, error: action.error, is_fetching: false }
    case SET_SHOULD_AGREE_WITH_TERMS_AND_CONDITIONS:
      return {
        ...state,
        should_agree_with_new_terms_and_conditions: action.value,
      }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  is_accepted: false,
  data: {
    id: 0,
    created_at: '',
    updated_at: '',
    version: '',
    blade: '',
    language: '',
    type: '',
  },
  error: null,
  should_agree_with_new_terms_and_conditions: false,
}

export interface State {
  is_fetching: boolean
  data: TermsAndConditions
  is_accepted: boolean
  error: any
  should_agree_with_new_terms_and_conditions: boolean
}
