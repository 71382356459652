import {
  POST_FOREIGN_INVESTOR_PROVIDE_DETAILS_FAILURE,
  POST_FOREIGN_INVESTOR_PROVIDE_DETAILS_REQUEST,
  POST_FOREIGN_INVESTOR_PROVIDE_DETAILS_SUCCESS,
} from './types'
import PostForeignInvestorProvideDetails from '../../../../../models/Auth/ForeignInvestorProfileData'

export const createForeignInvestorProvideDetailsRequestAction = (
  requestBody: PostForeignInvestorProvideDetails
) => ({
  requestBody,
  type: POST_FOREIGN_INVESTOR_PROVIDE_DETAILS_REQUEST,
})
export const createForeignInvestorProvideDetailsSuccessAction = (
  state?: string
) => ({
  state,
  type: POST_FOREIGN_INVESTOR_PROVIDE_DETAILS_SUCCESS,
})
export const createForeignInvestorProvideDetailsFailureAction = (
  error: any
) => ({
  error,
  type: POST_FOREIGN_INVESTOR_PROVIDE_DETAILS_FAILURE,
})
