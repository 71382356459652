import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Button } from 'reactstrap'
import { getUserProfile } from '../../../store/user/profile/actions/getUserProfile'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import { purchase } from '../../../utils/gtm/checkout'
import { useEffect } from 'react'

interface Props extends WithTranslation, MappedDispatch {}

const ThankYou = (props: Props) => {
  const { t, getUserProfile } = props

  // google tag
  useEffect(() => {
    purchase()
  }, [])

  return (
    <div className="thank-you">
      <div className="thank-you-tick__circle">
        <div className="tick-first-part">
          <div className="line-1-shadow" />
          <div className="line-1" />
        </div>
        <div className="tick-second-part">
          <div className="line-2-shadow" />
          <div className="line-2" />
        </div>
      </div>
      <h1>{t('THANK_YOU')}!</h1>
      <h3>{t('LOAN_SUCCESS')}</h3>
      <h6 className="mt-3">
        {t('SIGNED_CONTRACT_HAS_BEEN_SENT_TO_YOUR_EMAIL_ADDRESS')}
      </h6>
      <Button className="mt-5" onClick={() => getUserProfile()}>
        {t('OK')}
      </Button>
    </div>
  )
}

interface MappedDispatch {
  getUserProfile: () => void
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  getUserProfile: () => dispatch(getUserProfile('/dashboard')),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<any, MappedDispatch>(null, mapDispatchToProps)
)

export default enhancer(ThankYou)
