import { SET_BORROWER_AFFILIATE_CODE_AND_NAME } from './types'
import { SET_INVESTOR_AFFILIATE_CODE_AND_NAME } from './types'

export const setBorrowerAffiliateTrackingCode = (
  unique_code,
  affiliate_name
) => ({
  unique_code,
  affiliate_name,
  type: SET_BORROWER_AFFILIATE_CODE_AND_NAME,
})

export const setInvestorAffiliateTrackingCode = (
  unique_code,
  affiliate_name
) => ({
  unique_code,
  affiliate_name,
  type: SET_INVESTOR_AFFILIATE_CODE_AND_NAME,
})
