import { connect } from 'react-redux'
import { State } from '../../store'
import PrivateRoutePresentation from './PrivateRoutePresentation'

export default connect((state: State) => {
  const { data } = state.user.profile
  return {
    isAuthorized:
      data.loans.length > 0 ||
      data.id === 0 ||
      (data.legacy_users.length &&
        data.legacy_users[0].legacy_role === 'borrower'),
    pathname: '/dashboard/borrower/loan-application',
  }
  // @ts-ignore
})(PrivateRoutePresentation)
