import Portfolio from '../../../../../models/Legacy/Portfolio'
import {
  GET_PORTFOLIO_FAILURE,
  GET_PORTFOLIO_REQUEST,
  GET_PORTFOLIO_SUCCESS,
} from './types'

export const createGetPortfolioRequestAction = () => ({
  type: GET_PORTFOLIO_REQUEST,
})
export const createGetPortfolioSuccessAction = (data: Portfolio) => ({
  data,
  type: GET_PORTFOLIO_SUCCESS,
})
export const createGetPortfolioFailureAction = (error: any) => ({
  error,
  type: GET_PORTFOLIO_FAILURE,
})
