import { compose } from 'redux'
import { connect } from 'react-redux'
import Presentation from './Presentation'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../store'
import { withTranslation } from 'react-i18next'
import toggleAutoInvestStatus from '../../../store/user/investor/auto_invest/actions/toggleAutoInvestStatus'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel from './MappedDispatch.model'
import browserHistory from '../../../browserHistory'

const mapStateToProps = (state: State, props): MappedStateModel => {
  const url = browserHistory.location.pathname.split('/')

  const companyId = url[3]
  if (url.includes('/dashboard/company')) {
    // @ts-ignore
    return companyId
  }

  if (companyId) {
    const company = state.user.profile.data.companies.find(
      (company) => company.id === parseInt(companyId)
    )

    const data = state.user.investor.auto_invest.companiesAutoInvest.find(
      (company) => company.company_id === parseInt(companyId)
    )
    if (company && data) {
      const { is_fetching } = state.user.investor.auto_invest
      return {
        isAutoInvestOn: data.is_active,
        isHidden: company.deposits.length === 0 || !data.is_enabled,
        isFetching: is_fetching,
        depositId: company.deposits.length
          ? company.deposits[0].mambu_deposit_id
          : '',
        isSignRight: data.signing_right,
      }
    }
  }

  const { data, is_fetching } = state.user.investor.auto_invest
  return {
    isAutoInvestOn: data.is_active,
    isHidden: state.user.profile.data.deposits.length === 0 || !data.is_enabled,
    isFetching: is_fetching,
    depositId: state.user.profile.data.deposits.length
      ? state.user.profile.data.deposits[0].mambu_deposit_id
      : '',
    isSignRight: data.signing_right,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  toggleAutoInvest: (depositId: string, companyId) =>
    dispatch(toggleAutoInvestStatus(depositId, companyId)),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, MappedDispatchModel, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Presentation)
