import {
  createGetTransactionsRequestAction,
  createGetTransactionsSuccessAction,
  createGetTransactionsFailureAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import InvestorAPI from '../../../../../api/legacy/InvestorAPI'

export default (
  legacyUserId: string
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createGetTransactionsRequestAction())

  try {
    const investorAPI = new InvestorAPI()
    const response = await investorAPI.getTransactions(legacyUserId)
    dispatch(createGetTransactionsSuccessAction(response.data.data))
  } catch (error) {
    dispatch(createGetTransactionsFailureAction(error))
  }
}
