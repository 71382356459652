import { connect } from 'react-redux'
import { State } from '../../store'
import { getFixuraToken, getLegacyData, isLegacy } from '../../utils/auth'
import PrivateRoutePresentation from './PrivateRoutePresentation'

export default connect((state: State) => {
  const { profile } = state.user
  const { auth } = state.legacy
  return {
    isAuthorized: !(
      profile.data.id !== 0 ||
      auth.is_logged_in ||
      profile.is_fetching ||
      !!getFixuraToken()
    ),
    pathname:
      profile.data.id !== 0 ||
      profile.is_fetching ||
      (!!getFixuraToken() && !isLegacy())
        ? '/dashboard'
        : `/restricted/${getLegacyData().role}`,
  }
})(PrivateRoutePresentation)
