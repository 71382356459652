import {
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
} from './actions/types'
import Transaction from '../../../../models/Legacy/Investor/Transaction'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_REQUEST:
      return { ...state, is_fetching: true }
    case GET_TRANSACTIONS_SUCCESS:
      return { ...initialState, data: action.data }
    case GET_TRANSACTIONS_FAILURE:
      return { ...initialState, error: action.error }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  data: [],
  error: null,
}

export interface State {
  is_fetching: boolean
  data: Transaction[]
  error: any
}
