import React from 'react'
import { Col, Row } from 'reactstrap'
import options from './options'
import RadioGroup from '../../../../../shared/RadioGroup'
import Rows from '../../../../../shared/Wrapper/Rows'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { ScrollElement } from '../../../../../../utils/scroll'

interface ComponentProps {
  setFields: (fields) => void
  fields: any
  errors: any
}

interface Props extends WithTranslation, ComponentProps {}

const RiskProfile = (props: Props) => {
  const { t, setFields, fields, errors } = props
  const passFields = (id: string) => {
    setFields({
      ...fields,
      risk_profile: id,
    })
  }

  return (
    <div className=" mb-5">
      <Row className="mb-4">
        <Col>
          <ScrollElement name="risk_profile" />
          <div className="font-20-body">{t('RISK_OF_PROFILE')}</div>
        </Col>
      </Row>
      <RadioGroup
        elements={options}
        selectedElementId={
          fields.hasOwnProperty('risk_profile') && fields['risk_profile']
        }
        onChange={passFields}
        ElementWrapper={Rows}
        hasError={errors.hasOwnProperty('risk_profile')}
        errorMessage={errors['risk_profile']}
      />
    </div>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(RiskProfile)
