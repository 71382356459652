import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Alert } from 'reactstrap'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Indicator from '../../../shared/Indicator'
import CodeInput from '../../CodeInput'
import { State } from '../../../../store'
import browserHistory from '../../../../browserHistory'
import { ThunkDispatch } from 'redux-thunk'
import verifyPhoneNumber from '../../../../store/auth/login/sms/actions/verifyPhoneNumber'
import codeExpire from '../../../../store/auth/login/sms/actions/codeExpire'
import resendSms from '../../../../store/auth/login/sms/actions/resendSms'
import { setSpaceForPhoneNumber, toMinuteSecond } from '../../../../utils'
import { RESENT_SMS_TIME } from '../../../../constants'
import { useHistory } from 'react-router'

interface Props extends WithTranslation, MapStateToProps {
  mobilePhone: string
  isFetching: boolean
  postVerificationCode: (code: string) => void
  codeExpire: (code: string) => void
  resendVerificationCode: () => void
}

const VerifyPhoneNumber = (props: Props) => {
  const {
    t,
    mobilePhone,
    resendVerificationCode,
    postVerificationCode,
    codeExpire,
    isFetching,
    error,
  } = props

  const history = useHistory()
  const [fields, setFields] = useState({})
  const [timeLeft, setTimeLeft] = useState(RESENT_SMS_TIME)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (timeLeft > 0) {
      const timeoutID = setTimeout(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)
      return () => {
        clearTimeout(timeoutID)
      }
    } else {
      codeExpire('expired')
      history.push(`/login/sms/code`)
    }
  }, [codeExpire, history, timeLeft])

  const submitData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleValidation(fields, setErrors, t)) {
      postVerificationCode(fields['code'])
    }
  }

  function resendVerifyCode() {
    resendVerificationCode()
    setTimeLeft(RESENT_SMS_TIME)
  }

  return (
    <div id="container" className="container borrower-onboarding">
      <Indicator isVisible={isFetching} />
      <div className="code">
        <h1 className="font-50 step-big-title">{t('SMS_VERIFICATION_CODE')}</h1>
        {mobilePhone && (
          <p className="text-center mb-5">
            {t('CODE_WAS_SENT_TO')} {setSpaceForPhoneNumber(mobilePhone)}{' '}
            {t('SMS_VERIFICATION_DIDNT_GET_CODE', {
              time: timeLeft > 0 && toMinuteSecond(timeLeft),
            })}
            {'.'}
          </p>
        )}
        <Form className="verification-code__form" onSubmit={submitData}>
          {error && <Alert color="danger">{error}</Alert>}
          <Col className="d-flex justify-content-center align-items-center flex-row">
            <CodeInput
              onChange={(code) => setFields({ code })}
              hasError={errors.hasOwnProperty('code')}
              errorMessage={errors['code']}
            />
          </Col>
          <Col className="d-flex justify-content-center align-items-center flex-column">
            <Button type="submit" className="my-4 min-w-10">
              {t('CONTINUE')}
            </Button>
            {mobilePhone && (
              <Button
                color="link"
                onClick={resendVerifyCode}
                disabled={timeLeft > 0}
              >
                {t('DIDNOT_GET_CODE')}
              </Button>
            )}
            <Button color="link" onClick={browserHistory.goBack}>
              {t('SMS_VERIFICATION_EDIT_PHONE_NUMBER')}
            </Button>
          </Col>
        </Form>
      </div>
    </div>
  )
}

function handleValidation(fields, setErrors, t) {
  const errors = {}
  let formIsValid = true

  if (!fields.hasOwnProperty('code')) {
    formIsValid = false
    errors['code'] = t('CANNOT_BE_EMPTY')
  } else {
    const re = /^[0-9]{4}$/im
    if (!re.test(fields['code'].toLowerCase())) {
      formIsValid = false
      errors['code'] = t('CANNOT_BE_EMPTY')
    }
  }

  setErrors(errors)

  return formIsValid
}

interface MapStateToProps {
  mobilePhone: string
  isFetching: boolean
  error: string
}

interface MapDispatchToProps {
  postVerificationCode: (code: string) => void
  resendVerificationCode: () => void
}

const mapStateToProps = (state: State) => {
  return {
    isFetching: false,
    mobilePhone: state.auth.login.sms.mobile_phone,
    error: state.auth.login.sms.error,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  postVerificationCode: (code: string) => dispatch(verifyPhoneNumber(code)),
  resendVerificationCode: () => dispatch(resendSms()),
  codeExpire: (status: string) => dispatch(codeExpire(status)),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MapStateToProps, MapDispatchToProps, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(VerifyPhoneNumber)
