import React from 'react'
import InvoiceTypeEnum from './InvoiceType.enum'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import ic_flat from 'assets/images/ic_flat.png'
import ic_late from 'assets/images/ic_late.png'
import ic_pdf_white from 'assets/images/ic_pdf_white.svg'

interface Props extends WithTranslation {
  invoiceStatus: InvoiceTypeEnum
}

const InvoiceStatus = (props: Props) => {
  const { invoiceStatus, t } = props
  return (
      <>
        <span className="d-sm-inline-block">{t(invoiceStatus)}</span>
      </>
  )
}

const enhancer = compose(withTranslation())

export default enhancer(InvoiceStatus)
