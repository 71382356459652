import React from 'react'

const PriceList: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared">
      <h3>FIXURA AB OY – PRISLISTA</h3>
      <p>Gällande från och med 15.6.2021</p>
      <p>
        Denna prislista (”Prislista”) gäller för Låneavtal och Investeringsavtal
        från och med 15.6.2021. Innan 1.2.2020 ingångna Låneavtal och
        Investeringsavtal omfattas av de tidigare, 1.3.2019 daterade, Allmänna
        villkor som finns tillgängliga på Fixuras nättjänst.
      </p>
      <br />

      <h3>1. LÅNTAGARE</h3>

      <table className="definitions">
        <tbody>
          <tr>
            <td>
              <b>Låneskötselavgift</b>
            </td>
            <td>
              Låneskötselavgiften är 0,01 % per dag av Lånets ursprungliga
              lånekapital. Låneskötselavgiften debiteras enligt Låneavtalet 0,30
              % per månad eller 3,65 % per år. Låneskötselavgiften kan dock
              högst uppgå till sammanlagt 150,00 € per år. Första årets
              låneskötselavgifter debiteras i samband med utbetalning av lånet.
            </td>
          </tr>
          <tr>
            <td>
              <b>Ändring av förfallodag</b>
            </td>
            <td>5,00 € (max. 4 gånger per år)</td>
          </tr>
          <tr>
            <td>
              <b>Ändring av betalningsplan</b>
            </td>
            <td>50,00 €</td>
          </tr>
          <tr>
            <td>
              <b>Ränteintyg</b>
            </td>
            <td>20,00 €</td>
          </tr>
          <tr>
            <td>
              <b>Saldointyg</b>
            </td>
            <td>10,00 € (kostnadsfri en gång per år)</td>
          </tr>
          <tr>
            <td>
              <b>Returnering av överbetalning</b>
            </td>
            <td>5,00 €</td>
          </tr>
          <tr>
            <td>
              <b>Pappersfaktura</b>
            </td>
            <td>5,00 €</td>
          </tr>
        </tbody>
      </table>

      <br />
      <h3>2. INVESTERARE</h3>
      <table className="definitions">
        <tbody>
          <tr>
            <td>
              <b>Administrationsavgift</b>
            </td>
            <td>50 % av den ränteinkomst som Investeraren fått.</td>
          </tr>
          <tr>
            <td>
              <b>Försäljningsavgift</b>
            </td>
            <td>
              1 % av totalbeloppet på Låneinvesteringar som sålts på
              eftermarknaden.
            </td>
          </tr>
          <tr>
            <td>
              <b>Försäljning av förfallna Lån</b>
            </td>
            <td>
              Fixura strävar till att sälja förfallna lån åt tredje part till
              det bästa möjliga pris.
            </td>
          </tr>
        </tbody>
      </table>

      <br />
      <p>
        För tjänster som inte är listade i Prislistan debiteras en avgift enligt
        de timmar som används för att slutföra begäran. Timpriset för detta är
        70,00 € per timme plus moms.
      </p>
    </div>
  )
}

export default PriceList
