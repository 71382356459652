import React, { useEffect } from 'react'
import { Button } from 'reactstrap'
import browserHistory from '../../../browserHistory'
import { Row, Col } from 'reactstrap'
import { State } from '../../../store'
import { useTranslation } from 'react-i18next'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import fetchBrokerUrl from 'store/user/borrower/loan_application/actions/fetchBrokerUrl'
import patchAffiliateClick from 'store/user/borrower/loan_application/actions/patchAffiliateClick'
import getActiveLoanApplication from 'store/user/borrower/loan_application/actions/getActiveLoanApplication'
import Indicator from 'components/shared/Indicator'

interface Props extends MappedState, MappedDispatch {}

const LoanRejection = (props: Props) => {
  const {
    brokerUrl,
    brokerName,
    loanApplicationId,
    isFetching,
    fetchBrokerUrl,
    patchAffiliateClick,
    getActiveLoans,
  } = props

  const { t } = useTranslation()

  function redirectToBroker() {
    patchAffiliateClick()
    window.open(brokerUrl, '_blank')
  }

  useEffect(() => {
    getActiveLoans()
    if (loanApplicationId) {
      fetchBrokerUrl()
    }
  }, [fetchBrokerUrl, getActiveLoans, loanApplicationId])

  return (
    <div className="loan-rejection__wrapper">
      <Indicator isVisible={isFetching} />
      <aside className="loan-reject-redirect-message__container">
        <h1 className="loan__main-title pb-15">
          {t('LOAN_REJECTION_REDIRECT_TITLE')}
        </h1>
        <p>{t('LOAN_REJECTION_REDIRECT_DETAIL')}</p>
        {brokerUrl ? (
            <p>
          {t('LOAN_REJECTION_REDIRECT_DETAIL_SECOND_LINE', {
            brokerName: brokerName,
          })}
        </p>
        ) : null}
        {brokerUrl ? (
          <Button
            className="redirect-broker-btn"
            color="secondary"
            onClick={redirectToBroker}
          >
            {t('LOAN_REJECTION_REDIRECT_BUTTON', { brokerName: brokerName })}
          </Button>
        ) : null}
        <Row className="redirect-btn">
          <Col>
            <a href={`https://fixura.com/`}>
              {t('LOAN_REJECTION_REDIRECT_MARKETING_SITE')}
            </a>
          </Col>
        </Row>
      </aside>
      {/* Hidden previous loan rejection page */}
      <aside className="loan-reject-message__container hide">
        <h1 className="loan__main-title">{t('LOAN_REJECTION_TITLE')}</h1>
        <p className="loan-rejection__description">
          {t('LOAN_REJECTION_DETAIL')}
        </p>
        <Button
          className="hero light loan-back-to-home__btn"
          color="secondary"
          onClick={() => browserHistory.push('/dashboard')}
        >
          {t('BACK_TO_HOME')}
        </Button>
      </aside>
    </div>
  )
}

interface MappedState {
  brokerUrl: string
  brokerName: string,
  loanApplicationId: number
  isFetching: boolean
}

interface MappedDispatch {
  fetchBrokerUrl: () => void
  patchAffiliateClick: () => void
  getActiveLoans: () => void
}

const mapStateToProps = (state: State): MappedState => ({
  brokerUrl: state.user.borrower.loan_application.broker_url,
  brokerName: state.user.borrower.loan_application.broker_name,
  loanApplicationId: state.user.borrower.loan_application.data.id,
  isFetching: state.user.borrower.loan_application.is_fetching,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  fetchBrokerUrl: () => dispatch(fetchBrokerUrl()),
  patchAffiliateClick: () => dispatch(patchAffiliateClick()),
  getActiveLoans: () => dispatch(getActiveLoanApplication()),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(LoanRejection)
