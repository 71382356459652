import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Table } from 'reactstrap'
import { LinkedCalendar } from '../../shared/RangeDatePicker'
import dayjs from 'dayjs'
import { WithTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel from './MappedDispatch.model'
import TransactionRow from './TransactionRow'
import Types from './Types'
import Indicator from '../../shared/Indicator'
import { ScrollElement } from 'utils/scroll'
import LoadMore from '../../shared/LoadMore'

interface Dates {
  startDate?: dayjs.Dayjs
  endDate?: dayjs.Dayjs
}

interface Props
  extends WithTranslation,
    MappedStateModel,
    MappedDispatchModel {}

const Transactions = (props: Props) => {
  const {
    t,
    transactions,
    getInvestorTransactions,
    getDocument,
    numberOfDepositAccounts,
    isFetching,
    depositId,
  } = props
  const [numberOfLoadedRecords, setNumberOfLoadedRecords] = useState(16)
  const [date, setDate] = useState<Dates>({
    startDate: dayjs().subtract(1, 'month'),
    endDate: dayjs(),
  })
  const [isCalendarVisible, setIsCalendarVisible] = useState(false)
  const [type, setType] = useState<string | undefined>(undefined)
  const onChangeCalendarDate = (date?: Dates) => {
    toggleShowCalendar()
    if (date) {
      setDate(date)
    }
  }
  const toggleShowCalendar = () => setIsCalendarVisible(!isCalendarVisible)
  useEffect(
    () => {
      if (depositId) getInvestorTransactions(date, type, depositId)
    },
    // eslint-disable-next-line
    [numberOfDepositAccounts, type, date]
  )
  const loadMore = () => {
    setNumberOfLoadedRecords(numberOfLoadedRecords + 10)
  }

  return (
    <div className="transactions-background">
      <ScrollElement name="transactions" />
      <Indicator isVisible={isFetching} />
      <Container>
        <Row>
          <Col>
            <h1 className="transactions-header">{t('TRANSACTIONS')}</h1>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="m-auto text-center">
            <Button
              onClick={toggleShowCalendar}
              className="light-grey transactions-buttons"
            >
              {date.startDate && date.startDate.format('DD.MM.YYYY')}
              <span style={{ color: 'rgb(248, 248, 248)' }}> ► </span>
              {date.endDate &&
                (dayjs().date() === date.endDate.date()
                  ? t('TODAY')
                  : date.endDate.format('DD.MM.YYYY'))}
            </Button>
            <LinkedCalendar
              initialDate={date}
              onDatesChange={onChangeCalendarDate}
              showDropdowns={false}
              opens="right"
              isVisible={isCalendarVisible}
              name="transactions"
            />
            <Types selectedOption={type} onClick={setType} />
          </Col>
        </Row>
        <Row>
          <Col md="8" className="m-auto">
            <Table borderless className="m-0">
              <thead>
                <tr className="header_transactions-small-mode">
                  <th className="table-item">{t('DATE')}</th>
                  <th className="table-item">{t('TYPE')}</th>
                  <th className="table-item">{t('FROM')}</th>
                  <th className="table-item">{t('TO')}</th>
                  <th className="table-item text-right">{t('AMOUNT')}</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map(
                  (transaction, index: number) =>
                    index < numberOfLoadedRecords && (
                      <TransactionRow
                        key={index}
                        date={transaction.created}
                        from={transaction.from}
                        to={transaction.to}
                        amount={transaction.amount}
                        type={transaction.type}
                      />
                    )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <LoadMore
          onClick={loadMore}
          isVisible={numberOfLoadedRecords < transactions.length}
        />
        <Row className="hide">
          <Col className="text-center">
            <Button
              onClick={() => getDocument(date, type, depositId)}
              className="transactions-button-margin"
            >
              {t('DOWNLOAD_AS_CSV')}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Transactions
