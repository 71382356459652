export const POST_EMAIL_AND_SMS_REQUEST =
  'auth.register.sms.POST_EMAIL_AND_SMS_REQUEST'
export const POST_EMAIL_AND_SMS_SUCCESS =
  'auth.register.sms.POST_EMAIL_AND_SMS_SUCCESS'
export const POST_EMAIL_AND_SMS_FAILURE =
  'auth.register.sms.POST_EMAIL_AND_SMS_FAILURE'

export const POST_SMS_VERIFICATION_CODE_REQUEST =
  'auth.register.sms.POST_SMS_VERIFICATION_CODE_REQUEST'
export const POST_SMS_VERIFICATION_CODE_SUCCESS =
  'auth.register.sms.POST_SMS_VERIFICATION_CODE_SUCCESS'
export const POST_SMS_VERIFICATION_CODE_FAILURE =
  'auth.register.sms.POST_SMS_VERIFICATION_CODE_FAILURE'
