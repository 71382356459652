import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../../store'
import getBorrowerLoan from '../../../../store/user/borrower/loan/actions/getBorrowerLoan'
import LoanStatusEnum from './LoanStatus.enum'

interface IProps {
  loanId?: number
  legacyLoanId?: number
  loanMambuId?: string
}
interface Props extends MappedState, MappedDispatch, IProps {}

const LoanStatus = (props: Props) => {
  const { loanAccountState, getBorrowerLoan, loanId } = props
  const { t } = useTranslation()

  useEffect(() => {
    if (loanId) {
      getBorrowerLoan(loanId)
    }
  }, [getBorrowerLoan, loanId])

  if (!loanAccountState) {
    return null
  }

  function getLoanStatusAndColorChange(loanAccountState: string) {
    switch (loanAccountState) {
      case LoanStatusEnum.CLOSED:
        return {
          color: 'loanAccountState badge badge__gray',
          value: LoanStatusEnum.PAID,
        }
      case LoanStatusEnum.ACTIVE:
        return {
          color: 'loanAccountState badge badge__green',
          value: LoanStatusEnum.ACTIVE,
        }
      case LoanStatusEnum.DEBT_COLLECTION:
        return {
          color: 'loanAccountState badge badge__red',
          value: LoanStatusEnum.DEBT_COLLECTION,
        }
      default:
        return {
          color: 'loanAccountState badge badge__green',
          value: LoanStatusEnum.ACTIVE,
        }
    }
  }

  //status equal to CLOSED than Paid, rest's Active
  return loanAccountState ? (
    <div className={getLoanStatusAndColorChange(loanAccountState).color}>
      {t(getLoanStatusAndColorChange(loanAccountState).value)}
    </div>
  ) : null
}

interface MappedState {
  loanAccountState?: string
}

interface MappedDispatch {
  getBorrowerLoan: (loanId: number) => void
}

const mapStateToProps = (
  state: State,
  { legacyLoanId, loanId, loanMambuId }: IProps
): MappedState => {
  const data = state.user.borrower.loan.loansStatus
  const legacyData = state.legacy.borrower.loan.data

  if (legacyLoanId) {
    const loan = legacyData.find((loan) => loan.Reference === legacyLoanId)
    if (loan) {
      return {
        loanAccountState: loan.loanAccountState,
      }
    }
  }
  if (loanId) {
    // @ts-ignore
    const loan = data.find((loan) => loan.loan_id === loanMambuId)
    if (loan) {
      return {
        loanAccountState: loan.status,
      }
    }
  }

  return {
    loanAccountState: LoanStatusEnum.ACTIVE,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  getBorrowerLoan: (loanId: number) => dispatch(getBorrowerLoan(loanId)),
})

const enhancer = compose(
  connect<MappedState, MappedDispatch, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(LoanStatus)
