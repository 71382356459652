import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { getBodyContentFromHtml } from '../../../../../utils'
import {
  getContractFailureAction,
  getContractRequestAction,
  getContractSuccessAction,
} from './creators'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import { State } from '../../../../index'

export const getContract = (): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => State
) => {
  const { id } = getState().user.borrower.loan_application.data

  dispatch(getContractRequestAction())
  try {
    const borrowerAPI = new BorrowerAPI()
    const response = await borrowerAPI.getLoanContract(id)
    dispatch(
      getContractSuccessAction(getBodyContentFromHtml(response.data.data.html))
    )
  } catch (error) {
    dispatch(getContractFailureAction(error))
  }
}
