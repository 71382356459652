import { ThunkAction } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import { Dispatch } from 'redux'
import {
  createCreateBankSessionRequestAction,
  createCreateBankSessionSuccessAction,
  createCreateBankSessionFailureAction,
} from './creators'
import { State } from '../../../../index'

const createBankSession = (
  id: string
): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  dispatch(createCreateBankSessionRequestAction())

  try {
    const borrowerAPI = new BorrowerAPI()
    const response: AxiosResponse = await borrowerAPI.createBankSession(
      getState().user.borrower.loan_application.data.id,
      id
    )
    window.location.href = response.data.data.redirect_url
    dispatch(createCreateBankSessionSuccessAction())
  } catch (error) {
    dispatch(createCreateBankSessionFailureAction(error))
  }
}

export default createBankSession
