import {
  createCompanyAmlFailureAction,
  createCompanyAmlRequestAction,
  createCompanyAmlSuccessAction,
} from './creators'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import CompanyInvestorAPI from '../../../../../api/user/CompanyInvestorAPI'
import { getUserProfile } from '../../../profile/actions/getUserProfile'

const postCompanyAml = (
  data,
  companyId
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(createCompanyAmlRequestAction())

  try {
    const companyInvestorAPI = new CompanyInvestorAPI()
    await companyInvestorAPI.postCompanyAml(data, companyId)
    dispatch(createCompanyAmlSuccessAction())
    dispatch(getUserProfile(`/onboarding/company/${companyId}/contract`))
  } catch (error) {
    dispatch(createCompanyAmlFailureAction(error))
  }
}

export default postCompanyAml
