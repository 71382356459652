export const GET_INVESTOR_TRANSACTIONS_REQUEST =
  'user.investor.dashboard.transactions.GET_INVESTOR_TRANSACTIONS_REQUEST'
export const GET_INVESTOR_TRANSACTIONS_SUCCESS =
  'user.investor.dashboard.transactions.GET_INVESTOR_TRANSACTIONS_SUCCESS'
export const GET_INVESTOR_TRANSACTIONS_FAILURE =
  'user.investor.dashboard.transactions.GET_INVESTOR_TRANSACTIONS_FAILURE'
export const STORE_DATES_AND_TYPE =
  'user.investor.dashboard.transactions.STORE_DATES_AND_TYPES'
export const GET_INVESTOR_TRANSACTIONS_CSV_REQUEST =
  'user.investor.dashboard.transactions.GET_INVESTOR_TRANSACTIONS_CSV_REQUEST'
export const GET_INVESTOR_TRANSACTIONS_CSV_SUCCESS =
  'user.investor.dashboard.transactions.GET_INVESTOR_TRANSACTIONS_CSV_SUCCESS'
export const GET_INVESTOR_TRANSACTIONS_CSV_FAILURE =
  'user.investor.dashboard.transactions.GET_INVESTOR_TRANSACTIONS_CSV_FAILURE'
