import { combineReducers } from 'redux'
import register, {
  State as RegisterState,
  initialState as registerInitialState,
} from './register'
import login, {
  State as LoginState,
  initialState as loginInitialState,
} from './login'

export interface State {
  register: RegisterState
  login: LoginState
}

export default combineReducers<State>({
  register,
  login,
})

export const initialState: State = {
  register: registerInitialState,
  login: loginInitialState,
}
