import {
  createGetAutoInvestStatusFailureAction,
  createGetAutoInvestStatusRequestAction,
  createGetAutoInvestStatusSuccessAction,
  createGetCompanyAutoInvestStatusSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import InvestorAPI from '../../../../../api/user/InvestorAPI'

export default (
  depositId: string,
  companyId?: number
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createGetAutoInvestStatusRequestAction())
  try {
    const investorAPI = new InvestorAPI()
    const response = await investorAPI.getAutoInvestStatus(depositId)
    if (companyId) {
      dispatch(
        createGetCompanyAutoInvestStatusSuccessAction(
          response.data.data,
          companyId
        )
      )
    } else {
      dispatch(createGetAutoInvestStatusSuccessAction(response.data.data))
    }
  } catch (error) {
    dispatch(createGetAutoInvestStatusFailureAction(error))
  }
}
