import {
  LOGIN_USER_PASS_FAILURE,
  LOGIN_USER_PASS_REQUEST,
  LOGIN_USER_PASS_SUCCESS,
} from './types'
import Auth from '../../../../models/Legacy/Auth'

export const createLoginWithUserPassRequestAction = () => ({
  type: LOGIN_USER_PASS_REQUEST,
})
export const createLoginWithUserPassSuccessAction = (data: Auth) => ({
  data,
  type: LOGIN_USER_PASS_SUCCESS,
})
export const createLoginWithUserPassFailureAction = (error: any) => ({
  error,
  type: LOGIN_USER_PASS_FAILURE,
})
