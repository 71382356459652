import {
  getAmlFailureAction,
  getAmlRequestAction,
  getAmlSuccessAction,
} from './creators'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import InvestorAPI from '../../../../../api/user/InvestorAPI'
import { AxiosResponse } from 'axios'

const getAML = (): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(getAmlRequestAction())

  try {
    const investorAPI = new InvestorAPI()
    const response: AxiosResponse = await investorAPI.getAml()
    dispatch(getAmlSuccessAction(response.data.data))
  } catch (error) {
    dispatch(getAmlFailureAction(error))
  }
}

export default getAML
