import {
  GET_COMPANY_AGREEMENTS_REQUEST,
  GET_COMPANY_AGREEMENTS_SUCCESS,
  GET_COMPANY_AGREEMENTS_FAILURE,
  POST_SKIP_COMPANY_SIGNED_AGREEMENTS_SUCCESS,
} from './types'
import { Agreements } from '../../../../../models/Auth/ProfileData'

export const createCompanyAgreementsRequestAction = () => ({
  type: GET_COMPANY_AGREEMENTS_REQUEST,
})
export const createCompanyAgreementsSuccessAction = (data: Agreements) => ({
  data,
  type: GET_COMPANY_AGREEMENTS_SUCCESS,
})
export const createCompanyAgreementsFailureAction = (error: any) => ({
  error,
  type: GET_COMPANY_AGREEMENTS_FAILURE,
})
export const createPostSkipCompanySignedAgreementsSuccessAction = () => ({
  type: POST_SKIP_COMPANY_SIGNED_AGREEMENTS_SUCCESS,
})
