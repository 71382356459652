import axios from 'axios'
import { BASE_URL } from '../constants'
import { getFixuraToken } from '../utils/auth'
import { store } from '../store'
import logoutUserWithNoAPICall from '../store/user/profile/actions/logoutUserWithNoAPICall'
import { createSetShouldAgreeWithTermsAndConditionsAction } from '../store/auth/register/terms_and_conditions/actions/creators'

export default class API {
  private apiProvider: any

  constructor() {
    this.apiProvider = axios.create({
      baseURL: BASE_URL,
      timeout: 300000,
      withCredentials: true,
    })
  }

  private errorHandler(error): void {
    if (error.response) {
      const { status } = error.response
      if (status === 401) {
        // @ts-ignore
        store.dispatch(logoutUserWithNoAPICall())
      }
      if (status === 403) {
        const { message } = error.response.data
        if (message === 'Latest terms and conditions has not been signed') {
          /* TODO checkme */
          store.dispatch(createSetShouldAgreeWithTermsAndConditionsAction(true))
        }
      }
    }
    throw error
  }

  private getHeaders(): any {
    let headers: { Authorization?: string } = {}
    // @ts-ignore
    headers['Accept-Language'] = 'en_US'
    // @ts-ignore
    headers['Content-Type'] = 'application/json'
    // @ts-ignore
    headers['Accept'] = 'application/json'

    if (getFixuraToken()) {
      headers.Authorization = `Bearer ${getFixuraToken()}`
    }
    return headers
  }

  public async post(
    url: string,
    body: any,
    headers: any = {},
    baseUrl: string = BASE_URL
  ) {
    const requestHeaders = { ...this.getHeaders(), ...headers }
    try {
      return await this.apiProvider.post(`${baseUrl}/${url}`, body, {
        headers: requestHeaders,
      })
    } catch (error) {
      this.errorHandler(error)
    }
  }

  public async get(
    url: string,
    params: any = {},
    headers: any = {},
    otherOptions: any = {}
  ) {
    const requestHeaders = { ...this.getHeaders(), ...headers }
    try {
      return await this.apiProvider.get(url, {
        params,
        headers: requestHeaders,
        ...otherOptions,
      })
    } catch (error) {
      this.errorHandler(error)
    }
  }

  public async delete(url: string, headers: any = {}) {
    const requestHeaders = { ...this.getHeaders(), ...headers }
    try {
      return await this.apiProvider.delete(url, {
        headers: requestHeaders,
      })
    } catch (error) {
      this.errorHandler(error)
    }
  }

  public async put(url: string, body: any, headers: any = {}) {
    const requestHeaders = { ...this.getHeaders(), ...headers }
    try {
      return await this.apiProvider.put(url, body, {
        headers: requestHeaders,
      })
    } catch (error) {
      this.errorHandler(error)
    }
  }

  public async patch(url: string, body: any, headers: any = {}) {
    const requestHeaders = { ...this.getHeaders(), ...headers }
    try {
      return await this.apiProvider.patch(url, body, {
        headers: requestHeaders,
      })
    } catch (error) {
      this.errorHandler(error)
    }
  }
}
