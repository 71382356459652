import {
  createCompanyFailureAction,
  createCompanyRequestAction,
  createCompanySuccessAction,
} from './creators'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import CompanyInvestorAPI from '../../../../../api/user/CompanyInvestorAPI'
import { getUserProfile } from '../../../profile/actions/getUserProfile'

const postCompany = (businessCode): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(createCompanyRequestAction())

  try {
    const companyInvestorAPI = new CompanyInvestorAPI()
    const response = await companyInvestorAPI.postCompany(businessCode)
    dispatch(createCompanySuccessAction())
    dispatch(
      getUserProfile(`/dashboard/company/add-company/${response.data.data.id}`)
    )
  } catch (error) {
    dispatch(
      createCompanyFailureAction(
        error &&
          error.response &&
          error.response.data &&
          error.response.data.message
      )
    )
  }
}

export default postCompany
