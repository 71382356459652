import { combineReducers } from 'redux'
import kpi, { State as KpiState, initialState as kpiInitialState } from './kpi'
import withdraw, {
  State as WithdrawState,
  initialState as withdrawInitialState,
} from './withdraw'
import transactions, {
  State as TransactionsState,
  initialState as transactionsInitialState,
} from './transactions'

export interface State {
  kpi: KpiState
  withdraw: WithdrawState
  transactions: TransactionsState
}

export default combineReducers<State>({
  kpi,
  withdraw,
  transactions,
})

export const initialState: State = {
  kpi: kpiInitialState,
  transactions: transactionsInitialState,
  withdraw: withdrawInitialState,
}
