import React from 'react'
import { Button } from 'reactstrap'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import browserHistory from '../browserHistory'
import CustomerService from '../components/shared/CustomerService'

/* TODO move this -to component folder */
interface Props extends WithTranslation {}

const OnFidoVerify = (props: Props) => {
  const { t } = props

  return (
    <div className="loan-rejection__wrapper">
      <aside className="on-fido-message__container">
        <h1 className="loan__main-title">{t('ON_FIDO_TITLE')}</h1>
        <p className="loan-rejection__description">{t('ON_FIDO_DETAILS')}</p>
        <Button
          className="hero light loan-back-to-home__btn"
          color="secondary"
          onClick={() => browserHistory.push('/login')}
        >
          {t('BACK_TO_HOME')}
        </Button>
      </aside>

      <CustomerService />
    </div>
  )
}

const enhance = compose(withTranslation())

export default enhance(OnFidoVerify)
