import {
  createGetWithdrawStatusFailureAction,
  createGetWithdrawStatusRequestAction,
  createGetWithdrawStatusSuccessAction,
} from './creators'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import InvestorAPI from '../../../../../../api/user/InvestorAPI'
import { AxiosResponse } from 'axios'

const getWithdrawStatus = (
  depositId
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(createGetWithdrawStatusRequestAction())

  try {
    const investorAPI = new InvestorAPI()
    const response: AxiosResponse = await investorAPI.getWithdrawStatus(
      depositId
    )
    dispatch(createGetWithdrawStatusSuccessAction(response.data.data))
  } catch (error) {
    dispatch(createGetWithdrawStatusFailureAction(error))
  }
}

export default getWithdrawStatus
