import { ThunkAction } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import { Dispatch } from 'redux'
import {
  createFetchBrokerUrlRequestAction,
  createFetchBrokerUrlAction,
  createFetchBrokerUrlFailureAction,
} from './creators'
import { State } from '../../../../index'

const fetchBrokerUrl = (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  dispatch(createFetchBrokerUrlRequestAction())

  try {
    const borrowerAPI = new BorrowerAPI()
    const response: AxiosResponse = await borrowerAPI.fetchBrokerUrl(
      getState().user.borrower.loan_application.data.id
    )
    dispatch(createFetchBrokerUrlAction(response.data))
  } catch (error) {
    dispatch(createFetchBrokerUrlFailureAction(error))
  }
}

export default fetchBrokerUrl
