import React from 'react'
import { Button } from 'reactstrap'
import { WithTranslation, useTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import { ScrollElement } from 'utils/scroll'
import browserHistory from 'browserHistory'
import isDueDatePassed from './checkDueDate'
import LoanStatusEnum from '../LoanStatus/LoanStatus.enum'

interface Props extends WithTranslation, MappedStateModel {}

const DueInstallment = (props: Props) => {
  const { dueData, loanAccountState } = props
  const { t } = useTranslation()

  /* Trigger for this visual component: */
  const isTrigger =
    loanAccountState === 'ACTIVE_IN_ARREARS' ||
    (dueData && isDueDatePassed(dueData))

  if (!isTrigger || loanAccountState === LoanStatusEnum.DEBT_COLLECTION) {
    return null
  }

  return (
    <>
      <ScrollElement name="due-payment" />
      <div className="due-payment">
        <>
          <div className="text-center">
            <h1 className="text-white pt-40">{t('UNPAID_INSTALLMENTS')}</h1>
            <div className="p-10">
              <p className="text-white">{t('PAY_THE_DUE_AMOUNT')}</p>
            </div>
            <Button
              onClick={() =>
                browserHistory.push('/dashboard/borrower#next-installment')
              }
            >
              {t('DUE_PAYMENT')}
            </Button>
          </div>
        </>
      </div>
    </>
  )
}

export default DueInstallment
