import i18next from 'i18next'
import i18nextBrowserLanguagedetector from 'i18next-browser-languagedetector'
import i18nextXhrBackend from 'i18next-xhr-backend'

import fiJson from './translations/fi.json'
import seJson from './translations/se.json'
import enJson from './translations/en.json'
import deJson from './translations/de.json'

const localeNamespace = 'translations'
const defaultLocale = localStorage.getItem('i18nextLng') || 'fi'

i18next
  .use(i18nextXhrBackend)
  .use(i18nextBrowserLanguagedetector)
  .init({
    lng: defaultLocale,
    fallbackLng: defaultLocale, // same fallback as the initial user.locale
    debug: false,
    // have a common namespace used around the full app
    ns: [localeNamespace],
    defaultNS: localeNamespace,
    returnObjects: true,
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    // we init with resources
    resources: {
      fi: { translations: fiJson },
      en: { translations: enJson },
      se: { translations: seJson },
      de: { translations: deJson },
    },
  })

export default i18next
