import API from '../API'

export default class InvestorAPI extends API {
  public getPortfolio(legacyUserId: string) {
    return this.get('api/legacy/portfolio', { legacy_user_id: legacyUserId })
  }

  public getTransactions(legacyUserId: string) {
    return this.get('api/legacy/transactions', { legacy_user_id: legacyUserId })
  }

  public postWithdraw(amount: number, legacyUserId: string) {
    const params = {
      amount,
      legacy_user_id: legacyUserId,
    }
    return this.post('api/legacy/withdraw', params)
  }
}
