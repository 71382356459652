import React from 'react'
import { DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

interface IProps {
  url: string
  label: string
}

const NavMenuItem: React.FunctionComponent<IProps> = ({ url, label }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const location = useLocation()
  const checkUserSelectedCompanyAccount =
    location.pathname.split('/')[2] === 'company'
  const companyId = location.pathname.split('/')[3]
  const investorUrl = `/dashboard/investor/fair-credit-portfolio`
  const companyUrl = `/dashboard/company/${companyId}/investor/fair-credit-portfolio`

  const trigger = (url: any) => {
    const isTakeLoan = url.includes('https://fixura.com/')
    if (isTakeLoan) {
      window.open(url, '_blank')
    } else {
      history.push(url)
    }
  }

  return (
    <DropdownItem onClick={() => trigger(url)}>
      <Link
        onClick={(e) => {
          e.preventDefault()
        }}
        to={url}
        className={
          checkUserSelectedCompanyAccount
            ? companyUrl.includes(url)
              ? 'dropdown-item-checked'
              : ''
            : investorUrl.includes(url)
            ? 'dropdown-item-checked'
            : ''
        }
      >
        {t(label)}
      </Link>
    </DropdownItem>
  )
}

export default NavMenuItem
