import {
  POST_WITHDRAW_FAILURE,
  POST_WITHDRAW_REQUEST,
  POST_WITHDRAW_RESET,
  POST_WITHDRAW_SUCCESS,
  STORE_WITHDRAW_AMOUNT,
} from './types'

export const createWithdrawRequestAction = () => ({
  type: POST_WITHDRAW_REQUEST,
})
export const createWithdrawSuccessAction = (amount: number) => ({
  amount,
  type: POST_WITHDRAW_SUCCESS,
})
export const createWithdrawFailureAction = (error: any) => ({
  error,
  type: POST_WITHDRAW_FAILURE,
})
export const createWithdrawResetAction = () => ({ type: POST_WITHDRAW_RESET })

export const createStoreWithdrawAmountAction = (amount: number) => ({
  amount,
  type: STORE_WITHDRAW_AMOUNT,
})
