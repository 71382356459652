import reactScroll from 'react-scroll'

export const ScrollElement = reactScroll.Element
const scroller = reactScroll.scroller

export function scroll(elementName: string) {
  scroller.scrollTo(elementName, {
    duration: 100,
    smooth: true,
  })
}
