import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PortfolioSummary from './PortfolioSummary'
import { default as LegacyPortfolioSummary } from './PortfolioSummary/legacy'
import AddFunds from './AddFunds'
import InvestorDashboard from './InvestorDashboard'
import CollectFunds from './CollectFunds'
import { default as CollectFundsLegacy } from './CollectFunds/legacy'
import Transactions from './Transactions'
import { default as LegacyTransactions } from './Transactions/legacy'
import CustomerService from '../shared/CustomerService'
import { State } from '../../store'
import StartInvesting from './StartInvesting'
import PortfolioSettings from './PortfolioSettings'
import { withTranslation, WithTranslation } from 'react-i18next'
import WithdrawalSuccessful from './WithdrawalSuccessful'
import { default as WithdrawalSuccessfulLegacy } from './WithdrawalSuccessful/legacy'
import BackButton from '../shared/BackButton'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import browserHistory from '../../browserHistory'
import { useParams } from 'react-router-dom'
import { isLegacy } from 'utils/auth'
import CollectFundsMessage from './CollectFundsMessage'
import useHashLink from 'hooks/useHashLinks'
import {
  LegacyUser,
  Company,
  Agreements,
  CompanyAgreements,
} from 'models/Auth/ProfileData'
import { useHistory } from 'react-router'
import { isOnFidoUserVerified } from 'utils/onFidoUser'
import OnFidoVerify from 'Views/OnFidoVerify'
import { getOnFidoStatus } from '../../store/user/investor/onFido/actions/getOnFidoStatus'
import { ThunkDispatch } from 'redux-thunk'
import getAgreements from '../../store/user/investor/agreements/actions/getAgreements'
import getCompanyAgreements from '../../store/user/investor/companyAgreements/actions/getCompanyAgreements'
import { FAIR_CREDIT } from '../../constants'

interface IProps extends MappedStateModel, MappedDispatch, WithTranslation {}
const Investor = (props: IProps) => {
  const {
    hasLoadedProfile,
    t,
    isFetching,
    legacyUsers,
    companies,
    forceAmlFillIn,
    areFundsCollected,
    getOnFidoStatus,
    isActive,
    hasLoan,
    agreements,
    has_skipped_signed_latest_agreements,
    getAgreements,
    companyAgreements,
    has_skipped_signed_company_latest_agreements,
    getCompanyAgreements,
  } = props
  const { search, pathname, hash } = useLocation()
  const qs = queryString.parse(search)
  const { userId: legacyUserId, companyId } = useParams()

  const legacyInvestorUserId = legacyUserId
    ? legacyUserId
    : legacyUsers[0]?.legacy_role === 'investor'
    ? legacyUsers[0]?.legacy_user_id
    : undefined

  const company: Company | undefined = companyId
    ? companies.find((company) => company.id === parseInt(companyId))
    : undefined

  const hasSignedContract = company
    ? company.deposits.length > 0 && company.deposits[0].has_signed_contract
    : props.hasSignedContract

  const dashboardUrl = companyId
    ? `dashboard/company/${companyId}`
    : 'dashboard'
  const onboardingUrl = companyId
    ? `onboarding/company/${companyId}`
    : 'onboarding'

  const type = pathname.includes('fixed')
    ? 'fixed'
    : pathname.includes('fair')
    ? 'fair'
    : ''

  const isCompany = browserHistory.location.pathname.includes('company')
  const isLegacyCompany = browserHistory.location.pathname.includes(
    '/dashboard/company/fixed-rate-portfolio/'
  )
  const isLegacyInvestor = browserHistory.location.pathname.includes(
    '/dashboard/investor/fixed-rate-portfolio/'
  )

  const history = useHistory()

  const isOnFidoUserVerify = isOnFidoUserVerified()

  useHashLink(isFetching)

  useEffect(() => {
    if (hasLoadedProfile && (!companyId || (companyId && company))) {
      if (
        type === 'fixed' &&
        legacyInvestorUserId &&
        !isLegacyCompany &&
        !isLegacyInvestor
      ) {
        if (
          legacyUsers.length === 0 ||
          legacyUsers[0].legacy_role !== 'investor'
        ) {
          history.replace(`/dashboard/investor${hash}`)
        }
      }
      if (type === FAIR_CREDIT) {
        if (!hasSignedContract) {
          history.replace(`/${dashboardUrl}/investor${hash}`)
        } else if (hasSignedContract) {
          if (forceAmlFillIn) {
            history.push(`/${onboardingUrl}/investor/aml`)
          }
        }
      }
      if (type === '') {
        if (hasSignedContract) {
          if (isCompany) {
            history.push(`/${dashboardUrl}`)
          } else if (forceAmlFillIn) {
            history.push(`/${onboardingUrl}/investor/aml`)
          }
        } else if (
          legacyUsers.length &&
          legacyUsers[0].legacy_role === 'investor' &&
          !companyId
        ) {
          history.replace(
            `/${dashboardUrl}/investor/fixed-rate-portfolio/${legacyInvestorUserId}${hash}`
          )
        }
        if (
          !hasSignedContract &&
          (companyId ||
            legacyUsers.length === 0 ||
            legacyUsers[0].legacy_role !== 'investor')
        ) {
          history.push(`/${onboardingUrl}/investor/aml`)
        }
      }
    }
  }, [
    company,
    companyId,
    dashboardUrl,
    hasLoadedProfile,
    hasSignedContract,
    hash,
    history,
    legacyUserId,
    legacyUsers,
    onboardingUrl,
    type,
    forceAmlFillIn,
    isCompany,
    isLegacyCompany,
    isLegacyInvestor,
    legacyInvestorUserId,
  ])

  useEffect(() => {
    if (
      false &&
      (!agreements.has_signed_latest_investor_contract ||
        !agreements.has_signed_latest_terms_and_conditions) &&
      !has_skipped_signed_latest_agreements &&
      !isCompany
    )
      getAgreements()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      false &&
      (!companyAgreements.has_signed_latest_company_contract ||
        !companyAgreements.has_signed_latest_terms_and_conditions) &&
      !has_skipped_signed_company_latest_agreements &&
      isCompany
    )
      getCompanyAgreements(companyId)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      false &&
      type === FAIR_CREDIT &&
      !isCompany &&
      !agreements.has_signed_latest_investor_contract &&
      !has_skipped_signed_latest_agreements
    ) {
      history.push(`/onboarding/investor/contract`)
    }
  })

  useEffect(() => {
    if (
      false &&
      type === FAIR_CREDIT &&
      isCompany &&
      !companyAgreements.has_signed_latest_company_contract &&
      !has_skipped_signed_company_latest_agreements
    ) {
      history.push(`/onboarding/company/${companyId}/contract`)
    }
  })

  useEffect(() => {
    if (isOnFidoUserVerify) {
      getOnFidoStatus()
    }
  }, [isOnFidoUserVerify, getOnFidoStatus])

  if (isOnFidoUserVerify) {
    return <OnFidoVerify />
  }
  return (
    <div key={companyId} id="container">
      {type && hasLoan && <BackButton />}
      {type === '' && <InvestorDashboard />}
      {qs.msg && qs.msg === 'welcome' && (
        <Modal isOpen centered className="custom-class">
          <ModalBody>
            {t('ONBOARDING_FIRST_IMPRESSION_MODAL_CONTENT')}
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button
              onClick={() =>
                browserHistory.replace(
                  `/${dashboardUrl}/investor/fair-credit-portfolio`
                )
              }
            >
              {t('OK')}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {type === 'fixed' && (
        <div key={legacyInvestorUserId}>
          {!hasSignedContract && <StartInvesting />}
          <PortfolioSettings />
          <LegacyPortfolioSummary />
          <LegacyTransactions />
          {areFundsCollected ? (
            <WithdrawalSuccessfulLegacy />
          ) : (
            <>
              <CollectFundsLegacy />
              {!isLegacy() && hasSignedContract && <CollectFundsMessage />}
            </>
          )}
          <CustomerService />
        </div>
      )}
      {type === FAIR_CREDIT && (
        <>
          <PortfolioSettings />
          <PortfolioSummary />
          <Transactions />
          <AddFunds />
          {areFundsCollected && !isActive ? (
            <WithdrawalSuccessful />
          ) : (
            <CollectFunds />
          )}
          <CustomerService />
        </>
      )}
    </div>
  )
}

interface MappedStateModel {
  hasLoadedProfile: boolean
  hasSignedContract: boolean
  areFundsCollected: boolean
  legacyUsers: LegacyUser[]
  isFetching: boolean
  companies: Company[]
  forceAmlFillIn: boolean
  isActive: boolean
  hasLoan: boolean
  agreements: Agreements
  has_skipped_signed_latest_agreements: number
  companyAgreements: CompanyAgreements
  has_skipped_signed_company_latest_agreements: number
}

interface MappedDispatch {
  getOnFidoStatus: () => void
  getAgreements: () => void
  getCompanyAgreements: (companyId: number) => void
}

const mapStateToProps = (state: State): MappedStateModel => {
  const { data } = state.user.profile
  const { dashboard } = state.user.investor
  const legacyInvestor = state.legacy.investor
  return {
    hasLoadedProfile: !!data.id,
    hasSignedContract:
      data.deposits.length > 0 && data.deposits[0].has_signed_contract,
    areFundsCollected:
      state.user.investor.dashboard.withdraw.is_successful ||
      state.legacy.investor.withdraw.is_successful,
    legacyUsers: state.user.profile.data.legacy_users,
    isFetching:
      state.user.profile.is_fetching ||
      dashboard.reference_number.is_fetching ||
      dashboard.kpi.is_fetching ||
      dashboard.transactions.is_fetching ||
      dashboard.withdraw.is_fetching ||
      legacyInvestor.kpi.is_fetching ||
      legacyInvestor.transactions.is_fetching ||
      legacyInvestor.withdraw.is_fetching,
    companies: state.user.profile.data.companies,
    forceAmlFillIn: data.force_aml_fill_in,
    isActive: state.user.investor.dashboard.withdraw.data.active,
    hasLoan:
      (state.user.profile.data.legacy_users.length &&
        state.user.profile.data.legacy_users[0].legacy_role === 'borrower') ||
      state.user.profile.data.loans.length > 0,
    agreements: state.user.investor.agreements.data,
    has_skipped_signed_latest_agreements:
      state.user.investor.agreements.has_skipped_signed_latest_agreements,
    companyAgreements: state.user.investor.companyAgreements.data,
    has_skipped_signed_company_latest_agreements:
      state.user.investor.companyAgreements
        .has_skipped_signed_latest_company_agreements,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  getOnFidoStatus: () => dispatch(getOnFidoStatus()),
  getAgreements: () => dispatch(getAgreements()),
  getCompanyAgreements: (companyId: number) =>
    dispatch(getCompanyAgreements(companyId)),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, MappedDispatch, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Investor)
