import React from 'react'
import { State } from '../../../../store'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Indicator from '../../../shared/Indicator'
import SignicatForm from '../../SignicatForm'
import { Broker } from 'utils/broker'

interface Props extends WithTranslation {
  data: string[]
  isFetching: boolean
}

const LoginWithSca = (props: Props) => {
  const { t, isFetching } = props
  /* isServiceBreak false (display none) */
  const isServiceBreak = false
  let isBroker = Broker()

  return (
    <div>
      <Indicator isVisible={isFetching} />
      <div className="bank">
        {!isBroker ? (
          <p className="login-method__title">
            {t('CHOOSE_A_VERIFICATION_METHOD')}
          </p>
        ) : (
          <p className="login-method__title">
            {t('CHOOSE_A_VERIFICATION_METHOD_FOR_BROKER')}
          </p>
        )}
        {isServiceBreak && (
          <div className="center-text pt-10 px-15 pb-30">
            <p className="color-red">
              {t('ASIAKASTEITO_SERVICES_NOT_AVAILABLE')}
            </p>
          </div>
        )}
        <SignicatForm goal="login" />
      </div>
    </div>
  )
}

interface MapStateToProps {
  isFetching: boolean
}

const mapStateToProps = (state: State) => ({
  isFetching:
    state.auth.register.terms_and_conditions.is_fetching ||
    state.auth.register.signicat.is_fetching,
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MapStateToProps, any, any, any>(mapStateToProps)
)

export default enhancer(LoginWithSca)
