import {
  createGetInvestorKpiFailureAction,
  createGetInvestorKpiRequestAction,
  createGetInvestorKpiSuccessAction,
  createStoreDatesAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import InvestorAPI from '../../../../../../api/user/InvestorAPI'

const getInvestorKpi = (
  dates,
  depositId
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createGetInvestorKpiRequestAction())
  dispatch(createStoreDatesAction(dates))
  try {
    const investorAPI = new InvestorAPI()
    const response = await investorAPI.getInvestorKpi(depositId, dates)
    dispatch(createGetInvestorKpiSuccessAction(response.data.data))
  } catch (error) {
    dispatch(createGetInvestorKpiFailureAction(error))
  }
}

export default getInvestorKpi
