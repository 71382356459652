import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'

import {
  createGetActiveLoanApplicationFailureAction,
  createGetActiveLoanApplicationRequestAction,
  createGetActiveLoanApplicationSuccessAction,
} from './creators'
import StoreLoanApplicationRequest from 'models/Borrower/StoreLoanApplicationRequest'
import storeLoanApplication from './storeLoanApplication'
import browserHistory from 'browserHistory'

const getActiveLoanApplicationAndStore = (
  data: StoreLoanApplicationRequest
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(createGetActiveLoanApplicationRequestAction())

  try {
    const borrowerAPI = new BorrowerAPI()

    const response: AxiosResponse = await borrowerAPI.getActiveLoanApplication()
    dispatch(createGetActiveLoanApplicationSuccessAction(response.data.data))

    // user has active loan, we should redirect user to dashboard
    browserHistory.push('/dashboard/borrower/loan-application')
  } catch (error) {
    dispatch(createGetActiveLoanApplicationFailureAction(error))

    // apply for a loan because user does not have any loan
    dispatch(storeLoanApplication(data))
  }
}

export default getActiveLoanApplicationAndStore
