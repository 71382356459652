import React from 'react'
import CheckBox from './CheckBox'
import { CheckBoxElement } from './Models'
import Identical from '../Wrapper/Identical'

interface Props {
  elements: CheckBoxElement[]
  selectedElementIds: string[]
  onChange: (ids: string[]) => void
  ElementWrapper?: React.ElementType
}

const CheckBoxGroup = (props: Props): JSX.Element => {
  const { ElementWrapper, elements, selectedElementIds, onChange } = props
  return (
    <>
      {elements.map((element) => (
        // @ts-ignore
        <ElementWrapper key={element.id}>
          <CheckBox
            data={element}
            isSelected={selectedElementIds.includes(element.id)}
            onChange={() =>
              onChange(changeSelectedIds(selectedElementIds, element.id))
            }
          />
        </ElementWrapper>
      ))}
    </>
  )
}

CheckBoxGroup.defaultProps = {
  ElementWrapper: Identical,
}

const changeSelectedIds = (
  selectedElementIds: string[],
  selectedId: string
): string[] => {
  if (selectedElementIds.includes(selectedId)) {
    return selectedElementIds.filter((item) => item !== selectedId)
  }
  return [...selectedElementIds, selectedId]
}

export default CheckBoxGroup
