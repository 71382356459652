import React from 'react'
import { Route } from 'react-router-dom'
import LoanApplication from '../components/Borrower/LoanApplication'
import ChooseBank from '../components/Borrower/LoanApplication/ChooseBank'
import ChooseBankGuard from './guards/LoanApplication/ChooseBankGuard'

export default () => (
  <div id="container">
    <Route exact path="/dashboard/borrower/loan-application">
      <LoanApplication />
    </Route>
    {/* @ts-ignore */}
    <ChooseBankGuard path="/dashboard/borrower/loan-application/choose-bank">
      <ChooseBank />
    </ChooseBankGuard>
  </div>
)
