import {
  createAgreementsFailureAction,
  createAgreementsRequestAction,
  createAgreementsSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import TermsAndConditionsAPI from '../../../../../api/general/TermsAndConditionsAPI'

export default (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch
) => {
  dispatch(createAgreementsRequestAction())
  try {
    const termsAndConditionsApi = new TermsAndConditionsAPI()
    const response: AxiosResponse = await termsAndConditionsApi.getTermsAndConditionsAndContractStatus()
    dispatch(createAgreementsSuccessAction(response.data.data))
  } catch (error) {
    dispatch(createAgreementsFailureAction(error))
  }
}
