import AutoInvest from '../../../../models/Investor/AutoInvest'
import {
  GET_AUTO_INVEST_STATUS_FAILURE,
  GET_AUTO_INVEST_STATUS_REQUEST,
  GET_AUTO_INVEST_STATUS_SUCCESS,
  TOGGLE_AUTO_INVEST_STATUS_FAILURE,
  TOGGLE_AUTO_INVEST_STATUS_REQUEST,
  TOGGLE_AUTO_INVEST_STATUS_SUCCESS,
  ADD_COMPANY_INVESTOR,
  GET_COMPANY_AUTO_INVEST_STATUS_SUCCESS,
  TOGGLE_COMPANY_AUTO_INVEST_STATUS_SUCCESS,
  GET_COMPANY_SIGN_RIGHT_STATUS_REQUEST,
  GET_COMPANY_SIGN_RIGHT_STATUS_SUCCESS,
  GET_COMPANY_SIGN_RIGHT_STATUS_FAILURE,
} from './actions/types'

export default (state: State = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_SIGN_RIGHT_STATUS_REQUEST:
    case GET_AUTO_INVEST_STATUS_REQUEST:
    case TOGGLE_AUTO_INVEST_STATUS_REQUEST:
      return { ...state, is_fetching: true }
    case GET_AUTO_INVEST_STATUS_SUCCESS:
    case TOGGLE_AUTO_INVEST_STATUS_SUCCESS:
      return {
        ...state,
        is_fetching: false,
        data: { ...state.data, ...action.data },
      }
    case GET_COMPANY_SIGN_RIGHT_STATUS_FAILURE:
    case TOGGLE_AUTO_INVEST_STATUS_FAILURE:
    case GET_AUTO_INVEST_STATUS_FAILURE:
      return { ...state, is_fetching: false, error: action.error }
    case ADD_COMPANY_INVESTOR:
      const companiesAutoInvest: AutoInvest[] = action.data.map((company) => {
        return {
          is_active: false,
          is_enabled: false,
          company_id: company.id,
        }
      })
      return {
        ...state,
        companiesAutoInvest,
      }
    case GET_COMPANY_SIGN_RIGHT_STATUS_SUCCESS:
    case GET_COMPANY_AUTO_INVEST_STATUS_SUCCESS:
    case TOGGLE_COMPANY_AUTO_INVEST_STATUS_SUCCESS:
      const updatedCompaniesutoInvest: AutoInvest[] = state.companiesAutoInvest.map(
        (company) => {
          if (company.company_id === parseInt(action.companyId)) {
            return {
              ...company,
              ...action.data,
            }
          }
          return company
        }
      )
      return {
        ...state,
        companiesAutoInvest: updatedCompaniesutoInvest,
        is_fetching: false,
      }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  data: {
    is_active: false,
  },
  companiesAutoInvest: [],
  error: null,
}

export interface State {
  is_fetching: boolean
  data: AutoInvest
  error: any
  companiesAutoInvest: AutoInvest[]
}
