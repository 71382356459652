import React from 'react'

const PriceList: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared">
      <h3>FIXURA AB OY – PRICE LIST</h3>
      <p>Valid as of 15th of June 2021</p>
      <p>
        This price list (“Price List”) will be applied to Loan Agreements and
        Investment Agreements from 15th of June 2021 onwards. For Loan
        Agreements and Investment Agreements entered into before 1st of February
        2020, the Terms and Conditions that are available at Fixura’s website
        and dated 1st of March 2019 will apply.
      </p>
      <br />

      <h3>1. BORROWERS</h3>

      <table className="definitions">
        <tbody>
          <tr>
            <td>
              <b>Loan Account Fee</b>
            </td>
            <td>
              The loan account fee is 0,01 % per day of the original Loan
              amount. The loan account fee is separately defined for each Loan
              and will be charged as 0,30 % monthly or 3,65 % yearly. The fees
              for each Loan can be a total of 150,00 € per year.
            </td>
          </tr>
          <tr>
            <td>
              <b>Change Due Date</b>
            </td>
            <td>5,00 € (maximum of four times per year)</td>
          </tr>
          <tr>
            <td>
              <b>Modify Repayment Plan</b>
            </td>
            <td>50,00 €</td>
          </tr>
          <tr>
            <td>
              <b>Interest Statement</b>
            </td>
            <td>20,00 €</td>
          </tr>
          <tr>
            <td>
              <b>Balance Statement</b>
            </td>
            <td>10,00 € (free of charge once per year)</td>
          </tr>
          <tr>
            <td>
              <b>Refund of Excess Payment</b>
            </td>
            <td>5,00 €</td>
          </tr>
          <tr>
            <td>
              <b>Paper Invoice</b>
            </td>
            <td>5,00 €</td>
          </tr>
        </tbody>
      </table>

      <br />
      <h3>2. INVESTORS</h3>
      <table className="definitions">
        <tbody>
          <tr>
            <td>
              <b>Administration Fee</b>
            </td>
            <td>50 % of interest income received by the Investor.</td>
          </tr>
          <tr>
            <td>
              <b>Sales Fee</b>
            </td>
            <td>
              1 % of the total amount of Investments in Loans sold on the
              aftermarkets.
            </td>
          </tr>
          <tr>
            <td>
              <b>Selling of Overdue Loans</b>
            </td>
            <td>
              Fixura strives to sell overdue loans to a third party at the best
              possible price.
            </td>
          </tr>
        </tbody>
      </table>

      <br />
      <p>
        Services not listed on the Price List, will be charged according to the
        hours used to complete the request. The hourly rate for this is 70,00 €
        per hour plus VAT.
      </p>
    </div>
  )
}

export default PriceList
