import React from 'react'
import { formatDate } from '../../../utils'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  PositiveTransactionEnum,
  NegativeTransactionEnum,
  InvestmentTransactionEnum,
} from './Transaction.enum'

interface ComponentProps {
  date: string
  type: string
  receiver?: string
  from?: string
  to?: string
  amount: number
}

interface Props extends WithTranslation, ComponentProps {}

const TransactionRow = (props: Props) => {
  const { t, amount, date, from, to, type } = props

  const changeReceiverToLocale = (receiver: string) => {
    if (receiver.includes('INVESTMENT_')) {
      return (
        <span>{t(receiver.split('_')[0]) + ' ' + receiver.split('_')[1]}</span>
      )
    }
    if (receiver.includes('FIXURA20_')) {
      return (
        <span>{t(receiver.split('_')[0]) + ' ' + receiver.split('_')[1]}</span>
      )
    }
    return <span>{t(receiver)}</span>
  }

  return (
    <tr className="table-rows">
      <td className="transaction-padding">{formatDate(date, 'YYYY-MM-DD')}</td>
      <td className="transaction-padding transaction-center">{t(type)}</td>
      <td className="sm-hide">{changeReceiverToLocale(from ? from : '')}</td>
      <td className="sm-hide">{changeReceiverToLocale(to ? to : '')}</td>
      <td className="transactions-align-right transaction-padding">
        {changeColor(amount, type)}
      </td>
    </tr>
  )
}

/* change color to red or green  */
/* format number to two decimal  */
const changeColor = (amount: number, type: string): React.ReactNode => {
  if (NegativeTransactionEnum.includes(type)) {
    return <span className="negative-color">{amount} €</span>
  } else if (PositiveTransactionEnum.includes(type)) {
    return <span className="positive-color">{amount} €</span>
  } else if (InvestmentTransactionEnum.includes(type)) {
    return <span>{(Math.round(amount * 100) / 100).toFixed(2)} €</span>
  }
  return <span>{amount} €</span>
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(TransactionRow)
