import { RadioElement } from '../../../../../shared/RadioGroup/Models'

const options: RadioElement[] = [
  {
    id: 'five-thousands',
    label: '0€-5.000€',
  },
  {
    id: 'five-ten-thousands',
    label: '5.000€-10.000€',
  },
  {
    id: 'ten-fifty-thousands',
    label: '10.000€-50.000€',
  },
  {
    id: 'more-than-fifty',
    label: '50.000€+',
  },
]

export default options
