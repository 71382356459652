import React from 'react'
import { getDefaultState, interval } from './utils'
import dayjs, { Dayjs } from 'dayjs'

interface Props {
  onDatesChange: Function
  component: any
  isVisible: boolean
  initialDate: {
    startDate?: Dayjs
    endDate?: Dayjs
  }
  name?: string
}

export default class PickerUI extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props)

    this.state = {
      ...getDefaultState(),
      ...props.initialDate,
    }
  }

  onDayMouseEnter = (day: any) => {
    const { startDate, endDate } = this.state
    const range = day >= startDate ? endDate || day : startDate
    this.setState({ range })
  }
  // TODO: fix the logic
  onDayClick = (day: any) => {
    if (dayjs().isAfter(day)) {
      const isOpen = this.state.closedOrOpen === interval.OPEN
      const startDate = isOpen ? this.state.startDate : day
      const endDate = isOpen && day >= startDate ? day : null
      const range = endDate || startDate
      const closedOrOpen =
        isOpen && day >= startDate ? interval.CLOSED : interval.OPEN
      this.props.name === 'transactions'
        ? this.setState(
            {
              startDate,
              endDate,
              closedOrOpen,
              range,
            },
            this.onApply
          )
        : this.setState(
            {
              endDate,
              closedOrOpen,
              range,
            },
            this.onApply
          )
    }
  }

  onApply = () => {
    const { startDate, endDate } = this.state
    const { onDatesChange } = this.props
    if (onDatesChange && startDate && endDate) {
      onDatesChange({ startDate, endDate })
    }
  }

  dateLabel = () => {
    const { startDate, endDate } = this.state
    return startDate && endDate
      ? `${startDate.format('DD/MM/YYYY')} - ${endDate.format('DD/MM/YYYY')}`
      : ''
  }

  render() {
    // tslint:disable-next-line:no-this-assignment
    const { onDayClick, onDayMouseEnter } = this
    const props = { onDayClick, onDayMouseEnter, ...this.state, ...this.props }
    const { component: Component } = this.props
    if (this.props.isVisible) {
      return (
        <>
          <Component {...props}>
            <div className="ranges" />
            <div className="drp-buttons">
              <span className="drp-selected">{this.dateLabel()}</span>{' '}
            </div>
          </Component>
          <div
            onClick={() => this.props.onDatesChange(this.props.initialDate)}
            className="close-when-clickoff"
          />
        </>
      )
    }
    return null
  }
}
