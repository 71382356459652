import {
  POST_CANCEL_WITHDRAW_FAILURE,
  POST_CANCEL_WITHDRAW_REQUEST,
  POST_CANCEL_WITHDRAW_SUCCESS,
  GET_WITHDRAW_STATUS_FAILURE,
  GET_WITHDRAW_STATUS_REQUEST,
  GET_WITHDRAW_STATUS_SUCCESS,
  POST_WITHDRAW_FAILURE,
  POST_WITHDRAW_REQUEST,
  POST_WITHDRAW_RESET,
  POST_WITHDRAW_SUCCESS,
  STORE_WITHDRAW_AMOUNT,
} from './types'
import WithDrawStatusData from '../../../../../../models/Investor/WithDrawStatus'

export const createCancelWithdrawRequestAction = () => ({
  type: POST_CANCEL_WITHDRAW_REQUEST,
})
export const createCancelWithdrawSuccessAction = () => ({
  type: POST_CANCEL_WITHDRAW_SUCCESS,
})
export const createCancelWithdrawFailureAction = (error: any) => ({
  error,
  type: POST_CANCEL_WITHDRAW_FAILURE,
})
export const createGetWithdrawStatusRequestAction = () => ({
  type: GET_WITHDRAW_STATUS_REQUEST,
})
export const createGetWithdrawStatusSuccessAction = (
  data: WithDrawStatusData
) => ({
  data,
  type: GET_WITHDRAW_STATUS_SUCCESS,
})
export const createGetWithdrawStatusFailureAction = (error: any) => ({
  error,
  type: GET_WITHDRAW_STATUS_FAILURE,
})
export const createWithdrawRequestAction = () => ({
  type: POST_WITHDRAW_REQUEST,
})
export const createWithdrawSuccessAction = (amount: number) => ({
  amount,
  type: POST_WITHDRAW_SUCCESS,
})
export const createWithdrawFailureAction = (error: any) => ({
  error,
  type: POST_WITHDRAW_FAILURE,
})
export const createWithdrawResetAction = () => ({ type: POST_WITHDRAW_RESET })

export const createStoreWithdrawAmountAction = (amount: number) => ({
  amount,
  type: STORE_WITHDRAW_AMOUNT,
})
