import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { State } from '../../../../store'
import { Col, Row } from 'reactstrap'
import { formatMoney } from '../../../../utils'
import LoanStatusEnum from '../LoanStatus/LoanStatus.enum'
import browserHistory from 'browserHistory'
import { ThunkDispatch } from 'redux-thunk'
import getTotalLoanAmtLeft from 'store/user/borrower/totalLoanAmtLeft/actions/getTotalLoanAmtLeft'

interface Props extends MappedState, MappedDispatch {}

const LoanSummary = (props: Props) => {
  const {
    totalLoanAmtLeft,
    allLoans,
    loanAccountState,
    getTotalLoanAmtLeft,
  } = props
  const { t } = useTranslation()

  const isBorrowerDashboard =
    browserHistory.location.pathname === '/dashboard/borrower'

  useEffect(() => {
    if (allLoans > 1) {
      getTotalLoanAmtLeft()
    }
  }, [getTotalLoanAmtLeft, allLoans])

  //return null if loans less than two or in debt
  if (
    totalLoanAmtLeft === 0 ||
    allLoans < 2 ||
    loanAccountState === LoanStatusEnum.DEBT_COLLECTION
  ) {
    return null
  }

  return totalLoanAmtLeft ? (
    <div className={isBorrowerDashboard ? 'pb-30' : ''}>
      <section className="loan-main-info">
        <Row>
          <Col
            lg={{ size: 6, offset: 3 }}
            md={{ size: 8, offset: 2 }}
            sm="12"
            className="text-center"
          >
            <div className="loan-left__wrapper">
              <div className="loan-info__title">
                {t('BORROWER_DASHBOARD_LOAN_LEFT_TITLE')}
              </div>
              <h1 className="loan-left__number">
                {totalLoanAmtLeft && `${formatMoney(totalLoanAmtLeft)} €`}
              </h1>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  ) : null
}

interface MappedState {
  totalLoanAmtLeft: number
  allLoans: number
  loanAccountState?: string
}

//TODO Fix me for more than one loans and legacy loans
const mapStateToProps = (state: State): MappedState => {
  const { data } = state.user.borrower.loan
  const { loans } = state.user.profile.data
  const legacyLoans = state.legacy.borrower.loan.data
  const totalLoanAmtLeft = state.user.borrower.totalLoanAmtLeft.totalLoanAmtLeft
  return {
    totalLoanAmtLeft,
    allLoans: legacyLoans.length + loans.length,
    loanAccountState: data?.loanAccountState,
  }
}

interface MappedDispatch {
  getTotalLoanAmtLeft: () => void
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  getTotalLoanAmtLeft: () => dispatch(getTotalLoanAmtLeft()),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(LoanSummary)
