import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { State } from '../../store'
import { setSpaceForPhoneNumber } from '../../utils'
import ic_call from 'assets/images/ic_call.svg'

interface Props extends MappedState {}

const MobilePhone = (props: Props) => {
  const { mobilePhone } = props

  return (
    <div className="user-info mb-5">
      <div>
        <img src={ic_call} alt="user phone" />
      </div>
      <div className="ml-3">{setSpaceForPhoneNumber(mobilePhone)}</div>
    </div>
  )
}

interface MappedState {
  mobilePhone: string
}

const mapStateToProps = (state: State): MappedState => {
  return {
    mobilePhone: state.user.profile.data.mobile_phone,
  }
}

const enhancer = compose(connect<MappedState, any, any, any>(mapStateToProps))

export default enhancer(MobilePhone)
