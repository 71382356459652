import React, { useEffect, useRef, useState } from 'react'
import { Collapse, Container, Nav, Navbar, NavbarToggler } from 'reactstrap'
import FixuraLogo from '../shared/FixuraLogo'
import { Link, useLocation } from 'react-router-dom'
import NavLinks from './NavLinks'
import { getFixuraToken } from '../../utils/auth'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { store } from '../../store'

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [dashboardLink, setDashboardLink] = useState('/dashboard')
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsOpen(false))
  const location = useLocation()
  const { data } = store.getState().user.profile
  const legacyLoans = store.getState().legacy.borrower.loan.data
  const hasLoan =
    (data.legacy_users.length &&
      data.legacy_users[0].legacy_role === 'borrower') ||
    data.loans.length > 0
  const loans = data.loans
  const loansLength = legacyLoans.length + loans.length

  const hasSignedContract =
    data.deposits.length > 0 && data.deposits[0].has_signed_contract

  useEffect(() => {
    if (hasSignedContract && hasLoan) {
      setDashboardLink('/dashboard')
    } else if (hasSignedContract) {
      setDashboardLink('/dashboard/investor')
    } else if (loansLength !== 0 && !hasLoan) {
      setDashboardLink('/dashboard/borrower')
    } else {
      setDashboardLink('/dashboard')
    }
  }, [hasSignedContract, loansLength, hasLoan])

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  function toggle() {
    setIsOpen(!isOpen)
  }

  return (
    <nav className="nav-look navbar-expand-lg">
      <Container>
        <div ref={ref}>
          <Navbar className="px-md-0">
            {/* Fix me : redirect to index using history */}
            <div className="nav-pad navbar-brand">
              <Link to={getFixuraToken() ? dashboardLink : '/'}>
                <FixuraLogo size={'small'} color={'dark'} />
              </Link>
            </div>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavLinks />
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </Container>
    </nav>
  )
}

export default NavBar
