import React from 'react'
import { Col, Row } from 'reactstrap'
import CheckBoxGroup from '../../../../../shared/CheckBoxGroup'
import options from './options'
import Rows from '../../../../../shared/Wrapper/Rows'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import FixuraInput from '../../../../../shared/FixuraInput'
import { ScrollElement } from '../../../../../../utils/scroll'

interface ComponentProps {
  setFields: (fields) => void
  fields: any
  errors: any
  handleChange: (
    fieldName: string,
    e: React.FormEvent<HTMLInputElement>
  ) => void
}

interface Props extends WithTranslation, ComponentProps {}

const Vehicles = (props: Props) => {
  const { t, setFields, fields, errors, handleChange } = props
  const passFields = (ids: string[]) => {
    const selectedValues = {}
    options.forEach(
      (option) => (selectedValues[option.id] = ids.includes(option.id))
    )
    setFields({ ...fields, ...selectedValues })
  }
  return (
    <div className="mb-5">
      <Row className="mb-4">
        <Col lg={{ size: 8, offset: 2 }}>
          <ScrollElement name="investment_funds_specification" />
          <div className="font-20-body">{t('INVESTMENT_VEHICLES')}</div>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 8, offset: 2 }}>
          <CheckBoxGroup
            elements={options}
            selectedElementIds={options
              .filter(
                (option) =>
                  fields.hasOwnProperty(option.id) && fields[option.id]
              )
              .map((option) => option.id)}
            onChange={passFields}
            ElementWrapper={Rows}
          />
          {errors.hasOwnProperty('investment_funds_specification') && (
            <small className="error">
              {errors['investment_funds_specification']}
            </small>
          )}
        </Col>
      </Row>
      <Row className="big-distance-bot">
        <Col md="7" className="mt-1" lg={{ size: 8, offset: 2 }}>
          <FixuraInput
            className="input-characteristics"
            placeholder={t('WHICH_ONES')}
            onChange={handleChange.bind(
              undefined,
              'investment_funds_specification'
            )}
            hasError={errors.hasOwnProperty('investment_funds_specification')}
          />
        </Col>
      </Row>
    </div>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(Vehicles)
