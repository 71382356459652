import { ThunkAction } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import { Dispatch } from 'redux'
import {
  createGetActiveLoanApplicationFailureAction,
  createGetActiveLoanApplicationRequestAction,
  createGetActiveLoanApplicationSuccessAction,
} from './creators'

const getActiveLoanApplication = (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch
) => {
  dispatch(createGetActiveLoanApplicationRequestAction())
  try {
    const borrowerAPI = new BorrowerAPI()
    const response: AxiosResponse = await borrowerAPI.getActiveLoanApplication()
    dispatch(createGetActiveLoanApplicationSuccessAction(response.data.data))
  } catch (error) {
    dispatch(createGetActiveLoanApplicationFailureAction(error))
  }
}

export default getActiveLoanApplication
