import {
  createToggleAutoInvestFailureAction,
  createToggleAutoInvestStatusRequestAction,
  createToggleAutoInvestSuccessAction,
  createCompanyToggleAutoInvestSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import InvestorAPI from '../../../../../api/user/InvestorAPI'

export default (
  depositId: string,
  companyId?: number
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createToggleAutoInvestStatusRequestAction())

  try {
    const investorAPI = new InvestorAPI()
    const response = await investorAPI.toggleAutoInvest(depositId)
    if (companyId) {
      dispatch(
        createCompanyToggleAutoInvestSuccessAction(
          response.data.data,
          companyId
        )
      )
    } else {
      dispatch(createToggleAutoInvestSuccessAction(response.data.data))
    }
  } catch (error) {
    dispatch(createToggleAutoInvestFailureAction(error))
  }
}
