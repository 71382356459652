import React from 'react'
import { Company } from 'models/Auth/ProfileData'
import { capitalizeFirstLetter } from 'utils'
import { useTranslation } from 'react-i18next'
import ic_home from 'assets/images/ic_home.svg'
import Contracts from './Contracts'

interface ComponentProps {
  data: Company
  companyId?: number
}

interface Props extends ComponentProps {}

const CompanyProfile: React.FunctionComponent<Props> = ({ data: company }) => {
  const { t } = useTranslation()
  const { address } = company

  return (
    <div className="company-profile pt-30">
      <h2 className="mt-3 company-title">
        {capitalizeFirstLetter(company.name)}
      </h2>
      <div className="mb-3">{`${t('BUSINESS_CODE')}  ${
        company.business_code
      }`}</div>

      <div className="user-info my-4">
        <div>
          <img src={ic_home} alt={t('USER_ADDRESS')} />
        </div>
        <div className="ml-3">
          {address && (
            <>
              <div>{address.line1}</div>
              <div>{address.line2}</div>
              <div>
                {address.postcode} {address.city}
              </div>
              <div>{address.country}</div>
            </>
          )}
        </div>
      </div>
      <Contracts companyId={company.id} />
    </div>
  )
}

export default CompanyProfile
