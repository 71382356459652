import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { createPostSkipCompanySignedAgreementsSuccessAction } from './creators'

export const postSkipCompanySignedAgreements = (): ThunkAction<
  any,
  any,
  any,
  any
> => async (dispatch: Dispatch) => {
  dispatch(createPostSkipCompanySignedAgreementsSuccessAction())
}
