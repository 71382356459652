import React from 'react'
import classNames from 'classnames'
import { Th } from './Table'

interface Props {
  prev?: boolean
  calendar: any
  handlePrev?: Function
  locale?: any
}

export default class CalendarPrev extends React.Component<Props> {
  static defaultProps = {
    prev: true,
    locale: {},
  }

  handlePrev(calendar: any) {
    const { handlePrev } = this.props
    if (handlePrev) {
      handlePrev(calendar)
    }
  }

  render() {
    const { prev, calendar } = this.props
    const className = classNames({ prev, available: prev })
    const onClick = calendar ? this.handlePrev.bind(this, calendar) : () => {}
    const Span = prev ? <span /> : null
    const prevProps = {
      className,
      onClick,
    }
    return <Th {...prevProps}>{Span}</Th>
  }
}
