import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import {
  createGetContractInvoiceFailureAction,
  createGetContractInvoiceRequestAction,
  createGetContractInvoiceSuccessAction,
} from './creators'
import Document from '../../../../api/user/Document'
import { AxiosResponse } from 'axios'
import jsFileDownload from 'js-file-download'

export const getCompanyDocument = (
  documentId: number
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createGetContractInvoiceRequestAction())

  try {
    const profile = new Document()
    const response: AxiosResponse = await profile.getCompanyDocument(documentId)
    jsFileDownload(
      response.data,
      'Fixura-company-document.pdf',
      'application/pdf'
    )
    dispatch(createGetContractInvoiceSuccessAction())
  } catch (error) {
    dispatch(createGetContractInvoiceFailureAction(error))
  }
}
