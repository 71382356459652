import React, { useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap'
import PurposeOfInvestment from './PurposeOfInvestment'
import PurposeOfInvestmentCompany from './PurposeOfInvestmentCompany'
import EstimatedPeriodOfTransferFunds from './EstimatedPeriodOfTransferFunds'
import AnnualInvestEstimation from './AnnualInvestEstimation'
import RiskProfile from './RiskProfile'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import FixuraInput from '../../../../shared/FixuraInput'
import { ScrollElement } from '../../../../../utils/scroll'
import { useParams } from 'react-router-dom'

interface ComponentProps {
  setFields: (fields) => void
  fields: any
  errors: any
}

interface Props extends WithTranslation, ComponentProps {}

let hideFieldOtherPurpose = true

const InvestmentGoal = (props: Props) => {
  const { t, setFields, fields, errors } = props
  const setHideFieldOtherPurpose = (value) => (hideFieldOtherPurpose = value)
  const { companyId } = useParams()

  const handleChange = (
    fieldName: string,
    e: React.FormEvent<HTMLInputElement>
  ) => {
    passFields(fieldName, e.currentTarget.value)
  }
  const passFields = (fieldName: string, value: string) => {
    const newFields = { ...fields }
    newFields[fieldName] = value

    setFields(newFields)
  }

  useEffect(() => {
    hideFieldOtherPurpose = !fields['goal_of_investment_is_other']
  }, [fields])

  return (
    <Container className="aml__wrapper">
      <Row className="mt-5 mb-5">
        <Col className="text-center" lg={{ size: 8, offset: 2 }}>
          <div className="font-28">{t('GOAL_OF_INVESTMENT')}</div>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 8, offset: 2 }}>
          <ScrollElement name="goal_of_investment_specification" />
          {companyId ? (
            <PurposeOfInvestmentCompany
              setFields={setFields}
              fields={fields}
              errors={errors}
              hideField={setHideFieldOtherPurpose}
            />
          ) : (
            <PurposeOfInvestment
              setFields={setFields}
              fields={fields}
              errors={errors}
              hideField={setHideFieldOtherPurpose}
            />
          )}
          <Row
            className={
              hideFieldOtherPurpose ? 'hide-field' : 'big-distance-bot'
            }
          >
            <Col md="12">
              <FixuraInput
                className={
                  hideFieldOtherPurpose ? 'hide-field' : 'input-characteristics'
                }
                placeholder={t('FROM_WHERE')}
                onChange={handleChange.bind(
                  undefined,
                  'goal_of_investment_specification'
                )}
                hasError={errors.hasOwnProperty(
                  'goal_of_investment_specification'
                )}
                errorMessage={errors['goal_of_investment_specification']}
                defaultValue={fields['goal_of_investment_specification']}
              />
            </Col>
          </Row>
          <EstimatedPeriodOfTransferFunds
            setFields={setFields}
            fields={fields}
            errors={errors}
          />
          <AnnualInvestEstimation
            setFields={setFields}
            fields={fields}
            errors={errors}
          />
          <RiskProfile setFields={setFields} fields={fields} errors={errors} />
        </Col>
      </Row>
    </Container>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(InvestmentGoal)
