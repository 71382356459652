import React from 'react'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import CustomerService from '../components/shared/CustomerService'

/* TODO move this -to component folder */

interface Props extends WithTranslation {}
const RejectedOnFido = (props: Props) => {
  const { t } = props

  return (
    <div className="loan-rejection__wrapper">
      <aside className="loan-reject-message__container">
        <h1 className="loan__main-title">{t('REGISTER_REJECTION_TITLE')}</h1>
        <p className="loan-rejection__description">
          {t('ON_FIDO_REJECTED_DETAIL')}
        </p>
        <p className="loan-rejection__description">
          {t('ON_FIDO_RETRY_DETAIL')}
        </p>
      </aside>

      <CustomerService />
    </div>
  )
}

const enhance = compose(withTranslation())

export default enhance(RejectedOnFido)
