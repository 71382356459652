import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { WithTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel from './MappedDispatch.model'
import arrow_to_right from 'assets/images/arrow_to_right.png'
import { ScrollElement } from 'utils/scroll'

interface Props
  extends WithTranslation,
    MappedStateModel,
    MappedDispatchModel {}

const Presentation = (props: Props) => {
  const { t, referenceNumber } = props
  return (
    <Container className="add-funds__wrapper">
      <ScrollElement name="add-funds" />
      <Row>
        <Col md="4">
          <h1 className="add-funds-margin">{t('ADD_FUNDS_HEADER')}</h1>
        </Col>
        <Col md="8" className="d-flex align-items-center">
          <Row className="w-15">
            <Col md="1">
              <img
                className="arrow-to-right d-none d-md-block"
                src={arrow_to_right}
                alt=">"
              />
            </Col>
          </Row>
          <Row className="w-100">
            <Col md="12">
              <Row className="w-100">
                <Col md="12">
                  <Row>
                    <Col md="3" xs="12" className="pb-4 pb-md-0">
                      <div>{t('RECEIVER')}</div>
                      <strong>{t('FIXURA_AB_OY')}</strong>
                    </Col>
                    <Col md="4" xs="12" className="pb-4 pb-md-0">
                      <div>{t('ACCOUNT_NUMBER')}</div>
                      <strong>FI71 1318 3000 1080 67</strong>
                    </Col>
                    <Col md="4" xs="12" className="pb-4 pb-md-0">
                      <div>{t('REFERENCE_NUMBER')}</div>
                      <strong>{referenceNumber}</strong>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="w-100 py-md-2">
                <Col md="12">
                  <Row>
                    <Col md="3" xs="12" className="pb-4 pb-md-0">
                      <div>{t('BIC')}</div>
                      <strong>NDEAFIHH</strong>
                    </Col>
                    <Col md="4" xs="12" className="pb-4 pb-md-0">
                      <div>{t('BANK_NAME')}</div>
                      <strong>Nordea</strong>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Presentation
