import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import IsBeneficialOwners from './IsBeneficialOwners'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'

interface ComponentProps {
  setFields: (fields) => void
  fields: any
  errors: any
}

interface Props extends WithTranslation, ComponentProps {}

const BeneficialOwners = (props: Props) => {
  const { t, setFields, fields, errors } = props
  return (
    <Container className="aml__wrapper">
      <Row className="mt-5 mb-5">
        <Col className="text-center">
          <div className="font-20-body">{t('BENEFICIAL_OWNERS')}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <IsBeneficialOwners
            setFields={setFields}
            fields={fields}
            errors={errors}
          />
        </Col>
      </Row>
    </Container>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(BeneficialOwners)
