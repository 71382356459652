import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import MappedStateModel from './MappedState.model'
import { State } from '../../../store'
import Presentation from './Presentation'
import { ThunkDispatch } from 'redux-thunk'
import MappedDispatchModel from './MappedDispatch.model'
import { createWithdrawResetAction } from '../../../store/legacy/investor/withdraw/actions/creators'

const mapStateToProps = (state: State): MappedStateModel => ({
  amount: state.legacy.investor.withdraw.amount,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  reset: () => dispatch(createWithdrawResetAction()),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, MappedDispatchModel, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Presentation)
