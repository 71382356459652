import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import reduxThunk from 'redux-thunk'
import user, {
  initialState as userInitialState,
  State as UserState,
} from './user'
import legacy, {
  initialState as legacyInitialState,
  State as LegacyState,
} from './legacy'
import auth, {
  initialState as authInitialState,
  State as AuthState,
} from './auth'
import marketing, {
  initialState as marketingInitialState,
  State as MarketingState,
} from './marketing'
import broker, {
  initialState as brokerInitialState,
  State as BrokerState,
} from './broker'

export interface State {
  user: UserState
  legacy: LegacyState
  auth: AuthState
  marketing: MarketingState
  broker: BrokerState
}

export const reducer = combineReducers<State>({
  user,
  legacy,
  auth,
  marketing,
  broker,
})

/* app’s top-level reducers */
export const appReducer = combineReducers<State>({
  user,
  legacy,
  auth,
  marketing,
  broker,
})

export const rootReducer = (state = initialState, action) => {
  if (
    action.type === 'user.profile.LOGOUT_SUCCESS' ||
    action.type === 'user.profile.LOGOUT_FAILURE'
  ) {
    state = initialState
  }
  return appReducer(state, action)
}

export const initialState = {
  legacy: legacyInitialState,
  user: userInitialState,
  auth: authInitialState,
  marketing: marketingInitialState,
  broker: brokerInitialState,
}

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        shouldHotReload: false,
      })
    : compose

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(reduxThunk))
)
