export const POST_WITHDRAW_REQUEST =
  'legacy.investor.withdraw.LEGACY_INVESTOR_WITHDRAW_REQUEST'
export const POST_WITHDRAW_SUCCESS =
  'legacy.investor.withdraw.LEGACY_INVESTOR_WITHDRAW_SUCCESS'
export const POST_WITHDRAW_FAILURE =
  'legacy.investor.withdraw.LEGACY_INVESTOR_WITHDRAW_FAILURE'
export const POST_WITHDRAW_RESET =
  'legacy.investor.withdraw.POST_WITHDRAW_RESET'
export const STORE_WITHDRAW_AMOUNT =
  'legacy.investor.withdraw.STORE_WITHDRAW_AMOUNT'
