export default interface Auth {
  token?: string
  role?: Role
  can_be_onboarded: boolean
  is_foreigner: boolean
}

export enum Role {
  INVESTOR = 'investor',
  BORROWER = 'borrower',
}
