import React, { useState } from 'react'
import {
  ButtonDropdown,
  Col,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
} from 'reactstrap'
import CountryItem from './CountryItem'
import { countries } from './data'
import { useTranslation } from 'react-i18next'

interface Props {
  onChange: (country: string) => void
  hasError?: boolean
  errorMessage?: string
  onBlur?: (string) => void
  autoFocus?: boolean
}

const CountryInput = (props: Props) => {
  const { hasError, errorMessage, onChange } = props
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedCountry, setSelectedCountry] = useState(countries[0])
  const { t } = useTranslation()

  function handleCountryChanged(country) {
    setSelectedCountry(country)
    onChange(country.iso_code)
  }

  function toggleDropDown() {
    setIsDropDownOpen(!isDropDownOpen)
  }

  return (
    <FormGroup className="country-input">
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <div className="country-input-col">
            <ButtonDropdown
              className="country__dropdown"
              isOpen={isDropDownOpen}
              toggle={toggleDropDown}
            >
              <DropdownToggle caret className="flag__caret">
                <img src={selectedCountry.img} className="flag__img" alt="" />
              </DropdownToggle>
              <DropdownMenu>
                <div className="country-input_search">
                  <Input
                    onChange={(e) => {
                      setSearchTerm(e.target.value)
                    }}
                    placeholder={t('SEARCH_COUNTRIES')}
                    value={searchTerm}
                  />
                </div>
                {countries
                  .filter((country) => {
                    if (searchTerm === '') return true
                    return (
                      country.name
                        .toLowerCase()
                        .indexOf(searchTerm.toLowerCase()) === 0
                    )
                  })
                  .map((country) => (
                    <CountryItem
                      onClick={() => handleCountryChanged(country)}
                      country={country}
                      key={country.name}
                    />
                  ))}
              </DropdownMenu>
            </ButtonDropdown>
            <span className="country-code">{selectedCountry.name}</span>
            {hasError && (
              <small className="form-error-message">{errorMessage}</small>
            )}
          </div>
        </Col>
      </Row>
    </FormGroup>
  )
}

CountryInput.defaultProps = {
  hasError: false,
  errorMessage: '',
}

export default CountryInput
