import {
  createGetInvestorTransactionsFailureAction,
  createGetInvestorTransactionsRequestAction,
  createGetInvestorTransactionsSuccessAction,
  createStoreDatesAndTypeAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import Dates from '../../../../../../models/RangeDatepicker/Dates'
import InvestorAPI from '../../../../../../api/user/InvestorAPI'

const getInvestorTransactions = (
  dates: Dates,
  type?: string,
  depositId?: string
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createGetInvestorTransactionsRequestAction())
  dispatch(createStoreDatesAndTypeAction(dates, type))
  try {
    const investorAPI = new InvestorAPI()
    const response = await investorAPI.getInvestorTransactions(
      depositId || '',
      dates,
      type
    )
    dispatch(createGetInvestorTransactionsSuccessAction(response.data.data))
  } catch (error) {
    dispatch(createGetInvestorTransactionsFailureAction(error))
  }
}

export default getInvestorTransactions
