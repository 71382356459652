import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import { State } from '../../../store'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { MapStateToProps } from './MappedState'
import { WithTranslation, withTranslation } from 'react-i18next'
import { olarkSiteId } from '../../../constants'

interface Props extends WithTranslation, MapStateToProps {}

declare global {
  interface Window {
    olark: any
  }
}

const Olark = (props: Props) => {
  const { t, language } = props
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const injectOlark = () => {
      if (!window.olark) {
        let langConfiguration = ''
        if (language !== 'fi') {
          Object.entries(t('Olark')).forEach(([key, value]) => {
            langConfiguration += `olark.configure("${key}", "${value}");\n`
          })
        }
        const script = document.createElement('script')
        script.setAttribute('id', 'olark-react-snippet')
        script.setAttribute('type', 'text/javascript')
        script.setAttribute('async', 'true')
        script.innerHTML = `;(function(o,l,a,r,k,y){if(o.olark)return;
          r="script";y=l.createElement(r);
          r=l.getElementsByTagName(r)[0];
          y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r);
          y=o.olark=function(){k.s.push(arguments);
          k.t.push(+new Date)};
           y.extend=function(i,j){y("extend",i,j)};
          y.identify=function(i){y("identify",k.i=i)};
          y.configure=function(i,j){y("configure",i,j);
          k.c[i]=j};
          k=y._={s:[],t:[+new Date],c:{},l:a};
          })(window,document,"static.olark.com/jsclient/loader.js");
          /* custom configuration goes here (www.olark.com/documentation) */
          ${langConfiguration}
          olark.identify('${olarkSiteId}');`
        document.body.appendChild(script)
      }
    }
    injectOlark()
  }, [language, t])

  const toggleChatBox = () => {
    isOpen ? hideChatBox() : openChatBox()
    setIsOpen(!isOpen)
  }

  const openChatBox = () => {
    window.olark('api.box.expand')
  }

  const hideChatBox = () => {
    window.olark('api.box.shrink')
  }

  return (
    <Button onClick={toggleChatBox} className="link-bg-white">
      {t('START_CONVERSATION')}
    </Button>
  )
}

const mapStateToProps = (state: State): MapStateToProps => ({
  language: state.user.profile.data.language,
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect(mapStateToProps)
)

export default enhancer(Olark)
