import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { default as Investor } from '../components/Investor/legacy'
import { default as Withdraw } from '../components/Investor/CollectFunds/WithdrawModal/Legacy'
import { default as Borrower } from '../components/Borrower/Dashboard/legacy'
import { getLegacyData } from '../utils/auth'

export default () => (
  <Switch>
    <Route exact path="/restricted">
      <Redirect to={{ pathname: `/restricted/${getLegacyData().role}` }} />
    </Route>
    <Route path="/restricted/investor/fixed-rate-portfolio/:userId">
      <InvestorRoute />
    </Route>
    <Route exact path="/restricted/investor">
      <InvestorRoute />
    </Route>
    <Route path="/restricted/borrower/fixed-rate-loan/:id">
      <Borrower />
    </Route>
    <Route exact path="/restricted/borrower">
      <Borrower />
    </Route>
  </Switch>
)

const InvestorRoute = () => (
  <>
    <Investor />
    <Switch>
      <Route path="/restricted/investor/fixed-rate-portfolio/:userId/withdraw/">
        <Withdraw />
      </Route>
    </Switch>
  </>
)
