import {
  LOAN_CALCULATION_FAILURE,
  LOAN_CALCULATION_REQUEST,
  LOAN_CALCULATION_SUCCESS,
} from './actions/types'
import LoanCalculatorResponse from '../../../../models/Borrower/LoanCalculatorResponse'

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAN_CALCULATION_REQUEST:
      return { ...state, is_fetching: true }
    case LOAN_CALCULATION_SUCCESS:
      return { ...state, data: action.data, is_fetching: false }
    case LOAN_CALCULATION_FAILURE:
      return { ...initialState, error: action.error, is_fetching: false }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  error: null,
  data: {
    'monthly-loan-instalment': 0,
    'payment-plan': [],
    APR: 0,
    disclaimers: {
      en: '',
      fi: '',
      sv: '',
    },
  },
}

export interface State {
  is_fetching: boolean
  error: any
  data: LoanCalculatorResponse
}
