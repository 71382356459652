import { compose } from 'redux'
import { connect } from 'react-redux'
import Presentation from './Presentation'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../store'
import Dates from '../../../models/RangeDatepicker/Dates'
import { withTranslation } from 'react-i18next'
import getInvestorTransactions from '../../../store/user/investor/dashboard/transactions/actions/getInvestorTransactions'
import getDocument from '../../../store/user/investor/dashboard/transactions/actions/getDocument'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel from './MappedDispatch.model'
import { Company } from 'models/Auth/ProfileData'
import browserHistory from 'browserHistory'

const mapStateToProps = (state: State): MappedStateModel => {
  const companies = state.user.profile.data.companies
  const companyId = browserHistory.location.pathname.startsWith(
    '/dashboard/company'
  )
    ? browserHistory.location.pathname.split('/')[3]
    : undefined

  const company: Company | undefined = companyId
    ? companies.find((company) => company.id === parseInt(companyId))
    : undefined
  const deposits = companyId
    ? company?.deposits || []
    : state.user.profile.data.deposits

  return {
    transactions: state.user.investor.dashboard.transactions.data,
    numberOfDepositAccounts: deposits.length,
    depositId: deposits.length ? deposits[0].mambu_deposit_id : undefined,
    isFetching: state.user.investor.dashboard.transactions.is_fetching,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  getInvestorTransactions: (dates: Dates, type?: string, depositId?: string) =>
    dispatch(getInvestorTransactions(dates, type, depositId)),
  getDocument: (dates: Dates, type?: string, depositId?: string) =>
    dispatch(getDocument(dates, type, depositId)),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, MappedDispatchModel, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Presentation)
