import { connect } from 'react-redux'
import { State } from '../../store'
import { getFixuraToken, isLegacy } from '../../utils/auth'
import PrivateRoutePresentation from './PrivateRoutePresentation'
import { isOnFidoUserVerified } from '../../utils/onFidoUser'
import { isOnFidoUserRejected } from '../../utils/onFidoUser'

export default connect((state: State) => {
  const { profile } = state.user
  const isOnFidoUserVerifiedStatus = isOnFidoUserVerified()
  const onFidoRejected = isOnFidoUserRejected()
  return {
    isAuthorized:
      !onFidoRejected &&
      (isOnFidoUserVerifiedStatus ||
        profile.data.id !== 0 ||
        profile.is_fetching ||
        (!!getFixuraToken() && !isLegacy())),
    pathname: onFidoRejected ? '/onfido/rejected' : '/login',
  }
})(PrivateRoutePresentation)
