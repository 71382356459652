import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap'
import PhoneInput from '../../shared/PhoneInput'
import { connect } from 'react-redux'
import postEmailAndSms from '../../../store/auth/register/sms/actions/postEmailAndSms'
import Indicator from '../../shared/Indicator'
import Steps from './Steps'
import FixuraInput from '../../shared/FixuraInput'
import EmailAndSmsForRegistrationRequest from '../../../models/Auth/Sms/EmailAndSmsForRegistrationRequest'
import { State } from '../../../store'
import { unprocessableEntityErrorHandler } from '../../../utils'
import { ThunkDispatch } from 'redux-thunk'
import { checkoutStep1 } from '../../../utils/gtm/purchase'
import { useTranslation } from 'react-i18next'
import getPersonalDetails from 'store/legacy/user/action/getPersonalDetails'
import User from 'models/Legacy/User'
import { getLegacyData } from 'utils/auth'
import LegacyPersonalData from './LegacyPersonalData'
import { Link, useLocation } from 'react-router-dom'

interface ComponentProps {
  isRegister: boolean
}

interface Props extends MappedState, MappedDispatch, ComponentProps {}

const ProvideDetails = (props: Props) => {
  const {
    isRegister,
    isFetching,
    postEmailAndSmsData,
    getPersonalDetails,
    error,
    email,
    is_checked_offers,
    legacyData,
  } = props

  const isForeigner = getLegacyData().is_foreigner
  const { t } = useTranslation()
  const location = useLocation()

  useEffect(() => {
    if (!isRegister) {
      getPersonalDetails()
    }
  }, [getPersonalDetails, isRegister])

  const localUnprocessableEntityErrorHandler = unprocessableEntityErrorHandler.bind(
    undefined,
    ['email', 'mobile_phone'],
    error
  )

  const [fields, setFields] = useState({
    email: email ? email : '',
    mobile_phone: '',
    is_checked_offers: is_checked_offers ? is_checked_offers : false,
    state: '',
    language: '',
    verify_email: email ? email : '',
    isTermsAndConditionsAccepted: false,
  })

  const [errors, setErrors] = useState(localUnprocessableEntityErrorHandler())
  useEffect(() => {
    setErrors(localUnprocessableEntityErrorHandler())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    checkoutStep1()
  }, [])

  const localHandleChange = (
    field: string,
    e: React.FormEvent<HTMLInputElement>
  ) => handleChange(field, fields, setFields, e)
  const submitData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleValidation(fields, isRegister, isForeigner, setErrors, t)) {
      if (!fields.hasOwnProperty('is_checked_offers')) {
        return postEmailAndSmsData({ ...fields, is_checked_offers: false })
      }
      // logic is_checked_offers send to BE (replacing false with true)
      // requirement is change UI consent to opposite text
      // that not require BE to change
      if (fields.is_checked_offers) {
        postEmailAndSmsData({ ...fields, is_checked_offers: false })
      } else {
        postEmailAndSmsData({ ...fields, is_checked_offers: true })
      }
    }
  }

  const toggleIsCheckedOffers = () => {
    if (fields.hasOwnProperty('is_checked_offers')) {
      setFields({ ...fields, is_checked_offers: !fields['is_checked_offers'] })
    } else {
      setFields({ ...fields, is_checked_offers: true })
    }
  }

  const toggleTermsAndConditions = () => {
    setFields({
      ...fields,
      isTermsAndConditionsAccepted: !fields['isTermsAndConditionsAccepted'],
    })
  }

  // fixme name of user should be shown.
  return (
    <section id="container" className="container borrower-onboarding">
      <Indicator isVisible={isFetching} />
      <Steps currentStepIndex={1} />
      <div className="phone">
        <div className={'d-block'}>
          <h1 className="font-50 step-big-title">
            {t('PROVIDE_DETAILS_TITLE')}!
          </h1>
          <p className="text-center pb-4 mb-4">{t('IDENTIFICATION_MESSAGE')}</p>
          <Form onSubmit={submitData}>
            <PhoneInput
              autoFocus
              onChange={(mobile_phone: string) => {
                setFields({ ...fields, mobile_phone })
              }}
              hasError={errors.hasOwnProperty('mobile_phone')}
              errorMessage={errors['mobile_phone']}
            />
            <FormGroup className="my-5">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <FixuraInput
                    className="w-100"
                    type="text"
                    placeholder={t('EMAIL_PLACEHOLDER')}
                    onChange={localHandleChange.bind(undefined, 'email')}
                    hasError={errors.hasOwnProperty('email')}
                    errorMessage={errors['email']}
                    defaultValue={fields.email}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="mb-5">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <FixuraInput
                    className="w-100"
                    type="text"
                    placeholder={t('CONFIRM_EMAIL_PLACEHOLDER')}
                    onChange={localHandleChange.bind(undefined, 'verify_email')}
                    hasError={errors.hasOwnProperty('verify_email')}
                    errorMessage={errors['verify_email']}
                    onPaste={(e) => e.preventDefault()}
                    defaultValue={fields.verify_email}
                  />
                </Col>
              </Row>
            </FormGroup>
            {isForeigner && !isRegister && (
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <LegacyPersonalData data={legacyData} />
                </Col>
              </Row>
            )}
            <FormGroup>
              <Row>
                {isForeigner && (
                  <Col lg={{ size: 6, offset: 3 }}>
                    <div className="d-flex text-left">
                      <div>
                        <input
                          className="terms-and-conditions__checkbox__color"
                          type="checkbox"
                          disabled={false}
                          checked={fields.isTermsAndConditionsAccepted}
                          onChange={toggleTermsAndConditions}
                        />
                      </div>
                      <div className="ml-3 accept-term">
                        <Link to={`${location.pathname}/terms-and-conditions`}>
                          {t('TERMS_AND_CONDITIONS_PRICE_LIST_PRIVACY_POLICY')}
                        </Link>
                        {errors.hasOwnProperty(
                          'isTermsAndConditionsAccepted'
                        ) && (
                          <div>
                            <small className="text-danger">
                              {errors['isTermsAndConditionsAccepted']}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                )}
                <Col lg={{ size: 6, offset: 3 }}>
                  <FormGroup check className="receive-offers__wrapper">
                    <Input
                      type="checkbox"
                      onChange={toggleIsCheckedOffers}
                      checked={fields.is_checked_offers}
                    />
                    <div className="receive-offers__title">
                      {t('NOT_RECEIVE_OFFERS_TITLE')}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            <Row>
              <Col className="text-center">
                <Button type="submit" className="mt-5 min-w-10">
                  {t('CONTINUE')}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </section>
  )
}

function handleChange(
  field,
  fields,
  setFields,
  e: React.FormEvent<HTMLInputElement>
) {
  fields[field] = e.currentTarget.value
  setFields(fields)
}

function handleValidation(fields, isRegister, isForeigner, setErrors, t) {
  const errors = {}
  let formIsValid = true

  //mobile phone validation
  const mobileValidatior = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{2}[-\s.]?[0-9]{4,8}$/im
  if (!mobileValidatior.test(fields['mobile_phone'].toLowerCase())) {
    formIsValid = false
    errors['mobile_phone'] = t('PHONE_NUMBER_IS_NOT_VALID')
  }

  // email validation
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!re.test(fields['email'].toLowerCase())) {
    formIsValid = false
    errors['email'] = t('EMAIL_IS_NOT_VALID')
  } else if (fields['email'] !== fields['verify_email']) {
    formIsValid = false
    errors['verify_email'] = t('VERIFY_EMAIL_ADDRESS_IS_WRONG')
  }

  if (isForeigner) {
    if (!fields['isTermsAndConditionsAccepted']) {
      errors['isTermsAndConditionsAccepted'] = t('PLEASE_CHECK_TERMS')
      formIsValid = false
    }
  }

  setErrors(errors)
  return formIsValid
}

interface MappedState {
  isFetching: boolean
  error: any
  email?: string
  is_checked_offers?: boolean
  legacyData: User
  isCcisAccepted?: boolean
}

interface MappedDispatch {
  postEmailAndSmsData: (data: EmailAndSmsForRegistrationRequest) => void
  getPersonalDetails: () => {}
}

const mapStateToProps = (state: State): MappedState => ({
  isFetching:
    state.auth.register.sms.is_fetching || state.legacy.user.is_fetching,
  error: state.auth.register.sms.error,
  email:
    state.auth.register.sms.request_body &&
    state.auth.register.sms.request_body.email,
  is_checked_offers:
    state.auth.register.sms.request_body &&
    !state.auth.register.sms.request_body.is_checked_offers,
  legacyData: state.legacy.user.data,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  postEmailAndSmsData: (data) => dispatch(postEmailAndSms(data)),
  getPersonalDetails: () => dispatch(getPersonalDetails()),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ProvideDetails)
