import {
  createGetTotalLoanAmtLeftFailureAction,
  createGetTotalLoanAmtLeftRequestAction,
  createGetTotalLoanAmtLeftSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'

const getTotalLoanAmtLeft = (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch
) => {
  dispatch(createGetTotalLoanAmtLeftRequestAction())
  try {
    const borrowerAPI = new BorrowerAPI()
    const response = await borrowerAPI.getTotalLoanAmtLeft()
    dispatch(createGetTotalLoanAmtLeftSuccessAction(response.data.data))
  } catch (error) {
    dispatch(createGetTotalLoanAmtLeftFailureAction(error))
  }
}

export default getTotalLoanAmtLeft
