import {ThunkAction} from 'redux-thunk'
import {Dispatch} from 'redux'
import {
    createStoreLoanApplicationFailureAction,
    createStoreLoanApplicationRequestAction,
    createStoreLoanApplicationSuccessAction,
} from './creators'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import browserHistory from '../../../../../browserHistory'
import {AxiosResponse} from 'axios'
import StoreLoanApplicationRequest from '../../../../../models/Borrower/StoreLoanApplicationRequest'

const storeLoanApplication = (
    data: StoreLoanApplicationRequest
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
    dispatch(createStoreLoanApplicationRequestAction())

    try {
        const borrowerAPI = new BorrowerAPI()
        const response: AxiosResponse = await borrowerAPI.storeLoanApplication(data)

        dispatch(
            createStoreLoanApplicationSuccessAction(
                response && response.data && response.data.data.loan_application
            )
        )

        if (response.data.data.rejected) {
            browserHistory.push('/dashboard/borrower/loan-rejected')
        } else {
            browserHistory.push('/dashboard/borrower/ccis')
        }
    } catch (err) {
        browserHistory.push('/dashboard/borrower')
    }
}

export default storeLoanApplication
