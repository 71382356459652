import React from 'react'
import { Col, Row, Container } from 'reactstrap'
import { RadioElement } from '../../../../../shared/RadioGroup/Models'
import RadioGroup from '../../../../../shared/RadioGroup'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import { ScrollElement } from '../../../../../../utils/scroll'
import Columns from '../../../../../shared/Wrapper/Columns'
import AddBeneficialOwners from '../AddBeneficialOwners'

interface ComponentProps {
  setFields: (fields) => void
  fields: any
  errors: any
}

interface Props extends ComponentProps {}

const extractPepYesNoFromFields = (is_pep?: boolean) => {
  if (is_pep === true) {
    return 'yes'
  } else if (is_pep === false) {
    return 'no'
  }
  return undefined
}

const IsBeneficialOwners = (props: Props) => {
  const { setFields, fields, errors } = props
  const { t } = useTranslation()
  const hasError = errors.hasOwnProperty('is_beneficial_owner')

  const BOCheckElements: RadioElement[] = [
    {
      id: 'yes',
      label: t('YES'),
    },
    {
      id: 'no',
      label: t('NO'),
    },
  ]
  const passFields = (id: string) => {
    const newFields = { ...fields, is_beneficial_owner: id === 'yes' }
    setFields(newFields)
  }

  return (
    <Container className="aml__wrapper">
      <ScrollElement name="is_beneficial_owner" />
      <Row className="mb-5">
        <Col className="text-center">
          <div>{t('BENEFICIAL_OWNERS_CHECK_QUESTION')}</div>
        </Col>
      </Row>
      <Row className="text-center">
        <RadioGroup
          elements={BOCheckElements}
          selectedElementId={extractPepYesNoFromFields(
            fields['is_beneficial_owner']
          )}
          onChange={passFields}
          ElementWrapper={Columns}
        />
      </Row>
      <div className="text-center mb-5">
        {hasError && (
          <small className="validation-error">
            {errors['is_beneficial_owner']}
          </small>
        )}
      </div>
      {fields['is_beneficial_owner'] && (
        <>
          <AddBeneficialOwners setFields={setFields} fields={fields} />
        </>
      )}
    </Container>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>()

export default enhancer(IsBeneficialOwners)
