import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'

interface ComponentProps {
  allIsLoaded: boolean
  toggle: () => void
  isVisible?: boolean
}

interface Props extends WithTranslation, ComponentProps {}

const LoadMoreLoadLess = (props: Props) => {
  const { t, allIsLoaded, toggle, isVisible } = props

  return (
    isVisible && (
      <Row className="mt-5">
        <Row className="m-auto">
          <Col>
            <Button
              className={loadClass(allIsLoaded)}
              color="link"
              onClick={toggle}
            >
              {allIsLoaded ? t('LOAD_LESS') : t('LOAD_MORE')}
            </Button>
          </Col>
        </Row>
      </Row>
    )
  )
}

LoadMoreLoadLess.defaultProps = {
  isVisible: false,
}

const loadClass = (assIsLoaded: boolean) => {
  if (assIsLoaded) {
    return 'load-more_btn less d-inline-block'
  }
  return 'load-more_btn more d-inline-block'
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(LoadMoreLoadLess)
