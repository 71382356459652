import React from 'react'

interface IProps {
  html?: string
}

const Contract: React.FunctionComponent<IProps> = ({ html, children }) => {
  return (
    <div className="contract">
      <div className="contract__frame">
        <div className="contract__content text-left">
          {children ? (
            <div className="contracts-shared">{children}</div>
          ) : (
            <div
              className="contracts-shared"
              dangerouslySetInnerHTML={{ __html: html || '' }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default Contract
