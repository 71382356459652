import User from '../../../models/Legacy/User'
import {
  GET_PERSONAL_DETAILS_FAILURE,
  GET_PERSONAL_DETAILS_REQUEST,
  GET_PERSONAL_DETAILS_SUCCESS,
} from './action/types'
import { LegacyUser, LegacyCompany } from 'models/Auth/ProfileData'

export default (state: State = initialState, action) => {
  switch (action.type) {
    case GET_PERSONAL_DETAILS_REQUEST:
      return { ...initialState, is_fetching: true }
    case GET_PERSONAL_DETAILS_SUCCESS:
      return {
        ...initialState,
        data: action.data,
        legacy_users: action.legacy_users,
      }
    case GET_PERSONAL_DETAILS_FAILURE:
      return { ...initialState, error: action.error }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  legacy_users: [],
  legacy_companies: [],
  data: {
    Email: '',
    FirstName: '',
    LastName: '',
    SSN: '',
    MobilePhone: '',
    Gender: '',
    Address: {
      Country: '',
      City: '',
      Address: '',
      PostCode: '',
    },
    Nationality: '',
    isForeigner: false,
  },
  error: null,
}

export interface State {
  is_fetching: boolean
  legacy_users: LegacyUser[]
  legacy_companies: LegacyCompany[]
  data: User
  error: any
}
