import browserHistory from 'browserHistory'
import { setUrl } from 'components/shared/helper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Row } from 'reactstrap'
import { store } from '../../../store'

const MarketingSection = () => {
  const { t } = useTranslation()
  const language = store.getState().user.profile.data.language

  function onClickTakeLoan() {
    window.open(setUrl(language), '_blank')
    browserHistory.push('/dashboard')
  }
  return (
    <section className="p-3 text-center">
      <h1>{t('APPLY_FOR_A_NEW_LOAN')}</h1>
      <div>
        <p className="my-2 font-weight-bolder">{t('APPLY_NOW')}</p>
        <p className="mb-1">{t('MARKETING_BOTTOM_DESCRIPTION_FIRST_LINE')}</p>
        <p>{t('MARKETING_BOTTOM_DESCRIPTION_SECOND_LINE')}</p>
      </div>
      <Row className="center">
        <Col lg="3" className="text-center take-loan__btn">
          <Button onClick={onClickTakeLoan}>{t('APPLY_FOR_A_LOAN')}</Button>
        </Col>
      </Row>
    </section>
  )
}

export default MarketingSection
