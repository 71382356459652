import { SET_BROKER_LOAN_APPLICATION_SUCCESS } from './types'
import { SET_BROKER_LOAN_APPLICATION_REQUEST } from './types'
import { SET_BROKER_LOAN_APPLICATION_FAILURE } from './types'

export const createGetBrokerLoanApplicationSuccessAction = (
  requested_amount: string,
  requested_term: string
) => ({
  requested_amount,
  requested_term,
  type: SET_BROKER_LOAN_APPLICATION_SUCCESS,
})

export const createGetBrokerLoanApplicationRequestAction = () => ({
  type: SET_BROKER_LOAN_APPLICATION_REQUEST,
})

export const createGetBrokerLoanApplicationFailureAction = (error: any) => ({
  error,
  type: SET_BROKER_LOAN_APPLICATION_FAILURE,
})
