import unitedKingdomFlagRoundSmall from 'assets/images/flags/united-kingdom-flag-round-small.png'
import finlandFlagRoundSmall from 'assets/images/flags/finland-flag-round-small.png'
import swedenFlagRoundSmall from 'assets/images/flags/sweden-flag-round-small.png'
import germanFlagRoundSmall from 'assets/images/flags/germany-flag-round-small.png'

export const RANGE_DEFAULT_MONTHS = 48
export const RANGE_DEFAULT_AMOUNT = 4500
export const RESENT_SMS_TIME = 300
export const VALID_UP_TO__THREE_DAYS = 3
export const LOCAL_STORAGE_TOKEN_NAME = 'fixura_token'
export const LOCAL_STORAGE_IS_LEGACY = 'is_legacy'
export const LOCAL_STORAGE_LEGACY_ROLE = 'legacy_role'
export const LOCAL_STORAGE_LEGACY_CAN_BE_ONBOARDED = 'legacy_can_be_onboarded'
export const LOCAL_STORAGE_LEGACY_IS_FOREIGNER = 'legacy_is_foreigner'
export const FINNISH_NATIONALITY = 'FI'
export const FINNISH_LANGUAGE = 'fi'
export const FAIR_CREDIT = 'fair'
export const ALLOW_TO_INVEST = false //TEMP changed 010722
export const INVEST_IN_SAVLEND_LINK = 'https://fixura.com/en/invest/'
export const SHOW_INVESTOR_REGISTER_LINK = false
export const BASE_URL =
  process.env.REACT_APP_BASE_URL ?? 'https://dev-api-gw.fixura.com'
export const TINK_LINK_URL =
  process.env.REACT_APP_TINK_LINK_URL ??
  'https://link.tink.com/1.0/authorize/?client_id=8965f4199249422aa4ed91b5273cee2f&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Ftink-callback&scope=accounts:read,investments:read,transactions:read,user:read&market=FI&locale=en_US&test=true'
export const countries = [
  { name: 'English', locale: 'en', img: unitedKingdomFlagRoundSmall },
  { name: 'Suomeksi', locale: 'fi', img: finlandFlagRoundSmall },
  { name: 'Svenska', locale: 'se', img: swedenFlagRoundSmall },
  { name: 'Deutsch', locale: 'de', img: germanFlagRoundSmall },
]
export const olarkSiteId = '9325-565-10-9280'
export const LOCAL_STORAGE_REDIRECT_PATH_AFTER_LOGIN =
  'redirect_path_after_login'

export const urlTermsAndConditionsEN = `https://fixura.com/en/general-terms`
export const urlTermsAndConditionsSV = `https://fixura.com/sv/allmanna-villkor`
export const urlTermsAndConditionsFI = `https://fixura.com/fi/yleisetehdot`

export const urlPrivacyPolicyEN = `https://fixura.com/en/privacypolicy`
export const urlPrivacyPolicySV = `https://fixura.com/sv/dataskyddbeskrivning`
export const urlPrivacyPolicyFI = `https://fixura.com/fi/tietosuojaseloste`

export const urlPriceListtEN = `https://fixura.com/en/pricing`
export const urlPriceListtSV = `https://fixura.com/sv/priser`
export const urlPriceListtFI = `https://fixura.com/fi/hinnasto`

export const urlApplyLoanFI = `https://fixura.com/`
export const urlApplyLoanEN = `https://fixura.com/en/loans`
export const urlApplyLoanSV = `https://fixura.com/sv/lan`
