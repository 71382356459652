import { Redirect, Route, Switch } from 'react-router'
import React from 'react'
import IdentityVerification from '../components/Auth/Register/IdentityVerification'
import TermsAndConditionsModal from '../components/Auth/TermsAndConditionsModal'
import TermsAndConditionsModal1stMarch2021 from '../components/Auth/TermsAndConditionsModalProduct21'
import ProvideDetails from '../components/Auth/Register/ProvideDetails'
import ForeignInvestorProvideDetails from '../components/Auth/Register/ForeignInvestor/ForeignInvestorProvideDetails'
import VerifyPhoneNumber from '../components/Auth/Register/VerifyPhoneNumber'
import ProvideDetailGuardRoute from './guards/register/ProvideDetailGuardRoute'
import VerifyPhoneNumberGuardRoute from './guards/register/VerifyPhoneNumberGuardRoute'
import RegisterRejectedGuardRoute from './guards/register/RegisterRejectedGuardRoute'
import RegisterRejected from '../components/Auth/Register/RegisterRejected'

export default () => (
  <Switch>
    <Route exact path="/register">
      <Redirect to={{ pathname: '/register/identity-verification' }} />
    </Route>
    <Route path="/register/identity-verification">
      <IdentityVerificationView />
    </Route>
    {/* @ts-ignore */}
    <ProvideDetailGuardRoute path="/register/provide-details">
      <ProvideDetails isRegister={true} />
    </ProvideDetailGuardRoute>
    <Route path="/register/foreign-investor/provide-details">
      <ForeignIdentityVerificationView />
    </Route>
    {/* @ts-ignore */}
    <RegisterRejectedGuardRoute path="/register/rejected">
      <RegisterRejected />
    </RegisterRejectedGuardRoute>
    {/* @ts-ignore */}
    <VerifyPhoneNumberGuardRoute path="/register/verify-phone-number">
      <VerifyPhoneNumber />
    </VerifyPhoneNumberGuardRoute>
  </Switch>
)

const IdentityVerificationView = () => (
  <>
    <IdentityVerification />
    <Switch>
      <Route path="/register/identity-verification/terms-and-conditions">
        <TermsAndConditionsModal />
      </Route>
      <Route path="/register/identity-verification/terms-and-conditions-and-price-list">
        <TermsAndConditionsModal1stMarch2021 />
      </Route>
    </Switch>
  </>
)

const ForeignIdentityVerificationView = () => (
  <>
    <ForeignInvestorProvideDetails />
    <Switch>
      <Route path="/register/foreign-investor/provide-details/terms-and-conditions">
        <TermsAndConditionsModal />
      </Route>
      <Route path="/register/foreign-investor/provide-details/terms-and-conditions-and-price-list">
        <TermsAndConditionsModal1stMarch2021 />
      </Route>
    </Switch>
  </>
)
