import {
  GET_INVESTOR_KPI_FAILURE,
  GET_INVESTOR_KPI_REQUEST,
  GET_INVESTOR_KPI_SUCCESS,
  STORE_DATES,
} from './actions/types'
import InvestorKpi from '../../../../../models/InvestorKpi'
import Dates from '../../../../../models/RangeDatepicker/Dates'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INVESTOR_KPI_REQUEST:
      return { ...state, is_fetching: true }
    case GET_INVESTOR_KPI_SUCCESS:
      return { ...state, data: action.data, is_fetching: false, error: null }
    case GET_INVESTOR_KPI_FAILURE:
      return { ...initialState, error: action.error }
    case STORE_DATES:
      return { ...state, dates: action.dates }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  data: {
    nr_active_investments: 0,
    active_investments: '0.0',
    free_capital: '0.0',
    withdrawals: '0.0',
    deposits: '0.0',
    repaid_capital: '0.0',
    interest_paid: '0.0',
    portfolio_value: '0.0',
    fees_paid: '0.0',
    reference_number: '',
    roi: 0,
    portfolio_growth: 0,
    credit_losses: 0,
  },
  dates: {},
  error: null,
}

export interface State {
  is_fetching: boolean
  data: InvestorKpi
  dates: Dates
  error: any
}
