import {
  ACCEPT_TERMS_AND_CONDITIONS,
  GET_TERMS_AND_CONDITIONS_FAILURE,
  GET_TERMS_AND_CONDITIONS_REQUEST,
  GET_TERMS_AND_CONDITIONS_SUCCESS,
  REFUSE_TERMS_AND_CONDITIONS,
  TOGGLE_TERMS_AND_CONDITIONS,
  SIGN_TERMS_AND_CONDITIONS_REQUEST,
  SIGN_TERMS_AND_CONDITIONS_SUCCESS,
  SIGN_TERMS_AND_CONDITIONS_FAILURE,
  SIGN_TERMS_AND_CONDITIONS_PRE_RELEASE_REQUEST,
  SIGN_TERMS_AND_CONDITIONS_PRE_RELEASE_SUCCESS,
  SIGN_TERMS_AND_CONDITIONS_PRE_RELEASE_FAILURE,
  SIGN_COMPANY_TERMS_AND_CONDITIONS_REQUEST,
  SIGN_COMPANY_TERMS_AND_CONDITIONS_SUCCESS,
  SIGN_COMPANY_TERMS_AND_CONDITIONS_FAILURE,
  SET_SHOULD_AGREE_WITH_TERMS_AND_CONDITIONS,
} from './types'
import TermsAndConditions from '../../../../../models/General/TermsAndConditions'

export const createGetTermsAndCondtionsRequestAction = () => ({
  type: GET_TERMS_AND_CONDITIONS_REQUEST,
})
export const createGetTermsAndCondtionsSuccessAction = (
  data: TermsAndConditions
) => ({
  data,
  type: GET_TERMS_AND_CONDITIONS_SUCCESS,
})
export const createGetTermsAndCondtionsFailureAction = (error: any) => ({
  error,
  type: GET_TERMS_AND_CONDITIONS_FAILURE,
})

export const createAcceptTermsAndConditionsAction = () => ({
  type: ACCEPT_TERMS_AND_CONDITIONS,
})
export const createRefuseTermsAndConditionsAction = () => ({
  type: REFUSE_TERMS_AND_CONDITIONS,
})
export const createToggleTermsAndConditionsAction = () => ({
  type: TOGGLE_TERMS_AND_CONDITIONS,
})

export const createSignTermsAndConditionsRequestAction = () => ({
  type: SIGN_TERMS_AND_CONDITIONS_REQUEST,
})
export const createSignTermsAndConditionsSuccessAction = () => ({
  type: SIGN_TERMS_AND_CONDITIONS_SUCCESS,
})
export const createSignTermsAndConditionsFailureAction = (error) => ({
  error,
  type: SIGN_TERMS_AND_CONDITIONS_FAILURE,
})

export const createSignTermsAndConditionsPreReleaseRequestAction = () => ({
  type: SIGN_TERMS_AND_CONDITIONS_PRE_RELEASE_REQUEST,
})
export const createSignTermsAndConditionsPreReleaseSuccessAction = () => ({
  type: SIGN_TERMS_AND_CONDITIONS_PRE_RELEASE_SUCCESS,
})
export const createSignTermsAndConditionsPreReleaseFailureAction = (error) => ({
  error,
  type: SIGN_TERMS_AND_CONDITIONS_PRE_RELEASE_FAILURE,
})

export const createSignCompanyTermsAndConditionsRequestAction = () => ({
  type: SIGN_COMPANY_TERMS_AND_CONDITIONS_REQUEST,
})
export const createSignCompanyTermsAndConditionsSuccessAction = () => ({
  type: SIGN_COMPANY_TERMS_AND_CONDITIONS_SUCCESS,
})
export const createSignCompanyTermsAndConditionsFailureAction = (error) => ({
  error,
  type: SIGN_COMPANY_TERMS_AND_CONDITIONS_FAILURE,
})

export const createSetShouldAgreeWithTermsAndConditionsAction = (value) => ({
  value,
  type: SET_SHOULD_AGREE_WITH_TERMS_AND_CONDITIONS,
})
