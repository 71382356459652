import {
  GET_TOTAL_LOAN_AMT_LEFT_REQUEST,
  GET_TOTAL_LOAN_AMT_LEFT_SUCCESS,
  GET_TOTAL_LOAN_AMT_LEFT_FAILURE,
} from './actions/types'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TOTAL_LOAN_AMT_LEFT_REQUEST:
      return { ...initialState }
    case GET_TOTAL_LOAN_AMT_LEFT_SUCCESS:
      return { totalLoanAmtLeft: action.data[0].total_loan_amount_left }
    case GET_TOTAL_LOAN_AMT_LEFT_FAILURE:
      return { ...initialState }
    default:
      return state
  }
}

export const initialState: State = {
  totalLoanAmtLeft: 0,
}

export interface State {
  totalLoanAmtLeft: number
}
