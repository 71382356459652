import {
  SING_CONTRACT_FAILURE,
  SING_CONTRACT_REQUEST,
  SING_CONTRACT_SUCCESS,
  GET_CONTRACT_REQUEST,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAILURE,
} from './actions/types'

export default (state = initialState, action) => {
  switch (action.type) {
    case SING_CONTRACT_REQUEST:
      return { ...initialState, is_fetching: true, html: state.html }
    case SING_CONTRACT_SUCCESS:
      return { ...initialState, html: state.html }
    case SING_CONTRACT_FAILURE:
      return { ...initialState, error: action.error, html: state.html }
    case GET_CONTRACT_REQUEST:
      return { ...initialState, is_fetching: true }
    case GET_CONTRACT_SUCCESS:
      return { ...initialState, html: action.data }
    case GET_CONTRACT_FAILURE:
      return { ...initialState, error: action.error }
    default:
      return state
  }
}

export interface State {
  is_fetching: boolean
  error: any
  html: string
}

export const initialState: State = {
  is_fetching: false,
  error: null,
  html: '',
}
