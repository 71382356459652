import {
  GET_REFERENCE_NUMBER_FAILURE,
  GET_REFERENCE_NUMBER_REQUEST,
  GET_REFERENCE_NUMBER_SUCCESS,
} from './actions/types'
import ReferenceNumber from '../../../../../models/ReferenceNumber'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REFERENCE_NUMBER_REQUEST:
      return { ...state, is_fetching: true }
    case GET_REFERENCE_NUMBER_SUCCESS:
      return { ...initialState, data: action.data }
    case GET_REFERENCE_NUMBER_FAILURE:
      return { ...initialState, error: action.error }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  data: {
    reference_number: '',
    type: '',
  },
  error: null,
}

export interface State {
  is_fetching: boolean
  data: ReferenceNumber
  error: any
}
