import dayjs from 'dayjs'
import { store } from '../store'

export function formatDate(
  date: string,
  format: string = 'YYYY-MM-DD HH:mm:ss',
  outputFormat: string = 'DD.MM.YYYY'
): string {
  return dayjs(date, format).format(outputFormat)
}

export function formatMoney(param: number | string, fixNumber = 2) {
  let { language } = store.getState().user.profile.data
  let separator = language === 'fi' || language === 'se' ? ',' : '.'

  if (typeof param === 'number') {
    return param
      .toFixed(fixNumber)
      .toString()
      .replace('.', separator)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }
  if (typeof param === 'string') {
    return param.replace('.', separator).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }
  return param
}

export function convertPointToComma(num: number) {
  return num ? num.toString().replace('.', ',') : null
}

export function validateEmail(email: string) {
  const regex = /\S+@\S+\.\S+/
  return regex.test(String(email).toLowerCase())
}

export function uniqueString(length: number = 15) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i: number = 0; i < length; i = i + 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const unprocessableEntityErrorHandler = (
  fieldNames: string[],
  error
) => {
  const errors = {}
  if (error && error.response && error.response.status === 422) {
    const backendResponse = error.response.data.errors
    fieldNames.forEach((fieldName) => {
      if (backendResponse[fieldName]) {
        errors[fieldName] = backendResponse[fieldName][0]
      }
    })
  }
  return errors
}

export function setSpaceForReferenceNumber(referenceNumber?: string | number) {
  if (referenceNumber) {
    const digitArray = referenceNumber.toString().split('')
    digitArray.splice(1, 0, ' ')
    digitArray.splice(8, 0, ' ')
    return digitArray.join('')
  }
  return referenceNumber
}

export function setSpaceForPhoneNumber(phoneNumber: string) {
  const digitArray = phoneNumber.toString().split('')
  digitArray.splice(4, 0, ' ')
  digitArray.splice(7, 0, ' ')
  digitArray.splice(11, 0, ' ')
  return digitArray
}

export function getFormattedDueDate(date: string) {
  const day = new Date(date).getDate()
  return day === 1 || day === 21 || day === 31
    ? `${day}ST`
    : day === 2 || day === 22
    ? `${day}ND`
    : `${day}TH`
}

export function capitalizeFirstLetter(input: string) {
  return input
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export function capitalizeAndExtractFirstLetter(input: string) {
  return capitalizeFirstLetter(input.toLowerCase().split(' ')[0])
}

export function removeDecimal(data: number | string) {
  if (typeof data === 'number') {
    return data.toFixed(0)
  }
  return parseInt(data, 10).toFixed(0)
}

export function getBodyContentFromHtml(html: string): any {
  if (html) {
    const result = /<body.*?>([\s\S]*)<\/body>/.exec(html)
    if (result && result.length) {
      return result[1]
    }
  }
  return ''
}

export function fixNumber(
  value: number | string,
  fixedValue: number = 0
): number {
  if (typeof value === 'number') {
    return Number(value.toFixed(fixedValue))
  } else {
    return Number(Number(value).toFixed(fixedValue))
  }
}

export function downloadBorrowerInvoice(data: string) {
  const linkSource = `data:application/pdf;base64,${data}`
  const downloadLink = document.createElement('a')
  const fileName = 'invoice.pdf'

  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}

export const toMinuteSecond = (time) => {
  return `${Math.floor(time / 60)}:${time % 60}`
}

export const formatDueDate = (dueDate: string | number | undefined) => {
  switch (dueDate) {
    case 1:
      return '1ST_OF_MONTH'
    case 10:
      return '10TH_OF_MONTH'
    case 15:
      return '15TH_OF_MONTH'
    case 20:
      return '20TH_OF_MONTH'
    case 25:
      return '25TH_OF_MONTH'
    case 30:
    case 31:
      return 'LAST_OF_MONTH'
    default:
      return
  }
}
