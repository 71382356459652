import { compose } from 'redux'
import { connect } from 'react-redux'
import Presentation from './Presentation'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../store'
import { withTranslation } from 'react-i18next'
import MappedDispatchModel from './MappedDispatch.model'
import MappedStateModel from './MappedState.model'
import { createStoreWithdrawAmountAction } from '../../../store/user/investor/dashboard/withdraw/actions/creators'
import { withRouter } from 'react-router'
import postSignCompanyRight from '../../../store/user/investor/auto_invest/actions/postSignCompanyRight'
import browserHistory from 'browserHistory'
import cancelWithdraw from '../../../store/user/investor/dashboard/withdraw/actions/cancelWithdraw'
import getWithdrawStatus from '../../../store/user/investor/dashboard/withdraw/actions/getWithdrawStatus'

interface ComponentProps {
  isSignRight: any
  companyId?: number
}

const mapStateToProps = (
  state: State,
  props: ComponentProps
): MappedStateModel => {
  const companyId = browserHistory.location.pathname.startsWith(
    '/dashboard/company'
  )
    ? browserHistory.location.pathname.split('/')[3]
    : undefined

  if (companyId) {
    const company = state.user.profile.data.companies.find(
      (company) => company.id === parseInt(companyId)
    )
    const data = state.user.investor.auto_invest.companiesAutoInvest.find(
      (company) => company.company_id === parseInt(companyId)
    )
    if (company && data) {
      return {
        bankAccounts: company.bank_accounts,
        data: state.user.investor.dashboard.kpi.data,
        is_fetching: state.user.investor.dashboard.withdraw.is_fetching,
        error: state.user.investor.dashboard.withdraw.error,
        isSignRight: data.signing_right,
        depositId:
          company.deposits.length > 0
            ? company.deposits[0].mambu_deposit_id
            : '',
        status: state.user.investor.dashboard.withdraw.data,
      }
    }
  }
  return {
    bankAccounts: state.user.profile.data.bank_accounts,
    data: state.user.investor.dashboard.kpi.data,
    is_fetching: state.user.investor.dashboard.withdraw.is_fetching,
    error: state.user.investor.dashboard.withdraw.error,
    depositId:
      state.user.profile.data.deposits.length > 0
        ? state.user.profile.data.deposits[0].mambu_deposit_id
        : '',
    status: state.user.investor.dashboard.withdraw.data,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  withdraw: (amount: number) =>
    dispatch(createStoreWithdrawAmountAction(amount)),
  cancelWithdraw: (depositId: string) => dispatch(cancelWithdraw(depositId)),
  postSignCompany: (code: string, companyId: number) =>
    dispatch(postSignCompanyRight(code, companyId)),
  getWithdrawStatus: (depositId: string) =>
    dispatch(getWithdrawStatus(depositId)),
})

const enhancer = compose<React.FunctionComponent>(
  withRouter,
  withTranslation(),
  connect<MappedStateModel, MappedDispatchModel, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Presentation)
