import React, { useEffect } from 'react'
import Indicator from '../shared/Indicator'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { State } from '../../store'
import { ThunkDispatch } from 'redux-thunk'
import CallbackData from '../../models/Auth/Signicat/CallbackData'
import postSignicatCallbackData from '../../store/auth/register/signicat/actions/postSignicatCallbackData'
import { compose } from 'redux'
import { connect } from 'react-redux'
import browserHistory from '../../browserHistory'

interface Props extends MappedStateModel, MappedDispatchModel {}

const SignicatCallBack = (props: Props) => {
  const location = useLocation()
  const { isFetching, postSignicatCallbackData } = props

  useEffect(
    () => {
      const qs = queryString.parse(location.search)

      if (qs.error) {
        browserHistory.push(
          `/register/identity-verification${location.search.toString()}`
        )
        return
      }

      const data: CallbackData = {
        code: qs.code ? qs.code.toString() : '',
        state: qs.state ? qs.state.toString() : '',
        session_state: qs.session_state ? qs.session_state.toString() : '',
        status: qs.status ? qs.status.toString() : '',
      }
      if (data.status === 'cancel') {
        browserHistory.push(`/register/foreign-investor/provide-details`)
        return
      }
      postSignicatCallbackData(data)
    },
    // eslint-disable-next-line
    []
  )

  return <Indicator isVisible={isFetching} />
}

interface MappedStateModel {
  isFetching: boolean
}

interface MappedDispatchModel {
  postSignicatCallbackData: (data: CallbackData) => void
}

const mapStateToProps = (state: State): MappedStateModel => ({
  isFetching: state.auth.register.signicat.is_fetching,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  postSignicatCallbackData: (data: CallbackData) =>
    dispatch(postSignicatCallbackData(data)),
})

const enhancer = compose<any>(
  connect<MappedStateModel, MappedDispatchModel, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(SignicatCallBack)
