import { connect } from 'react-redux'
import { State } from '../../../../store'
import PrivateRoutePresentation from '../../PrivateRoutePresentation'

export default connect((state: State) => ({
  isAuthorized:
    state.user.profile.data.deposits.length &&
    !state.user.profile.data.deposits[0].has_signed_contract,
  pathname: '/dashboard',
  // @ts-ignore
}))(PrivateRoutePresentation)
