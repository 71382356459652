import {
  GET_COMPANY_AGREEMENTS_REQUEST,
  GET_COMPANY_AGREEMENTS_SUCCESS,
  GET_COMPANY_AGREEMENTS_FAILURE,
  POST_SKIP_COMPANY_SIGNED_AGREEMENTS_SUCCESS,
} from './actions/types'
import { CompanyAgreements } from '../../../../models/Auth/ProfileData'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_AGREEMENTS_REQUEST:
      return { ...state }
    case GET_COMPANY_AGREEMENTS_SUCCESS:
      return { ...initialState, data: { ...action.data } }
    case GET_COMPANY_AGREEMENTS_FAILURE:
      return { ...initialState }
    case POST_SKIP_COMPANY_SIGNED_AGREEMENTS_SUCCESS:
      return {
        ...initialState,
        has_skipped_signed_latest_company_agreements: 1,
      }
    default:
      return state
  }
}

export const initialState: State = {
  has_skipped_signed_latest_company_agreements: 0,
  data: {
    has_signed_terms_and_conditions: false,
    has_signed_latest_terms_and_conditions: false,
    has_signed_company_contract: false,
    has_signed_latest_company_contract: false,
  },
}

export interface State {
  has_skipped_signed_latest_company_agreements: number
  data: CompanyAgreements
}
