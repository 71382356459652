import { Route, Redirect } from 'react-router'
import React from 'react'
import LoginContainer from '../components/LoginContainer'
import LoginWithSca from '../components/Auth/Login/Sca/LoginWithSca'
import TermsAndConditionsModal from '../components/Auth/TermsAndConditionsModal'
import TermsAndConditionsModal1stMarch2021 from '../components/Auth/TermsAndConditionsModalProduct21'
import Login from '../components/Legacy/Auth/Login'
import { Switch } from 'react-router-dom'
import LoginWithPhone from '../components/Auth/Login/Sms/LoginWithPhone'
import VerifyPhoneNumber from '../components/Auth/Login/Sms/VerifyPhoneNumber'

export default () => (
  <LoginContainer>
    <Switch>
      <Route path="/login/email" component={Login} />
      <Route path="/login/sms/code" component={LoginWithPhone} />
      <Route path="/login/sms/verify" component={VerifyPhoneNumber} />
      <Route path="/login/bank" component={LoginWithScaView} />
      <Redirect to={{ pathname: '/login/sms/code' }} />
    </Switch>
  </LoginContainer>
)

const LoginWithScaView = () => (
  <>
    <LoginWithSca />
    <Switch>
      <Route path="/login/bank/terms-and-conditions">
        <TermsAndConditionsModal />
      </Route>
      <Route path="/login/bank/terms-and-conditions-and-price-list">
        <TermsAndConditionsModal1stMarch2021 />
      </Route>
    </Switch>
  </>
)
