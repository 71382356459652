import {
  createGetProfileFailureAction,
  createGetProfileRequestAction,
  createGetProfileSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import UserAPI from '../../../../api/user/UserAPI'
import browserHistory from '../../../../browserHistory'
import i18next from 'i18next'
import { scroll } from '../../../../utils/scroll'
import {
  getRedirectPathAfterLogin,
  removeRedirectPathAfterLogin,
} from 'utils/auth'
import { createAddCompanyInvestorAction } from 'store/user/investor/auto_invest/actions/creators'
import { createAddLoanAction } from 'store/user/borrower/loan/actions/creators'

export const getUserProfile = (
  redirectRoute: string | null = null,
  shouldScroll = false
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createGetProfileRequestAction())
  try {
    const userApi = new UserAPI()
    const response: AxiosResponse = await userApi.getUserProfile()
    const redirectPathAfterLogin = getRedirectPathAfterLogin()

    // set default language for the app
    i18next.changeLanguage(response.data.data.language)

    if (redirectPathAfterLogin) {
      removeRedirectPathAfterLogin()
      browserHistory.push(redirectPathAfterLogin)
    } else if (redirectRoute) {
      if (shouldScroll) {
        scroll('top-of-the-page')
      }
      browserHistory.push(redirectRoute)
    }
    dispatch(createAddCompanyInvestorAction(response.data.data.companies))
    dispatch(createAddLoanAction(response.data.data.loans))
    dispatch(createGetProfileSuccessAction(response.data.data))
  } catch (error) {
    browserHistory.push('/')
    dispatch(createGetProfileFailureAction(error))
  }
}
