import React from 'react'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import { State } from '../../../store'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import aispRetry from '../../../store/user/borrower/loan_application/actions/aispRetry'
import { useHistory } from 'react-router'
import Indicator from '../../shared/Indicator'
import { loanUnmatched } from '../../../utils/gtm/checkout'
import { useEffect } from 'react'
import getActiveLoanApplication from '../../../store/user/borrower/loan_application/actions/getActiveLoanApplication'

interface Props extends WithTranslation, MappedState, MappedDispatch {}

const Reject = (props: Props) => {
  const { t, aispRetry, isFetching, getActiveLoans, retry } = props

  const history = useHistory()

  useEffect(() => {
    getActiveLoans()
  }, [getActiveLoans])

  // google tag
  useEffect(() => {
    loanUnmatched()
  }, [])

  return (
    <>
      <Indicator isVisible={isFetching} />
      <div className="loan-rejection__wrapper">
        <aside className="loan-reject-message__container">
          <div className="container">
            <h1 className="loan__main-title text-center">
              {t('AISP_REJECTION_TITLE')}
            </h1>
            <p className="loan-rejection__description text-center">
              {t('AISP_REJECTION_DESCRIPTION')}
            </p>
            <div className="d-flex justify-content-center align-items-center mt-5 ">
              <Button
                className="hero mr-5"
                color="secondary"
                disabled={!retry}
                onClick={aispRetry}
              >
                {t('TRY_AGAIN')}
              </Button>
              <Button
                className="hero light"
                color="secondary"
                onClick={() => history.push('/dashboard')}
              >
                {t('CANCEL_APPLICATION')}
              </Button>
            </div>
          </div>
        </aside>
      </div>
    </>
  )
}

export interface MappedState {
  retry: boolean
  isFetching: boolean
}

interface MappedDispatch {
  aispRetry: () => void
  getActiveLoans: () => void
}

const mapStateToProps = (state: State): MappedState => {
  return {
    retry: !!state.user.borrower.loan_application.data.retry,
    isFetching: state.user.borrower.loan_application.is_fetching,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  aispRetry: () => dispatch(aispRetry()),
  getActiveLoans: () => dispatch(getActiveLoanApplication()),
})

const enhance = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedState, MappedDispatch, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhance(Reject)
