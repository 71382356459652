import React from 'react'
import FixuraLogo from '../../shared/FixuraLogo'

const TermsAndConditions: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared t-and-c__v02">
      <FixuraLogo size="large" color={'dark'} />
      <br />
      <h3>FIXURA AB OY – ALLMÄNNA VILLKOR</h3>
      <h3>Gällande från och med 15.6.2021</h3>
      <p>
        Dessa allmänna villkor (”Allmänna Villkor”) gäller för alla
        Investeringsavtal och Låneavtal från och med 15.6.2021. Lån som lyfts
        och investeringar som gjorts innan 1.2.2020 omfattas av de tidigare,
        1.3.2019 daterade, Allmänna Villkor som finns tillgängliga på Fixuras
        nättjänst.
      </p>

      <h3>1. DEFINTIONER</h3>
      <table className="definitions">
        <tbody>
          <tr>
            <td>
              <b>Autoinvest</b>
            </td>
            <td>
              Autoinvest Automatisk placeringsfunktion som Fixura erbjuder genom
              sina tjänster.
            </td>
          </tr>
          <tr>
            <td>
              <b>Autosell</b>
            </td>
            <td>
              Automatisk försäljningsfunktion som Fixura erbjuder genom sina
              tjänster.
            </td>
          </tr>
          <tr>
            <td>
              <b>Fixura</b>
            </td>
            <td>Fixura Ab Oy (FO-nummer 2246639-7).</td>
          </tr>
          <tr>
            <td>
              <b>Fixura-konto</b>
            </td>
            <td>
              Ett separat konto i Fixuras tjänst, som skapats åt användaren och
              genom vilken användaren kan investera och låna medel.
            </td>
          </tr>
          <tr>
            <td>
              <b>Ränta</b>
            </td>
            <td>Ränta på Lånet.</td>
          </tr>
          <tr>
            <td>
              <b>Lån</b>
            </td>
            <td>
              Lån Belopp som Investerare betalat åt Låntagaren på grund av ett
              Låneavtal.
            </td>
          </tr>
          <tr>
            <td>
              <b>Låntagare</b>
            </td>
            <td>
              Användare som har lämnat in en Låneansökan och/eller ingått ett
              Låneavtal i egenskap av låntagare.
            </td>
          </tr>
          <tr>
            <td>
              <b>Låneavtal</b>
            </td>
            <td>
              Avtal som ingåtts mellan Låntagaren och Investeraren, där parterna
              avtalar om bl.a. lånekapital, ränta och betalningsplan.
            </td>
          </tr>
          <tr>
            <td>
              <b>Bankdag</b>
            </td>
            <td>
              Vilken som helst vardag med undantag av lördagar, söndagar eller
              övriga helg- och vilodagar.
            </td>
          </tr>
          <tr>
            <td>
              <b>Investering</b>
            </td>
            <td>
              Investering Ett av Investeraren placerat belopp från vilket
              Låntagaren kan ansöka om lånekapital.
            </td>
          </tr>
          <tr>
            <td>
              <b>Investerare</b>
            </td>
            <td>
              Användare av nättjänsten som godkänt Fixuras Investeringsavtal och
              placerat medel till Fixura-kontot för utlåning till Låntagare.
            </td>
          </tr>
          <tr>
            <td>
              <b>Investeringsavtal</b>
            </td>
            <td>
              Avtal som ingåtts mellan Investeraren och Fixura där parterna
              avtalar om investeringssamarbetet.
            </td>
          </tr>
          <tr>
            <td>
              <b>Prislista</b>
            </td>
            <td>
              Prislista Den rådande prislista som finns i Fixuras nättjänst,
              enligt vilken Fixura debiterar kostnader av Investerare och
              Låntagare.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <h3>2. INLEDNING</h3>
      <p>
        Fixura är ett företag, vars affärsidé och verksamhetsmodell är att
        fungera som en länk i syfte att förena Låntagare med sådana som vill
        låna ut sina medel till andra. Fixura fungerar som en flexibel
        marknadsplats för dessa aktörer och förmedlar Lån från Investerare till
        Låntagare som en del av affärsverksamheten. De kostnader som Fixura
        debiterar av Investerare och Låntagare finns i Prislistan, som utgör en
        integrerad del av dessa Allmänna Villkor.
      </p>
      <p>
        Genom att registrera sig som användare i nättjänsten godkänner
        användaren dessa Allmänna Villkor som rättsligt bindande. Dessa Allmänna
        Villkor utgör en del av Låneavtalet. Om dessa Allmänna Villkor avviker
        från det som uttryckligen avtalats i Låneavtalet ges Låneavtalets
        bestämmelser företräde.
      </p>
      <p>
        Dessa Allmänna Villkor utgör också en del av Investeringsavtalet. Om
        dessa Allmänna Villkor avviker från vad som uttryckligen avtalats i
        Investeringsavtalet ges Investeringsavtalets bestämmelser företräde.
      </p>
      <p>
        Fixura betonar att Fixura endast fungerar som en förmedlare mellan
        Investerare och Låntagare. Fixura erbjuder inte sådana
        investeringstjänster som antyds i lagen om investeringstjänster. Fixura
        är registrerad i registret för kreditgivare och förmedlare av
        person-till-person-lån hos Regionförvaltningsverket i Södra-Finland.
      </p>

      <h3>3. VILLKOR FÖR LÅNTAGARE</h3>
      <h3>Möjlighet att få Lån</h3>
      <p>
        En Låntagare har möjlighet att få Lån som förmedlas av Fixura om
        personen i fråga:
      </p>
      <ul>
        <li>är en myndig person, minst 21 år;</li>
        <li>bott stadigvarande i Finland under minst två år;</li>
        <li>har regelbundna inkomster;</li>
        <li>
          försett Fixura med all behövlig information för att vidare kunna
          bedöma Låntagarens kreditvärdighet;
        </li>
        <li>
          inte har offentliga betalningsanmärkningar, obetalda eller förfallna
          Lån; och
        </li>
        <li>
          inte är styrelseledamot i ett företag som har registrerade
          betalningsanmärkningar.
        </li>
      </ul>
      <p>
        Innan Lånet beviljas går Fixura igenom Låntagarens information och
        uppgifter utifrån Låntagarens samtycke och dataskyddslagen. Låntagaren
        ansvarar personligen för att informationen är riktig och sanningsenlig.
        Låntagaren bör även utan dröjsmål meddela Fixura om alla eventuella
        adress- och namnändringar. Investeraren eller Fixura ansvarar inte för
        fel som framgår ur ansökningsuppgifterna och/eller Låneavtalet, ej
        heller för dröjsmål eller andra påföljder som följer av sådana fel. Om
        Låntagaren inte uppfyller sin skyldighet att underrätta Fixura med all
        behövlig information har Fixura rätt att av Låntagaren kräva ersättning
        för utgifter som följer av utredning av sådan information.
      </p>

      <p>
        Innan Fixura godkänner en låneansökan, bedömer Fixura Låntagarens
        kreditvärdighet igenom kredituppgiftsregister som tillhandahålls av
        tredje parter, transaktioner som skett på Låntagarens bankkonto samt
        Fixuras egna kund- och kredituppgiftsregister för att säkerställa
        Låntagarens betalningsförmåga. Låntagaren ger sitt samtycke till att
        Fixura utvärderar Låntagarens kreditvärdighet med hjälp av tjänster som
        tillhandahålls av tredje parter. Sådan kreditinformation inhämtas genom
        Bisnode Finland Oy eller Suomen Asiakastieto Oy. Låntagaren godkänner
        att uppgifterna som Låntagaren uppgett granskas och bevaras under hela
        kundförhållandet samt enligt Fixuras dataskyddsbeskrivning efter att
        kundförhållandet upphört. Fixura förbehåller sig rätten att förhindra
        den ifrågavarande personen att ansöka och skaffa lån samt att avslå en
        redan inlämnad Låneansökan.
      </p>

      <p>
        Om det ansökta lånekapitalet inte finansierats fullständigt kan
        Låntagaren erbjudas ett lägre Lån än vad Låntagaren ursprungligen ansökt
        om. Därmed garanteras inte att Låntagaren beviljas det ansökta
        lånekapitalet till fullo, och Investerarna är vidare inte skyldiga att
        erbjuda Lån för det fullständiga belopp som Låntagaren ansökt om.
        Informationen som anges i lånekalkylatorn i samband med låneansökan är
        alltid endast en preliminär uppskattning av det ansökta Lånet. Lånets
        verkliga uppgifter såsom kapitalbeloppet, Ränta, betalningstid och
        storlek av delbetalningarna klargörs i låneofferten, som Fixura alltid
        uppmanar Låntagaren att noggrant bekanta sig med.
      </p>
      <h3>Ränta och avgifter för Låntagaren</h3>
      <p>
        Låntagaren åtar sig att återbetala Lånet månatligen i enlighet med de
        villkor som föreskrivs i Låneavtalet. Den månatliga återbetalningen
        består av amortering, Ränta och låneskötselavgifter samt eventuella
        tilläggskostnader som medföljer sådana tilläggstjänster som användaren
        själv valt.
      </p>
      <p>
        Lånets Ränta och låneskötselavgifter kan vara högs så höga som det vid
        tidpunkten för beviljande av krediten bestäms i de villkor som
        stipuleras för kostnaderna i konsumentskyddslagens 7 kapitel för
        konsumentkreditavtal gällande den årliga nominella räntan och de övriga
        kreditkostnadernas högsta belopp.
      </p>
      <p>
        Möjliga tilläggstjänster debiteras enligt den då rådande Prislistan. De
        ovannämnda kostnaderna inkluderar inte mervärdesskatt eftersom
        verksamheten inte är mervärdesskattepliktig. Fixura ansvarar inte för
        försenade eller uteblivna betalningar om betalningarna inte betalats i
        enlighet med Låneavtalet.
      </p>

      <p>
        Låntagaren har rätt att i sin helhet återbetala Lånet i förtid. När
        Låntagaren återbetalar Lånet i förtid är Låntagaren skyldig att erlägga:
      </p>
      <ul>
        <li>det kvarvarande lånekapitalet;</li>
        <li>upplupen men obetald Ränta;</li>
        <li>upplupna men obetalda låneskötselavgifter; och</li>
        <li>
          kostnader som specificerats i Låneavtalet och som hänför sig till
          Lånets öppnande.
        </li>
      </ul>
      <p>
        I dessa fall behöver Låntagaren varken betala låneskötselavgifter eller
        Ränta för återstoden av låneperioden.
      </p>
      <br />
      <p>
        Om Låntagarens sista delbetalning överskrider det kvarvarande
        lånekapitalets belopp med högst fem (5) euro har Fixura rätt att inte
        återbetala den överskjutande delen av delbetalningen. Till följd av för
        stora slutbetalningsbelopp vilka överskrider fem (5) euro från den
        kvarvarande lånekapitalets belopp debiterar Fixura en behandlingsavgift
        som är fem (5) euro. Skuldförhållandets centrala villkor såsom Ränta,
        effektiv årlig ränta, kostnader och återbetalningstid fastställs separat
        i Låneavtalet.
      </p>
      <p>
        Låntagaren har en i lag föreskriven rätt till förlängning av
        betalningstiden exempelvis på grund av arbetslöshet eller sjukdom. Vid
        behov kan Fixura flytta fram förfallodagen eller ändra på den
        ursprungliga betalningsplanen. Ändringar i betalningsplanen uppdateras i
        nättjänsten. Avtalsenlig Ränta debiteras fortsättningsvis för den
        obetalda delen av Lånet.
      </p>
      <h3>Lån som beviljats före den 01.02.2020</h3>
      <p>
        På Lån som beviljats före den 1.2.2020 tillämpas de 1.3.2019 daterade
        Allmänna Villkor, som finns tillgängliga i Fixuras nättjänst.
      </p>
      <h3>Utebliven betalning, uppsägning och försäljning av Lån</h3>
      <p>
        Fixura betonar att det är viktigt att betala Lånets månatliga
        delbetalningar inom rätt tid. Om Låntagaren inte betalar sina månatliga
        delbetalningar får Låntagaren efter fjorton (14) dagar från
        förfallodagen en skriftlig betalningspåminnelse inklusive en
        påminnelseavgift som är fem (5) euro. Om delbetalningen fortfarande
        efter ytterligare fjorton (14) dagar är obetald skickas en andra
        skriftlig betalningspåminnelse till Låntagaren och ytterligare en
        påminnelseavgift läggs till räkningen.
      </p>
      <p>
        Vid försenad betalning kan Fixura kräva att Låntagaren betalar en i
        Låneavtalet fastställd dröjsmålsränta. Om dröjsmålsräntan till sin
        procentenhet är lägre än Lånets Ränta, tillämpas i stället
        procentenheten för Lånets Ränta som miniminivå på dröjsmålsräntan både
        under lånetiden, samt under 180 dagar efter att Lånet förfallit i sin
        helhet. Dröjsmålsräntan på det obetalda beloppet beräknas separat för
        varje enskild dag från förfallodagen fram till dagen då hela
        lånekapitalet återbetalats.
      </p>
      <p>
        Om summan av de obetalda fakturorna tillsammans uppgår till 5,00 % eller
        mera av det ursprungliga Lånekapitalet, förfaller Lånet i sin helhet,
        varefter Fixura skickar en uppsägningsanmälan av Lånet. Fortsatt
        utebliven betalning kan medföra rättsliga åtgärder samt leda till en
        betalningsanmärkning i kredituppgifterna om ärendet överförs till
        utmätning.
      </p>
      <p>
        Fixura har rätt att förmedla Lånets fordringar samt Låntagarens
        uppgifter till indrivning. Då kreditupplysningslagens förutsättningar
        uppfylls har Fixura dessutom rätt att anmäla en betalningsförsummelse
        som varat över 60 dagar. Till de ärenden som Fixura överför till
        indrivning fogas indrivningsbyråns kostnader samt dröjsmålsränta.
        Förfallna Lån kan överföras till indrivningsbyråns handläggning, säljas
        till tredje parter eller köpas av Fixura. Fixura meddelar Låntagaren
        ifall Låneavtalet överförts till en part annan än en Investerare.
      </p>
      <p>
        Betalningar som Fixura mottagit genom indrivningsåtgärder riktas först
        till Lånets Räntor samt det förfallna lånekapitalet och därefter till
        indrivnings- och övriga kostnader samt eventuella dröjsmålsräntor som
        hänförs till sådana kostnader. Amorteringar av Lånet samt dess räntor
        tillhör Investeraren och de övriga kostnaderna tillhör indrivningsbyrån
        och Fixura.
        <br />
        <br />
        Alla ändringar och överenskommelser som berör återbetalningarnas
        förfallotid bör man skriftligen avtala om i förväg.
      </p>
      <h3>Fakturering</h3>
      <p>
        Fakturans förfallodag fastställs i Låneavtalet. Om förfallodagen inte
        inträffar på en Bankdag är förfallodagen den näst påföljande Bankdagen.
        Faktureringen sker i första hand via elektronisk faktureringsmetod
        såvida Låntagaren och Fixuras kundtjänst inte överenskommit om en annan
        faktureringsmetod. Fixura kan skicka fakturan via e-post, textmeddelande
        och/eller motsvarande sätt. Samma kommunikationsmetoder gäller för
        betalningspåminnelser och anmälan om uppsägning. Om faktureringen sker
        genom en annan faktureringsmetod, i sådana fall debiteras
        faktureringstillägg.
      </p>
      <h3>4. VILLKOR FÖR INVESTERARE</h3>
      <h3>Risk</h3>
      <p>
        Fixura betonar att Investeraren bör vara medveten om att det i varje
        Investering finns risk att förlora kapitalet både delvis eller
        fullständigt. I person-till-person-lån är investeringarnas centrala
        risker att Låntagaren saknar betalningsförmåga, vilket kan innebära att
        Låntagaren helt eller delvis inte har förmåga att återbetala Lånet.
        Dessutom finns risk att den verkliga avkastningen förblir lägre än
        förväntad. Investeraren bör notera att bl.a. ett Lån som betalas i
        förtid föranleder till av att avkastningen blir lägre än förväntat.
      </p>
      <p>
        Fixura strävar till att erbjuda Investeraren en flexibel marknadsplats
        där Investeringarna kan överlåtas till andrahandsmarknaden. Fixura kan
        dock inte garantera andrahandsmarknadens verkliga funktion eller
        effektivitet. Därmed är det möjligt att Investeraren inte kan överlåta
        sina rättigheter och skyldigheter i förhållande till Investeringen på
        andra Investerare. Det är möjligt att Investeraren nödvändigtvis inte
        kan realisera sina Investeringar vid önskad tidpunkt och därtill kan
        Investeringarnas maturitet avvika anmärkningsvärt från vad Investeraren
        vid investeringstidpunkten förväntat sig. Vidare bör Investeraren
        anmärka att Lånens tidigare avkastningar inte garanterar framtida
        avkastningar och avseende nya Låneprodukter utgör en indikation om
        förväntad avkastning enbart ett estimat i saknad av avkastningshistoria.
      </p>
      <p>
        Innan Fixura godkänner en låneansökan, bedömer Fixura Låntagarens
        kreditvärdighet igenom kredituppgiftsregister som tillhandahålls av
        tredje parter, transaktioner som skett på Låntagarens bankkonto samt
        Fixuras egna kund- och kredituppgiftsregister för att säkerställa
        Låntagarens betalningsförmåga. Fixura strävar därtill att begränsa
        Investerarens risk genom att kreditklassificera Låntagare och avvisa
        sådana låneansökare som har kreditanmärkningar eller otillförlitliga
        kredituppgifter, samt genom att överföra förfallna Lån till indrivning
        eller sälja dem. Fixura kontrollerar Låntagarens kredituppgifter genom
        Bisnode Finland Oy eller Suomen Asiakastieto Oy men Fixura kan inte
        garantera att dessa kredituppgifter stämmer överens med verkligheten.
      </p>
      <p>
        Låntagaren har en i lag föreskriven rätt att förlänga sin betalningstid
        exempelvis till följd av arbetslöshet eller sjukdom. Fixura kan även
        flytta fram förfallodagen eller göra ändringar i den ursprungliga
        betalningsplanen. Avtalsenlig Ränta debiteras fortsättningsvis för den
        kvarvarande delen av lånekapitalet. Därtill har Låntagaren rätt att
        återbetala Lånet i förtid, varmed Ränta inte debiteras på de månatliga
        delbetalningar som återbetalas i förtid.
      </p>

      <h3>Avgifter för Investeraren</h3>
      <p>
        Fixura debiterar avgifter av Investerare enligt den då rådande
        Prislistan..
      </p>
      <p>
        Investeraren ansvarar själv för skattepåföljder såväl som för skatter
        och andra statliga deklarationer, såsom rapportering till
        skattemyndigheterna om ränteintäkter, kreditförluster och utgifter samt
        betalning av eventuella kapitalskatter. Angående finska privatpersoner
        överlåter Fixura dessa personers uppgifter om skatteintäkter och
        kreditförluster direkt till skattemyndigheterna, men Investeraren är
        skyldig att kontrollera riktigheten i de angivna uppgifterna.
      </p>

      <h3>
        Avräkning av inkomna medel och försäljning av förfallna fordringar
      </h3>

      <p>
        Om Låntagaren inte betalar sin månatliga delbetalning överförs skulden
        till en indrivningsbyrå, säljs till en tredje part eller köps av Fixura
        för aktuellt marknadspris. Om förfallna Lånet säljs till en tredje part
        är marknadspriset det pris som erbjuds av ifrågavarnde tredje part vid
        tidpunkten för försäljningen. Ifall Fixura köper förfallna Lånet
        motsvarar marknadspriset det pris som erbjuds av en indrivningsbyrå och
        bestäms årligen i januari för därpå följande 12-månaders period på basis
        av en Låneportfölj omfattande alla Lån som förfallit under de föregående
        12 månader. Marknadspriset för pågående 12-månaders period angivs i för
        tidpunkten gällande Prislistan. Trots det ovan nämnda har Fixura, i fall
        av betydande förändringar i marknadsförhållanden som påverkar
        finansservicesektorn (t.ex. betydande ändringar i prime-räntor), rätt
        att bestämma marknadsräntan oftare genom att erhålla en ny erbjudan av
        en indrivningsbyrå som återspeglar sådana förändringar. I detta fall
        ersätts den i Prislistan angivna marknadsräntan för pågående 12-månaders
        period av denna nya marknadsränta.
      </p>
      <p>
        Till den del som Investerarens fordran överförs till indrivning riktas
        Låntagarens delbetalningar i Låntagarens och Investerarens
        skuldförhållande enligt gällande lag, så att delbetalningarna alltid
        först riktas till Lånets förfallna Ränta och det förfallna lånekapitalet
        och därefter till indrivnings- och övriga kostnader samt dröjsmålsräntor
        som hänförs till sådana kostnader. Oberoende av vad som nämnts ovan
        avräknar indrivningsbyrån automatiskt indrivningskostnaderna från
        Låntagarens delbetalningar innan de indrivna medlen redovisas till
        Investerarens Fixura-konto.
      </p>
      <p>
        När Investerarens fordran blivit såld till en tredje part eller köps av
        Fixura riktas försäljningsintäkterna som tillhör Investeraren på
        skuldens lånekapital. I sådana fall betalas de inkomster som tillhör
        Investeraren inom tre (3) Bankdagar från att Fixura tagit emot
        försäljningsintäkterna. Låntagaren har inte rätt att överföra eller
        sälja lånet till en tredje part.
      </p>

      <h3>Automatisk placeringsfunktion (Autoinvest)</h3>

      <p>
        Investerarens medel placeras automatiskt genom Autoinvest-funktionen,
        varmed Investeraren tar del av Fixuras marknadsplats både genom att
        finansiera nya Lån och genom att köpa Lån från andrahandsmarknaden.
        Följaktligen kan medel placeras i alla Låneprodukter som beskrivs i
        dessa för tidpunkten gällande Allmänna Villkoren samt, via
        andrahandsmarknaden, i för tidpunkten existerande Lån vilket innebär att
        medel kan även placeras i Låneprodukter som inte längre erbjuds till nya
        Låntagare. Medel kan placeras i alla Låneprodukter beskrivna i för
        tidpunkten gällande Allmänna Villkoren. Medel kan även placeras via
        Autoinvest-funktionen i Lån som säljs på andrahandsmarknaden och i
        relation till vilka betalningar har förfallit och varit utestående under
        en period om upp till 90 dagar, innan sådana Lån överförs till
        indrivning eller säljs.
      </p>
      <p>
        Autoinvest-funktionen fördelar Investeringarna mellan flera olika Lån i
        strävan att diversifiera riskerna. Autoinvest-funktionen diversifierar
        Investerarnas fria kapital på så vis att högst 3,00 %, dock alltid
        åtminstone 20,00 euro, av Investerarens fria kapital placeras i ett enda
        Lån. En investering i ett Lån kan variera från 20,00 euro till högst 1
        000,00 euro. En Investering i ett Lån köpt på andrahandsmarknaden kan
        dock vara under 20,00 euro.
      </p>
      <p>
        Fixura betonar att Autoinvest-funktionen inte aktivt söker
        placeringsobjekt åt Investeraren som är lämpliga eller bättre för
        Investerarens investeringsprofil. Autoinvest-funktionen hjälper heller
        inte till med att analysera eller utnyttja olika placeringsobjekt. Det
        är inte fråga om någon typ av investerings eller finansiell rådgivning
        eller -annan finansiell tjänst och Fixura ansvarar inte till någon del
        över lönsamheten på de placeringar som genomförs genom Autoinvest.
      </p>

      <h3>Automatisk försäljningsfunktion (Autosell)</h3>
      <p>
        Autosell-funktionen fungerar som ett verktyg för Investeraren som
        Investeraren kan gynnas av i fråga om andrahandsmarknaden.
        Autosell-funktionen säljer placeringar för Investerarens del. Om
        Investeraren överför ett större belopp medel från sitt Fixura-konto än
        vad det fria kapitalet motsvarar, aktiveras Autosell-funktionen
        automatiskt och strävar till att sälja Investerarens Investeringar i Lån
        till andra Fixura-investerare för en summa som täcker differensen. När
        Autosell-funktionen sålt Investeringarna kan Investeraren överföra det
        fria egna kapitalet till sitt eget bankkonto eller fortsätta placeringen
        genom Autoinvest-funktionen. Då Autosell-funktionen är aktiverad är
        samtidigt Autoinvest-funktionen oaktiverad och skapar då inga nya
        placeringar till Investerarens portfölj. Autosell-funktionen kan sälja
        Investerarens Investeringar både delvis och fullständigt.
      </p>

      <h3>Företag och samfund</h3>
      <p>
        Företag och samfund som investerar genom Fixura bör säkerställa att
        deras placeringar och placeringsbeslut följer gällande regleringar i
        aktiebolagslagen, annan samfundslagstiftning ävensom övrig tillämplig
        lagstiftning i det land företaget eller samfundet är registrerat.
      </p>

      <h3>Tidsram inom vilken penningtransaktionen sker</h3>

      <p>
        Fixura strävar att genomföra penningtransaktionen från Fixura-kontot
        till Investerarens eget bankkonto under en (1) Bankdag men senast efter
        tre (3) Bankdagar från att Investeraren inlämnat förfrågan om
        penningtransaktionen. Investeraren bör göra alla förfrågningar om
        penningtransaktionen skriftligt till Fixura och genom att i sin egen
        profil vara identifierad i nättjänsten. Tidsramen för
        transaktionsprocessen påverkas därtill av bankers regler gällande
        betalningstransaktioner. Fixura har alltid rätt att avböja en förfrågan
        att genomföra en penningtransaktion såvida det föreligger en i lag eller
        i myndighetsbeslut föreskriven anledning.
      </p>

      <p>
        Om förfrågan om en penningtransaktion förutsätter att Investeringar
        genom Autosell-funktionen säljs till andra Investerare kan förfrågan
        överföras till Fixura först efter att försäljningen i
        Autosell-funktionen genomförts färdigt. Om försäljningsmålsättningen för
        placeringarna uppfylls endast delvis, kan Investeraren avbryta
        försäljningen av placeringarna, begära att de redan insamlade
        tillgångarna överförs till Investerarens bankkonto eller omplacera
        placeringarna genom nättjänsten. Om försäljningsmålsättningen för
        placeringarna uppfylls fullständigt kan Investeraren inlämna en
        förfrågan om penningtransaktion från Fixura-kontot till sitt eget
        bankkonto eller omplacera sina Investeringar genom nättjänsten.
      </p>

      <h3>5. LÅNEPRODUKTER</h3>
      <h3>Lån med sjunkande ränta</h3>
      <p>
        Fixuras Låneprodukt Lån med sjunkande ränta är en konsumentkredit med
        fast ränta utan säkerhet, till vilket Fixura kan erbjuda Låntagaren
        villkor för en sjunkande ränta. Lånets Ränta och låneskötselavgifter kan
        vara högs så höga som det vid tidpunkten för beviljande av krediten
        bestäms i de villkor som stipuleras för kostnaderna i
        konsumentskyddslagens 7 kapitel för konsumentkreditavtal gällande den
        årliga nominella räntan och de övriga kreditkostnadernas högsta belopp.
      </p>
      <p>
        Den årliga nominella räntan som används i dessa betalningsplaner börjar
        från den procentenhet som man avtalat om i Låneavtalet och sjunker
        därefter med den i Låneavtalet möjligen överenskomna villkoret om
        sjunkande Ränta. Ifall Låntagaren inte uppfyller de villkor som finns i
        Låneavtalet, är Låntagaren inte berättigad till sjunkande Ränta och den
        avtalade nominella Räntan kvarstår enligt avtal. Fixura kan, i enlighet
        med de villkor som stipuleras om sjunkande Ränta höja Räntan tillbaka
        till den i Låneavtalet överenskomna högsta nominella Räntan om
        Låntagaren inte uppfyller de krav som bestäms i villkoren om sjunkande
        Ränta.
      </p>
      <p>
        Om det i Låneavtalet avtalats om villkor för sjunkande Ränta, har den
        ursprungliga betalningsplanen och dess månatliga betalningsrat beräknats
        utgående från att Låntagaren följer de villkor som avtalats om sjunkande
        Ränta. Om Låntagaren inte uppfyller de villkor som gäller sjunkande
        Ränta, sjunker räntenivån inte enligt den tidigare avtalade
        betalningsplanen varvid den summan på den månatliga betalningen, längden
        på betalningsplanen och/eller summan på den sista betalningsraten
        ändras. I sådana fall tilläggs den obetalda delen av lånekapitalet i den
        sista delbetalningen. Låntagaren kan vid önskemål få en ny
        betalningsplan för den obetalda delen av lånekapitalet. Låntagaren kan
        även begära att Fixura skickar en amorteringstabell, från vilken de
        obetalda betalningarna, betalningsperioderna och -villkoren framgår.
      </p>
      <p>
        Fixura kan erbjuda Låntagaren Lån även utan villkor för sjunkande Ränta,
        varvid den i Låneavtalet avtalade fasta nominella räntan kan användas
        för hela Lånetiden. I detta fall har Fixura dock möjlighet att efter
        eget gottfinnande sänka den nominella räntan för Lånet om Låntagaren har
        betalat de månatliga betalningarna i enlighet med betalningsplanen.
      </p>

      <h3>Före 15.6.2021 beviljade Lån med sjunkande Ränta</h3>
      <p>
        Den årliga nominella räntan som använda i betalningsplanen för Lån med
        sjunkande Ränta beviljade före den 15 juni 2021, är 2,90 – 9,90 %. Den
        årliga nominella räntan som används i dessa betalningsplaner börjar från
        den procentenhet som man avtalat om i Låneavtalet och sjunker därefter
        månadsvis med 0,1 procentenheter, under förutsättning att Låntagaren
        betalar sin månatliga delbetalning inom rätt tid och i enlighet med
        betalningsplanen utan att en betalningspåminnelse skickas. Den nominella
        årsräntan är alltid minst 2,90 %.
      </p>
      <p>
        Om Låntagaren inte betalat sin månatliga delbetalning innan Fixura
        hunnit skicka en betalningspåminnelse är Låntagaren inte berättigad till
        den sjunkande räntan i de efterföljande delbetalningarna och räntenivån
        hålls därefter på den avtalade räntenivån eller på den räntenivå som
        beloppet för den ifrågavarande tidpunkten nått. Såvida Låntagaren
        betalar alla sina månatliga delbetalningar försenat d.v.s. efter att
        Fixura skickat betalningspåminnelser, sjunker Räntan inte överhuvudtaget
        utan istället tillämpas den i Låneavtalet avtalade nominella räntan som
        räntenivå.
      </p>
      <p>
        Den ursprungliga betalningsplanen och dess månatliga delbetalning har
        beräknats med antagande att Låntagaren betalar alla sina månatliga
        betalningar inom rätt tid innan förfallodagen. Om Låntagaren betalar
        sina månatliga delbetalningar försent sjunker räntenivån inte i enlighet
        med den ursprungliga betalningsplanen, varmed lånekapitalet amorteras i
        en långsammare takt än vad som ursprungligen överenskommits i
        betalningsplanen. I sådana fall tilläggs den obetalda delen av
        lånekapitalet i den sista delbetalningen. Låntagaren kan vid önskemål få
        en ny betalningsplan för den obetalda delen av lånekapitalet. Låntagaren
        kan även begära att Fixura skickar en amorteringstabell, från vilken de
        obetalda betalningarna, betalningsperioderna och -villkoren framgår.
      </p>

      <h3>6. KONTAKT MELLAN INVESTERAREN OCH LÅNTAGAREN</h3>
      <p>
        Fixura undertecknar Låneavtalet på Investerarens vägnar, fakturerar
        fordringar som uppstår till följd av Låneavtalet och vidarebefordrar
        fordringarna till indrivning eller utmätning samt vid behov säljer dem.
        Lånförhållandet uppstår dock i alla situationer endast mellan
        Investeraren och Låntagaren. Fixura ansvarar för all kontakt mellan
        Låntagaren och Investeraren. Det är inte tillåtet att på egen hand
        kontakta motparten gällande Lånet utan Fixuras samtycke, såvida annat
        inte överenskommits.
      </p>
      <h3>7. BEHANDLING AV PERSONUPPGIFTER OCH KREDITKONTROLL</h3>
      <p>
        Fixura behandlar personuppgifter i enlighet med dess
        dataskyddsbeskrivning bl.a. för följande ändamål:
      </p>
      <ul>
        <li>
          personuppgifter behandlas i syfte att fullgöra Fixuras skyldigheter
          gentemot Låntagaren och Investeraren;
        </li>
        <li>
          erhållna och behandlade personuppgifter handläggs i samarbete med
          myndigheter och samarbetsparter;
        </li>
        <li>
          personuppgifter används i egen marknadsföring samt i samarbetsparters
          marknadsföring av produkter och tjänster;
        </li>
        <li>fakturering och reskontra sköts av en tredje part;</li>
        <li>
          Fixura kontrollerar personens kredituppgifter och kreditklassificering
          genom kreditupplysningstjänster.
        </li>
      </ul>
      <p>
        Fixura kan inte garantera att den information som Fixura använder i
        kreditklassificeringen är fullständigt korrekt. Fixura använder den
        e-postadress och telefonnummer som kunden uppgett i all kommunikation
        med kunden. Kunden är förpliktigad att omedelbart informera Fixura om
        eventuella förändringar i e-postadressen, telefonnummer eller i andra
        kontaktuppgifter.
      </p>

      <h3>8. ANSVARSBEGRÄNSNING</h3>
      <p>
        Fixura ansvarar inte över några som helst direkta eller indirekta skador
        som kan orsakas Låntagaren eller Investeraren. Fixura ansvarar
        exempelvis således inte för utebliven vinst, avkastning, renommé,
        affärsvärde eller information, och inte heller för anskaffningskostnader
        som uppkommit från ersättande produkter och tjänster eller för andra
        immateriella eller indirekta skador.
      </p>

      <h3>9. ÖVRIGA VILLKOR</h3>
      <p>
        Fixura förbehåller sig rätten att ändra dessa Allmänna Villkor och
        Prislistan. Alla berörda parter informeras skriftligen om förändringar
        innan de träder i kraft. Informeringen sker med e-post om inte
        Låntagaren eller Investeraren kontaktat Fixuras kundtjänst och kommit
        överens om en annan kommukationsmetod.
      </p>
      <p>
        Om Låneavtalet eller Investeringsavtalet till innehållet strider mot
        dessa Allmänna Villkor eller Prislistan ges Låneavtalet och
        Investeringsavtalet företräde.
      </p>
      <p>
        Av dessa Allmänna Villkor, Prislista, Låneavtal och Investeringsavtal
        finns språkversioner på finska, svenska och engelska. Om
        språkversionerna avviker från varandra efterföljs den finska versionen i
        första hand.
      </p>

      <h3>10. FORCE MAJEURE</h3>
      <p>
        Fixura, Investeraren eller Låntagaren ansvarar inte för skador som
        orsakats på grund av ett oväntat hinder, d.v.s. till följd av force
        majeure. Ansvar anses inte uppkomma om parten kan bevisa att
        genomförandet av partens skyldighet blivit förhindrat av en ovanlig och
        oförutsebar anledning som varit utanför partens kontroll och vars
        konsekvenser parten inte kunde ha undvikit, trots att parten skulle
        uppfyllt största möjliga omsorg. Exempel på sådana situationer är krig,
        naturhändelser, bränder, avbrott i den offentliga kommunikationen,
        arbetstvister, myndighetsåtgärder, valutabegränsningar, krisåtgärder
        och/eller handelsbegränsningar.
      </p>

      <h3>11. TILLÄMPLIG LAG OCH TVISTLÖSNING</h3>
      <p>
        På dessa Allmänna Villkor tillämpas finsk lag, med undantag för dess
        lagvalsregler.
      </p>

      <p>
        Alla tvister som uppkommer mellan Låntagaren, Investeraren och Fixura
        och gäller dessa Allmänna Villkor, Låneavtalet eller Investeringsavtalet
        avgörs som utgångspunkt genom förhandlingar. Låntagaren har dessutom
        rätt att vända sig till en behörig myndighet och vidare föra ärendet
        till konsumentsskyddsnämnden för att avgöras. Om parterna inom skälig
        tid inte sinsemellan kan avgöra sina tvister genom att förhandla bör
        tvisterna avgöras i Österbottens tingsrätt så som första rättsinstans.
      </p>
    </div>
  )
}

export default TermsAndConditions
