import React from 'react'
import { Col, Container, Row, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import browserHistory from '../../../browserHistory'

interface IProps {}
const CollectFundsMessage: React.FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const isCompany = browserHistory.location.pathname.includes('company')
  return (
    <div className="b-collect-funds" id="withdraw">
      <Container className="pb-5">
        <Row>
          <Col>
            <h1 className="text-center mt-5 mb-5">{t('WITHDRAW_FUNDS')}</h1>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <Row>
              <Col md="12" xs="10" className="text-center">
                <span className="p-4 ">
                  <span className="p-2">
                    {`${t('WITHDRAWALS_MADE_FROM')} `}
                    {!isCompany ? (
                      <Link to="/dashboard/investor/fair-credit-portfolio#withdraw">
                        {t('FAIR_CREDIT_PORTFOLIO')}
                      </Link>
                    ) : (
                      <Button color="link" onClick={browserHistory.goBack}>
                        {t('FAIR_CREDIT_PORTFOLIO')}
                      </Button>
                    )}
                  </span>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CollectFundsMessage
