import { ThunkAction } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import { Dispatch } from 'redux'
import {
  createFetchBanksRequestAction,
  createFetchBanksSuccessAction,
  createFetchBanksFailureAction,
} from './creators'
import { State } from '../../../../index'

const fetchBanksList = (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  dispatch(createFetchBanksRequestAction())

  try {
    const borrowerAPI = new BorrowerAPI()
    const response: AxiosResponse = await borrowerAPI.getBanks(
      getState().user.borrower.loan_application.data.id
    )
    dispatch(createFetchBanksSuccessAction(response.data.data))
  } catch (error) {
    dispatch(createFetchBanksFailureAction(error))
  }
}

export default fetchBanksList
