import { combineReducers } from 'redux'
import reference_number, {
  initialState as referenceNumberInitialState,
  State as ReferenceNumberState,
} from './reference_number'
import withdraw, {
  initialState as withdrawInitialState,
  State as WithdrawState,
} from './withdraw'
import kpi, { initialState as kpiInitialState, State as KpiState } from './kpi'
import transactions, {
  initialState as transactionsInitialState,
  State as TransactionsState,
} from './transactions'

export interface State {
  reference_number: ReferenceNumberState
  withdraw: WithdrawState
  kpi: KpiState
  transactions: TransactionsState
}

export default combineReducers<State>({
  reference_number,
  withdraw,
  kpi,
  transactions,
})

export const initialState: State = {
  reference_number: referenceNumberInitialState,
  withdraw: withdrawInitialState,
  kpi: kpiInitialState,
  transactions: transactionsInitialState,
}
