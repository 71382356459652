import {
  FETCH_SIGNICAT_AUTH_URL_REQUEST_FAILURE,
  FETCH_SIGNICAT_AUTH_URL_REQUEST_REQUEST,
  FETCH_SIGNICAT_AUTH_URL_REQUEST_SUCCESS,
  POST_SIGNICAT_CALLBACK_DATA_FAILURE,
  POST_SIGNICAT_CALLBACK_DATA_REQUEST,
  POST_SIGNICAT_CALLBACK_DATA_SUCCESS,
} from './actions/types'
import CallbackResponse from '../../../../models/Auth/Signicat/CallbackResponse'

// fixme I need user data from backend
export default (state: State = initialState, action) => {
  switch (action.type) {
    case POST_SIGNICAT_CALLBACK_DATA_REQUEST:
    case FETCH_SIGNICAT_AUTH_URL_REQUEST_REQUEST:
      return { ...initialState, is_fetching: true }
    case FETCH_SIGNICAT_AUTH_URL_REQUEST_SUCCESS:
      return { ...state, is_fetching: false, error: null }
    case POST_SIGNICAT_CALLBACK_DATA_SUCCESS:
      return { ...initialState, callback_response: action.data }
    case POST_SIGNICAT_CALLBACK_DATA_FAILURE:
    case FETCH_SIGNICAT_AUTH_URL_REQUEST_FAILURE:
      return { ...initialState, error: action.error }
    default:
      return state
  }
}

export interface State {
  is_fetching: boolean
  callback_response: CallbackResponse
  error: any
}

export const initialState: State = {
  is_fetching: false,
  callback_response: {
    token: undefined,
    token_type: undefined,
    expires_at: undefined,
    state: undefined,
  },
  error: null,
}
