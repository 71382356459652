import {
  POST_COMPANY_REQUEST,
  POST_COMPANY_SUCCESS,
  POST_COMPANY_FAILURE,
  POST_COMPANY_AML_REQUEST,
  POST_COMPANY_AML_SUCCESS,
  POST_COMPANY_AML_FAILURE,
} from './actions/types'

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_COMPANY_REQUEST:
      return { ...state, is_fetching: true, error: null }
    case POST_COMPANY_SUCCESS:
      return { ...initialState, is_successful: true }
    case POST_COMPANY_FAILURE:
      return { ...initialState, error: action.error }
    case POST_COMPANY_AML_REQUEST:
      return { ...state, is_fetching: true }
    case POST_COMPANY_AML_SUCCESS:
      return { ...initialState, is_successful: true }
    case POST_COMPANY_AML_FAILURE:
      return { ...initialState, error: action.error }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  is_successful: false,
  error: null,
}

export interface State {
  is_fetching: boolean
  is_successful: boolean
  error: any
}
