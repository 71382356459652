import React from 'react'
import { Company } from 'models/Auth/ProfileData'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { State } from '../../store'
import { FINNISH_NATIONALITY } from '../../constants'

interface Props extends MappedState {}

const AddCompany = (props: Props) => {
  const { t } = useTranslation()
  const { companies, isOnFidoUser, nationality, hasSignedContract } = props

  const isCompanyExist = companies.length > 0
  const isOnFidoUserProfile = isOnFidoUser === 1

  return (
    <div className="company-profile pt-30">
      {!isOnFidoUserProfile &&
        nationality === FINNISH_NATIONALITY &&
        hasSignedContract && (
          <div className="add-funds__wrapper">
            <h1 className="mt-6">{t('CORPORATE_ACCOUNTS')}</h1>
            {isCompanyExist ? (
              <span className="p-20">{t('CORPORATE_ACCOUNTS_DETAILS')}</span>
            ) : (
              <span className="p-20">{t('ADD_CORPORATE_ACCOUNTS')}</span>
            )}
          </div>
        )}
    </div>
  )
}

interface MappedState {
  companies: Company[]
  isOnFidoUser: number
  nationality?: string
  hasSignedContract: boolean
}

const mapStateToProps = (state: State): MappedState => {
  const { data } = state.user.profile
  return {
    companies: state.user.profile.data.companies,
    isOnFidoUser: data.is_foreigner,
    nationality: data.nationality,
    hasSignedContract:
      state.user.profile.data.deposits.length > 0 &&
      state.user.profile.data.deposits[0].has_signed_contract,
  }
}

export default connect<MappedState, any, any, any>(mapStateToProps)(AddCompany)
