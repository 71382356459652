import { combineReducers } from 'redux'
import sms, { State as SmsState, initialState as smsInitialState } from './sms'

export interface State {
  sms: SmsState
}

export default combineReducers<State>({
  sms,
})

export const initialState: State = {
  sms: smsInitialState,
}
