import { store } from '../../../store'

export function checkCompaniesBusinessCode(companyId) {
  if (companyId) {
    const company = store
      .getState()
      .user.profile.data.companies.find(
        (company) => company.id === parseInt(companyId)
      )
    if (company) {
      return {
        business_code: company.business_code,
      }
    }
  }
}
