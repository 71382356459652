import React from 'react'
import { Col, Row } from 'reactstrap'
import CheckBoxGroup from '../../../../../shared/CheckBoxGroup'
import options from './options'
import Rows from '../../../../../shared/Wrapper/Rows'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { ScrollElement } from '../../../../../../utils/scroll'
interface ComponentProps {
  setFields: (fields) => void
  fields: any
  errors: any
  hideField: (value) => void
}

interface Props extends WithTranslation, ComponentProps {}

const hideFieldId = 'has_other_financial_situation'

const Vehicles = (props: Props) => {
  const { t, setFields, fields, errors, hideField } = props
  const passFields = (ids: string[]) => {
    hideField(!ids.includes(hideFieldId))
    const selectedValues = {}
    options.forEach(
      (option) => (selectedValues[option.id] = ids.includes(option.id))
    )
    setFields({ ...fields, ...selectedValues })
  }
  return (
    <div className=" mb-3">
      <ScrollElement name="professional_situation" />
      <Row className="mb-4">
        <Col>
          <div className="font-20-body">{t('YOUR_PROFESSIONAL_SITUATION')}</div>
        </Col>
      </Row>
      <CheckBoxGroup
        elements={options}
        selectedElementIds={options
          .filter(
            (option) => fields.hasOwnProperty(option.id) && fields[option.id]
          )
          .map((option) => option.id)}
        onChange={passFields}
        ElementWrapper={Rows}
      />
      {errors.hasOwnProperty('professional_situation') && (
        <small className="error">{errors['professional_situation']}</small>
      )}
    </div>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(Vehicles)
