import { Alert, Col, Row } from 'reactstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { State } from '../../store'
import { getDocument } from '../../store/user/document/actions/getDocument'
import { getCompanyDocument } from '../../store/user/document/actions/getCompanyDocument'
import { ThunkDispatch } from 'redux-thunk'
import { formatDate } from '../../utils'
import { Document } from 'models/Auth/ProfileData'
import ic_pdf from 'assets/images/ic_pdf.svg'

interface Props extends MappedState, MappedDispatch {
  companyId?: number
}

const Contracts = (props: Props) => {
  const {
    documents,
    downloadError,
    getContractInvoice,
    getCompanyContractInvoice,
    companyId,
  } = props
  const { t } = useTranslation()

  return (
    <Row>
      {downloadError && (
        <Col md={12}>
          <Alert color="danger">{t('SERVER_ERROR')}</Alert>
        </Col>
      )}
      {documents.map((userDocument) => {
        if (userDocument.document.type === 'terms_and_conditions') return null
        return (
          <Col key={userDocument.id} md={12}>
            {(userDocument.document.type === 'borrower_contract' ||
              userDocument.document.type === 'investor_contract') && (
              <h2>
                {userDocument.document.type === 'borrower_contract'
                  ? t('BORROWER')
                  : t('INVESTMENTS')}
              </h2>
            )}
            <div className="download-item">
              <div className="download-item__wrapper">
                <div>
                  <strong className="download-item--title">
                    {t(userDocument.document.type.toUpperCase())}
                  </strong>
                  <div>{formatDate(userDocument.created_at)}</div>
                </div>
                <div>
                  <button
                    className="btn btn-link download-invoice__link"
                    onClick={() => {
                      if (companyId) {
                        getCompanyContractInvoice(userDocument.id)
                      } else {
                        getContractInvoice(userDocument.id)
                      }
                    }}
                  >
                    <img
                      src={ic_pdf}
                      alt={t('DOWNLOAD_PDF')}
                      aria-label={t('DOWNLOAD_PDF')}
                    />
                    {t('DOWNLOAD_PDF')}
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )
      })}
    </Row>
  )
}

interface UserDocument {
  id: number
  document_id: number
  created_at: string
  document: Document
}
interface MappedState {
  documents: UserDocument[]
  downloadError: any
}

interface MappedDispatch {
  getContractInvoice: (documentId: number) => void
  getCompanyContractInvoice: (documentId: number) => void
}

const mapStateToProps = (state: State, props: Props): MappedState => {
  if (props.companyId) {
    const company = state.user.profile.data.companies.find(
      (company) => company.id === props.companyId
    )
    if (company) {
      return {
        documents: company.company_documents,
        downloadError: state.user.document.error,
      }
    }
  }
  return {
    documents: state.user.profile.data.user_documents,
    downloadError: state.user.document.error,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  getContractInvoice: (documentId: number) => dispatch(getDocument(documentId)),
  getCompanyContractInvoice: (documentId: number) =>
    dispatch(getCompanyDocument(documentId)),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(Contracts)
