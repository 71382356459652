import React from 'react'
import FixuraLogo from '../../shared/FixuraLogo'

const TermsAndConditions: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared t-and-c__v02">
      <FixuraLogo size="large" color={'dark'} />
      <br />
      <h3>FIXURA AB OY – ALLMÄNNA VILLKOR</h3>
      <h3>Gällande från och med 01.02.2020</h3>
      <p>
        Dessa Allmänna Villkor gäller för alla Investeringsavtal och Låneavtal
        som ingåtts från och med 01.02.2020. Lån som lyfts innan 01.02.2020
        omfattas av de tidigare, 01.03.2019 uppdaterade, Allmänna villkor som
        finns tillgängliga på Fixuras nättjänst.
      </p>

      <h3>1. DEFINTIONER</h3>
      <table className="definitions">
        <tbody>
          <tr>
            <td>
              <b>Autoinvest</b>
            </td>
            <td>
              Automatisk placeringsfunktion som Fixura erbjuder genom sina
              tjänster.
            </td>
          </tr>
          <tr>
            <td>
              <b>Autosell</b>
            </td>
            <td>
              Automatisk försäljningsfunktion som Fixura erbjuder genom sina
              tjänster.
            </td>
          </tr>
          <tr>
            <td>
              <b>Fixura</b>
            </td>
            <td>Fixura Ab Oy (FO-nummer 2246639-7).</td>
          </tr>
          <tr>
            <td>
              <b>Fixura-konto</b>
            </td>
            <td>
              Ett separat konto i Fixuras tjänst, som skapats åt användaren och
              genom vilken användaren kan investera och låna medel.
            </td>
          </tr>
          <tr>
            <td>
              <b>Ränta</b>
            </td>
            <td>Ränta på lånekapitalet.</td>
          </tr>
          <tr>
            <td>
              <b>Lån</b>
            </td>
            <td>
              Belopp som Investeraren betalat åt Låntagaren på grund av ett
              Låneavtal.
            </td>
          </tr>
          <tr>
            <td>
              <b>Låntagare</b>
            </td>
            <td>
              Användare som har lämnat in en låneansökan och/eller ingått ett
              Låneavtal i egenskap av Lånets mottagare.
            </td>
          </tr>
          <tr>
            <td>
              <b>Låneavtal</b>
            </td>
            <td>
              Avtal som ingåtts mellan Låntagaren och Investeraren, där parterna
              avtalar om bl.a. lånekapital, ränta och betalningsplan.
            </td>
          </tr>
          <tr>
            <td>
              <b>Bankdag</b>
            </td>
            <td>
              Vilken som helst vardag med undantag av lördagar, söndagar eller
              övriga helg- och vilodagar.
            </td>
          </tr>
          <tr>
            <td>
              <b>Investering</b>
            </td>
            <td>
              Ett av Investeraren placerat belopp från vilket Låntagaren kan
              ansöka om lånekapital.
            </td>
          </tr>
          <tr>
            <td>
              <b>Investerare</b>
            </td>
            <td>
              Användare av nättjänsten som godkänt Fixuras Investeringsavtal och
              placerat medel till Fixura-kontot för utlåning till Låntagare.
            </td>
          </tr>
          <tr>
            <td>
              <b>Investeringsavtal</b>
            </td>
            <td>
              Avtal som ingåtts mellan Investeraren och Fixura där parterna
              avtalar om investeringssamarbetet och utgående från vilket Fixura
              förmedlar låneansökningar till Investerarna, ansvarar för
              kontakten mellan Låntagaren och Investeraren samt hanterar
              penningflödet.
            </td>
          </tr>
          <tr>
            <td>
              <b>Allmänna villkor</b>
            </td>
            <td>
              Tidsenliga Allmänna villkor som tillämpas på Låneavtal samt
              Investeringsavtal och finns tillgängliga i nättjänsten.
            </td>
          </tr>
          <tr>
            <td>
              <b>Prislista</b>
            </td>
            <td>
              Den rådande prislista som finns i Fixuras nättjänst, enligt vilken
              Fixura debiterar Investerare och Låntagare.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <h3>2. INLEDNING</h3>
      <p>
        Fixura är ett företag, vars affärsidé och verksamhetsmodell är att
        fungera som en länk i syfte att förena Låntagare med sådana som vill
        låna ut sina medel till andra. Fixura fungerar som en flexibel
        marknadsplats för dessa aktörer och förmedlar Lån från Investerare till
        Låntagare som en del av affärsverksamheten. De kostnader som Fixura
        debiterar av Investerare och Låntagare finns i Prislistan, som utgör en
        integrerad del av dessa Almänna villkor. Genom att registrera sig som
        användare i nättjänsten godkänner användaren dessa Allmänna villkor som
        rättsligt bindande. Dessa Allmänna villkor utgör en del av Låntagarens
        skuldförhållande även som det separata Låneavtalet som ingås mellan
        Investeraren och Låntagaren. Om dessa Allmänna villkor avviker från det
        som uttryckligen avtalats i Låneavtalet ges Låneavtalets bestämmelser
        företräde. De Allmänna villkoren utgör också en del av
        Investeringsavtalet mellan Investeraren och Fixura. Investeraren
        godkänner de Allmänna villkoren som rättsligt bindande i enlighet med
        vad som framgår ur Investeringsavtalet. Om dessa Allmänna villkor
        avviker från vad som uttryckligen avtalats i Investeringsavtalet ges
        Investeringsavtalets bestämmelser företräde.
      </p>
      <p>
        Fixura betonar att Fixura inte utgör en avtalspart i skuldförhållandet
        mellan Investeraren och Låntagaren samt att Fixura endast fungerar som
        en kreditförmedlare mellan dem. Fixura erbjuder heller inte sådana
        investerinstjänster som antyds i lagen om investeringstjänster. Fixura
        Ab Oy är registrerad i registret för kreditgivare och förmedlare av
        person-till-person-lån hos Regionförvaltningsverket i Södra-Finland.
      </p>

      <h3>3. VILLKOR FÖR LÅNTAGARE</h3>
      <h3>Möjlighet att få Lån</h3>
      <p>
        En Låntagare har möjlighet att få Lån som förmedlas av Fixura om
        personen i fråga:
      </p>
      <ul>
        <li>är en myndig person, minst 21 år;</li>
        <li>bott stadigvarande i Finland under minst två år;</li>
        <li>har regelbundna inkomster;</li>
        <li>
          försett Fixura med all behövlig information för att vidare kunna
          bedöma Låntagarens kreditvärdighet;
        </li>
        <li>
          inte har offentliga betalningsanmärkningar, obetalda eller förfallna
          Lån; och
        </li>
        <li>
          inte är styrelseledamot i ett företag som har registrerade
          betalningsanmärkningar.
        </li>
      </ul>
      <p>
        Innan Lånet beviljas går Fixura igenom Låntagarens information och
        uppgifter utifrån Låntagarens samtycke och dataskyddslagen. Låntagaren
        ansvarar personligen för att informationen är riktig och sanningsenlig.
        Låntagaren bör även utan dröjsmål meddela Fixura om alla eventuella
        adress- och namnändringar. Investeraren eller Fixura ansvarar inte för
        fel som framgår ur ansökningsuppgifterna och/eller Låneavtalet, ej
        heller för dröjsmål eller andra påföljder som följer av sådana fel. Om
        Låntagaren inte uppfyller sin skyldighet att underrätta Fixura med all
        behövlig information har Fixura rätt att av Låntagaren kräva ersättning
        för utgifter som följer av utredning av sådan information.
      </p>

      <p>
        Vid utvärderingen huruvida Lån beviljas används information som
        erhållits och genomgåtts av tredje parter. Låntagaren ger sitt samtycke
        till att Fixura utvärderar Låntagarens kreditvärdighet med hjälp av
        tjänster som tillhandahålls av tredje parter. Sådan kreditinformation
        inhämtas genom Bisnode Finland Oy eller Suomen Asiakastieto Oy.
        Låntagaren godkänner att uppgifterna som Låntagaren uppgett granskas och
        bevaras under hela kundförhållandet samt enligt dataskyddsbeskrivningen
        efter att kundförhållandet upphört. Fixura förbehåller sig rätten att
        förhindra den ifrågavarande personen att ansöka och skaffa lån samt att
        avslå en redan inlämnad låneansökan.
      </p>

      <p>
        När Låntagaren accepterat det beviljade Lånet kan Låntagaren efter sex
        (6) månader lämna in en ny låneansökan med förutsättning att de sex (6)
        första delbetalningarna av föregående lån, inklusive Ränta, betalats i
        sin helhet. Låntagaren får ha ett totalt lånekapital om högst 20 000,00
        euro. Om det ansökta lånekapitalet inte finansierats fullständigt får
        Investeraren bevilja Låntagaren ett lägre Lån än vad Låntagaren
        ursprungligen ansökt om. Därmed garanteras inte att Låntagaren beviljas
        det ansökta lånekapitalet till fullo, och Investerarna är vidare inte
        skyldiga att erbjuda Lån för det fullständiga belopp som Låntagaren
        ansökt om. Informationen som anges i lånekalkylatorn i samband med
        låneansökan är alltid endast en preliminär uppskattning av det ansökta
        Lånet. Lånets verkliga uppgifter såsom kapitalbeloppet, Ränta,
        betalningstid och storlek av delbetalningarna klargörs i låneofferten,
        som Fixura alltid uppmanar Låntagaren att noggrant bekanta sig med.
      </p>
      <h3>Avgifter för Låntagaren</h3>
      <p></p>
      <p>
        Låntagaren åtar sig att återbetala Lånet månatligen i enlighet med de
        villkor som föreskrivs i Låneavtalet. Den månatliga återbetalningen
        består av amortering, ränta och låneskötselavgifter samt eventuella
        tilläggskostnader som medföljer sådana tilläggstjänster som användaren
        själv valt. Låneperioden kan vara högst 84 månader. Lånets nominella
        ränta kan vara högst 10,00 % och lägst 2,90 %. Låneskötselavgiften är
        0,01 % per dag av Lånets ursprungliga lånekapital. Låneskötselavgiften
        debiteras enligt Låneavtalet 0,30 % per månad eller 3,65 % per år.
        Låneskötselavgiften kan dock högst uppgå till sammanlagt 150,00 euro per
        år. Möjlilga tilläggstjänster debiteras enligt den då rådande
        Prislistan. De ovannämnda kostnaderna inkluderar inte mervärdesskatt
        eftersom verksamheten inte är mervärdesskattepliktig. Fixura ansvarar
        inte för försenade eller uteblivna betalningar om betalningarna inte
        betalats i enlighet med Låneavtalet.
      </p>

      <p>
        Låntagaren har rätt att i sin helhet återbetala Lånet i förtid. När
        Låntagaren återbetalar Lånet i förtid är Låntagaren skyldig att erlägga:
      </p>
      <ul>
        <li>det kvarvarande lånekapitalet;</li>
        <li>upplupen men obetald Ränta;</li>
        <li>upplupna men obetalda låneskötselavgifter; och</li>
        <li>
          kostnader som specificerats i Låneavtalet och som hänför sig till
          Lånets öppnande.
        </li>
      </ul>
      <p>
        I dessa fall behöver Låntagaren varken betala låneskötselavgifter eller
        Ränta för återstoden av låneperioden.
      </p>
      <br />
      <p>
        Om Låntagarens sista delbetalning överskrider det kvarvarande
        lånekapitalets belopp med högst fem (5) euro har Fixura rätt att inte
        återbetala den överskjutande delen av delbetalningen. Till följd av för
        stora slutbetalningsbelopp vilka överskrider fem (5) euro från den
        kvarvarande lånekapitalets belopp debiterar Fixura en behandlingsavgift
        som är fem (5) euro. Skuldförhållandets centrala villkor (såsom lånets
        Ränta, effektiv årlig ränta, avgifter och återbetalningstid) fastställs
        separat i Låneavtalet mellan Låntagaren och Investeraren.
      </p>
      <p>
        Låntagaren har en i lag föreskriven rätt till förlängning av
        betalningstiden exempelvis på grund av arbetslöshet eller sjukdom. Vid
        behov kan Fixura flytta fram förfallodagen eller ändra på den
        ursprungliga betalningsplanen. Ändringar i betalningsplanen uppdateras i
        nättjänsten. Avtalsenlig Ränta debiteras fortsättningsvis för den
        obetalda delen av Lånet.
      </p>
      <h3>Lån som beviljats före den 01.02.2020</h3>
      <p>
        På Lån som beviljats före den 01.02.2020 tillämpas de 01.03.2019
        uppdaterade Allmänna villkor, som finns tillgängliga i Fixuras
        nättjänst.
      </p>
      <h3>Utebliven betalning</h3>
      <p>
        Fixura betonar att det är viktigt att betala Lånets månatliga
        delbetalningar inom rätt tid. Om Låntagaren inte betalar sina månatliga
        delbetalningar får Låntagaren efter fjorton (14) dagar från
        förfallodagen en skriftlig betalningspåminnelse inklusive en
        påminnelseavgift som är fem (5) euro. Om delbetalningen fortfarande
        efter ytterligare fjorton (14) dagar är obetald skickas en andra
        skriftlig betalningspåminnelse till Låntagaren och ytterligare en
        påminnelseavgift läggs till räkningen.
      </p>
      <p>
        Vid försenad betalning kan Fixura kräva att Låntagaren betalar en i
        Låneavtalet fastställd dröjsmålsränta. Om dröjsmålsräntan till sin
        procentenhet är lägre än Lånets Ränta, tillämpas i stället
        procentenheten för Lånets Ränta som miniminivå på dröjsmålsräntan både
        under lånetiden, samt under 180 dagar efter att Lånet förfallit i sin
        helhet. Dröjsmålsräntan på det obetalda beloppet beräknas separat för
        varje enskild dag från förfallodagen fram till dagen då hela
        lånekapitalet återbetalats.
      </p>
      <p>
        Om summan av de obetalda fakturorna tillsammans uppgår till 5,00 % eller
        mera av det ursprungliga lånekapitalet, förfaller Lånet i sin helhet,
        varefter Fixura skickar en uppsägningsanmälan av Lånet och kan överföra
        Lånet till indrivning. Fortsatt utebliven betalning kan medföra
        rättsliga åtgärder samt leda till en betalningsanmärkning i
        kredituppgifterna om ärendet överförs till utmätning.
      </p>
      <p>
        Ett förfallet Lån kan säljas till en tredje part. Fixura har rätt att
        förmedla Lånets fordringar samt Låntagarens uppgifter till indrivning.
        Då kreditupplysningslagens förutsättningar uppfylls har Fixura dessutom
        rätt att anmäla en betalningsförsummelse som varat över 60 dagar. Till
        de ärenden som Fixura överför till indrivning fogas indrivningsbyråns
        kostnader samt dröjsmålsränta. Förfallna Lån kan överföras till
        indrivningsbyråns handläggning eller säljas till tredje parter. Fixura
        meddelar Låntagaren ifall Låneavtalet överförts till en tredje part.
      </p>
      <p>
        Betalningar som Fixura mottagit genom indrivningsåtgärder riktas först
        till Lånets Räntor samt det förfallna lånekapitalet och därefter till
        indrivnings- och övriga kostnader samt eventuella dröjsmålsräntor som
        hänförs till sådana kostnader. Amorteringar av Lånet samt dess räntor
        tillhör Investeraren och de övriga kostnaderna tillhör indrivningsbyrån
        och Fixura.
        <br />
        <br />
        Alla ändringar och överenskommelser som berör återbetalningarnas
        förfallotid bör man skriftligen avtala om i förväg.
      </p>
      <h3>Fakturering</h3>
      <p>
        Fakturans förfallodag fastställs i Låneavtalet. Om förfallodagen inte
        inträffar på en Bankdag är förfallodagen den näst påföljande Bankdagen.
        Faktureringen sker genom e-post såvida Låntagaren och Fixuras kundtjänst
        inte överenskommit om en annan faktureringsmetod. Fixura kan även skicka
        fakturan via textmeddelande. Detta gäller även för betalningspåminnelser
        och anmälan om indrivning. Om faktureringen sker genom en annan
        faktureringsmetod, i sådana fall debiteras faktureringstillägg.
      </p>
      <h3>4. VILLKOR FÖR INVESTERARE</h3>
      <h3>Risk</h3>
      <p>
        Fixura betonar att Investeraren bör vara medveten om att det i varje
        Investering finns risk att förlora kapitalet både delvis eller
        fullständigt. I person-till-person-lån är investeringarnas centrala
        risker att Låntagaren saknar betalningsförmåga, vilket kan innebära att
        Låntagaren helt eller delvis inte har förmåga att återbetala lånet.
        Dessutom finns risk att den verkliga avkastningen förblir lägre än
        förväntad. Investeraren bör notera att bl.a. ett Lån som betalas i
        förtid föranleder till av att avkastningen blir lägre än förväntat.
      </p>
      <p>
        Fixura strävar till att erbjuda Investeraren en flexibel marknadsplats
        där Investeringarna kan överlåtas till andrahandsmarknaden. Fixura kan
        dock inte garantera andrahandsmarknadens verkliga funktion eller
        effektivitet. Därmed är det möjligt att Investeraren inte kan överlåta
        sina rättigheter och skyldigheter i förhållande till Investeringen på
        andra Investerare. Det är möjligt att Investeraren nödvändigtvis inte
        kan realisera sina Investeringar vid önskad tidpunkt och därtill kan
        Investeringarnas maturitet avvika anmärkningsvärt från vad Investeraren
        vid investeringstidpunkten förväntat sig. Vidare bör Investeraren
        anmärka att Lånens tidigare avkastningar inte garanterar framtida
        avkastningar. Diversifiering är det bästa sättet att minska riskerna och
        kreditförlusterna.
      </p>
      <p>
        För att säkerställa Låntagarens betalningsförmåga går Fixura igenom
        kredituppgiftsregister som tillhandahålls av tredje parter. Innan Fixura
        godkänner Låntagarens låneansökan granskas transaktioner som skett på
        kundens bankkonto samt Fixuras egna kund- och kredituppgiftsregister.
        Fixura strävar därtill att begränsa Investerarens risk genom att
        kreditklassificera Låntagare och avvisa sådana låneansökare som har
        kreditanmärkningar eller otillförlitliga kredituppgifter, samt genom att
        överföra förfallna Lån till indrivning eller sälja dem till tredje
        parter. Fixura kontrollerar Låntagarens kredituppgifter genom Bisnode
        Finland Oy eller Suomen Asiakastieto Oy men Fixura kan inte garantera
        att dessa kredituppgifter stämmer överens med verkligheten. Om
        Låntagaren inte betalar sin månatliga delbetalning överförs skulden till
        en indrivningsbyrå eller säljs i sin helhet till en tredje part i
        enlighet med ikraftvarande prislista. Fixura kan även köpa Lånet själv
        till motsvarande pris.
      </p>
      <p>
        Låntagaren har en i lag föreskriven rätt att förlänga sin betalningstid
        exempelvis till följd av arbetslöshet eller sjukdom. Fixura kan även
        flytta fram förfallodagen eller göra ändringar i den ursprungliga
        betalningsplanen. Avtalsenlig Ränta debiteras fortsättningsvis för den
        kvarvarande delen av lånekapitalet. Därtill har Låntagaren rätt att
        återbetala Lånet i förtid, varmed Ränta inte debiteras på de månatliga
        delbetalningar som återbetalas i förtid.
      </p>

      <h3>Avgifter för Investeraren</h3>
      <p>Fixura debiterar Investerare enligt den då rådande Prislistan.</p>
      <p>
        Investeraren ansvarar själv för skattepåföljder såväl som för skatter
        och andra statliga deklarationer, såsom rapportering till
        skattemyndigheterna om ränteintäkter, kreditförluster och utgifter samt
        betalning av eventuella kapitalskatter. Angående finska privatpersoner
        överlåter Fixura dessa personers uppgifter om skatteintäkter och
        kreditförluster direkt till skattemyndigheterna, men Investeraren är
        skyldig att kontrollera riktigheten i de angivna uppgifterna.
      </p>

      <h3>
        Avräkning av inkomna medel och försäljning av förfallna fordringar
      </h3>

      <p>
        Fixura har rätt att enligt egen bedömning överföra en förfallen
        lånefordran inkluderande alla rättigheter och skyldigheter till
        indrivning eller sälja den till en tredje part till marknadspris. Lånens
        försäljningspris till tredje part framgår alltid i den då rådande
        Prislistan.
      </p>
      <p>
        Till den del som Investerarens fordran överförs till indrivning riktas
        Låntagarens delbetalningar i Låntagarens och Investerarens
        skuldförhållande enligt gällande lag, så att delbetalningarna alltid
        först riktas till Lånets förfallna Ränta och det förfallna lånekapitalet
        och därefter till indrivnings- och övriga kostnader samt dröjsmålsräntor
        som hänförs till sådana kostnader. Oberoende av vad som nämnts ovan
        avräknar indrivningsbyrån automatiskt indrivningskostnaderna från
        Låntagarens delbetalningar innan de indrivna medlen redovisas till
        Investerarens Fixura-konto.
      </p>
      <p>
        När Investerarens fordran blivit såld till en tredje part riktas
        försäljningsintäkterna som tillhör Investeraren på skuldens lånekapital.
        I sådana fall betalas de inkomster som tillhör Investeraren inom tre (3)
        Bankdagar från att Fixura tagit emot försäljningsintäkterna. Låntagaren
        har inte rätt att överföra eller sälja lånet till en tredje part.
      </p>

      <h3>Automatisk placeringsfunktion (Autoinvest)</h3>

      <p>
        Autoinvest-funktionen gör placeringar åt Investeraren. För att sprida
        riskerna strävar systemet att fördela Investeringarna mellan flera olika
        Lån. Autoinvest-funktionen sprider Investerarnas fria kapital på så vis
        att högst 3,00 %, dock alltid åtminstone 20,00 euro, av Investerarens
        fria kapital placeras i ett enda Lån. En investering i ett Lån kan
        variera från 20,00 euro till högst 1 000,00 euro. En Investering i ett
        Lån köpt på andrahandsmarknaden kan dock vara under 20,00 euro. Eftersom
        den maximala andelen som kan placeras i ett enskilt Lån är 1 000,00
        euro, kan en Investerare i enskilda fall komma att ensam investera 1
        000,00 euro i ett Lån, om ingen annan Investerare gett anbud på
        ifrågavarande Lån.
      </p>
      <p>
        Fixura betonar att Autoinvest-funktionen inte aktivt söker
        placeringsobjekt åt Investeraren som är lämpliga eller bättre för
        Investerarens investeringsprofil. Autoinvest-funktionen hjälper heller
        inte till med att analysera eller utnyttja olika placeringsobjekt. Det
        är inte fråga om någon typ av investeringsrådgivning eller -tjänst och
        Fixura ansvarar inte till någon del över lönsamheten på de placeringar
        som genomförs genom Autoinvest.
      </p>

      <h3>Automatisk försäljningsfunktion (Autosell)</h3>
      <p>
        Autosell-funktionen fungerar som ett verktyg för Investeraren som
        Investeraren kan gynnas av i fråga om andrahandsmarknaden.
        Autosell-funktionen säljer placeringar för Investerarens del. Om
        Investeraren överför ett större belopp medel från sitt Fixura-konto än
        vad det fria kapitalet motsvarar, aktiveras Autosell-funktionen
        automatiskt och strävar till att sälja Investerarens Investeringar till
        andra Fixura-investerare för en summa som täcker differensen. När
        Autosell-funktionen sålt Investeringarna kan Investeraren överföra det
        fria egna kapitalet till sitt eget bankkonto eller fortsätta placeringen
        genom Autoinvest-funktionen. Då Autosell-funktionen är aktiverad är
        samtidigt Autoinvest-funktionen oaktiverad och skapar då inga nya
        placeringar till Investerarens portfölj. Autosell-funktionen kan sälja
        Investerarens Investeringar både delvis och fullständigt.
      </p>

      <h3>Företag och samfund</h3>
      <p>
        Företag och samfund som investerar genom Fixura bör säkerställa att
        deras placeringar och placeringsbeslut följer gällande regleringar i
        aktiebolagslagen, annan samfundslagstiftning ävensom övrig tillämplig
        lagstiftning i det land företaget eller samfundet är registrerat.
      </p>

      <h3>Tidsram inom vilken penningtransaktionen sker</h3>

      <p>
        Fixura strävar att genomföra penningtransaktionen från Fixura-kontot
        till Investerarens eget bankkonto under en (1) Bankdag men senast efter
        tre (3) Bankdagar från att Investeraren inlämnat förfrågan om
        penningtransaktionen. Investeraren bör göra alla förfrågningar om
        penningtransaktionen skriftligt till Fixura och genom att i sin egen
        profil vara identifierad i nättjänsten. Tidsramen för Fixuras interna
        transaktionsprocess påverkas därtill av reglerna som gäller bankrörelsen
        mellan olika banker. Fixura har alltid rätt att avböja en förfrågan att
        genomföra en penningtransaktion såvida det föreligger en i lag eller i
        myndighetsbeslut föreskriven anledning.
      </p>

      <p>
        Om förfrågan om en penningtransaktion förutsätter att Investeringar
        genom Autosell-funktionen säljs till andra Investerare kan förfrågan
        överföras till Fixura först efter att försäljningen i
        Autosell-funktionen genomförts färdigt. Om försäljningsmålsättningen för
        placeringarna uppfylls endast delvis, kan Investeraren avbryta
        försäljningen av placeringarna, begära att de redan insamlade
        tillgångarna överförs till Investerarens bankkonto eller omplacera
        placeringarna genom nättjänsten. Om försäljningsmålsättningen för
        placeringarna uppfylls fullständigt kan Investeraren inlämna en
        förfrågan om penningtransaktion från Fixura-kontot till sitt eget
        bankkonto eller omplacera sina Investeringar genom nättjänsten.
      </p>

      <h3>Förskottsinnehållning</h3>
      <p>
        I fråga om räntor som betalas till privatpersoner kan Fixura vid behov
        göra en förskottsinnehållning. Förskottsinnehållningen som betalas till
        Skatteförvaltningen i Finland svarar till sitt belopp den procentenhet
        som i lag och förordning gäller för kapitalinkomster vid tidpunkten i
        fråga. Förskottsinnehållningen verkställs med en cents noggrannhet genom
        att avrunda beloppet till närmaste cent. Fixura betalar inte någon Ränta
        för förskottsinnehållningen.
      </p>
      <h3>5. LÅNETYPER</h3>
      <h3>Lån med sjunkande ränta</h3>
      <p>
        Den nominella räntan som används i betalningsplaner för lån med
        sjunkande ränta är 2,90 – 9,90 %. Den årliga nominella räntan som
        används i dessa betalningsplaner börjar från den procentenhet som man
        avtalat om i Låneavtalet och sjunker därefter månadsvis med 0,1
        procentenheter, under förutsättning att Låntagaren betalar sin månatliga
        delbetalning inom rätt tid och i enlighet med betalningsplanen utan att
        en betalningspåminnelse skickas. Den nominella årsräntan är alltid minst
        2,90 %.
      </p>
      <p>
        Om Låntagaren inte betalat sin månatliga delbetalning innan Fixura
        hunnit skicka en betalningspåminnelse är Låntagaren inte berättigad till
        den sjunkande räntan i de efterföljande delbetalningarna och räntenivån
        hålls därefter på den avtalade räntenivån eller på den räntenivå som
        beloppet för den ifrågavarande tidpunkten nått. Såvida Låntagaren
        betalar alla sina månatliga delbetalningar försenat d.v.s. efter att
        Fixura skickat betalningspåminnelser, sjunker Räntan inte överhuvudtaget
        utan istället tillämpas den i Låneavtalet avtalade nominella räntan som
        räntenivå.
      </p>
      <p>
        Den ursprungliga betalningsplanen och dess månatliga delbetalning har
        beräknats med antagande att Låntagaren betalar alla sina månatliga
        betalningar inom rätt tid innan förfallodagen. Om Låntagaren betalar
        sina månatliga delbetalningar försent sjunker räntenivån inte i enlighet
        med den ursprungliga betalningsplanen, varmed lånekapitalet amorteras i
        en långsammare takt än vad som ursprungligen överenskommits i
        betalningsplanen. I sådana fall tilläggs den obetalda delen av
        lånekapitalet i den sista delbetalningen. Låntagaren kan vid önskemål få
        en ny betalningsplan för den obetalda delen av lånekapitalet. Låntagaren
        kan även begära att Fixura skickar en amorteringstabell, från vilken de
        obetalda betalningarna, betalningsperioderna och -villkoren framgår.
      </p>

      <h3>6. KONTAKT MELLAN INVESTERAREN OCH LÅNTAGAREN</h3>
      <p>
        Fixura undertecknar Låneavtalet på Investerarens vägnar, fakturerar
        fordringar som uppstår till följd av Låneavtalet och vidarebefordrar
        fordringarna till indrivning eller utmätning samt säljer dem till en
        tredje part vid behov. Skuldförhållandet uppstår dock i alla situationer
        endast mellan Investeraren och Låntagaren. Fixura ansvarar för all
        kontakt mellan Låntagaren och Investeraren. Det är inte tillåtet att på
        egen hand kontakta motparten gällande Lånet utan Fixuras samtycke,
        såvida annat inte överenskommits.
      </p>
      <h3>7. BEHANDLING AV PERSONUPPGIFTER OCH KREDITKONTROLL</h3>
      <p>
        Fixura behandlar personuppgifter i enlighet med dess
        dataskyddsbeskrivning bl.a. för följande ändamål:
      </p>
      <ul>
        <li>
          personuppgifter behandlas i syfte att fullgöra Fixuras skyldigheter
          gentemot Låntagaren och Investeraren;
        </li>
        <li>
          erhållna och behandlade personuppgifter handläggs i samarbete med
          myndigheter och samarbetsparter;
        </li>
        <li>
          personuppgifter används i egen marknadsföring samt i samarbetsparters
          marknadsföring av produkter och tjänster;
        </li>
        <li>fakturering och reskontra sköts av en tredje part;</li>
        <li>
          Fixura kontrollerar personens kredituppgifter och kreditklassificering
          genom kreditupplysningstjänster.
        </li>
      </ul>
      <p>
        Fixura kan inte garantera att den information som Fixura använder i
        kreditklassificeringen är fullständigt korrekt. Fixura använder den
        e-postadress och telefonnummer som kunden uppgett i all kommunikation
        med kunden. Kunden är förpliktigad att omedelbart informera Fixura om
        eventuella förändringar i e-postadressen, telefonnummer eller i andra
        kontaktuppgifter.
      </p>

      <h3>8. ANSVARSBEGRÄNSNING</h3>
      <p>
        Fixura ansvarar inte över några som helst direkta eller indirekta skador
        som kan orsakas Låntagaren eller Investeraren. Fixura ansvarar
        exempelvis således inte för utebliven vinst, avkastning, renommé,
        affärsvärde eller information, och inte heller för anskaffningskostnader
        som uppkommit från ersättande produkter och tjänster eller för andra
        immateriella eller indirekta skador.
      </p>

      <h3>9. ÖVRIGA VILLKOR</h3>
      <p>
        Fixura förbehåller sig rätten att ändra dessa Allmänna villkor. Alla
        berörda parter informeras skriftligen om förändringar innan de träder i
        kraft. Om Låneavtalet eller Investeringsavtalet till innehållet strider
        mot dessa Allmänna villkor ges Låneavtalet och Investeringsavtalet
        företräde.
      </p>

      <h3>10. FORCE MAJEURE</h3>
      <p>
        Fixura, Investeraren eller Låntagaren ansvarar inte för skador som
        orsakats på grund av ett oväntat hinder, d.v.s. till följd av force
        majeure. Ansvar anses inte uppkomma om parten kan bevisa att
        genomförandet av partens skyldighet blivit förhindrat av en ovanlig och
        oförutsebar anledning som varit utanför partens kontroll och vars
        konsekvenser parten inte kunde ha undvikit, trots att parten skulle
        uppfyllt största möjliga omsorg. Exempel på sådana situationer är krig,
        naturhändelser, bränder, avbrott i den offentliga kommunikationen,
        arbetstvister, myndighetsåtgärder, valutabegränsningar, krisåtgärder
        och/eller handelsbegränsningar.
      </p>

      <h3>11. TILLÄMPLIG LAG OCH TVISTLÖSNING</h3>
      <p>
        I Låntagarens och Investerarens skuldförhållande samt i Investerarens
        och Fixuras samarbetsförhållande tillämpas Finlands lag, med undantag
        för dess lagvalsregler.
      </p>

      <p>
        Alla tvister som uppkommer mellan Låntagaren, Investeraren och Fixura
        och gäller dessa Allmänna villkor och/eller Låneavtalet avgörs som
        utgångspunkt genom förhandlingar. Låntagaren har dessutom rätt att vända
        sig till en behörig myndighet och vidare föra ärendet till
        konsumentsskyddsnämnden för att avgöras. Om parterna inom skälig tid
        inte sinsemellan kan avgöra sina tvister genom att förhandla bör
        tvisterna avgöras i Österbottens tingsrätt så som första rättsinstans.
      </p>
    </div>
  )
}

export default TermsAndConditions
