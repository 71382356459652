import { store } from '../../store'
import { formatMoney } from '../../utils'
declare global {
  interface Window {
    dataLayer: any
  }
}

// google tag manager check loan status
export const checkoutStep3: () => void = () => {
  const { data } = store.getState().user.borrower.loan_application

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'checkout3', //In future will be different
    userId: data.user_id,
    ecommerce: {
      checkout: {
        actionField: { step: 3 },
        products: [
          {
            name: 'Fixura Loan',
            price: data.amount_offered,
            quantity: 1,
            metric1: data.amount,
            metric2: data.terms,
            metric3: data.amount_offered,
            metric4: data.terms_offered,
            metric5:
              data.average_effective_annual_interest &&
              formatMoney(data.average_effective_annual_interest * 100),
            metric6:
              data.average_nominal_interest &&
              formatMoney(data.average_nominal_interest * 100),
            metric7: data.installment_offered,
          },
        ],
      },
    },
  })
}

// google tag manager view offer
export const checkoutStep4: () => void = () => {
  const { data } = store.getState().user.borrower.loan_application

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'checkout4', //In future will be different
    userId: data.user_id,
    ecommerce: {
      checkout: {
        actionField: { step: 4 },
        products: [
          {
            name: 'Fixura Loan',
            price: data.amount_offered,
            quantity: 1,
            metric1: data.amount,
            metric2: data.terms,
            metric3: data.amount_offered,
            metric4: data.terms_offered,
            metric5:
              data.average_effective_annual_interest &&
              formatMoney(data.average_effective_annual_interest * 100),
            metric6:
              data.average_nominal_interest &&
              formatMoney(data.average_nominal_interest * 100),
            metric7: data.installment_offered,
          },
        ],
      },
    },
  })
}

// google tag manager thank you
export const purchase: () => void = () => {
  const { data } = store.getState().user.borrower.loan_application

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'purchase',
    userId: data.user_id,
    ecommerce: {
      purchase: {
        actionField: {
          id: data.id,
          revenue: data.amount_offered,
          tax: '0.00',
          shipping: '0.00',
        },
        products: [
          {
            name: 'Fixura Loan',
            price: data.amount_offered,
            quantity: 1,
            metric1: data.amount,
            metric2: data.terms,
            metric3: data.amount_offered,
            metric4: data.terms_offered,
            metric5:
              data.average_effective_annual_interest &&
              formatMoney(data.average_effective_annual_interest * 100),
            metric6:
              data.average_nominal_interest &&
              formatMoney(data.average_nominal_interest * 100),
            metric7: data.installment_offered,
          },
        ],
      },
    },
  })
}

// google tag manager rejected
export const rejected: () => void = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'rejected',
  })
}

// google tag manager un matched
export const loanUnmatched: () => void = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'loan_unmatched',
  })
}
