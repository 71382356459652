import React from 'react'
import { isLegacy } from 'utils/auth'
import { DropdownMenu, UncontrolledDropdown, DropdownToggle } from 'reactstrap'
import NavMenuItem from './NavMenuItem'
import { useTranslation } from 'react-i18next'
import { State, store } from 'store'
import { connect } from 'react-redux'
import { LegacyUser } from 'models/Auth/ProfileData'
import {
  isOnFidoUserVerified,
  isOnFidoUserRejected,
} from '../../utils/onFidoUser'
import { capitalizeAndExtractFirstLetter } from '../../utils'
import { Company } from 'models/Auth/ProfileData'
import { useLocation } from 'react-router'
import { FINNISH_NATIONALITY } from '../../constants'
import NavMenuSavelendItem from './NavMenuSavelendItem'
import { setUrl } from 'components/shared/helper'

interface IProps extends MappedState {}
const language = store.getState().user.profile.data.language

const menuItems = {
  startInvestingLegacy: {
    url: '/restricted/investor#start',
    label: 'START_INVESTING',
  },
  portfolioLegacy: {
    url: '/restricted/investor#portfolio',
    label: 'PORTFOLIO_SUMMARY',
  },
  transactionsLegacy: {
    url: '/restricted/investor#transactions',
    label: 'TRANSACTIONS',
  },
  withdrawalLegacy: {
    url: '/restricted/investor#withdraw',
    label: 'WITHDRAW',
  },
  /* redirecing temp to savelend site 020722 */
  startInvesting: {
    url: '/dashboard/investor#start',
    label: 'START_INVESTING',
  },
  portfolio: {
    url: '/dashboard/investor/fair-credit-portfolio#portfolio',
    label: 'PORTFOLIO_SUMMARY',
  },
  transactions: {
    url: '/dashboard/investor/fair-credit-portfolio#transactions',
    label: 'TRANSACTIONS',
  },
  withdrawal: {
    url: '/dashboard/investor/fair-credit-portfolio#withdraw',
    label: 'WITHDRAW',
  },
  addFunds: {
    url: '/dashboard/investor/fair-credit-portfolio#add-funds',
    label: 'ADD_FUNDS',
  },
  loanInfoLegacy: {
    url: '/restricted/borrower#loan-info',
    label: 'LOAN_INFO',
  },
  loanInfo: {
    url: '/dashboard/borrower#loan-info',
    label: 'LOAN_INFO',
  },

  nextInstallmentLegacy: {
    url: '/restricted/borrower#next-installment',
    label: 'BORROWER_NEXT_INSTALLMENT',
  },
  nextInstallment: {
    url: '/dashboard/borrower#next-installment',
    label: 'BORROWER_NEXT_INSTALLMENT',
  },

  paymenyPlanLegacy: {
    url: '/restricted/borrower#payment-plan',
    label: 'PAYMENT_PLAN',
  },
  paymenyPlan: {
    url: '/dashboard/borrower#payment-plan',
    label: 'PAYMENT_PLAN',
  },
  takeLoan: {
    url: setUrl(language),
    label: 'APPLY_FOR_A_LOAN',
  },

  privateInvestorDasboard: {
    url: '/dashboard/investor',
  },
  companyDashboard: {
    url: '/dashboard/company',
  },

  addCompany: {
    label: 'ADD_COMPANY',
  },
}

const NavMenu: React.FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const isOnFidoUserVerify = isOnFidoUserVerified()
  const isOnFidoUserReject = isOnFidoUserRejected()
  const location = useLocation()

  const isCompany = location.pathname.includes('company')
  const isPrivateInvestor =
    location.pathname.split('/')[2] === 'investor' ||
    location.pathname.split('/').length === 2 ||
    location.pathname.split('/')[2] === 'profile' ||
    location.pathname.split('/')[2] === 'borrower'
  const isOldPortfolio = location.pathname.includes('fixed-rate-portfolio')
  const companyId = location.pathname.split('/')[3]

  const {
    hasSignedContract,
    legacyRole,
    legacyUsers,
    nationality,
    isOnFidoUser,
    firstName,
    lastName,
    companies,
    loansLength,
  } = props

  //for foreigner investor's
  const isCompanyExist = companies && companies.length > 0

  const allAccountsMenu = (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret className="nav-white-btn">
        {firstName && capitalizeAndExtractFirstLetter(firstName)}
      </DropdownToggle>
      <DropdownMenu right>
        {!isLegacy() && (
          <>
            <NavMenuItem
              url={menuItems.privateInvestorDasboard.url}
              label={`${
                firstName && capitalizeAndExtractFirstLetter(firstName)
              } ${lastName && capitalizeAndExtractFirstLetter(lastName)} `}
            />
            {(nationality === FINNISH_NATIONALITY || isCompanyExist) &&
              (isOnFidoUser === 0 || isCompanyExist) &&
              companies.map((company) => {
                return (
                  <NavMenuItem
                    key={company.id}
                    url={`${menuItems.companyDashboard.url}/${company.id}`}
                    label={company.name}
                  />
                )
              })}
            {nationality === FINNISH_NATIONALITY && isOnFidoUser === 0 && (
              <NavMenuItem
                url={`/dashboard/add-company`}
                label={menuItems.addCompany.label}
              />
            )}
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  )

  const borrowerMenu = (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret className="nav-white-btn">
        {t('BORROWER')}
      </DropdownToggle>
      <DropdownMenu right>
        {isLegacy() && legacyRole === 'borrower' && (
          <>
            <NavMenuItem
              url={menuItems.loanInfoLegacy.url}
              label={menuItems.loanInfoLegacy.label}
            />
            <NavMenuItem
              url={menuItems.nextInstallmentLegacy.url}
              label={menuItems.nextInstallmentLegacy.label}
            />
            <NavMenuItem
              url={menuItems.paymenyPlanLegacy.url}
              label={menuItems.paymenyPlanLegacy.label}
            />
            <NavMenuItem
              url={menuItems.takeLoan.url}
              label={menuItems.takeLoan.label}
            />
          </>
        )}

        {!isLegacy() && loansLength !== 0 && (
          <>
            <NavMenuItem
              url={menuItems.loanInfo.url}
              label={menuItems.loanInfo.label}
            />
            <NavMenuItem
              url={menuItems.nextInstallment.url}
              label={menuItems.nextInstallment.label}
            />
            <NavMenuItem
              url={menuItems.paymenyPlan.url}
              label={menuItems.paymenyPlan.label}
            />
            <NavMenuItem
              url={menuItems.takeLoan.url}
              label={menuItems.takeLoan.label}
            />
          </>
        )}
        {!isLegacy() && loansLength === 0 && (
          <NavMenuItem
            url={menuItems.takeLoan.url}
            label={menuItems.takeLoan.label}
          />
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  )

  const investorMenu = (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret className="nav-white-btn">
        {t('INVESTOR')}
      </DropdownToggle>
      <DropdownMenu right>
        {isLegacy() && legacyRole === 'investor' && (
          <>
            <NavMenuItem
              url={menuItems.startInvestingLegacy.url}
              label={menuItems.startInvestingLegacy.label}
            />
            <NavMenuItem
              url={menuItems.portfolioLegacy.url}
              label={menuItems.portfolioLegacy.label}
            />
            <NavMenuItem
              url={menuItems.transactionsLegacy.url}
              label={menuItems.transactionsLegacy.label}
            />
          </>
        )}
        {!isLegacy() && (
          <>
            {!hasSignedContract && (
              <NavMenuSavelendItem
                url={menuItems.startInvesting.url}
                label={menuItems.startInvesting.label}
              />
            )}

            {(hasSignedContract ||
              (legacyUsers.length > 0 &&
                legacyUsers[0].legacy_role === 'investor')) && (
              <>
                <NavMenuItem
                  url={menuItems.addFunds.url}
                  label={menuItems.addFunds.label}
                />
                <NavMenuItem
                  url={menuItems.portfolio.url}
                  label={menuItems.portfolio.label}
                />
                <NavMenuItem
                  url={menuItems.transactions.url}
                  label={menuItems.transactions.label}
                />
                <NavMenuItem
                  url={menuItems.withdrawal.url}
                  label={menuItems.withdrawal.label}
                />
              </>
            )}
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
  const companyMenu = (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret className="nav-white-btn">
        {t('INVESTOR')}
      </DropdownToggle>
      <DropdownMenu right>
        {!isLegacy() && (
          <>
            {(hasSignedContract ||
              (legacyUsers.length > 0 &&
                legacyUsers[0].legacy_role === 'investor')) && (
              <>
                <NavMenuItem
                  url={`/dashboard/company/${companyId}/investor/fair-credit-portfolio#add-funds`}
                  label={menuItems.addFunds.label}
                />
                <NavMenuItem
                  url={`/dashboard/company/${companyId}/investor/fair-credit-portfolio#portfolio`}
                  label={menuItems.portfolio.label}
                />
                <NavMenuItem
                  url={`/dashboard/company/${companyId}/investor/fair-credit-portfolio#transactions`}
                  label={menuItems.transactions.label}
                />
                <NavMenuItem
                  url={`/dashboard/company/${companyId}/investor/fair-credit-portfolio#withdraw`}
                  label={menuItems.withdrawal.label}
                />
              </>
            )}
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  )

  return (
    <>
      {/* allInvestmentAccountsMenu */}
      {((isLegacy() && legacyRole === 'investor') || !isLegacy()) &&
        !isOnFidoUserVerify &&
        !isOnFidoUserReject &&
        (hasSignedContract ||
          (legacyUsers.length > 0 &&
            legacyUsers[0].legacy_role === 'investor')) &&
        allAccountsMenu}

      {/* borrowerMenu */}
      {((isLegacy() && legacyRole === 'borrower') ||
        (!isLegacy() && nationality === FINNISH_NATIONALITY)) &&
        isOnFidoUser === 0 &&
        !isCompany &&
        borrowerMenu}

      {/*  investorMenu*/}
      {((isLegacy() && legacyRole === 'investor') || !isLegacy()) &&
        !isOnFidoUserVerify &&
        !isOnFidoUserReject &&
        !isCompany &&
        isPrivateInvestor &&
        investorMenu}

      {/* companyMenu */}
      {((isLegacy() && legacyRole === 'investor') || !isLegacy()) &&
        !isOnFidoUserVerify &&
        !isOnFidoUserReject &&
        isCompany &&
        companyId &&
        !isOldPortfolio &&
        companyMenu}
    </>
  )
}

interface MappedState {
  hasSignedContract: boolean
  legacyRole?: string
  nationality?: string
  legacyUsers: LegacyUser[]
  hasLoan: boolean
  isOnFidoUser: number
  firstName: string
  lastName: string
  companies: Company[]
  loansLength: number
}

const mapStateToProps = (state: State): MappedState => {
  const { data } = state.user.profile
  const loans = data.loans
  return {
    loansLength: loans.length, //total 3.0loans only
    hasSignedContract:
      data.deposits.length > 0 && data.deposits[0].has_signed_contract,
    legacyRole: state.legacy.auth.data.role,
    nationality: data.nationality,
    legacyUsers: data.legacy_users,
    hasLoan:
      (data.legacy_users.length &&
        data.legacy_users[0].legacy_role === 'borrower') ||
      data.loans.length > 0,
    isOnFidoUser: data.is_foreigner,
    firstName: data.first_name,
    lastName: data.last_name,
    companies: data.companies,
  }
}

export default connect(mapStateToProps)(NavMenu)
