import { combineReducers } from 'redux'
import loanApplication, {
  initialState as loanApplicationInitialState,
  State as LoanApplicationInitialStateState,
} from './loanApplication'

import loanApplicationRequest, {
  initialState as loanApplicationRequestInitialState,
  State as LoanApplicationRequestInitialStateState,
} from './loanApplicationRequest'

export interface State {
  loanApplication: LoanApplicationInitialStateState
  loanApplicationRequest: LoanApplicationRequestInitialStateState
}

export default combineReducers<State>({
  loanApplication,
  loanApplicationRequest,
})

export const initialState: State = {
  loanApplication: loanApplicationInitialState,
  loanApplicationRequest: loanApplicationRequestInitialState,
}
