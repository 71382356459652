import React from 'react'
import { Col, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import ic_bar_char_decrease from 'assets/images/ic_bar_char_decrease.svg'
import ic_bar_char_fixed from 'assets/images/ic_bar_char_fixed.svg'
import finlandFlagRoundSmall from 'assets/images/flags/finland-flag-round-small.png'

const LoanSetting = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const type = location.pathname.includes('fair') ? 'fair' : 'fixed'

  return (
    <>
      <div className="next-installment">
        <>
          <Row>
            <Col>
              <h1 className="next-installment__header">{t('LOAN')}</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={{ size: 8, offset: 2 }}>
              <div className="next-installment-info__wrapper ml-10">
                <div className="next-installment-info">
                  <img
                    className="img_bar_chart"
                    src={
                      type === 'fair' ? ic_bar_char_decrease : ic_bar_char_fixed
                    }
                    alt="chart_icon"
                  />
                  {type === 'fair' ? (
                    <span>{t('VARIABLE_INTEREST_LOANS')}</span>
                  ) : (
                    <span>{t('LOAN_WITH_FIXED_RATE')}</span>
                  )}
                </div>
                <div className="next-installment-info">
                  <img
                    className="mb-1_add_company h-2 px-10"
                    src={finlandFlagRoundSmall}
                    alt="flag"
                  />
                  <span>{t('FINLAND')}</span>
                </div>
              </div>
            </Col>
          </Row>
        </>
      </div>
    </>
  )
}

export default LoanSetting
