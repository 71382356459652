import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Address } from '../../models/Auth/ProfileData'
import { State } from '../../store'
import { WithTranslation, withTranslation } from 'react-i18next'
import ic_home from 'assets/images/ic_home.svg'

interface Props extends MappedState, WithTranslation {}

const AddressComponent = (props: Props) => {
  const { address, t } = props

  return (
    <div className="user-info my-2">
      <div>
        <img src={ic_home} alt={t('USER_ADDRESS')} />
      </div>
      <div className="ml-3">
        {address && (
          <>
            <div>{address.line1}</div>
            <div>
              {address.postcode} {address.city}
            </div>
            <div>{address.country}</div>
          </>
        )}
      </div>
    </div>
  )
}

interface MappedState {
  address?: Address
}

const mapStateToProps = (state: State): MappedState => {
  return {
    address: state.user.profile.data.address,
  }
}

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedState, any, any, any>(mapStateToProps)
)

export default enhancer(AddressComponent)
