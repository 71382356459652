import {
  POST_WALK_THRU_REQUEST,
  POST_WALK_THRU_SUCCESS,
  POST_WALK_THRU_FAILURE,
} from './types'

export const createWalkThruRequestAction = () => ({
  type: POST_WALK_THRU_REQUEST,
})
export const createWalkThruSuccessAction = () => ({
  type: POST_WALK_THRU_SUCCESS,
})
export const createWalkThruFailureAction = (error: any) => ({
  error,
  type: POST_WALK_THRU_FAILURE,
})
