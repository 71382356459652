import {
  LOGIN_WITH_SMS_FAILURE,
  LOGIN_WITH_SMS_REQUEST,
  LOGIN_WITH_SMS_SUCCESS,
  VERIFY_PHONE_NUMBER_FAILURE,
  VERIFY_PHONE_NUMBER_REQUEST,
  VERIFY_PHONE_NUMBER_SUCCESS,
} from './actions/types'

export interface State {
  is_fetching: boolean
  mobile_phone: string
  error: any
  blaId: string | undefined
}

export default (state: State = initialState, action) => {
  switch (action.type) {
    case LOGIN_WITH_SMS_REQUEST:
    case VERIFY_PHONE_NUMBER_REQUEST:
      return { ...state, is_fetching: true }
    case LOGIN_WITH_SMS_SUCCESS:
      return { ...initialState, mobile_phone: action.mobilePhone }
    case VERIFY_PHONE_NUMBER_SUCCESS:
      return { ...state, is_fetching: false, error: null, blaId: action.blaId }
    case LOGIN_WITH_SMS_FAILURE:
    case VERIFY_PHONE_NUMBER_FAILURE:
      return { ...state, is_fetching: false, error: action.error }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  mobile_phone: '',
  error: null,
  blaId: '',
}
