export const GET_TERMS_AND_CONDITIONS_REQUEST =
  'auth.register.terms_and_conditions.GET_TERMS_AND_CONDITIONS_REQUEST'
export const GET_TERMS_AND_CONDITIONS_SUCCESS =
  'auth.register.terms_and_conditions.GET_TERMS_AND_CONDITIONS_SUCCESS'
export const GET_TERMS_AND_CONDITIONS_FAILURE =
  'auth.register.terms_and_conditions.GET_TERMS_AND_CONDITIONS_FAILURE'

export const ACCEPT_TERMS_AND_CONDITIONS =
  'auth.register.terms_and_conditions.ACCEPT_TERMS_AND_CONDITIONS'
export const REFUSE_TERMS_AND_CONDITIONS =
  'auth.register.terms_and_conditions.REFUSE_TERMS_AND_CONDITIONS'
export const TOGGLE_TERMS_AND_CONDITIONS =
  'auth.register.terms_and_conditions.TOGGLE_TERMS_AND_CONDITIONS'

export const SIGN_TERMS_AND_CONDITIONS_REQUEST =
  'auth.register.terms_and_conditions.SIGN_TERMS_AND_CONDITIONS_REQUEST'
export const SIGN_TERMS_AND_CONDITIONS_SUCCESS =
  'auth.register.terms_and_conditions.SIGN_TERMS_AND_CONDITIONS_SUCCESS'
export const SIGN_TERMS_AND_CONDITIONS_FAILURE =
  'auth.register.terms_and_conditions.SIGN_TERMS_AND_CONDITIONS_FAILURE'

export const SIGN_TERMS_AND_CONDITIONS_PRE_RELEASE_REQUEST =
  'auth.register.terms_and_conditions.SIGN_TERMS_AND_CONDITIONS_PRE_RELEASE_REQUEST'
export const SIGN_TERMS_AND_CONDITIONS_PRE_RELEASE_SUCCESS =
  'auth.register.terms_and_conditions.SIGN_TERMS_AND_CONDITIONS_PRE_RELEASE_SUCCESS'
export const SIGN_TERMS_AND_CONDITIONS_PRE_RELEASE_FAILURE =
  'auth.register.terms_and_conditions.SIGN_TERMS_AND_CONDITIONS_PRE_RELEASE_FAILURE'

export const SIGN_COMPANY_TERMS_AND_CONDITIONS_REQUEST =
  'auth.register.terms_and_conditions.SIGN_COMPANY_TERMS_AND_CONDITIONS_REQUEST'
export const SIGN_COMPANY_TERMS_AND_CONDITIONS_SUCCESS =
  'auth.register.terms_and_conditions.SIGN_COMPANY_TERMS_AND_CONDITIONS_SUCCESS'
export const SIGN_COMPANY_TERMS_AND_CONDITIONS_FAILURE =
  'auth.register.terms_and_conditions.SIGN_COMPANY_TERMS_AND_CONDITIONS_FAILURE'

export const SET_SHOULD_AGREE_WITH_TERMS_AND_CONDITIONS =
  'auth.register.terms_and_conditions.SET_SHOULD_AGREE_WITH_TERMS_AND_CONDITIONS'
