import { CheckBoxElement } from '../../../../../shared/CheckBoxGroup/Models'

const options: CheckBoxElement[] = [
  {
    id: 'goal_of_investment_is_generate_returns',
    label: 'INVESTMENT_RETURNS',
  },
  {
    id: 'goal_of_investment_is_savings',
    label: 'SAVINGS',
  },
  {
    id: 'goal_of_investment_is_other',
    label: 'OTHER',
  },
]

export default options
