import React, { useEffect } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'
import Popup from 'reactjs-popup'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import { State } from '../../../store'
import Spinner from '../../shared/Spinner'
import logKreditzEvent from '../../../store/user/borrower/loan_application/actions/logKreditzEvent'
import updateCCIS from '../../../store/user/borrower/loan_application/actions/updateCCIS'
import updateRedirectUrl from '../../../store/user/borrower/loan_application/actions/updateRedirectUrl'
import getActiveLoanApplication from '../../../store/user/borrower/loan_application/actions/getActiveLoanApplication'
import LoanApplication from '../../../models/Borrower/LoanApplication'

interface Props extends MappedDispatch, MappedState {}

const Kreditz = (props: Props) => {
  const { loanApplication, kreditzUrl, logKreditzEvent,
    getActiveLoanApplication, updateCCIS, updateRedirectUrl } = props
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const qs = queryString.parse(location.search)
  const isRetry = !!qs.retry

  const handleIframeMessage = (e: MessageEvent) => {
    if (e.origin !== 'https://vista.kreditz.com') {
      return
    }

    const data = JSON.parse(e.data)
    logKreditzEvent(data.status, data.case_id, data.message)

    // just in case
    const message = data.message.toLowerCase()

    if (data.status === 'success' && message === 'user flow completed') {
      history.push('/kreditz/success')
    } else if ((data.status === 'success' && message === 'user cancelled bank authentication') || data.status === 'error') {
      history.push('/kreditz/failure')
    }
  }

  useEffect(
    () => {
      if (!loanApplication?.id) {
        getActiveLoanApplication()
      } else if (!kreditzUrl) {
        if (isRetry) {
          updateRedirectUrl()
        } else {
          updateCCIS()
        }
      }

      window.addEventListener('message', handleIframeMessage, false)
      // component will unmount
      return () => {
        window.removeEventListener('message', handleIframeMessage)
      }
    },
    // eslint-disable-next-line
    [loanApplication, kreditzUrl]
  )

  return (
    <div className="m-5 p-5 text-center">
      {kreditzUrl
        ? <iframe className="m-auto" title="Kreditz" width={600} height={650} src={kreditzUrl} />
        : <Spinner spinnerHeight={150} spinnerWidth={150} />}
      {isRetry && <Popup trigger={<div />} modal defaultOpen closeOnDocumentClick>
        {close => (<div>
          <p className="mt-2 mb-2">{t('AISP_REJECTION_DESCRIPTION')}</p>
          <Button
            className="hero light"
            color="secondary"
            onClick={close}
          >
            {t('CLOSE')}
          </Button>
        </div>)}
      </Popup>
      }
    </div>
  )
}

interface MappedState {
  loanApplication: LoanApplication
  kreditzUrl: string
}

interface MappedDispatch {
  logKreditzEvent: (status: string, caseId: number, message: string) => void
  getActiveLoanApplication: () => void
  updateCCIS: () => void
  updateRedirectUrl: () => void
}

const mapStateToProps = (state: State): MappedState => ({
  loanApplication: state.user.borrower.loan_application.data,
  kreditzUrl: state.user.borrower.loan_application.redirect_url
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  logKreditzEvent: (status: string, caseId: number, message: string) =>
    dispatch(logKreditzEvent(status, caseId, message)),
  getActiveLoanApplication: () => dispatch(getActiveLoanApplication()),
  updateCCIS: () => dispatch(updateCCIS()),
  updateRedirectUrl: () => dispatch(updateRedirectUrl())
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(Kreditz)
