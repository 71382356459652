export default function pageTitleUtils(
  name: string | string[] | null | undefined
) {
  switch (name) {
    case '/borrower/onboarding':
      return 'Borrower Onboarding'
    case '/login/sms/code':
      return 'Login SMS Code'
    case '/login':
      return 'Login'
    case '/login/bank':
      return 'Login Bank'
    case '/register/identity-verification':
      return 'Register Identity Verification'
    case '/register/provide-details':
      return 'Register Provide Details'
    case '/register/verify-phone-number':
      return 'Register Verify Phone Number'
    case '/dashboard/borrower/aisp':
      return 'Dashboard Borrower'
    case '/dashboard/borrower/sign-contract':
      return 'Dashboard Borrower Sign Contract'
    case '/dashboard/borrower/signed':
      return 'Dashboard Borrower Signed'
    default:
      return
  }
}
