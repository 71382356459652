import {
  POST_WALK_THRU_REQUEST,
  POST_WALK_THRU_SUCCESS,
  POST_WALK_THRU_FAILURE,
} from './actions/types'

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_WALK_THRU_REQUEST:
      return { ...state }
    case POST_WALK_THRU_SUCCESS:
      return { ...initialState, is_walk_thru: true }
    case POST_WALK_THRU_FAILURE:
      return { ...initialState }
    default:
      return state
  }
}

export const initialState: State = {
  is_walk_thru: false,
}

export interface State {
  is_walk_thru: boolean
}
