import {
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
} from './types'
import UpdateUser from '../../../../models/Auth/User/UpdateUser'

export const createLogoutRequestAction = () => ({ type: LOGOUT_REQUEST })
export const createLogoutSuccessAction = () => ({ type: LOGOUT_SUCCESS })
export const createLogoutFailureAction = (error) => ({
  error,
  type: LOGOUT_FAILURE,
})

export const createGetProfileRequestAction = () => ({
  type: GET_PROFILE_REQUEST,
})
export const createGetProfileSuccessAction = (data: any) => ({
  data,
  type: GET_PROFILE_SUCCESS,
})
export const createGetProfileFailureAction = (error) => ({
  error,
  type: GET_PROFILE_FAILURE,
})

export const createUpdateProfileRequestAction = () => ({
  type: UPDATE_PROFILE_REQUEST,
})
export const createUpdateProfileSuccessAction = (data: UpdateUser) => ({
  data,
  type: UPDATE_PROFILE_SUCCESS,
})
export const createUpdateProfileFailureAction = (error) => ({
  error,
  type: UPDATE_PROFILE_FAILURE,
})
