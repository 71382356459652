import { Button, Col, Row } from 'reactstrap'
import React from 'react'
import browserHistory from '../../../../browserHistory'
import { useTranslation } from 'react-i18next'

const TransferLoanButton = () => {
  const { t } = useTranslation()

  function handleViewOffer() {
    browserHistory.push('/dashboard/borrower/sign-contract')
  }

  return (
    <Row className="my-5 mx-0">
      <Col lg={{ size: 6, offset: 3 }} className="text-center">
        <Button className="hero" onClick={handleViewOffer}>
          {t('SIGN_TRANSFER_LOAN')}
        </Button>
      </Col>
    </Row>
  )
}

export default TransferLoanButton
