import React from 'react'
import { Col, Row } from 'reactstrap'
import { WithTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import { ScrollElement } from 'utils/scroll'
import { formatMoney } from '../../../../utils'
import { formatDueDate } from '../../../../utils/'
import LoanStatusEnum from '../LoanStatus/LoanStatus.enum'

interface Props extends WithTranslation, MappedStateModel {}

const LoanMainInfo = (props: Props) => {
  const {
    t,
    remainingDebt,
    interestRate,
    monthlyPayment,
    dueDate,
    isHidden,
    loanAmount,
    islegacy,
    loanAccountState,
  } = props

  if (isHidden || loanAccountState === LoanStatusEnum.DEBT_COLLECTION) {
    return null
  }

  return (
    <>
      <ScrollElement name="loan-info" />
      <section className="loan-main-info">
        <Row>
          <Col
            lg={{ size: 6, offset: 3 }}
            md={{ size: 8, offset: 2 }}
            sm="12"
            className="text-center"
          >
            <div className="loan-left__wrapper">
              <div className="loan-info__title">
                {t('BORROWER_DASHBOARD_LOAN_LEFT_TITLE')}
              </div>
              <h1 className="loan-left__number">
                {remainingDebt && `${formatMoney(remainingDebt)} €`}
              </h1>
            </div>
            <div className="pay-loan">{t('PAY_FULL_LOAN_NOW')}</div>
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ size: 6, offset: 3 }}
            md={{ size: 8, offset: 2 }}
            sm="12"
            className="loan-progress-bar__wrapper"
          >
            <div className="loan-progress-bar">
              <div
                className="filled"
                style={{
                  width: `${getLoanProgressBarValue(
                    remainingDebt,
                    loanAmount
                  )}%`,
                }}
              ></div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 8, offset: 2 }} sm="12" className="loan-size-date">
            <Col className="loan-info">
              <div className="loan-info__title">
                {t('BORROWER_DASHBOARD_INSTALLMENT_TITLE')}
              </div>
              <div className="loan-info__value">
                <span>
                  {monthlyPayment && `${formatMoney(monthlyPayment)} €`}
                </span>
                <span> /{t('MONTH')}</span>
              </div>
            </Col>
            <Col className="loan-info">
              <div className="loan-info__title">
                {t('BORROWER_DASHBOARD_DUE_DATE_TITLE')}
              </div>
              <div className="loan-info__value">
                <span>{t(`${formatDueDate(dueDate)}`)}</span>
              </div>
            </Col>
            {islegacy ? (
              <Col className="loan-info">
                <div className="loan-info__title">
                  {t('BORROWER_LEGACY_DASHBOARD_CURRENT_NOMINAL_TITLE')}
                </div>
                <div className="loan-info__value">
                  <span>{interestRate} %</span>
                </div>
              </Col>
            ) : (
              <Col className="loan-info">
                <div className="loan-info__title large-view">
                  {t('BORROWER_DASHBOARD_CURRENT_NOMINAL_TITLE')}
                </div>
                <div className="loan-info__title small-view">
                  {t('BORROWER_DASHBOARD_CURRENT_NOMINAL_TITLE_SMALL')}
                </div>
                <div className="loan-info__value">
                  <span>{interestRate} %</span>
                </div>
              </Col>
            )}
          </Col>
        </Row>
      </section>
    </>
  )
}

function getLoanProgressBarValue(remainingDebt, loanAmount) {
  const amount = ((loanAmount - remainingDebt) * 100) / loanAmount
  return amount > 0 ? amount : 0
}

export default LoanMainInfo
