import {
  GET_INVESTOR_TRANSACTIONS_REQUEST,
  GET_INVESTOR_TRANSACTIONS_SUCCESS,
  GET_INVESTOR_TRANSACTIONS_FAILURE,
  GET_INVESTOR_TRANSACTIONS_CSV_REQUEST,
  GET_INVESTOR_TRANSACTIONS_CSV_SUCCESS,
  GET_INVESTOR_TRANSACTIONS_CSV_FAILURE,
  STORE_DATES_AND_TYPE,
} from './types'
import InvestorTransactions from '../../../../../../models/InvestorTransactions'
import Dates from '../../../../../../models/RangeDatepicker/Dates'

export const createGetInvestorTransactionsRequestAction = () => ({
  type: GET_INVESTOR_TRANSACTIONS_REQUEST,
})
export const createGetInvestorTransactionsSuccessAction = (
  data: InvestorTransactions
) => ({ data, type: GET_INVESTOR_TRANSACTIONS_SUCCESS })
export const createGetInvestorTransactionsFailureAction = (error: any) => ({
  error,
  type: GET_INVESTOR_TRANSACTIONS_FAILURE,
})

export const createStoreDatesAndTypeAction = (dates: Dates, type?: string) => ({
  payload: { dates, type },
  type: STORE_DATES_AND_TYPE,
})

export const createGetInvestorTransactionsCsvRequestAction = () => ({
  type: GET_INVESTOR_TRANSACTIONS_CSV_REQUEST,
})
export const createGetInvestorTransactionsCsvSuccessAction = () => ({
  type: GET_INVESTOR_TRANSACTIONS_CSV_SUCCESS,
})
export const createGetInvestorTransactionsCsvFailureAction = (error: any) => ({
  error,
  type: GET_INVESTOR_TRANSACTIONS_CSV_FAILURE,
})
