import React from 'react'
import { useTranslation } from 'react-i18next'
import { State } from '../../../../store'
import { connect } from 'react-redux'
import { formatMoney } from '../../../../utils'
import { Col, Container, Row } from 'reactstrap'
import DateDropDown from './DateDropDown'
import AccountNumberDropDown from './AccountNumberDropDown'

interface Props extends MappedState {}

const OfferInformation = (props: Props) => {
  const {
    amountOffered,
    termsOffered,
    installmentOffered,
    averageNominalInterest,
    firstYearTotalFee,
    averageEffectiveAnnualInterest,
    totalInstallmentOffered,
  } = props
  const { t } = useTranslation()

  return (
    <div className="offer-information py-4">
      <Container>
        <Row>
          <Col lg="4" sm="12">
            <div className="offer-information__title">{t('LOAN_AMOUNT')}</div>
            <div className="offer-information__value font-80">
              {amountOffered} €
            </div>
          </Col>
          <Col lg="3">
            <div className="offer-information__title">
              {t('INSTALLMENT_PER_MONTH')}
            </div>
            <div className="offer-information__value font-50">
              {installmentOffered}
            </div>
          </Col>
          <Col lg="2">
            <div className="offer-information__title">
              {t('LOAN_TERM_IN_MONTHS')}
            </div>
            <div className="offer-information__value font-50">
              {termsOffered}
            </div>
          </Col>
          <Col lg="3">
            <div className="offer-information__title">
              {t('AVG_NOMINAL_INTEREST')}
            </div>
            <div className="offer-information__value font-50">
              {averageNominalInterest} %
            </div>
          </Col>
        </Row>
        <Row className="flex-column-reverse flex-md-column-reverse flex-lg-row d-flex">
          <Col className="d-flex flex-column" lg="4">
            <div className="offer-information__title">
              {t('INSTALLMENT_DATE_AND_BANK_ACCOUNT')}
            </div>
            <div className="offer-information__value text-lg-left text-center">
              <DateDropDown />
              <AccountNumberDropDown />
            </div>
          </Col>
          <Col lg="3" className="hide">
            <div className="offer-information__year-title">
              {t('1ST_YEARS_LOAN ACCOUNT_FEE_(WILL_BE_ADDED_TO_LOAN_AMOUNT)')}
            </div>
            <div className="offer-information__value mt-lg-1">
              {firstYearTotalFee} €
            </div>
          </Col>
          <Col lg="2">
            <div className="offer-information__title">
              {t('AVG_EFFECTIVE_INTEREST')}
            </div>
            <div className="offer-information__value">
              {averageEffectiveAnnualInterest} %
            </div>
          </Col>
          <Col lg="3">
            <div className="offer-information__title">{t('TOTAL_PAYABLE')}</div>
            <div className="offer-information__value">
              {totalInstallmentOffered} €
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const mapStateToProps = (state: State): MappedState => {
  const {
    amount_offered,
    terms_offered,
    installment_offered,
    average_nominal_interest,
    average_effective_annual_interest,
    total_installment_offered,
    first_year_total_fee,
  } = state.user.borrower.loan_application.data

  return {
    amountOffered:
      amount_offered && formatMoney(amount_offered + first_year_total_fee, 0),
    termsOffered: terms_offered,
    installmentOffered: formatMoney(Number(installment_offered), 2),
    averageNominalInterest:
      average_nominal_interest && formatMoney(average_nominal_interest * 100),
    averageEffectiveAnnualInterest:
      average_effective_annual_interest &&
      formatMoney(average_effective_annual_interest * 100, 2),
    totalInstallmentOffered: formatMoney(Number(total_installment_offered), 2),
    firstYearTotalFee: formatMoney(first_year_total_fee, 2),
  }
}

export interface MappedState {
  amountOffered?: number | string | null
  termsOffered?: number | null
  installmentOffered?: number | string | null
  averageNominalInterest?: number | string | null
  averageEffectiveAnnualInterest?: number | string | null
  totalInstallmentOffered: number | string | null
  firstYearTotalFee: number | string | null
}

export default connect<MappedState, any, any, any>(mapStateToProps)(
  OfferInformation
)
