import {
  GET_CONTRACT_REQUEST,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAILURE,
} from './types'

export const getContractRequestAction = () => ({ type: GET_CONTRACT_REQUEST })
export const getContractSuccessAction = (data: string) => ({
  data,
  type: GET_CONTRACT_SUCCESS,
})
export const getContractFailureAction = (error) => ({
  error,
  type: GET_CONTRACT_FAILURE,
})
