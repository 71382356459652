import {
  createGetTermsAndCondtionsFailureAction,
  createGetTermsAndCondtionsRequestAction,
  createGetTermsAndCondtionsSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import TermsAndConditionsAPI from '../../../../../api/general/TermsAndConditionsAPI'

export default (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch
) => {
  dispatch(createGetTermsAndCondtionsRequestAction())
  try {
    const termsAndConditionsApi = new TermsAndConditionsAPI()
    const response: AxiosResponse = await termsAndConditionsApi.getTermsAndConditions()
    dispatch(createGetTermsAndCondtionsSuccessAction(response.data.data))
  } catch (error) {
    dispatch(createGetTermsAndCondtionsFailureAction(error))
  }
}
