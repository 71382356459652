import React from 'react'
import { Button } from 'reactstrap'
import { WithTranslation, useTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import { ScrollElement } from 'utils/scroll'
import browserHistory from 'browserHistory'
import LoanStatusEnum from '../LoanStatus/LoanStatus.enum'

interface Props extends WithTranslation, MappedStateModel {}

const DebtCollection = (props: Props) => {
  const { loanAccountState, loanAmount } = props
  const { t } = useTranslation()
  const loanId = browserHistory.location.pathname.split('/')[4]

  /* Trigger for this visual component: */
  const isTrigger = loanAccountState === LoanStatusEnum.DEBT_COLLECTION

  if (!isTrigger) {
    return null
  }

  return (
    <>
      <ScrollElement name="debt-collection" />
      <div className="debt-collection">
        <>
          <div className="text-center">
            <h1 className="text-white pt-40">{t('DEBT_COLLECTION_HEADING')}</h1>
            <div className="p-10">
              <p className="text-white">
                {t('DEBT_COLLECTION_BODY_1', {
                  LOAN_ID: `${loanId}`,
                  LOAN_AMOUNT: `${loanAmount}`,
                })}
              </p>
              <p className="text-white">{t('DEBT_COLLECTION_BODY_2')}</p>
            </div>
            <Button onClick={() => browserHistory.push('/dashboard')}>
              {t('BACK')}
            </Button>
          </div>
        </>
      </div>
    </>
  )
}

export default DebtCollection
