import { ThunkAction } from 'redux-thunk'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import { Dispatch } from 'redux'
import {
  createPatchBrokerUrlRequestAction,
  createPatchBrokerUrlAction,
  createPatchBrokerUrlFailureAction,
} from './creators'
import { State } from '../../../../index'

const patchAffiliateClick = (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  dispatch(createPatchBrokerUrlRequestAction())

  try {
    const borrowerAPI = new BorrowerAPI()

    await borrowerAPI.patchAffiliateClick(
      getState().user.borrower.loan_application.data.id
    )
    dispatch(createPatchBrokerUrlAction())
  } catch (error) {
    dispatch(createPatchBrokerUrlFailureAction(error))
  }
}

export default patchAffiliateClick
