import React from 'react'

const CCISConsent: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared t-and-c__v02">
      <p>
        Kuluttajaluottojen kyselyjärjestelmä on Suomen Asiakastieto Oy:n
        tarjoama palvelu, jonka avulla keskinäisen sopimuksen tehneet
        luotonantajat luovuttavat halussaan olevaa kuluttajaa koskevaa tietoa
        toisilleen kuluttajan (lainanhakija) antaman toimeksiannon ja
        suostumuksen perusteella luottohakemuksen käsittelyä varten.
        Järjestelmässä käsiteltävät ja välitettävät tiedot koskevat luottoja ja
        niiden hoitamista, eikä niitä luovuteta ulkopuolisille ilman lain
        mukaista velvoitetta.
      </p>
      <p>
        Mikäli luotto myönnetään, tiedot säilytetään viiden vuoden ajan siitä,
        kun luotto on kokonaisuudessaan erääntynyt maksettavaksi, ellei laissa
        säädetä velvollisuutta säilyttää niitä tätä pidempään. Mikäli luottoa ei
        myönnetä, tiedot poistetaan kahden vuoden kuluttua luottohakemuksen
        tekemisestä.
      </p>
      <p>
        Luotonhakijalla on oikeus tarkastaa mille tahoille ja mitä tietoja
        Kuluttajaluottojen kyselyjärjestelmän kautta on luovutettu.
        Tarkistusoikeuden toteuttaa Suomen Asiakastieto Oy.
      </p>
      <p>
        Lisätietoja Suomen Asiakastieto Oy:n ylläpitämästä Kuluttajaluottojen
        kyselyjärjestelmästä sekä siihen kuuluvat yhtiöt:
        <a href="https://www.asiakastieto.fi/web/fi/positiivinen-tieto.html">
          &nbsp;asiakastieto.fi
        </a>
      </p>
      <p></p>
    </div>
  )
}

export default CCISConsent
