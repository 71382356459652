import React from 'react'
import { connect } from 'react-redux'
import { State } from '../../store'
import { Company } from 'models/Auth/ProfileData'
import CompanyProfile from './CompanyProfile'

interface Props extends MappedState {}

const UserCompanies = (props: Props) => {
  const { companies } = props

  const companiesProfile = companies.map((company) => {
    return <CompanyProfile key={company.id} data={company} />
  })

  return <React.Fragment>{companiesProfile}</React.Fragment>
}

interface MappedState {
  companies: Company[]
}

const mapStateToProps = (state: State): MappedState => {
  return {
    companies: state.user.profile.data.companies,
  }
}

export default connect<MappedState, any, any, any>(mapStateToProps)(
  UserCompanies
)
