import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import {
  createLoginWithUserPassFailureAction,
  createLoginWithUserPassRequestAction,
  createLoginWithUserPassSuccessAction,
} from './creators'
import LoginUserPassAPI from '../../../../api/auth/LoginUserPassAPI'
import { LegacyLoginData } from '../../../../models/Legacy/LegacyLoginData'
import { setToken } from '../../../../utils/auth'
import browserHistory from '../../../../browserHistory'
import { scroll } from '../../../../utils/scroll'

export const LoginWithUsernameAndPassword = (
  data: LegacyLoginData
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createLoginWithUserPassRequestAction())
  try {
    const loginAPI = new LoginUserPassAPI()
    const response = await loginAPI.loginWithUsernameAndPassword(data)
    dispatch(createLoginWithUserPassSuccessAction(response.data.data))
    setToken(response.data.data.token, true, response.data.data)
    if (response.data.data.role === 'borrower') {
      browserHistory.push('/restricted/borrower')
    } else {
      scroll('top-of-the-page')
      browserHistory.push('/restricted/investor')
    }
  } catch (error) {
    dispatch(createLoginWithUserPassFailureAction(error))
    if (error.response && parseInt(error.response.status, 10) === 403) {
      // wrong username or password
      if (
        error.response.data &&
        error.response.data.message.includes('legacy system exception')
      ) {
        browserHistory.push({
          pathname: '/login/email',
        })
        return
      }
      // user is already onboarded
      browserHistory.push({
        pathname: '/login/sms/code',
        search: 'error=onboarded',
      })
    }
    if (error.response && parseInt(error.response.status, 10) === 401) {
      browserHistory.push({
        pathname: '/login/email',
      })
    }
  }
}
