import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { getBodyContentFromHtml } from '../../../../../utils'
import {
  getContractFailureAction,
  getContractRequestAction,
  getContractSuccessAction,
} from './creators'
import InvestorAPI from '../../../../../api/user/InvestorAPI'
import { State } from '../../../../index'

export const getContract = (
  depositId?: string
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => State
) => {
  const depositAccountId = depositId
    ? depositId
    : getState().user.profile.data.deposits[0].mambu_deposit_id

  dispatch(getContractRequestAction())
  try {
    const investorAPI = new InvestorAPI()
    const response = await investorAPI.getInvestorContract(depositAccountId)
    dispatch(
      getContractSuccessAction(getBodyContentFromHtml(response.data.data.html))
    )
  } catch (error) {
    dispatch(getContractFailureAction(error))
  }
}
