import React from 'react'
import fixura_logo_color from 'assets/images/fixura_logo_color.svg'
import fixura_logo_savelend from 'assets/images/fixura_logo_savelend.png'
import fixura_logo_savelend_white from 'assets/images/fixura_logo_savelend_wht.png'

interface Props {
  size: 'small' | 'medium' | 'large'
  color: 'white' | 'dark'
}

const FixuraLogo = (props: Props) => {
  return (
    <div className={`fixura-logo__wrapper ${props.size}-logo`}>
      <img
        src={getLogoByColor(props.color)}
        alt="Fixura logo"
        className="fixura-logo"
      />
    </div>
  )
}

const getLogoByColor = (color: string) => {
  switch (color) {
    case 'color':
      return fixura_logo_color
    case 'dark':
      return fixura_logo_savelend
    case 'white':
      return fixura_logo_savelend_white
  }
}

export default FixuraLogo
