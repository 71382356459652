import {
  createUpdateProfileFailureAction,
  createUpdateProfileRequestAction,
  createUpdateProfileSuccessAction,
} from './creators'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import UserAPI from '../../../../api/user/UserAPI'
import { getUserProfile } from './getUserProfile'
import UpdateUser from '../../../../models/Auth/User/UpdateUser'
import { State } from '../../../index'

const updateUserProfile = (
  data: UpdateUser
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => State
) => {
  dispatch(createUpdateProfileRequestAction())
  const userApi = new UserAPI()
  try {
    if (!!getState().user.profile.data.id) {
      await userApi.updateUser(data)
      dispatch(getUserProfile())
    } else {
      // just update user profile data without api call, main usecase is for language update
      dispatch(createUpdateProfileSuccessAction(data))
    }
  } catch (error) {
    dispatch(createUpdateProfileFailureAction(error))
  }
}

export default updateUserProfile
