import {
  createGetCompanySignRightStatusFailureAction,
  createGetCompanySignRightStatusRequestAction,
  createGetCompanySignRightStatusSuccessAction,
} from './creators'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import SignCompanyRightAPI from '../../../../../api/user/SignCompanyRightAPI'

const postSignCompanyRight = (
  code: string,
  companyId: number
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const params = { business_code: code }

  dispatch(createGetCompanySignRightStatusRequestAction())

  try {
    const signCompanyRightAPI = new SignCompanyRightAPI()
    const response = await signCompanyRightAPI.postSignCompanyRight(params)
    dispatch(
      createGetCompanySignRightStatusSuccessAction(
        response.data.data,
        companyId
      )
    )
  } catch (error) {
    dispatch(createGetCompanySignRightStatusFailureAction(error))
  }
}

export default postSignCompanyRight
