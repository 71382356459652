import { connect } from 'react-redux'
import { State } from '../../../store'
import getBorrowerLoan from '../../../store/user/borrower/loan/actions/getBorrowerLoan'
import { ThunkDispatch } from 'redux-thunk'
import React, { useEffect } from 'react'
import LoanMainInfo from './LoanMainInfo'
import LoanSummary from './LoanSummary'
import PaymentPlan from './PaymentPlan'
import NextInstallment from './NextInstallment'
import DueInstallment from './DueInstallment'
import DebtCollection from './DebtCollection'
import LoanSetting from './LoanSetting'
import CustomerService from '../../shared/CustomerService'
import { default as BorrowerLegacy } from './legacy'
import BackButton from '../../shared/BackButton'
import useHashLink from 'hooks/useHashLinks'
import { getLegacyLoans } from 'store/legacy/borrower/loan/actions/getLegacyLoans'
import { Loan as LegacyLoan } from '../../../models/Legacy/Borrower/Loan'
import { Loan, LegacyUser } from '../../../models/Auth/ProfileData'
import { useParams } from 'react-router-dom'
import { useLocation, useHistory } from 'react-router'
import Indicator from 'components/shared/Indicator'
import { Row, Col, Button, Container } from 'reactstrap'
import LoanSwitch from './LoanSwitch'
import { useTranslation } from 'react-i18next'
import browserHistory from 'browserHistory'
import { capitalizeAndExtractFirstLetter } from '../../../utils'
import ic_plus from 'assets/images/ic_plus.svg'
import { isLegacy } from 'utils/auth'
import isDueDatePassed from './DueInstallment/checkDueDate'
import { INVEST_IN_SAVLEND_LINK } from '../../../constants'
import { setUrl } from '../../shared/helper'

interface Props extends MappedState, MappedDispatch {}

const Borrower = (props: Props) => {
  const {
    getBorrowerLoan,
    getLegacyLoans,
    isFetching,
    legacyUsers,
    loans,
    legacyLoans,
    nationality,
    hasSignedContract,
    firstName,
    dueData,
    language
  } = props

  useHashLink(isFetching)
  const { id } = useParams()
  const { pathname, hash } = useLocation()
  const history = useHistory()
  const { t } = useTranslation()

  const defaultLoanType = pathname.includes('fixed') ? 'fixed' : 'fair'

  useEffect(() => {
    if (nationality && nationality !== 'FI') {
      history.replace(`/dashboard/`)
    } else if (!id) {
      if (loans.length > 0) {
        if (hash) {
          history.replace(
            `/dashboard/borrower/fair-credit-loan/${loans[0].id}${hash}`
          )
        }
      } else if (legacyLoans.length > 0) {
        if (hash) {
          history.replace(
            `/dashboard/borrower/fixed-rate-loan/${legacyLoans[0].Reference}${hash}`
          )
        }
        history.replace(`/dashboard/borrower`)
      } else {
        history.replace(`/dashboard/borrower`)
      }
    }
    // eslint-disable-next-line
  }, [hash, history, id, loans, nationality, legacyUsers])

  useEffect(() => {
    if (legacyUsers.length && legacyUsers[0].legacy_role === 'borrower') {
      getLegacyLoans()
    }
  }, [getLegacyLoans, legacyUsers])

  useEffect(() => {
    if (id) {
      if (defaultLoanType === 'fair' && loans.length) {
        getBorrowerLoan(parseInt(id))
      }
    }
  }, [defaultLoanType, getBorrowerLoan, id, loans])

  // 3.0 user with 2.0 loan
  if (defaultLoanType === 'fixed') {
    return <BorrowerLegacy isNormalUser />
  }

  const isFair = pathname.includes('fair-credit-loan')
  const loanDetails = pathname.includes('loan')
  const isDashboard = browserHistory.location.pathname === '/dashboard'
  const isBorrowerDashboard =
    browserHistory.location.pathname === '/dashboard/borrower'

  function redirectToSavelendSite() {
    window.location.assign(INVEST_IN_SAVLEND_LINK)
  }

  // 3.0 user with 3.0 loan
  if (defaultLoanType === 'fair') {
    return (
      <div className={isDashboard ? 'bg-grey' : ''}>
        <Indicator isVisible={isFetching} />
        {dueData && !isDueDatePassed(dueData) && loanDetails && <BackButton />}
        {!isFair ? (
          <div className="portfolio-list">
            <>
              <Row className="center main-heading pb-15 row-w-90">
                <Col md="4" className="text-center">
                  <h1 className="add-funds-margin">
                    {!hasSignedContract
                      ? firstName &&
                        `${t('HELLO')} ${capitalizeAndExtractFirstLetter(
                          firstName
                        )}!`
                      : t('YOUR_LOANS')}
                  </h1>
                </Col>
              </Row>
              {isBorrowerDashboard && <LoanSummary />}
              <LoanSwitch />
              {/* appear on dashboard link to old web app */}
              {!isLegacy() && (
                <Container className="add-funds__wrapper px-25">
                  <Row className="center p-20 ">
                    <Col md={5} className="text-center">
                      <button
                        className="download-item__wrapper add_company pointer"
                        onClick={() => {
                          window.open(setUrl(language), '_blank')
                          browserHistory.push('/dashboard')
                        }}
                      >
                        <img
                          className="mb-1_add_company"
                          src={ic_plus}
                          alt={t('APPLY_FOR_A_NEW_LOAN')}
                        />
                        <span className="font-weight-bold">
                          {t('APPLY_FOR_A_NEW_LOAN')}
                        </span>
                      </button>
                    </Col>
                  </Row>
                </Container>
              )}
            </>
          </div>
        ) : (
          <>
            {/* @ts-ignore */}
            <DueInstallment />
            <LoanSetting />
            {/* @ts-ignore */}
            <DebtCollection />
            <LoanMainInfo />
            {/* @ts-ignore */}
            <NextInstallment />
            <PaymentPlan />
          </>
        )}
        {/* show when user not have investment account */}
        {!hasSignedContract && !isFair && (
          <div className="fixura_loans mt-20 hide">
            <div className="text-center">
              <h1 className="pt-45">{t('NEW_INVESTOR')}</h1>
              <p className="p-10">{t('NEW_INVESTOR_TEXT')}</p>
              <Row className="row-w-90">
                <Col className="text-center take-loan__btn">
                  <Button onClick={() => redirectToSavelendSite()}>
                    {t('START_INVESTING')}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        )}
        {!loanDetails && isBorrowerDashboard && hasSignedContract && (
          <CustomerService />
        )}
      </div>
    )
  } else return null
}

interface MappedState {
  loansLength: number
  isFetching: boolean
  legacyLoans: LegacyLoan[]
  legacyUsers: LegacyUser[]
  loans: Loan[]
  nationality?: string
  hasSignedContract: boolean
  firstName: string
  dueData?: string
  language: string
}

interface MappedDispatch {
  getBorrowerLoan: (id: number) => void
  getLegacyLoans: () => void
}

const mapStateToProps = (state: State): MappedState => {
  const legacyLoans = state.legacy.borrower.loan.data
  const loans = state.user.profile.data.loans

  return {
    loansLength: legacyLoans.length + loans.length,
    legacyLoans,
    loans: loans,
    nationality: state.user.profile.data.nationality,
    legacyUsers: state.user.profile.data.legacy_users,
    isFetching:
      state.legacy.borrower.loan.is_fetching ||
      state.user.borrower.loan.is_fetching ||
      state.user.profile.is_fetching,
    hasSignedContract:
      state.user.profile.data.deposits.length > 0 &&
      state.user.profile.data.deposits[0].has_signed_contract,
    firstName: state.user.profile.data.first_name,
    dueData: state.user.borrower.loan.data?.next_instalment?.due_date,
    language: state.user.profile.data.language,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  getBorrowerLoan: (id) => dispatch(getBorrowerLoan(id)),
  getLegacyLoans: () => dispatch(getLegacyLoans()),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(Borrower)
