import {
  createWithdrawFailureAction,
  createWithdrawRequestAction,
  createWithdrawSuccessAction,
} from './creators'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import InvestorAPI from '../../../../../api/legacy/InvestorAPI'
import fetchPortfolio from '../../kpi/actions/fetchPortfolio'
import fetchTransactions from '../../transactions/actions/fetchTransactions'

const postWithdraw = (
  amount: number,
  legacyUserId
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(createWithdrawRequestAction())

  try {
    const investorAPI = new InvestorAPI()
    await investorAPI.postWithdraw(amount, legacyUserId)
    dispatch(createWithdrawSuccessAction(amount))
    dispatch(fetchPortfolio(legacyUserId))
    dispatch(fetchTransactions(legacyUserId))
  } catch (error) {
    dispatch(createWithdrawFailureAction(error))
  }
}

export default postWithdraw
