import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { WithTranslation } from 'react-i18next'
import Indicator from '../../shared/Indicator'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel from './MappedDispatch.model'
import browserHistory from '../../../browserHistory'
import { useLocation } from 'react-router-dom'
import { ScrollElement } from 'utils/scroll'
import { useParams } from 'react-router-dom'
import { checkCompaniesBusinessCode } from './CheckCompaniesSignRight'
import LabeledValue from '../PortfolioSummary/LabeledValue'
import { formatMoney } from '../../../utils'
import { accountName } from '../../../utils/accountName'
import ic_icon_right_arrow from 'assets/images/ic_icon_right_arrow.svg'
import ic_icon_down_arrow from 'assets/images/ic_icon_down_arrow.svg'
import { Progress } from 'reactstrap'
import ic_info_icon_alert from 'assets/images/ic_info_icon_alert.svg'

interface ComponentProps {
  hasError?: boolean
  errorMessage?: string
  errors: any
}
interface Props
  extends WithTranslation,
    MappedStateModel,
    MappedDispatchModel,
    ComponentProps {}

const Presentation = (props: Props) => {
  const {
    t,
    withdraw,
    is_fetching,
    isHidden,
    postSignCompany,
    isSignRight,
    data,
    bankAccounts,
    depositId,
    cancelWithdraw,
    getWithdrawStatus,
    status,
    error,
  } = props
  const location = useLocation()
  const { userId: LegacyUserId, companyId } = useParams()
  const [fields, setFields] = useState({})
  const [errors, setErrors] = useState({})

  const [isSignRightStatus, setIsSignRightStatus] = useState(true)
  const isInProcess = status.active
  const notEnoughFundsError =
    error &&
    error.response.data.message.indexOf('Not enough money available') !== -1

  const handleChangeCollectFunds = (
    field: string,
    e: React.FormEvent<HTMLInputElement>
  ) => {
    handleChange(field, fields, setFields, e)
    // TODO: reenable alert when autosell is available again
    // handleNoticeAlert(fields, setErrors, t, data)
  }

  const isLegacy = location.pathname.startsWith('/restricted')
  const submitData = () => {
    if (handleValidation(fields, setErrors, t, data)) {
      if (isLegacy) {
        browserHistory.push(
          `/restricted/investor/fixed-rate-portfolio/${LegacyUserId}/withdraw`
        )
      } else if (companyId) {
        browserHistory.push(
          `/dashboard/company/${companyId}/investor/fair-credit-portfolio/withdraw`
        )
      } else {
        browserHistory.push(
          '/dashboard/investor/fair-credit-portfolio/withdraw'
        )
      }

      withdraw(fields['amount'])
    }
  }

  const handleCancelWithdraw = () => {
    cancelWithdraw(depositId)
  }

  const handleStopAndWithDraw = () => {
    if (isLegacy) {
      browserHistory.push(
        `/restricted/investor/fixed-rate-portfolio/${LegacyUserId}/withdraw`
      )
    } else if (companyId) {
      browserHistory.push(
        `/dashboard/company/${companyId}/investor/fair-credit-portfolio/withdraw`
      )
    } else {
      browserHistory.push('/dashboard/investor/fair-credit-portfolio/withdraw')
    }

    withdraw(data.free_capital)
  }

  useEffect(() => {
    checkCompaniesBusinessCode(companyId)
  }, [companyId])

  useEffect(() => {
    if (depositId) {
      getWithdrawStatus(depositId)
    }
  }, [getWithdrawStatus, depositId])

  //refresh status only for five minutes after 30 seconds
  useEffect(() => {
    const start = Date.now()
    const theInterval = setInterval(function () {
      if (Date.now() - start > 300000) {
        clearInterval(theInterval)
        return
      }
      if (isInProcess && depositId) {
        getWithdrawStatus(depositId)
      }
    }, 30000)
  }, [getWithdrawStatus, depositId, isInProcess])

  const checkCompanyBusinessCode = checkCompaniesBusinessCode(companyId)
  const code = checkCompanyBusinessCode
    ? checkCompanyBusinessCode.business_code
    : ''

  useEffect(() => {
    if (companyId && code) postSignCompany(code, companyId)
  }, [postSignCompany, companyId, code])

  useEffect(() => {
    const status = '2'
    if (companyId && parseInt(isSignRight) !== parseInt(status)) {
      setIsSignRightStatus(false)
    } else {
      setIsSignRightStatus(true)
    }
  }, [isSignRight, companyId])

  if (isHidden) {
    return null
  }

  const remainingAmountToSell = status.active
    ? (status.requestedAmount - status.remainingAmountToSell) /
      (status.requestedAmount / 100)
    : 0

  const investorBankAccount = bankAccounts && bankAccounts[0]?.IBAN

  return (
    <>
      <div className="back-color">
        <ScrollElement name="withdraw" />
        <Container className="pb-5 withraw-funds-align">
          {isInProcess ? (
            <>
              <Row>
                <Col>
                  <h1 className="withdraw-collect-funds-title">
                    {t('WITHDRAW_PROGRESS_TITLE')}
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 className="withdraw-collect-funds">
                    {t('WITHDRAW_PROGRESS_DETAILS', {
                      amount: formatMoney(status.requestedAmount),
                      accountNumber: investorBankAccount,
                    })}
                  </h6>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col>
                <h1 className="collect-funds">{t('WITHDRAW_TITLE')}</h1>
              </Col>
            </Row>
          )}
          {isLegacy ? (
            <Row>
              <Col>
                <h1 className="collect-funds">
                  {t('WITHDRAW_FUNDS_RESTRICTED')}
                </h1>
              </Col>
            </Row>
          ) : (
            <>
              {isSignRightStatus ? (
                <>
                  {isInProcess ? (
                    <>
                      <Row>
                        <Col>
                          <h1 className="withdraw-collect-funds ">
                            {formatMoney(
                              status.requestedAmount -
                                status.remainingAmountToSell
                            )}{' '}
                            €
                          </h1>
                        </Col>
                      </Row>
                      <Row className="pb-3">
                        <Col>
                          <Progress value={remainingAmountToSell}>
                            <span>{formatMoney(status.requestedAmount)} €</span>
                          </Progress>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      {data && (
                        <Row className="pb-5 withraw-funds-value">
                          {notEnoughFundsError && (
                            <div className="amount-error-message pt-8 color-red pl-12">
                              {t('WITHDRAW_ERROR_INSUFFICIENT_FUNDS')}
                            </div>
                          )}
                          {/*<LabeledValue
                            className="text-white pb-10"
                            title={t('TOTAL_VALUE')}
                            xs="6"
                            lg="3"
                          >
                            {formatMoney(data.portfolio_value)} €
                          </LabeledValue>*/}
                          <LabeledValue
                            className="text-white pb-10"
                            title={t('FREE_CAPITAL')}
                            xs="6"
                            lg="3"
                          >
                            {formatMoney(data.free_capital)} €
                          </LabeledValue>
                          {/*<LabeledValue
                            className="text-white pb-10 "
                            title={t('ACTIVE_INVESTMENTS')}
                            xs="6"
                            lg="4"
                          >
                            {formatMoney(data.active_investments)} €
                          </LabeledValue>*/}
                        </Row>
                      )}
                      <Row>
                        <Col md={{ size: 8, offset: 2 }}>
                          <Row>
                            <Col md="12" xs="11">
                              <input
                                placeholder={t('AMOUNT')}
                                className="collect-funds-input"
                                onChange={handleChangeCollectFunds.bind(
                                  undefined,
                                  'amount'
                                )}
                                type="number"
                                onKeyDown={handleKeypress}
                                min="0"
                              />
                              {errors.hasOwnProperty('notice') && (
                                <div className="amount-error-message pt-8 color-red pl-12">
                                  <img
                                    className="alert-pr-5"
                                    src={ic_info_icon_alert}
                                    alt={t('AMOUNT')}
                                  />
                                  {errors['notice']}
                                </div>
                              )}
                              {errors.hasOwnProperty('amount') && (
                                <div className="amount-error-message pt-8 color-red pl-12">
                                  {errors['amount']}
                                </div>
                              )}
                              <Indicator isVisible={is_fetching} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {investorBankAccount && (
                        <Row className="pt-5 justify-center withraw-funds-account">
                          <Col xs="12" lg="4">
                            <Row className="text-left">
                              <Col className="p-10 pt-0" xs="12" lg="12">
                                <span className="text-white title-transfer">
                                  {t('TRANSFERED_FROM')}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} lg={12}>
                                <div className="p-10 text-center-xs">
                                  <div className="portfolio_suomi">
                                    <span>{t('PORTFOLIO_SUOMI')}</span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <div className="right-arrow">
                            <img
                              src={ic_icon_right_arrow}
                              alt={'right-arrow'}
                            />
                          </div>
                          <div className="pt-10 down-arrow">
                            <img src={ic_icon_down_arrow} alt={'down-arrow'} />
                          </div>
                          <Col xs="12" lg="4">
                            <Row className="text-left">
                              <Col className="p-10 pt-0" xs="12" lg="12">
                                <span className="text-white title-transfer">
                                  {t('TRANSFERED_TO')}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                md={{ size: 5, offset: 3 }}
                                lg={{ size: 10, offset: 2 }}
                                className="p-2-xs pt-2"
                              >
                                <div className="portfolio_account text-center">
                                  <span className="portfolio_account__name hide">
                                    {accountName(investorBankAccount)}
                                  </span>
                                  <span className="portfolio_account__number">
                                    {investorBankAccount}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  {isInProcess ? (
                    <>
                      {data.free_capital > 0 && (
                        <Row className="center">
                          <Col xs="10" className="small-collect-button">
                            <Button
                              className="mt-0 ml-md-3 ml-sm-0 p-sm-0 stop-withdraw-btn"
                              xs="2"
                              onClick={handleStopAndWithDraw}
                            >
                              {t('STOP_AND_WITHDRAW', {
                                amount: formatMoney(
                                  status.requestedAmount -
                                    status.remainingAmountToSell
                                ),
                              })}
                            </Button>
                          </Col>
                        </Row>
                      )}
                      <Row className="center">
                        <Col
                          md="4"
                          xs="10"
                          className="small-collect-button p-10"
                        >
                          <Button
                            className="mt-0 ml-md-3 ml-sm-0 p-sm-0 cancel-btn"
                            xs="2"
                            onClick={handleCancelWithdraw}
                          >
                            {t('CANCEL_WITHDRAW')}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row className="center">
                        <Col
                          md="3"
                          xs="5"
                          className="small-collect-button pt-5"
                        >
                          <Button
                            className="mt-0 ml-md-3 ml-sm-0 p-sm-0"
                            xs="2"
                            onClick={submitData}
                          >
                            {t('WITHDRAW')}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              ) : (
                <Row>
                  <Col className="my-2" md={12}>
                    <h5 className="mb-3 grey-bg p-2">
                      {t('NOT_ALLOW_TO_AUTO_INVEST_AND_WITH_DRAW')}
                    </h5>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Container>
      </div>
    </>
  )
}

/* function handleNoticeAlert(fields, setErrors, t, data) {
  const errors = {}
  let formIsValid = true
  if (
    parseInt(fields.amount) === parseInt(data.portfolio_value) &&
    parseInt(fields.amount) > parseInt(data.free_capital)
  ) {
    formIsValid = false
    errors['notice'] = t('NOTICE_SOLD_ALL_INVESTMENTS')
  }
  if (
    parseInt(fields.amount) > parseInt(data.free_capital) &&
    parseInt(fields.amount) < parseInt(data.portfolio_value)
  ) {
    formIsValid = false
    errors['notice'] = t('NOTICE_SOLD_INVESTMENTS', {
      amount: formatMoney(fields.amount - data.free_capital),
    })
  }
  setErrors(errors)
  return formIsValid
} */

function handleValidation(fields, setErrors, t, data) {
  const errors = {}
  let formIsValid = true
  // TODO: use data.portfolio_value when autosell is available again
  if (parseInt(fields.amount) > parseInt(data.free_capital)) {
    formIsValid = false
    errors['amount'] = t('COLLECT_FUNDS_MAX_AMOUNT', {
      amount: formatMoney(data.free_capital),
    })
  }
  //TODO checkme text
  if (parseInt(data.portfolio_value) < 1) {
    formIsValid = false
    errors['amount'] = t('')
  }
  if (parseInt(fields.amount) <= 0) {
    formIsValid = false
    errors['amount'] = t('COLLECT_FUNDS_LESS_AMOUNT', {
      //TODO checkme
      amount: formatMoney(1),
    })
  }
  setErrors(errors)
  return formIsValid
}

function handleKeypress(e) {
  const characterCode = e.key
  if (characterCode.toString().includes('-')) {
    e.preventDefault()
  }
}

function handleChange(
  field,
  fields,
  setFields,
  e: React.FormEvent<HTMLInputElement>
) {
  fields[field] = e.currentTarget.value
  setFields(fields)
}

export default Presentation
