import React from 'react'

const PriceList: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared">
      <h3>FIXURA AB OY – HINNASTO</h3>
      <p>Voimassa 15.6.2021 alkaen</p>
      <p>
        Tätä hinnastoa (”Hinnasto”) sovelletaan 15.6.2021 lähtien
        Lainasopimuksiin ja Sijoittajasopimuksiin. Ennen 1.2.2020 tehtyihin
        Lainasopimuksiin ja Sijoittajasopimuksiin sovelletaan 1.3.2019
        päivitettyjä Yleisiä Ehtoja, jotka löytyvät Fixuran verkkopalvelusta.
      </p>
      <br />

      <h3>1. LAINANOTTAJAT</h3>

      <table className="definitions">
        <tbody>
          <tr>
            <td>
              <b>Lainanhoitomaksu</b>
            </td>
            <td>
              Lainanhoitomaksu on 0,01 % päivässä lainan alkuperäisestä
              lainapääomasta laskettuna ja veloitetaan lainasopimuksen
              mukaisesti 0,30 % kuukausittain tai 3,65 % vuosittain.
              Lainanhoitomaksut voivat olla yhteensä enintään 150,00 euroa
              vuodessa. Ensimmäisen vuoden lainanhoitomaksut veloitetaan
              ennakkoon lainan noston yhteydessä.
            </td>
          </tr>
          <tr>
            <td>
              <b>Eräpäivän siirto </b>
            </td>
            <td>5,00 € (enintään 4 kertaa vuodessa)</td>
          </tr>
          <tr>
            <td>
              <b>Maksusuunnitelman muokkaus</b>
            </td>
            <td>50,00 €</td>
          </tr>
          <tr>
            <td>
              <b>Korkotodistus</b>
            </td>
            <td>20,00 €</td>
          </tr>
          <tr>
            <td>
              <b>Saldotodistus</b>
            </td>
            <td>10,00 € (kerran vuodessa veloituksetta)</td>
          </tr>
          <tr>
            <td>
              <b>Liikasuorituksen palautus</b>
            </td>
            <td>5,00 €</td>
          </tr>
          <tr>
            <td>
              <b>Paperilasku</b>
            </td>
            <td>5,00 €</td>
          </tr>
        </tbody>
      </table>

      <br />
      <h3>2. SIJOITTAJAT</h3>
      <table className="definitions">
        <tbody>
          <tr>
            <td>
              <b>Hallinnointimaksu</b>
            </td>
            <td>50 % Sijoittajan saamasta korkotuotosta.</td>
          </tr>
          <tr>
            <td>
              <b>Myyntimaksu</b>
            </td>
            <td>
              1 % jälkimarkkinoilla myytyjen Lainasijoitusten kokonaissummasta.
            </td>
          </tr>
          <tr>
            <td>
              <b>Erääntyneiden Lainojen myynti</b>
            </td>
            <td>
              Fixura pyrkii myymään erääntyneet lainat kolmannelle osapuolelle
              saatavilla olevaan parhaaseen hintaan.
            </td>
          </tr>
        </tbody>
      </table>

      <br />
      <p>
        Hinnaston ulkopuolisista palveluista veloitetaan palkkio käytetyn
        työajan mukaan. Toimihenkilön työn tuntihinta on 70,00 € + ALV.
      </p>
    </div>
  )
}

export default PriceList
