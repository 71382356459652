import React from 'react'
import { Col, Row } from 'reactstrap'
import Contracts from './Contracts'
import Address from './Address'
import Name from './Name'
import MobilePhone from './MobilePhone'
import Email from './Email'
import MarketingConsent from './MarketingConsent'
import BackButton from '../shared/BackButton'
import UserCompanies from './UserCompanies'
import UserCompanyHeader from './UserCompanyHeader'
import AddCompany from './AddCompany'

const Profile = () => {
  return (
    <>
      <BackButton />
      <div className="profile container my-3">
        <Row>
          <Col lg={{ size: 8, offset: 2 }}>
            <Name />
            <Address />
            <MobilePhone />
            <Email />
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 8, offset: 2 }}>
            <Contracts />
            <MarketingConsent />
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 8, offset: 2 }}>
            <UserCompanyHeader />
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 8, offset: 2 }}>
            <UserCompanies />
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 8, offset: 2 }}>
            <AddCompany />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Profile
