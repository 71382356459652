import React from 'react'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Investor } from 'utils/isInvestor'

interface ComponentProps {
  currentStepIndex: number
}

interface Props extends WithTranslation, ComponentProps {}

const Steps = (props: Props) => {
  const { t, currentStepIndex } = props
  let isInvestor = Investor()

  const borrowerSteps = [
    t('BORROWER_ON_BOARDING_STEP_BANK'),
    t('BORROWER_ON_BOARDING_STEP_PHONE'),
    t('BORROWER_ON_BOARDING_STEP_ASIAKASTIETO'),
  ]
  const investorSteps = [
    t('IDENTITY_VERIFICATION'),
    t('INVESTOR_ON_BOARDING_STEP_PHONE'),
    t('INVESTOR_ON_BOARDING_STEP_AML'),
  ]
  const steps = isInvestor ? investorSteps : borrowerSteps
  return (
    <div className="steps-container mb-2">
      <ul className="steps-list">
        {steps.map((item, index) => (
          <li key={index} className={index <= currentStepIndex ? 'active' : ''}>
            <span>{item}</span>
            <span className="radial" />
          </li>
        ))}
      </ul>
    </div>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(Steps)
