import React from 'react'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import Spinner from '../../shared/Spinner'

interface Props extends WithTranslation {}

const AispCallback = (props: Props) => {
  const { t } = props

  return (
    <div className="m-5 py-5">
      <Spinner spinnerHeight={150} spinnerWidth={150} />
      <h1 className="text-center mt-5">{t('PROCESSING_THE_APPLICATION')}</h1>
      <h6 className="text-center pb-5">{t('DO_NOT_REFRESH')}.</h6>
    </div>
  )
}

const enhance = compose<React.FunctionComponent>(withTranslation())

export default enhance(AispCallback)
