import React from 'react'
import FixuraLogo from '../../shared/FixuraLogo'

const TermsAndConditions: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared t-and-c__v02">
      <FixuraLogo size="large" color={'dark'} />
      <br />
      <h3>FIXURA AB OY – YLEISET EHDOT</h3>
      <h3>Voimassa 15.6.2021 alkaen</h3>
      <p>
        Näitä yleisiä ehtoja (”Yleiset Ehdot”) sovelletaan Sijoittajasopimuksiin
        ja Lainasopimuksiin 15.6.2021 lähtien. Ennen 1.2.2020 tehtyihin
        sijoituksiin ja myönnettyihin lainoihin sovelletaan aiempia, 1.3.2019
        päivättyjä yleisiä ehtoja, jotka löytyvät Fixuran verkkopalvelusta.
      </p>

      <h3>1. MÄÄRITELMÄT</h3>
      <table className="definitions">
        <tbody>
          <tr>
            <td>
              <b>Autoinvest</b>
            </td>
            <td>
              Fixuran tarjoaman järjestelmän automaattinen sijoitustoiminto.
            </td>
          </tr>
          <tr>
            <td>
              <b>Autosell</b>
            </td>
            <td>
              Fixuran tarjoaman järjestelmän automaattinen myyntitoiminto.
            </td>
          </tr>
          <tr>
            <td>
              <b>Fixura</b>
            </td>
            <td>Fixura Ab Oy (Y-tunnus 2246639-7)</td>
          </tr>
          <tr>
            <td>
              <b>Fixura-tili</b>
            </td>
            <td>
              Käyttäjälle tehty erillinen tili Fixuran palveluun, jonka kautta
              käyttäjä voi sijoittaa tai lainata varoja.
            </td>
          </tr>
          <tr>
            <td>
              <b>Korko</b>
            </td>
            <td>Lainasta perittävä korko.</td>
          </tr>
          <tr>
            <td>
              <b>Laina</b>
            </td>
            <td>
              Sijoittajan Lainanottajalle Lainasopimuksen nojalla maksama summa.
            </td>
          </tr>
          <tr>
            <td>
              <b>Lainanottaja</b>
            </td>
            <td>
              Palvelun käyttäjä, joka on lähettänyt Lainahakemuksen ja/tai on
              tehnyt Lainasopimuksen lainanottajana.
            </td>
          </tr>
          <tr>
            <td>
              <b>Lainasopimus</b>
            </td>
            <td>
              Lainanottajan ja Sijoittajan välille solmittu sopimus, jonka
              nojalla sovitaan mm. lainattavasta pääomasta, korosta ja
              maksusuunnitelmasta.
            </td>
          </tr>
          <tr>
            <td>
              <b>Pankkipäivä</b>
            </td>
            <td>
              Mikä tahansa arkipäivä, lukuun ottamatta lauantaita, sunnuntaita
              tai yleisiä pyhä- tai vapaapäiviä.
            </td>
          </tr>
          <tr>
            <td>
              <b>Sijoitus</b>
            </td>
            <td>
              Sijoittajan sijoittama summa Lainanottajien haettavaksi pääomaksi.
            </td>
          </tr>
          <tr>
            <td>
              <b>Sijoittaja</b>
            </td>
            <td>
              Palvelun käyttäjä, joka on hyväksynyt Fixuran Sijoittajasopimuksen
              ja siirtänyt varoja Fixura-tilille lainattavaksi Lainanottajille.
            </td>
          </tr>
          <tr>
            <td>
              <b>Sijoittajasopimus</b>
            </td>
            <td>
              Sijoittajan ja Fixuran välille tehty sopimus, jossa sovitaan
              sijoitusyhteistyöstä.
            </td>
          </tr>
          <tr>
            <td>
              <b>Hinnasto</b>
            </td>
            <td>
              Verkkopalvelussa saatavilla oleva ajantasainen hinnasto, jonka
              mukaisesti Fixura veloittaa maksuja Sijoittajilta ja
              Lainanottajilta.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <h3>2. JOHDANTO</h3>
      <p>
        Fixura on yritys, jonka liikeidea ja toimintamalli on toimia linkkinä ja
        saattaa yhteen lainanhakijat ja ne, jotka haluavat lainata varojaan
        toisille. Fixura toimii joustavana markkinapaikkana tällaisille
        toimijoille ja osana tätä toimintaa välittää Lainoja Sijoittajilta
        Lainanottajille. Fixuran Sijoittajilta ja Lainanottajilta näistä
        palveluista veloitettavat maksut löytyvät Hinnastosta, joka on
        erottamaton osa näitä Yleisiä Ehtoja.
      </p>
      <p>
        Rekisteröitymällä käyttäjäksi verkkopalvelussa käyttäjä hyväksyy nämä
        Yleiset Ehdot häntä sitoviksi. Nämä Yleiset Ehdot ovat osa
        Lainasopimusta. Mikäli nämä Yleiset Ehdot ovat ristiriidassa sen kanssa,
        mitä on nimenomaisesti sovittu Lainasopimuksessa, Lainasopimuksen ehdot
        ovat ensisijaisia.
      </p>
      <p>
        Nämä Yleiset Ehdot ovat myös osa Sijoittajasopimusta. Mikäli nämä
        Yleiset Ehdot ovat ristiriidassa sen kanssa mitä on nimenomaisesti
        sovittu Sijoittajasopimuksessa, Sijoittajasopimuksen ehdot ovat
        ensisijaisia.
      </p>
      <p>
        Fixura korostaa, että Fixura toimii ainoastaan välittäjänä Sijoittajan
        ja Lainanottajan välillä. Fixura ei tarjoa sijoituspalvelulain mukaisia
        sijoituspalveluita. Fixura on rekisteröity Etelä-Suomen
        Aluehallintoviraston luotonantaja- ja vertaislainanvälittäjärekisteriin.
      </p>

      <h3>3. LAINANOTTAJAN EHDOT</h3>
      <h3>Mahdollisuus nostaa lainaa</h3>
      <p>Fixuran välittämää Lainaa voi nostaa henkilö:</p>
      <ul>
        <li>joka on täysivaltainen ja on vähintään 21-vuotias</li>
        <li>joka on asunut vakituisesti Suomessa vähintään kaksi vuotta;</li>
        <li>jolla on säännölliset tulot;</li>
        <li>
          joka on luovuttanut Fixuralle tarvittavat tiedot luottokelpoisuuden
          arviointia varten;
        </li>
        <li>
          jolla ei ole julkisia maksuhäiriöitä tai maksamattomia erääntyneitä
          Lainoja; ja
        </li>
        <li>
          joka ei ole hallituksen jäsenenä yrityksessä, jolle on rekisteröity
          maksuhäiriöitä.
        </li>
      </ul>
      <p>
        Ennen Lainan myöntämistä Fixura tarkistaa Lainanottajan antamat tiedot
        Lainanottajan antaman suostumuksen ja henkilötietolain mukaisesti.
        Lainanottaja on itse vastuussa antamiensa tietojen oikeellisuudesta ja
        totuudenmukaisuudesta. Lainanottajan on myös ilmoitettava mahdollisista
        osoitteen- tai nimenmuutoksista viipymättä Fixuralle. Sijoittaja tai
        Fixura eivät vastaa hakemustiedoissa ja/tai Lainasopimuksessa
        ilmenevistä virheistä tai niistä aiheutuvista viivästyksistä taikka
        muista seuraamuksista. Mikäli Lainanottaja ei täytä
        ilmoitusvelvollisuuttaan, on Fixuralla oikeus vaatia Lainanottajaa
        korvaamaan selvittelystä aiheutuneet kohtuulliset kulut.
      </p>
      <p>
        Ennen Lainanottajan lainahakemuksen hyväksymistä, Fixura selvittää
        Lainanottajan luottokelpoisuutta kolmansien osapuolten ylläpitämistä
        luottotietorekistereistä, Lainanottajan pankkitilin transaktioista sekä
        Fixuran asiakas- ja luottotietorekisteristä Lainanottajan maksukyvyn
        varmistamiseksi. Lainanottaja hyväksyy, että Fixura selvittää
        Lainanottajan luottokelpoisuuden ulkopuolisten toimijoiden tarjoamien
        palveluiden avulla. Tarvittavat luottotiedot hankitaan Bisnode Finland
        Oy:n tai Suomen Asiakastieto Oy:n kautta. Lainanottaja hyväksyy, että
        annetut tiedot tarkastetaan ja säilytetään asiakassuhteen ajan sekä sen
        päättymisen jälkeen Fixuran tietosuojaselosteen mukaisesti. Fixura
        pidättää itselleen oikeuden estää henkilöä hakemasta ja nostamasta
        Lainaa sekä hylätä jo tehdyn lainahakemuksen.
      </p>

      <p>
        Mikäli haettu lainasumma ei tule kokonaan rahoitetuksi, voidaan
        Lainanottajalle tarjota haettua Lainaa pienempi Laina. Näin ollen
        Lainanottaja ei ole taattu saamaan kokonaisuudessaan hakemaansa Lainaa,
        eivätkä Sijoittajat ole velvollisia tarjoamaan Lainaa Lainanottajan
        hakemalla summalla. Lainalaskurissa lainaa haettaessa ilmoitettavat
        tiedot ovat aina ainoastaan alustava arvio haettavasta Lainasta. Lainan
        todelliset tiedot kuten määrä, Korko, maksuaika ja maksuerän suuruus
        selviävät lainatarjouksesta, johon Fixura kehottaa Lainanottajaa aina
        tutustumaan huolellisesti.
      </p>

      <h3>Lainanottajan korko ja maksut</h3>
      <p>
        Lainanottaja sitoutuu maksamaan Lainan takaisin Lainasopimuksessa
        mainituin ehdoin kuukausittain. Kuukausittainen maksuerä koostuu Lainan
        lyhennyksestä, Korosta ja lainanhoitomaksusta sekä mahdollisista
        käyttäjän valitsemista lisäpalveluista johtuvista maksuista.
      </p>
      <p>
        Lainan Korko ja lainanhoitomaksu voivat olla korkeintaan yhtä suuret
        kuin luotonmyöntöhetkellä kuluttajansuojalain 7 luvussa säädetyt
        kuluttajaluottosopimuksessa sovittavien vuosittaisen nimelliskoron ja
        muiden luottokustannuksien sallitut enimmäismäärät.
      </p>
      <p>
        Mahdolliset lisäpalvelut veloitetaan kulloinkin voimassa olevan
        Hinnaston mukaisesti. Kaikki Fixuran veloittamat kulut ovat
        arvonlisäverottomia, koska toiminta ei ole arvonlisäverovelvollista.
        Fixura ei ole vastuussa myöhässä tapahtuvista maksuista tai saamatta
        jääneistä maksuista, jos maksuja ei ole maksettu Lainasopimuksen
        mukaisesti.
      </p>
      <p>
        Lainanottajalla on oikeus maksaa Lainansa kokonaan takaisin etuajassa.
        Ennenaikaisen takaisinmaksun yhteydessä Lainanottajan on maksettava:
      </p>
      <ul>
        <li>jäljellä oleva pääoma;</li>
        <li>kertynyt mutta maksamaton Korko;</li>
        <li>kertyneet mutta maksamattomat lainanhoitomaksut; ja</li>
        <li>
          Lainasopimuksessa yksilöidyt lainan perustamisesta aiheutuneet kulut.
        </li>
      </ul>
      <p>
        Lainanottajan ei tarvitse maksaa lainanhoitomaksuja eikä Korkoa jäljellä
        olevalta Laina-ajalta.
      </p>
      <br />
      <p>
        Mikäli Lainanottajan loppusuoritus ylittää Lainan jäljellä olevan summan
        enintään viidellä (5) eurolla, Fixuralla on oikeus olla palauttamatta
        liikasuoritusta. Viittä (5) euroa suuremman liikasuorituksen
        palauttamisesta Fixura veloittaa viiden (5) euron käsittelymaksun.
        Lainasuhteen keskeiset ehdot, kuten Korko, todellinen vuosikorko, kulut
        ja takaisinmaksuaika ovat yksilöity Lainasopimuksessa.
      </p>
      <p>
        Lainanottajalla on lainmukainen oikeus maksuajan pidennykseen,
        esimerkiksi työttömyyden tai sairastumisen vuoksi. Tarvittaessa Fixura
        voi myös siirtää eräpäivää tai muuttaa alkuperäistä maksusuunnitelmaa.
        Maksusuunnitelman muutokset päivitetään verkkopalveluun. Sopimuksen
        mukainen Korko veloitetaan edelleen jäljellä olevasta Lainasta.
      </p>
      <h3>Ennen 01.02.2020 myönnetyt Lainat</h3>
      <p>
        Ennen 1.2.2020 myönnettyihin Lainoihin sovelletaan 1.3.2019 päivättyjä
        Yleisiä Ehtoja, jotka löytyvät kokonaisuudessaan Fixuran
        verkkopalvelusta.
      </p>
      <h3>Viivästyneet maksut, lainojen erääntyminen ja myynti</h3>
      <p>
        Fixura painottaa, että on tärkeää maksaa Lainan kuukausierät ajoissa.
        Mikäli Lainanottaja ei maksa kuukausittaista maksueräänsä, lähetetään
        hänelle 14 päivän kuluttua eräpäivästä kirjallinen maksumuistutus ja
        laskuun lisätään viiden (5) euron muistutusmaksu. Mikäli maksuerä on 14
        päivän kuluttua edelleen maksamatta, lähetetään Lainanottajalle toinen
        kirjallinen maksumuistutus ja laskuun lisätään toinen muistutusmaksu.
      </p>
      <p>
        Jos maksun suorittaminen viivästyy, Fixura voi vaatia Lainanottajaa
        maksamaan viivästyskorkoa, joka on määritetty Lainasopimuksessa. Jos
        viivästyskorko on Lainan Korkoa alempi, viivästyskorko on Laina-aikana,
        sekä Lainan erääntyessä kokonaisuudessaan 180 vuorokauden ajan siitä,
        kuin Laina on kokonaisuudessaan erääntynyt, kuitenkin vähintään yhtä
        suuri, kuin Lainasopimuksen mukainen Korko. Viivästyskorko lasketaan
        jokaiselta päivältä eräpäivän ylittäneestä summasta eräpäivästä lähtien
        koko summan suorittamiseen saakka.
      </p>
      <p>
        Mikäli maksamattomien laskujen arvo on yhteensä 5,00 % tai enemmän
        Lainan alkuperäisestä pääomasta, erääntyy Laina kokonaan ja Fixura
        lähettää Lainanottajalle tästä irtisanomisilmoituksen. Jatkuva
        maksamatta jättäminen voi johtaa oikeustoimenpiteisiin ja mahdollisesti
        aiheuttaa maksuhäiriömerkinnän luottotietoihin, jos asia siirretään
        ulosottoon.
      </p>
      <p>
        Fixuralla on oikeus välittää Lainan saatavat ja Lainanottajan tiedot
        perintään sekä ilmoittaa luottotietolain edellytysten täyttyessä
        luottotietorekisteriin maksulaiminlyönnin, joka on kestänyt yli 60
        päivää. Perintään lähetettyihin asioihin lisätään perintätoimiston
        maksut sekä viivästyskorko. Erääntyneet Lainat voidaan myös myydä
        kolmansille osapuolille tai Fixura voi ostaa nämä. Fixura ilmoittaa
        Lainanottajalle Lainasopimuksen siirtymisestä, mikäli Lainasopimus on
        siirretty muulle osapuolelle kuin Sijoittajalle.
      </p>
      <p>
        Perinnän kautta Lainanottajalta saadut maksut kohdistetaan ensin Lainan
        Korkoihin ja erääntyneeseen pääomaan, sen jälkeen perintä- ja muihin
        kuluihin sekä mahdollisesti näihin liittyviin viivästyskorkoihin. Lainan
        lyhennykset ja korot kuuluvat Sijoittajalle, muut kulut
        perintätoimistolle ja Fixuralle.
        <br />
        <br />
        Kaikki maksujen erääntymiseen liittyvät muutokset ja sopimukset on
        tehtävä etukäteen kirjallisesti.
      </p>
      <h3>Laskutus</h3>
      <p>
        Laskun eräpäivä sovitaan Lainasopimuksessa. Mikäli eräpäivä ei ole
        Pankkipäivä, käytetään eräpäivää seuraavaa mahdollista Pankkipäivää.
        Laskutus tapahtuu ensisijaisesti sähköisesti, ellei Lainanottaja ole
        sopinut Fixuran asiakaspalvelun kanssa muusta laskutustavasta. Fixura
        voi lähettää laskun sähköpostilla, tekstiviestillä ja/tai vastaavilla
        menetelmillä. Vastaavia viestintätapoja sovelletaan maksumuistutuksiin
        ja irtisanomisilmoituksiin. Jos laskutus tapahtuu muulla
        laskutustavalla, lisätään Fixuran kulloinkin soveltama laskutuslisä.
      </p>
      <h3>4. SIJOITTAJAN EHDOT</h3>
      <h3>Riski</h3>
      <p>
        Fixura painottaa, että Sijoittajan on syytä huomioida, että jokaisessa
        Sijoituksessa on riski pääoman menettämisestä osittain tai kokonaan.
        Vertaislainoissa sijoittamisen keskeiset riskit muodostuvat
        Lainanottajan mahdollisesta maksukyvyttömyydestä, mikä voi johtaa
        siihen, ettei Lainanottaja kykene maksamaan Lainaa osittain tai kokonaan
        takaisin, sekä toteutuneen tuoton poikkeamisesta Sijoittajan odottamasta
        tuotosta. Sijoittajan tulee huomioida, että mm. Lainan ennenaikainen
        takaisinmaksu johtaa siihen, että tuotto jää odotettua pienemmäksi.
      </p>
      <p>
        Fixura pyrkii tarjoamaan Sijoittajille joustavan markkinapaikan, jossa
        Sijoitukset ovat jälkimarkkinakelpoisia, mutta Fixura ei voi taata
        jälkimarkkinan toimivuutta tai sen tehokkuutta. Näin ollen Sijoittaja ei
        välttämättä voi luovuttaa Sijoitukseen liittyviä oikeuksiaan ja
        velvollisuuksiaan edelleen toisille Sijoittajille. Sijoittaja ei
        välttämättä voi realisoida Lainoihin tekemiään Sijoituksia haluamanaan
        ajankohtana ja Sijoitusten toteutuva maturiteetti saattaa poiketa
        merkittävästi siitä, mitä Sijoittaja on sijoitusta tehdessään olettanut.
        Sijoittajan tulee edelleen huomioida, että Lainojen historiallinen
        tuotto ei ole tae tuotosta tulevaisuudessa ja uusien Lainatuotteiden
        osalta indikaatio odotettavissa olevista tuotoista on ainoastaan arvio
        tuottohistorian puuttuessa.
      </p>
      <p>
        Ennen Lainanottajan lainahakemuksen hyväksymistä, Fixura selvittää
        Lainanottajan luottokelpoisuutta kolmansien osapuolten ylläpitämistä
        luottotietorekistereistä, Lainanottajan pankkitilin transaktioista sekä
        Fixuran asiakas- ja luottotietorekisteristä Lainanottajan maksukyvyn
        varmistamiseksi. Fixura pyrkii lisäksi rajoittamaan Sijoittajan riskiä
        luottoluokittelemalla Lainanottajat, epäämällä Lainan hakemisen
        sellaisilta henkilöiltä, joilla on luottohäiriöitä tai epäluotettavia
        luottotietoja sekä siirtämällä perintään tai myymällä erääntyneet
        Lainat. Fixura tarkistaa Lainanottajan luottotiedot Bisnode Finland Oy:n
        tai Suomen Asiakastieto Oy:n kautta, mutta Fixura ei voi taata saatujen
        tietojen paikkansapitävyyttä.
      </p>
      <p>
        Lainanottajalla on lainmukainen oikeus maksuajan pidennykseen
        esimerkiksi työttömyyden tai sairastumisen seurauksena. Fixura voi myös
        siirtää eräpäivää tai muuttaa alkuperäistä maksusuunnitelmaa. Sopimuksen
        mukainen Korko veloitetaan edelleen jäljellä olevasta Lainasta. Lisäksi
        Lainanottajalla on oikeus maksaa Lainansa takaisin etuajassa, jolloin
        erääntymättömistä eristä ei veloiteta Korkoa.
      </p>

      <h3>Sijoittajan maksut</h3>
      <p>
        Fixura veloittaa Sijoittajalta maksuja kulloinkin voimassa olevan
        Hinnaston mukaisesti.
      </p>
      <p>
        Sijoittaja on itse vastuussa omista Sijoituksiin liittyvistä
        veroseuraamuksista sekä vero- ja muista viranomaisilmoituksista, kuten
        esimerkiksi korkotulojen, luottotappioiden ja kulujen ilmoittamisesta
        verottajalle sekä mahdollisten pääomaverojen maksamisesta. Suomalaisten
        yksityishenkilöiden osalta Fixura ilmoittaa verottajalle tiedot
        korkotuloista ja luottotappioista, mutta Sijoittaja on velvollinen
        tarkistamaan ilmoitettujen tietojen paikkansapitävyyden.
      </p>

      <h3>Saatujen maksujen kohdistus sekä erääntyneiden Lainojen myynti</h3>

      <p>
        Jos Lainanottaja ei maksa kuukausittaisia maksueriä muistutuksista
        huolimatta, Laina siirretään perintätoimiston hoidettavaksi, myydään
        kolmannelle osapuolelle tai Fixura ostaa Lainan nykyiseen
        markkinahintaan. Mikäli erääntynyt Laina myydään kolmannelle
        osapuolelle, markkinahinta on sellaisen kolmannen osapuolen
        myyntihetkellä tarjoama hinta. Jos Fixura ostaa erääntyneen Lainan,
        markkinahinta vastaa perintätoimiston tarjoamaa hintaa ja määritetään
        vuosittain tammikuun aikana seuraavalle 12 kuukauden ajanjaksolle
        perustuen Lainaportfolioon, joka kattaa kaikki sitä edeltävän 12
        kuukauden aikana erääntyneet Lainat. Markkinahinta kuluvalle 12
        kuukauden ajanjaksolle ilmenee kulloinkin voimassa olevasta Hinnastosta.
        Edellä mainitun estämättä, mikäli markkinaolosuhteissa tapahtuu
        merkittävä muutos, joka vaikuttaa finanssipalvelualaan (esim. merkittävä
        muutos prime-koroissa), Fixuralla on oikeus määrittää markkinahinta
        useammin hankkimalla perintätoimistolta uusi tarjous, joka heijastaa
        kyseisiä muutoksia. Tässä tapauksessa, Hinnastossa oleva markkinahinta
        kuluvalle 12-kuukauden ajanjaksolle korvataan kyseisellä uudella
        markkinahinnalla.
      </p>
      <p>
        Siltä osin kuin Sijoittajan saatava on siirretty perintään, kohdistetaan
        Lainanottajan maksut Lainanottajan ja Sijoittajan välisessä
        velkasuhteessa voimassa olevan lainsäädännön mukaisesti siten, että
        maksut kohdistetaan ensin Lainan erääntyneisiin Korkoihin ja
        erääntyneeseen pääomaan ja vasta sen jälkeen perintä- ja muihin kuluihin
        sekä näihin liittyviin mahdollisiin viivästyskorkoihin. Edellä
        mainitusta huolimatta perintätoimisto vähentää automaattisesti syntyneet
        perintäkulut Lainanottajan maksun kautta saaduista varoista kullekin
        Sijoittajalle tehtävän tilityksen yhteydessä ja suhteessa Sijoittajaan,
        ennen kuin varat tilitetään Sijoittajan Fixura-tilille.
      </p>
      <p>
        Siltä osin kuin Sijoittajan saatava on myyty kolmannelle osapuolelle tai
        Fixura on ostanut saatavan, kohdistetaan Sijoittajalle kuuluvat
        myyntitulot velkasaatavan pääomaan. Tällöin myynnistä saadut
        Sijoittajalle kuuluvat tulot tilitetään Sijoittajalle kolmen (3)
        pankkipäivän kuluessa siitä, kun Fixura on vastaanottanut myyntitulot.
        Lainanottajalla ei ole oikeutta siirtää tai myydä Lainaa kolmannelle
        osapuolelle.
      </p>

      <h3>Automaattinen sijoitustoiminto (Autoinvest)</h3>

      <p>
        Autoinvest-toiminto sijoittaa Sijoittajan varat automaattisesti, jolloin
        Sijoittaja osallistuu Fixuran markkinapaikalla sekä uusien Lainojen
        rahoittamiseen että Lainojen ostamiseen jälkimarkkinoilta. Näin ollen,
        varat voidaan sijoittaa mihin tahansa näissä Yleisissä Ehdoissa
        kulloinkin yksilöityihin Lainatuotteisiin sekä jälkimarkkinoiden kautta
        mihin tahansa olemassa oleviin Lainoihin tarkoittaen, että varoja
        voidaan myös sijoittaa Lainatuotteisiin, joita ei enää tarjota uusille
        Lainanottajille. Jälkimarkkinalla myytävät Lainat, joihin Sijoittajan
        varoja voidaan Autoinvest-toiminnolla sijoittaa, voivat myös kattaa
        Lainoja, joihin liittyvät erääntyneet maksut ovat olleet maksamatta
        enintään 90 päivää, ennen kyseisten Lainojen siirtämistä perintään tai
        myyntiä.
      </p>
      <p>
        Autoinvest-toiminto pyrkii riskien hajauttamiseksi jakamaan Sijoitukset
        useampiin Lainoihin. Autoinvest-toiminto hajauttaa Sijoittajien vapaan
        pääoman siten, että enintään 3,00 % suuruinen osuus, kuitenkin aina
        vähintään 20,00 euroa, Sijoittajan vapaasta pääomasta sijoitetaan yhteen
        Lainaan. Sijoitukset yksittäiseen Lainaan vaihtelevat 20,00 eurosta
        korkeintaan 1 000,00 euroon. Sijoitukset yksittäisiin jälkimarkkinoilta
        ostettuihin Lainoihin voivat kuitenkin olla alle 20,00 euroa.
      </p>
      <p>
        Fixura painottaa, ettei Autoinvest-toiminto aktiivisesti etsi
        yksittäiselle Sijoittajalle Sijoittajan sijoitusprofiiliin sopivia tai
        paremmin tuottavia sijoituskohteita taikka auta kohteiden analysoinnissa
        taikka hyödyntämisessä. Kyse ei myöskään ole sijoitus- tai
        taloudellisesta neuvonnasta taikka muusta finanssipalvelusta eikä Fixura
        ole miltään osin vastuussa Autoinvest-toiminnon kautta toteutuvien
        Sijoitusten kannattavuudesta.
      </p>

      <h3>Sijoitusten automaattinen myyntitoiminto (Autosell)</h3>
      <p>
        Autosell-toiminto toimii Sijoittajan jälkimarkkinatyökaluna, jonka
        avulla järjestelmä myy Sijoituksia Sijoittajan puolesta. Mikäli
        Sijoittaja nostaa Fixura-tililtään varoja enemmän kuin vapaan pääoman
        verran, Autosell-toiminto aktivoituu automaattisesti ja pyrkii myymään
        erotusta vastaavan summan Sijoittajan Lainasijoituksista muille
        Fixura-sijoittajille. Kun Autosell-toiminto on myynyt Sijoituksia,
        Sijoittaja voi halutessaan nostaa vapaan pääoman pankkitililleen tai
        jatkaa sijoittamista Autoinvest-toiminnolla. Autosell-toiminnon ollessa
        aktiivinen, Autoinvest-toiminto ei luo samanaikaisesti uusia Sijoituksia
        Sijoittajan salkkuun. Autosell-toiminto voi myydä Sijoittajan
        Sijoituksia joko kokonaan tai osittain.
      </p>

      <h3>Yritykset ja yhteisöt</h3>
      <p>
        Fixuran kautta sijoittavien yritysten ja yhteisöjen on varmistettava,
        että Sijoitukset ja sijoitusratkaisut noudattavat kulloinkin voimassa
        olevaa osakeyhtiölakia sekä muuta soveltuvaa lainsäädäntöä.
      </p>

      <h3>Rahansiirtojen toteuttamisen aikataulu</h3>
      <p>
        Fixura pyrkii toteuttamaan Sijoittajan rahansiirtopyynnön Fixura-tililtä
        Sijoittajan omalle pankkitilille yhden (1) Pankkipäivän, mutta
        viimeistään rahansiirtopyyntöä seuraavan kolmen (3) Pankkipäivän
        kuluessa. Sijoittajan tulee tehdä kaikki rahansiirtopyynnöt Fixuralle
        kirjallisesti ja tunnistautuneena verkkopalvelun kautta omassa
        profiilissaan. Fixuran sisäisen prosessin lisäksi varojen siirtymisen
        aikatauluun vaikuttaa pankkien maksuliikennesäännöt. Fixuralla on aina
        oikeus kieltäytyä rahansiirtopyynnön toteuttamisesta, mikäli siihen on
        lakiin tai viranomaismääräykseen perustuva syy.
      </p>
      <p>
        Mikäli rahansiirtopyyntö vaatii Autosell-toiminnon avulla toteutettavaa
        Sijoitusten myymistä toisille Sijoittajille, rahansiirtopyyntö voidaan
        lähettää Fixuralle vasta myyntien toteutumisen jälkeen. Mikäli
        Sijoitusten myyntitavoite täyttyy ainoastaan osittain, voi Sijoittaja
        keskeyttää Sijoitusten myynnin, pyytää kerättyjen varojen siirtämistä
        pankkitililleen tai jatkaa varojen uudelleensijoittamista verkkopalvelun
        kautta. Mikäli Sijoitusten myyntitavoite täyttyy kokonaan, voi
        Sijoittaja tehdä rahansiirtopyynnön omalle pankkitililleen tai jatkaa
        varojen uudelleensijoittamista verkkopalvelun kautta.
      </p>

      <h3>5. LAINATUOTTEET</h3>
      <h3>Laskevan koron Laina</h3>
      <p>
        Fixuran Laskevan koron Lainatuote on kiinteäkorkoinen vakuudeton
        kulutusluotto, johon Fixura voi tarjota Lainanottajalle Koron
        alenemiseen liittyviä ehtoja. Lainan Korko ja lainanhoitomaksu voivat
        olla korkeintaan yhtä suuret kuin luotonmyöntöhetkellä
        kuluttajansuojalain 7 luvussa säädetyt kuluttajaluottosopimuksessa
        sovittavien vuosittaisen nimelliskoron ja muiden luottokustannuksien
        sallitut enimmäismäärät.
      </p>
      <p>
        Lainan maksusuunnitelmassa käytettävä vuotuinen nimelliskorko alkaa
        Lainasopimuksessa sovitun suuruisena ja alenee Lainasopimuksessa
        mahdollisesti sovittujen Koron alenemiseen liittyvien ehtojen
        mukaisesti. Mikäli Lainanottaja ei täytä Koron alenemiseen liittyviä
        ehtoja, Lainanottaja ei ole oikeutettu Koron alenemiseen ja
        Lainasopimuksessa sovittua nimelliskorkoa sovelletaan sellaisenaan.
        Fixura voi Koron alenemiseen liittyvien ehtojen mukaisesti nostaa Koron
        takaisin korkeintaan Lainasopimuksessa sovittuun nimelliskorkoon, jos
        Lainanottaja ei täytä alemman Koron säilyttämiseen liittyviä ehtoja.
      </p>
      <p>
        Mikäli Lainasopimuksessa on sovittu Koron alenemiseen liittyvistä
        ehdoista, alkuperäinen maksusuunnitelma ja sen kuukausittainen maksuerä
        on laskettu olettaen, että Lainanottaja noudattaa Koron alenemiseen
        liittyviä ehtoja. Mikäli Lainanottaja ei täytä Lainasopimuksessa
        sovittuja Koron alenemiseen liittyviä ehtoja, korkotaso ei laske
        alkuperäisen maksusuunnitelman mukaisesti, jolloin kuukausittaisen
        maksuerän suuruus, maksusuunnitelman pituus ja/tai maksusuunnitelman
        viimeisen erän suuruus muuttuu. Mikäli maksusuunnitelman viimeiseen
        erään sisällytetään jäljelle jäänyttä maksamatonta velkapääomaa,
        Lainanottaja voi halutessaan saada uuden maksusuunnitelman tämän
        lyhentämistä varten. Fixura toimittaa Lainanottajalle lyhennystaulukon
        sitä pyydettäessä, josta ilmenee suoritettavat maksut, maksukaudet sekä
        -ehdot.
      </p>
      <p>
        Fixura voi tarjota Lainanottajalle Lainaa myös ilman Koron alenemiseen
        liittyviä ehtoja, jolloin Lainasopimuksessa sovittua kiinteää
        nimelliskorkoa voidaan soveltaa sellaisenaan koko Laina-ajan. Tällöin
        Fixuralla on kuitenkin mahdollisuus yksinomaisen harkintansa mukaan
        laskea Lainaan sovellettavaa nimelliskorkoa, mikäli Lainanottaja on
        maksanut kuukausittaiset maksuerät maksusuunnitelman mukaisesti.
      </p>
      <h3>Ennen 15.6.2021 myönnetyt Laskevan koron Lainat</h3>
      <p>
        Ennen 15.6.2021 myönnetyn Fixuran laskevan koron Lainan
        maksusuunnitelmassa käytettävä vuotuinen nimelliskorko on 2,90 – 9,90 %.
        Maksusuunnitelmassa käytettävä vuotuinen nimelliskorko alkaa
        Lainasopimuksessa sovitun suuruisena, ja alenee kuukausittain 0,10
        prosenttiyksikköä, mikäli Lainanottaja maksaa kulloisenkin
        kuukausittaisen maksueränsä ajoissa maksusuunnitelman mukaisesti ennen
        maksumuistutuksen lähettämistä. Vuotuinen nimelliskorko on kuitenkin
        aina vähintään 2,90 %.
      </p>
      <p>
        Mikäli Lainanottaja ei maksa maksuerää ennen Fixuran lähettämää
        maksumuistutusta, Lainanottaja ei ole oikeutettu Koron alenemiseen
        seuraavissa maksuerissä ja korkotaso pysyy sovitulla tai edellä olevan
        mukaisesti saavutetulla tasolla. Mikäli Lainanottaja maksaa kaikki
        kuukausittaiset maksuerät myöhässä eli Fixuran lähettämien
        maksumuistutusten jälkeen, ei Korko alene lainkaan, vaan
        Lainasopimuksessa sovittua nimelliskorkoa sovelletaan sellaisenaan.
      </p>
      <p>
        Alkuperäinen maksusuunnitelma ja sen kuukausittainen maksuerä on
        laskettu olettaen, että Lainanottaja maksaa kaikki kuukausittaiset erät
        ajoissa eräpäivään mennessä. Mikäli Lainanottaja maksaa kuukausittaisia
        maksueriä myöhässä, korkotaso ei laske alkuperäisen maksusuunnitelman
        mukaisesti, jolloin velkapääoma lyhenee alkuperäistä maksusuunnitelmaa
        hitaammin. Tällöin maksusuunnitelman viimeiseen erään sisällytetään
        jäljelle jäänyt maksamaton velkapääoma. Lainanottaja voi halutessaan
        saada uuden maksusuunnitelman jäljelle jääneelle maksamattomalle
        velkapääomalle. Fixura toimittaa Lainanottajalle lyhennystaulukon sitä
        pyydettäessä, josta ilmenee suoritettavat maksut, maksukaudet sekä
        -ehdot.
      </p>

      <h3>6. YHTEYDENOTOT SIJOITTAJAN JA LAINANOTTAJAN VÄLILLÄ</h3>
      <p>
        Fixura allekirjoittaa Lainasopimukset Sijoittajan puolesta, laskuttaa
        aiheutuneet saatavat, välittää tarvittaessa saatavat edelleen perintään
        tai ulosottoon ja tarvittaessa myy saatavat. Lainasuhde on kuitenkin
        kaikissa tilanteissa Sijoittajan ja Lainanottajan välillä. Fixura hoitaa
        tästä huolimatta kaiken yhteydenpidon Lainanottajan ja Sijoittajan
        välillä. Sijoittaja ja Lainanottaja eivät saa olla suoraan yhteydessä
        toisiinsa ilman Fixuran suostumusta, ellei toisin ole kirjallisesti
        sovittu.
      </p>

      <h3>7. HENKILÖTIETOJEN KÄSITTELY JA LUOTTOTARKASTUS</h3>
      <p>
        Fixura käsittelee henkilötietoja sen tietosuojaselosteen mukaisesti, mm.
        seuraaviin tarkoituksiin:
      </p>
      <ul>
        <li>
          henkilötietojen käsittely, jossa pyrkimyksenä on Fixuran
          velvollisuuksien hoitaminen Lainanottajaa ja Sijoittajaa kohtaan;
        </li>
        <li>
          saatujen ja käsiteltyjen henkilötietojen käyttäminen yhteistyössä
          viranomaisten ja yhteistyökumppanien kanssa;
        </li>
        <li>
          tietojen käyttäminen omassa markkinoinnissa sekä yhteistyökumppanien
          tuotteiden ja palveluiden markkinoinnissa;
        </li>
        <li>
          laskutuksen ja reskontran antamiseen kolmannen osapuolen
          hoidettavaksi;
        </li>
        <li>
          henkilön luottokelpoisuuden ja luottoluokituksen tarkastamiseen
          käyttämällä esimerkiksi luottotietopalveluja.
        </li>
      </ul>
      <p>
        Fixura ei voi taata luottoluokitukseen käytettävien tietojen
        paikkansapitävyyttä. Fixura käyttää asiakasviestinnässään ensisijaisesti
        asiakkaan antamaa sähköpostiosoitetta ja puhelinnumeroa. Asiakas on
        velvollinen huolehtimaan sähköpostin, puhelinnumeron ja muiden
        yhteystietojensa ajantasaisuudesta sekä ilmoittamaan muutoksista
        välittömästi Fixuralle.
      </p>

      <h3>8. VASTUUNRAJOITUS</h3>
      <p>
        Fixura ei vastaa mistään suorista, epäsuorista, välittömistä tai
        välillisistä vahingoista, joita Lainanottajalle tai Sijoittajalle voi
        aiheutua. Fixura ei näin ollen vastaa esimerkiksi saamatta jääneestä
        voitosta, tuotosta, maineen, liikearvon tai tiedon menetyksestä,
        korvaavien tuotteiden tai palveluiden hankintakustannuksista tai muista
        aineettomista taikka välillisistä vahingoista.
      </p>

      <h3>9. MUUT EHDOT</h3>
      <p>
        Fixura pidättää itsellään oikeuden muuttaa näitä Yleisiä Ehtoja ja
        Hinnastoa. Muutoksista ilmoitetaan niitä koskeville osapuolille
        kirjallisesti ennen muutoksen voimaantuloa. Ilmoitus tehdään
        sähköpostitse ellei Lainanottaja tai Sijoittaja ole ollut yhteydessä
        Fixuran asiakaspalveluun ja sopinut muusta viestintätavasta.
      </p>
      <p>
        Jos Lainasopimus taikka Sijoittajasopimus ja nämä Yleiset Ehdot tai
        Hinnasto ovat keskenään ristiriidassa, Lainasopimusta tai
        Sijoittajasopimusta noudatetaan ensisijaisena.
      </p>
      <p>
        Näistä Yleisistä Ehdoista, Hinnastosta, Lainasopimuksesta ja
        Sijoittajasopimuksesta on kieliversiot suomeksi, ruotsiksi ja
        englanniksi. Jos kieliversiot ovat keskenään ristiriidassa,
        suomenkielistä versiota noudatetaan ensisijaisena.
      </p>

      <h3>10. YLIVOIMAINEN ESTE</h3>
      <p>
        Fixura, Sijoittaja tai Lainanottaja ei vastaa vahingoista, jotka
        aiheutuvat ylivoimaisesta esteestä. Vastuuta ei ylivoimaisena esteenä
        pidettävällä perusteella synny, jos osapuoli voi osoittaa, että sille
        kuuluvan velvoitteen täyttämisen on estänyt sellainen epätavallinen tai
        ennalta-arvaamaton syy, johon se ei ole voinut vaikuttaa ja jonka
        seurauksia se ei kaikkea huolellisuutta noudattaen olisi voinut välttää;
        kuten esimerkiksi sota, luonnontapahtuma, tulipalo, yleisen
        tietoliikenteen keskeytyminen, työselkkaus, viranomaisten toimenpide,
        valuuttarajoitus, kriisiajan velvoite ja/tai kaupankäynnin rajoitus.
      </p>

      <h3>11. SOVELLETTAVA LAKI JA RIIDANRATKAISU</h3>
      <p>
        Näihin Yleisiin Ehtoihin sovelletaan Suomen lakia, lukuun ottamatta sen
        lainvalintasäännöksiä.
      </p>
      <p>
        Kaikki Lainanottajan, Sijoittajan ja Fixuran väliset erimielisyydet,
        jotka johtuvat näistä Yleisistä Ehdoista ja/tai Lainasopimuksesta tai
        Sijoittajasopimuksesta ratkaistaan lähtökohtaisesti neuvottelemalla.
        Lainanottajalla on oikeus lisäksi kääntyä toimivaltaisten viranomaisten
        puoleen ja saattaa asia kuluttajariitalautakunnan ratkaistavaksi. Jos
        osapuolet eivät pysty ratkaisemaan erimielisyyksiään kohtuullisessa
        ajassa vilpittömässä mielessä käytävien neuvotteluiden avulla,
        erimielisyydet ratkaistaan Pohjanmaan käräjäoikeudessa ensimmäisenä
        oikeusasteena.
      </p>
    </div>
  )
}

export default TermsAndConditions
