import { connect } from 'react-redux'
import { State } from '../../../../store'
import PrivateRoutePresentation from '../../PrivateRoutePresentation'
import { getLegacyData } from 'utils/auth'

export default connect((state: State) => ({
  isAuthorized:
    getLegacyData().is_foreigner ||
    !!state.auth.register.signicat.callback_response.state,
  pathname: '/onboarding/investor/identity-verification',
}))(PrivateRoutePresentation)
