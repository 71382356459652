import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { setBrokerLoanApplicationId } from './creators'
import { brokerLoanApplicationId } from '../../../../utils/broker'
import { createUpdateProfileSuccessAction } from 'store/user/profile/actions/creators'
import { FINNISH_LANGUAGE } from '../../../../constants'

/* TODO Unit Test */
export const getBrokerLoanApplication = (): ThunkAction<
  any,
  any,
  any,
  any
> => async (dispatch: Dispatch) => {
  const data = brokerLoanApplicationId()
  const blaId = data?.blaId
  const fromBroker = data?.fromBroker
  const updateUser = {
    language: FINNISH_LANGUAGE,
  }
  dispatch(setBrokerLoanApplicationId(blaId, fromBroker))
  if (blaId) {
    dispatch(createUpdateProfileSuccessAction(updateUser))
  }
}
