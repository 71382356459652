import { connect } from 'react-redux'
import PrivateRoutePresentation from './PrivateRoutePresentation'
import { isOnFidoUserRejected } from '../../utils/onFidoUser'

export default connect(() => {
  const onFidoRejected = isOnFidoUserRejected()
  return {
    isAuthorized: onFidoRejected,
    pathname: '/dashboard',
  }
})(PrivateRoutePresentation)
