import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Indicator from './shared/Indicator'
import { capitalizeAndExtractFirstLetter } from '../utils'
import scale from 'assets/images/scale.svg'
import arrow from 'assets/images/arrow.svg'
import ic_account_circle from 'assets/images/ic_account_circle.svg'
import { useTranslation } from 'react-i18next'
import { State } from '../store'
import { connect } from 'react-redux'
import browserHistory from '../browserHistory'
import Borrower from '../components/Borrower/Dashboard'
import LoanSummary from '../components/Borrower/Dashboard/LoanSummary'
import Investor from '../components/Investor'
import { FINNISH_NATIONALITY } from '../constants'
import { LegacyUser } from '../models/Auth/ProfileData'
import { ThunkDispatch } from 'redux-thunk'
import { getLegacyLoans } from 'store/legacy/borrower/loan/actions/getLegacyLoans'
import { INVEST_IN_SAVLEND_LINK } from '../constants'

interface Props extends MappedState, MappedDispatch {}

const Dashboard = (props: Props) => {
  const {
    isFetching,
    firstName,
    nationality,
    isOnFidoUser,
    hasSignedContract,
    loansLength,
    hasLoan,
    getLegacyLoans,
    legacyUsers,
    has3OLoan,
  } = props

  const { t } = useTranslation()

  //TODO Fixme
  useEffect(() => {
    if (hasSignedContract && hasLoan) {
      browserHistory.push('/dashboard')
    } else if (hasSignedContract && !hasLoan) {
      browserHistory.push('/dashboard/investor')
    } else if (loansLength !== 0 && !hasLoan) {
      browserHistory.push('/dashboard/borrower')
    }
  }, [hasSignedContract, loansLength, hasLoan])

  useEffect(() => {
    if (legacyUsers.length && !has3OLoan) {
      getLegacyLoans()
    }
  }, [getLegacyLoans, legacyUsers, has3OLoan])

  function redirectToSavelendSite() {
    window.location.assign(INVEST_IN_SAVLEND_LINK)
  }

  return (
    <div className="text-center main-dashboard">
      <Indicator isVisible={isFetching} />
      <h1 className="font-50 sm-pb-10">
        {t('HELLO')}
        {firstName && ` ${capitalizeAndExtractFirstLetter(firstName)}`}!
      </h1>
      {hasSignedContract && has3OLoan ? (
        <>
          <Investor />
          <div className="pt-30" />
          <LoanSummary />
          <Borrower />
        </>
      ) : (
        <>
          <Row className="row-w-90 sm-hide">
            <Col lg={{ size: 8, offset: 2 }} sm="12">
              <p className="my-3">
                <span>{t('DASHBOARD_TITLE')}</span>
              </p>
            </Col>
          </Row>
          <Row className="row-w-90">
            <Col
              className="main-actions__wrapper"
              lg={{ size: 8, offset: 2 }}
              sm="12"
            >
              {nationality === FINNISH_NATIONALITY && isOnFidoUser === 0 && (
                <Link to="/dashboard/borrower" className="main-action">
                  <img src={scale} alt={t('LOANS')} />
                  <h3>{t('DASHBOARD_TITLE_LOAN')}</h3>
                </Link>
              )}
              {/* below to is required, so it set as empty */}
              <Link
                to=""
                onClick={() => redirectToSavelendSite()}
                className="main-action"
              >
                <img src={arrow} alt={t('INVESTMENTS')} />
                <h3>{t('DASHBOARD_TITLE_INVESTMENTS')}</h3>
              </Link>
              <Link to="/dashboard/profile" className="main-action">
                <img src={ic_account_circle} alt={t('PROFILE')} />
                <h3>{t('DASHBOARD_TITLE_PROFILE')}</h3>
              </Link>
            </Col>
          </Row>
        </>
      )}
      {/* Removed CustomerService */}
    </div>
  )
}

interface MappedDispatch {
  getLegacyLoans: () => void
}

interface MappedState {
  firstName: string
  nationality?: string
  isFetching: boolean
  isOnFidoUser: number
  hasLoan: boolean
  hasSignedContract: boolean
  has3OLoan: boolean
  loansLength: number
  legacyUsers: LegacyUser[]
}

const mapStateToProps = (state: State): MappedState => {
  const { data, is_fetching } = state.user.profile
  const legacyLoans = state.legacy.borrower.loan.data
  const loans = data.loans
  return {
    loansLength: legacyLoans.length + loans.length,
    firstName: data.first_name,
    nationality: data.nationality,
    isFetching: is_fetching,
    isOnFidoUser: data.is_foreigner,
    hasLoan:
      (data.legacy_users.length &&
        data.legacy_users[0].legacy_role === 'borrower') ||
      data.loans.length > 0,
    hasSignedContract:
      data.deposits.length > 0 && data.deposits[0].has_signed_contract,
    legacyUsers: data.legacy_users,
    has3OLoan: data.loans.length > 0,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  getLegacyLoans: () => dispatch(getLegacyLoans()),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
