import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import {
  createLegacyLoanFailureAction,
  createLegacyLoanRequestAction,
  createLegacyLoanSuccessAction,
} from './creators'
import { AxiosResponse } from 'axios'
import BorrowerAPI from '../../../../../api/legacy/BorrowerAPI'

export const getLegacyLoans = (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch
) => {
  dispatch(createLegacyLoanRequestAction())
  try {
    const borrowerAPI = new BorrowerAPI()
    const response: AxiosResponse = await borrowerAPI.getLegacyLoans()
    dispatch(createLegacyLoanSuccessAction(response.data.data))
  } catch (error) {
    dispatch(createLegacyLoanFailureAction(error))
  }
}
