import API from '../API'
import UpdateUser from '../../models/Auth/User/UpdateUser'

export default class UserAPI extends API {
  public getUserProfile() {
    return this.get('api/user')
  }

  public updateUser(data: UpdateUser) {
    return this.patch('api/user', data)
  }
}
