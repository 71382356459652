import {
  GET_CONTRACT_REQUEST,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAILURE,
} from './actions/types'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTRACT_REQUEST:
      return { ...initialState, is_fetching: true }
    case GET_CONTRACT_SUCCESS:
      return { ...initialState, html: action.data, is_fetching: false }
    case GET_CONTRACT_FAILURE:
      return { ...initialState, error: action.error, is_fetching: false }
    default:
      return state
  }
}

export interface State {
  is_fetching: boolean
  error: any
  html: string
}

export const initialState: State = {
  is_fetching: false,
  error: null,
  html: '',
}
