import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { State } from '../../store'
import { Col, Row } from 'reactstrap'
import ic_plus from 'assets/images/ic_plus.svg'
import { useHistory } from 'react-router-dom'
import { FINNISH_NATIONALITY } from '../../constants'

interface Props extends MappedState {}

const AddCompany = (props: Props) => {
  const { t } = useTranslation()
  const { isOnFidoUser, nationality, hasSignedContract } = props
  const history = useHistory()

  const isOnFidoUserProfile = isOnFidoUser === 1

  return (
    <div className="company-profile">
      {!isOnFidoUserProfile &&
        nationality === FINNISH_NATIONALITY &&
        hasSignedContract && (
          <div className="add-funds__wrapper">
            <Row className="center p-20 ">
              <Col md={7} className="text-center">
                <button
                  className="download-item__wrapper add_company"
                  onClick={() => history.push(`/dashboard/add-company`)}
                >
                  <img
                    className="mb-1_add_company"
                    src={ic_plus}
                    alt={t('ADD_COMPANY')}
                  />
                  <span className="font-weight-bold">{t('ADD_COMPANY')}</span>
                </button>
              </Col>
            </Row>
          </div>
        )}
    </div>
  )
}

interface MappedState {
  isOnFidoUser: number
  nationality?: string
  hasSignedContract: boolean
}

const mapStateToProps = (state: State): MappedState => {
  const { data } = state.user.profile
  return {
    isOnFidoUser: data.is_foreigner,
    nationality: data.nationality,
    hasSignedContract:
      state.user.profile.data.deposits.length > 0 &&
      state.user.profile.data.deposits[0].has_signed_contract,
  }
}

export default connect<MappedState, any, any, any>(mapStateToProps)(AddCompany)
