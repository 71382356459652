import API from '../API'
import { VerifyPhoneNumber } from '../../models/Auth/Sms/VerifyPhoneNumber'
import { SendMobilePhoneToAuthenticateViaSmsData } from '../../models/Auth/Sms/SendMobilePhoneToAuthenticateViaSmsRequest'

export default class SmsLoginAPI extends API {
  public sendMobilePhoneToAuthenticateViaSms(
    data: SendMobilePhoneToAuthenticateViaSmsData
  ) {
    return this.post('api/auth/sms/login', data)
  }

  public verifyPhoneNumber(data: VerifyPhoneNumber) {
    return this.post('api/auth/sms/verify', data)
  }
}
