import updateLoanApplication from './updateLoanApplication'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../../index'

export default (bankAccountId: number) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => State
) => {
  const { due_date } = getState().user.borrower.loan_application.data
  if (due_date) {
    dispatch(
      updateLoanApplication({
        due_date,
        bank_account_id: bankAccountId,
      })
    )
  }
}
