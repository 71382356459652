import { ThunkAction } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import BorrowerAPI from '../../../../api/user/BorrowerAPI'
import { Dispatch } from 'redux'
import {
  createGetBrokerLoanApplicationFailureAction,
  createGetBrokerLoanApplicationRequestAction,
  createGetBrokerLoanApplicationSuccessAction,
} from './creators'
import { State } from '../../../index'

const getBrokerLoanApplicationRequest = (): ThunkAction<
  any,
  any,
  any,
  any
> => async (dispatch: Dispatch, getState: () => State) => {
  dispatch(createGetBrokerLoanApplicationRequestAction())

  const blaId = getState().auth.register.sms.blaId
    ? getState().auth.register.sms.blaId
    : getState().auth.register.signicat.callback_response.blaId ||
      getState().auth.login.sms.blaId

  try {
    const borrowerAPI = new BorrowerAPI()

    const response: AxiosResponse = await borrowerAPI.getBrokerLoanApplicationRequest(
      blaId
    )

    dispatch(
      createGetBrokerLoanApplicationSuccessAction(
        response.data.data.amount_offered,
        response.data.data.term_offered
      )
    )
  } catch (error) {
    dispatch(createGetBrokerLoanApplicationFailureAction(error))
  }
}

export default getBrokerLoanApplicationRequest
