import {
  createGetPortfolioFailureAction,
  createGetPortfolioRequestAction,
  createGetPortfolioSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import InvestorAPI from '../../../../../api/legacy/InvestorAPI'

export default (legacyUserId): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch
) => {
  dispatch(createGetPortfolioRequestAction())

  try {
    const investorAPI = new InvestorAPI()
    const response = await investorAPI.getPortfolio(legacyUserId)
    dispatch(createGetPortfolioSuccessAction(response.data.data))
  } catch (error) {
    dispatch(createGetPortfolioFailureAction(error))
  }
}
