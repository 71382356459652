import React from 'react'
import FixuraLogo from '../../shared/FixuraLogo'

const TermsAndConditions: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared t-and-c__v02">
      <FixuraLogo size="large" color={'dark'} />
      <br />
      <h3>FIXURA AB OY – TERMS AND CONDITIONS</h3>
      <h3>Valid as of 1st of February 2020</h3>
      <p>
        These Terms and Conditions will apply to Investment Agreements and Loan
        Agreements signed from 1st of February 2020 onwards. For Loans accepted
        before 1st of February 2020, Terms and Conditions that are available at
        Fixura’s website and dated 1st of March 2019 will apply.
      </p>

      <h3>1. DEFINITIONS</h3>
      <table className="definitions">
        <tbody>
          <tr>
            <td>
              <b>Autoinvest</b>
            </td>
            <td>An automatic investment function in the system.</td>
          </tr>
          <tr>
            <td>
              <b>Autosell</b>
            </td>
            <td>An automatic selling function in the system.</td>
          </tr>
          <tr>
            <td>
              <b>Borrower</b>
            </td>
            <td>
              A user who has applied for a loan and/or has signed a Loan
              Agreement as a Borrower.
            </td>
          </tr>
          <tr>
            <td>
              <b>Business day</b>
            </td>
            <td>Any weekday other than Saturday, Sunday or public holidays.</td>
          </tr>
          <tr>
            <td>
              <b>Fixura</b>
            </td>
            <td>Fixura Ab Oy (Reg. no 2246639-7).</td>
          </tr>
          <tr>
            <td>
              <b>Fixura account</b>
            </td>
            <td>
              An account created for users to Fixura’s webservice where users
              can invest or borrow funds.
            </td>
          </tr>
          <tr>
            <td>
              <b>Interest</b>
            </td>
            <td>The interest that will be charged from the Loan.</td>
          </tr>
          <tr>
            <td>
              <b>Investment</b>
            </td>
            <td>The amount the Investor has invested for Borrowers to lend.</td>
          </tr>
          <tr>
            <td>
              <b>Investment Agreement</b>
            </td>
            <td>
              Agreement between the Investor and Fixura in which they agree on a
              cooperation regarding Investments. Based on the Investment
              Agreement Fixura passes Loan applications to Investors and takes
              care of the financial transactions and of the communication
              towards the Borrowers.
            </td>
          </tr>
          <tr>
            <td>
              <b>Investor</b>
            </td>
            <td>
              User of the service, who has accepted Fixura’s Investor Agreement
              and who has deposited funds to a Fixura account for lending to
              Borrowers.
            </td>
          </tr>
          <tr>
            <td>
              <b>Loan</b>
            </td>
            <td>
              The amount which the Investor(s) will lend to the Borrower based
              on the Loan Agreement.
            </td>
          </tr>
          <tr>
            <td>
              <b>Loan Agreement</b>
            </td>
            <td>
              An agreement between the Borrower and the Investor(s) in which
              they agree i.a. on the capital of the Loan, the Interest and the
              repayment schedule.
            </td>
          </tr>
          <tr>
            <td>
              <b>Price List</b>
            </td>
            <td>
              Applicable list of fees, that Fixura charges from the Investors
              and Borrowers, can be found on Fixura’s website.
            </td>
          </tr>
          <tr>
            <td>
              <b>Terms and Conditions</b>
            </td>
            <td>
              Up-to-date Terms and Conditions for Loan Agreements and Investor
              Agreements available at Fixura’s website.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <h3>2. INTRODUCTION</h3>
      <p>
        Fixura is a company whose concept and purpose is to bring people hoping
        to borrow money together with those hoping to lend money. Fixura acts as
        a flexible marketplace for such interaction and as a part of Fixura’s
        operations the company arranges Loans between Investors and Borrowers.
        The Fees that Fixura is collecting from the Investors and Borrowers can
        be found from the Price List, which are an integrated part of these
        Terms and Conditions.
      </p>
      <p>
        By registering as a user on Fixura’s online service, the user accepts
        these Terms and Conditions. These Terms and Conditions constitute a part
        of the Loan agreement between the Investor and the Borrower which the
        parties have separately entered into. In the event of a conflict between
        these Terms and Conditions and the Loan Agreement, the conditions of the
        Loan Agreement shall prevail. These Terms and Conditions constitute a
        part of the Investment Agreement between the Investor and Fixura as set
        out in the Investment Agreement. In the event of a conflict between
        these Terms and Conditions and the Investment Agreement, the conditions
        of the Investment Agreement shall prevail. Fixura highlights that the
        company is not a party in the Loan Agreement between the Investor and
        the Borrower. Fixura merely acts as an intermediary between the Investor
        and the Borrower, and Fixura does not offer investment services
        according to the Act on Investment Services. Fixura is registered in the
        register of credit providers and mediators of peer-to-peer loans with
        the Regional State Administrative Agency in southern Finland.
      </p>

      <h3>3. CONDITIONS FOR BORROWERS</h3>
      <h3>Loan eligibility</h3>
      <p>
        To have the possibility to be granted a Loan through Fixura, the person
        shall:
      </p>
      <ul>
        <li>have full legal capacity and be at least 21 years of age;</li>
        <li>have been living permanently in Finland for at least two years;</li>
        <li>have regular income;</li>
        <li>
          have provided Fixura necessary information required to evaluate
          creditworthiness;
        </li>
        <li>not have credit defaults or overdue Loans and</li>
        <li>not be a board member of a company with credit defaults.</li>
      </ul>
      <p>
        Before granting a Loan Fixura will review the Borrower information
        according to the consent given by the Borrower and according to the
        applicable data protection regulations. The Borrower is responsible that
        the provided information is true and correct. The Borrower is also
        obligated to inform Fixura without delay of any changes in the
        Borrower’s personal details or contact information. If the Borrower has
        not updated the information, Fixura has the right to claim the
        reasonable costs from the Borrower from solving the issue. The Investor
        or Fixura shall not be responsible for defaults, delays or other
        consequences caused by errors in the information included in the loan
        application and/or in the Loan Agreement.
      </p>

      <p>
        Fixura uses information provided and/or analyzed by third parties in the
        evaluation of creditworthiness before granting a Loan. The Borrower
        approves that Fixura will assess the Borrower’s creditworthiness through
        services provided by third parties. The necessary credit information
        will be acquired from Bisnode Finland Oy or Suomen Asiakastieto Oy. The
        Borrower agrees that the information will be obtained from these
        companies and that Fixura has the right to store the information during
        the customer relationship, and as per the Privacy Policy after the
        customer relationship has ended. Fixura reserves the right to prevent
        the person from applying or accepting a Loan and to reject the already
        submitted Loan application.
      </p>

      <p>
        When the Borrower has accepted the Loan, a new loan can be applied after
        six (6) months, provided that six (6) first instalments of the previous
        Loan has been paid. A Borrower can have a maximum of 20 000,00 euros in
        aggregated loan capital. If a loan application is not fully financed,
        the Investors may grant the Borrower a smaller Loan than the one the
        Borrower applied for. It is not guaranteed that the Borrower will get
        the full Loan amount they applied for nor are the Investors obligated to
        offer a Loan with the amount the Borrower applied for. The details in
        the loan calculator at the application stage are always an estimate of
        the Loan applied by the Borrower. The final Loan details such as the
        amount, Interest, repayment schedule and the size of the monthly
        instalments are clarified in the loan offer. Fixura recommends that the
        Borrower reads the loan offer precisely.
      </p>
      <h3>Fees payable by the borrower</h3>
      <p></p>
      <p>
        The Borrower will be obliged to pay back the Loan according to the
        conditions defined in the Loan Agreement. The monthly instalment
        consists of capital, Interest, loan account fee and fees based on extra
        services the Borrower has chosen. The loan term can be a maximum of 84
        months. The nominal interest rate can be a maximum of 10,00% and a
        minimum of 2,9 % p.a. The loan account fee is 0,01% per day of the
        original Loan amount. The loan account fee is separately defined for
        each Loan and will be charged as 0,30% monthly or 3,65% yearly. The fees
        for each Loan can be a total of 150,00 euros per year. Possible
        additional services are charged according to the Price List. The
        above-mentioned fees are exclusive of VAT since the operations are not
        subject to VAT. Fixura is not responsible for any late repayments or
        unpaid instalments if the repayments have not been made according to the
        Loan Agreement.
      </p>

      <p>
        The Borrower is entitled to repay the entire Loan at any time. Upon
        early repayment, the Borrower is liable to pay:
      </p>
      <ul>
        <li>the outstanding loan capital;</li>
        <li>accurred but unpaid Interest;</li>
        <li>accurred but unpaid loan account fee(s); and</li>
        <li>opening costs of the Loan specified in the Loan Agreement.</li>
      </ul>
      <p>
        The Borrower is not liable to pay loan account fees or Interest for the
        remaining loan term originally agreed upon.
      </p>
      <br />
      <p>
        In case the final repayment from the Borrower exceeds the remaining debt
        amount on the Loan by five (5) euros at the most, Fixura has the right
        not to refund the overpayment. Fixura charges a handling fee of five (5)
        euros for repayments of more than five (5) euros. The most relevant
        conditions applicable such as loan Interest, effective annual interest
        rate, costs and repayment schedule are set out in the separate Loan
        Agreement that has been done between the Borrower and the Investor.
      </p>
      <p>
        The Borrower has a legal right to ask Fixura for an extended payment
        period, e.g. in case of unemployment or illness. If necessary, Fixura
        may change the due date or modify the original repayment schedule.
        Changes in the repayment schedule will be updated to Fixura ́s
        webservice. The agreed Interest will be charged of the remaining Loan
        amount.
      </p>
      <h3>Loans granted before 1st of February 2020</h3>
      <p>
        Terms and Conditions dated 1st of March 2019 will be applied for Loans
        granted before 1st of February 2020. These Terms and Conditions are
        available at Fixura ́s website.
      </p>
      <h3>Outstanding payments</h3>
      <p>
        Fixura states that it is important that the monthly instalments are paid
        on time. If the Borrower does not pay the monthly instalment, a written
        reminder is sent to the Borrower 14 days from the due date. A reminder
        fee of five (5) euros is added to the invoice. If the instalment remains
        unpaid after the 14 days, the Borrower will get a second reminder and
        another reminder fee of five (5) euros is added to the invoice.
      </p>
      <p>
        If the instalments are overdue, Fixura can claim penalty interest
        according to the Loan Agreement. If the penalty interest is less than
        the Interest of the Loan, the penalty interest will during the loan term
        and after the Loan is overdue, for a period of 180 days, be at least the
        current Interest of the loan. Penalty interest is charged from the due
        date until the instalment has been repaid in full.
      </p>
      <p>
        If the total amount of unpaid invoices exceeds 5,00 % of the original
        loan amount, the entire Loan becomes due and payable and a notice of
        termination will be sent to the Borrower. After sending the notice of
        termination Fixura can transfer the Loan to debt collection. Continued
        neglect of paying the monthly instalments may lead to legal proceedings
        and might result in payment default entries, if the case is brought to
        enforcement collection.
      </p>
      <p>
        A loan that is overdue can be sold to a third party. Fixura has the
        right to transfer the receivables and information about the Borrower to
        debt collection. According to the law, Fixura can also inform a credit
        default to the credit information register when the payment delay has
        continued for over 60 days. Debt collection fees and penalty interest
        will be added to cases that are sent to debt collection. An overdue Loan
        can be transferred to debt collection or it can be sold to third
        parties. Fixura will inform the Borrower if the Loan Agreement has been
        transmitted to a third party.
      </p>
      <p>
        Payments the Borrower has made to debt collection are first allocated
        towards Interest and loan capital, thereafter to collection agency fees
        as well as potential penalty interest on such fees. Loan capital and
        Interest are allocated to Investors, other costs are allocated to the
        collection agency and Fixura.
        <br />
        <br />
        All changes and agreements concerning delayed instalments shall be done
        beforehand in writing.
      </p>
      <h3>Invoicing</h3>
      <p>
        The due date of the monthly invoice will be agreed in the Loan
        Agreement. If the due date is not a Business day, the due date will be
        moved to the next possible Business day. Invoicing is done by e-mail,
        unless the Borrower has contacted Fixura customer support and agreed on
        another invoicing method. Fixura can also send invoices via text
        messages. The same applies for reminders and notice of terminations. If
        invoicing is not done by e-mail, an invoicing fee is added on the
        invoice.
      </p>
      <h3>4. CONDITIONS FOR INVESTORS</h3>
      <h3>Risk</h3>
      <p>
        Fixura underlines that the Investor should acknowledge that with every
        Investment there is a risk of losing the capital partly or entirely. The
        main risks regarding peer-to-peer loans are the Borrowers insolvency, a
        situation where the Borrower is not able to pay the Loan partly or
        entirely and the deviation from the return expected by the Investor. The
        Investor shall notice that among other things the profit will be lower
        if the Loan is paid before the end of the repayment schedule.
      </p>
      <p>
        Fixura strives to offer a flexible marketplace for Investors where the
        Investments are suitable for aftermarkets. However, Fixura cannot
        guarantee the functionality and efficiency of the aftermarkets.
        Therefore it is not guaranteed that the Investor can always transfer the
        rights and obligations of an Investment to another Investor.
      </p>
      <p>
        The Investor cannot necessarily liquidate the Investments made to the
        Loans when they wish to do so and the maturity of the Investment can
        significantly differ from what the Investor has expected when making the
        investment. The Investor shall consider that the profit history of the
        loans will not guarantee profits in the future. The best way to minimize
        risks and credit losses is through diversification.
      </p>
      <p>
        Before accepting the loan application, Fixura will assess the Borrowers
        creditworthiness from third party credit information registers, from
        transactions in the Borrowers bank account and Fixura ́s client and
        credit registers for ensuring the Borrowers financial situation. Fixura
        strives to minimize the risk for Investors by credit scoring, excluding
        people with credit defaults or unreliable credit information and by
        transferring or selling unpaid Loans to a debt collection agency. Fixura
        checks the Borrower’s credit default status through Bisnode Finland Oy
        or Suomen Asiakastieto Oy but Fixura cannot guarantee that the status is
        correct. If the Borrower does not pay the monthly instalments, the debt
        will be transferred to a collection agency or sold entirely to a third
        party. Fixura sells the Loan to a third party for the price at the
        current time listed in the Price List. Fixura also has the right to buy
        the Loan for corresponding price.
      </p>
      <p>
        The Borrower has legal right to ask Fixura for an extended repayment
        period, e.g. in case of unemployment or illness. If necessary, Fixura
        may change the due date or modify the original repayment schedule. The
        agreed Interest will be charged of the remaining Loan amount. The
        Borrower may repay the Loan at any time, in which case the Interest that
        has not accured will not be charged.
      </p>

      <h3>Fees for the investor</h3>
      <p>
        Fixura charges the Investors according to applicable Price List as found
        on Fixura ́s website.
      </p>
      <p>
        The Investor is responsible for any applicable tax and of tax or other
        notifications/declarations for the authorities, e.g. notifications about
        profits, credit losses and costs related to the investments. Fixura
        informs the tax authorities on behalf of the Finnish private Investors
        about profits and credit losses, but the Investor is responsible for
        checking that the information given is true and correct.
      </p>

      <h3>Allocation of repaid funds and selling of overdue debts</h3>

      <p>
        If Fixura considers it to be reasonable, they have the right to transfer
        or to sell overdue Loans at market price to a third party including all
        rights and obligations. The price of selling loans to a third party can
        be found from the Price List.
      </p>
      <p>
        To the extent when the Investor ́s receivable has been transferred to
        debt collection, funds repaid by the borrower are allocated in
        accordance with applicable law. Consequently, repaid funds are first
        allocated to interest and loan capital and thereafter to debt collection
        fees and other possible fees and finally to towards the potential
        penalty interest. Irrespective of the aforementioned and in relation to
        the investor, the debt collection agency automatically deducts incurred
        debt collection fees from the funds received prior to the funds being
        deposited to the investor’s Fixura account.
      </p>
      <p>
        To the extent, that the Investors ́ receivable has been sold to a third
        party, will the sales revenue belonging to the Investor be allocated to
        the capital of the debt.
      </p>
      <p>
        In this event, the income that belongs to the investor will be paid to
        the investor within three bank days after Fixura has received the
        revenue from the sale. The borrower does not have the right to transfer
        or sell the loan to a third party.
      </p>

      <h3>Automatic investment function (Autoinvest)</h3>

      <p>
        With the Autoinvest function the system carries out Investments on
        behalf of the Investor. The system strives to diversify the risks by
        dividing the Investment into multiple Loans. The Autoinvest function
        diversifies the free capital so that a maximum portion of 3,00 % of the
        Investors free capital will be invested into one Loan, with a minimum
        amount being 20,00 euros. The invested amounts to one Loan can differ
        from 20,00 euros to a maximum of 1 000,00 euros. Investments in the
        Loans bought from aftermarket may anyhow be below 20,00 euros. Because
        the maximum amount that can be invested in one Loan is 1 000,00 euros,
        it is possible that a situation occurs where one Investor alone invests
        1 000,00 euros to a Loan if the other Investors have not given an offer
        for the Loan in question.
      </p>
      <p>
        Fixura highlights that the Autoinvest function does not actively seek
        for more suitable or profitable investments for the Investors. Fixura
        does not help the Investors analyze or capitalize Investments, and the
        services Fixura offers does not include financial advising or other
        financial services. Fixura has no responsibility of the profitability of
        the Investments made through the Autoinvest function.
      </p>
      <h3>Automatic selling function (Autosell)</h3>
      <p>
        The Autosell function operates as a tool in the aftermarkets which helps
        the system to sell Investments on behalf of the Investors. If the
        Investor draws more funds from their Fixura account than the amount of
        the free capital, the Autosell function will activate automatically and
        it will try to sell loans to cover the difference. When Autosell has
        sold Investments, the Investor can withdraw the free capital to their
        bank account or continue investing through the Autosell function. When
        the Autosell function is active, the Autoinvest function does not create
        new Investments to the Investors portfolio at the same time. The
        Autosell function can sell the Investors Investments either partly or
        entirely.
      </p>

      <h3>Companies and entities</h3>
      <p>
        Companies and entities that invest using Fixura ́s platform shall ensure
        that their investments and investment decision are made according to the
        legislation in the country of residence of the company and entity in
        question.
      </p>

      <h3>Schedule for money transfer</h3>
      <p>
        Fixura strives to carry out the Investors request for a money transfer
        from the Fixura account to the Investors own bank account within one (1)
        Business day however, at the latest within the following three (3)
        Business days. The Investor shall make all the request in writing
        through the webservice in their own profile. In addition to Fixura ́s
        internal process, the transition of the funds will be affected also by
        the Banks own internal rules. Fixura has always the right to refuse to
        carry out a money transfer if there is a reason for refusal from law or
        orders from authorities.
      </p>
      <p>
        If the money transfer request demands that investments need to be sold
        to other Investors by the Autosell function, can the request be sent to
        Fixura only after the sale has been executed. If the sales target of the
        Investments will only be partly filled, the Investor can terminate the
        selling of the Investments or request that the collected funds will be
        transferred to their bank account or they can continue to invest the
        funds through the webservice. If the sales target of the Investments
        will be entirely filled, the Investor can request a money transfer to
        their own bank account or the Investor can continue to invest funds
        through the webservice.
      </p>

      <h3>Withholding of tax</h3>
      <p>
        If necessary, Fixura can withhold tax from the Interests paid to private
        persons. The withheld amount is accounted to the tax administration and
        is determined according to the law and statutes in Finland. The
        withholding of the tax will be carried out to the nearest cent so that
        the calculated amount will be rounded to the closest cent. Fixura pays
        no Interest to the withholding of tax.
      </p>
      <h3>5. LOAN PRODUCTS</h3>
      <h3>Loan with reducing interest</h3>
      <p>
        The annual nominal interest rate used in repayment schedules in Fixura
        Loans with a reducing interest is 2,90 – 9,90%. The nominal interest
        rate starts as agreed on the Loan Agreement and will be reducing by 0,1
        percentage point monthly if the Borrower has paid the monthly instalment
        according to the repayment schedule before a reminder has been sent to
        the them. The nominal interest rate is however always at least 2,9 %
        p.a. If the Borrower does not pay the monthly instalment on time and a
        reminder is sent, the interest rate will not reduce according to the
        original repayment schedule, which means that the Interest will stay at
        the same rate as earlier agreed or reached. If the Borrower pays all the
        monthly instalments late, meaning after Fixura has sent a reminder, the
        Interest will not reduce at all and the nominal rate agreed on the Loan
        Agreement shall be applied.
      </p>
      <p>
        If the Borrower does not pay the monthly instalment on time and a
        reminder is sent, the interest rate will not reduce according to the
        original repayment schedule, which means that the Interest will stay at
        the same rate as earlier agreed or reached. If the Borrower pays all the
        monthly instalments late, meaning after Fixura has sent a reminder, the
        Interest will not reduce at all and the nominal rate agreed on the Loan
        Agreement shall be applied.
      </p>
      <p>
        The original repayment schedule and the monthly instalments have been
        counted based on the assumption that the Borrower will pay all the
        monthly instalments in time by the due date. If the Borrower pays the
        monthly instalments late, the interest rate will not reduce according to
        the original repayment schedule, which means that the capital of the
        Loan will reduce slower than what has stated in the repayment schedule.
        In this case, the remaining unpaid capital of the Loan will be included
        into the last instalment of the repayment schedule, to which the
        Borrower can get a new repayment schedule if needed. By the Borrowers
        request Fixura can send a chart that shows the instalments, due dates
        and other payment conditions.
      </p>

      <h3>6. CONTACTING OF THE COUNTERPARTY</h3>
      <p>
        On behalf of the investor, Fixura invoices receivables arising from the
        debt, forwards receivables to collection or to recovery proceedings or
        if needed, sells the receivables to a third party. The Loan Agreement is
        always established between the Investor and the Borrower, however Fixura
        handles all contacts between the Borrower and the Investor. Contacting
        the counterparty on one’s own is not permitted unless given a permission
        from Fixura.
      </p>
      <h3>7. HANDLING PERSONAL DATA AND CREDIT CONTROL</h3>
      <p>
        Fixura handles personal data according to their privacy policy for the
        following purposes:
      </p>
      <ul>
        <li>
          to handle personal data to the extent necessary in order to fulfil
          their responsibilities towards the Borrower and the Investor;
        </li>
        <li>
          to use any information received and processed in the cooperation with
          authorities and cooperation partners;
        </li>
        <li>
          to use the information for marketing of Fixura ́s and their partners’
          products and services;
        </li>
        <li>to assign billing and bookkeeping to third parties; and</li>
        <li>
          to check the creditworthiness and credit scoring of the Borrower by
          using different credit information services.
        </li>
      </ul>
      <p>
        Fixura cannot guarantee the accuracy of the information used for credit
        scoring. Fixura uses primarily the e-mail address and the phone number
        that the customer has provided in communication with the customer. The
        customer has the obligation to inform Fixura immediately if there are
        any changes in the e-mail address, phone number or in other contact
        information.
      </p>

      <h3>8. LIMITATIONS</h3>
      <p>
        Fixura is not liable of any direct or indirect damages that can be
        caused to the Borrower or to the Investor. Therefore, Fixura is not
        liable for, e.g. loss of profit or revenue or of the loss of reputation,
        business value or information or of the costs of any replacing products
        or services or of other immaterial or indirect damages.
      </p>

      <h3>9. OTHER CONDITIONS</h3>
      <p>
        Fixura reserves the right to change these Terms and Conditions. All
        parties involved are informed in writing before the changes take effect.
        If there is an inconsistency between the Loan Agreement or the
        Investment Agreement and these Terms and Conditions, the Loan Agreement
        and the Investment Agreement shall be applied primarily.
      </p>

      <h3>10. FORCE MAJEURE</h3>
      <p>
        Fixura, the Investor or the Borrower are not liable for damages caused
        by an impediment beyond the party’s control. The party is not liable for
        damages it they can show the unusual or unforeseeable reason which they
        have not been able to affect on, and the consequences of which the party
        could not have avoided following all carefulness, has prevented from the
        filling of the obligations; such as for example war, natural disaster,
        fire, interruption of the general telecommunications, labour dispute,
        measure of the authorities, currency restrictions, obligations during
        time of crisis and/or trade restrictions.
      </p>

      <h3>11. APPLICABLE LAW AND DISPUTE RESOLUTION</h3>
      <p>
        In the debt agreement between the Borrower and the Investor as well as
        in the cooperation between the Investor and Fixura, Finnish law shall
        apply, with the exception of connecting factor rules.
      </p>
      <p>
        Disagreements between the Borrower, Investor and Fixura that arises from
        these Terms and Conditions and/or from the Loan Agreement, will be
        primarily solved by negotiations. The Borrower can also approach the
        competent authority and bring the case to the Consumer Disputes Board.
        If the parties fail to negotiate a solution, the disagreement will be
        resolved in the district court of Ostrobothnia as the first court
        instance.
      </p>
    </div>
  )
}

export default TermsAndConditions
