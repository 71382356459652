import React, { useEffect } from 'react'
import { State } from '../../store'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import SignicatTile from './SignicatTile'
import fetchTermsAndConditions from '../../store/auth/register/terms_and_conditions/actions/fetchTermsAndConditions'
import { ThunkDispatch } from 'redux-thunk'
import fetchSignicatAuthUrl from '../../store/auth/register/signicat/actions/fetchSignicatAuthUrl'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { createToggleTermsAndConditionsAction } from '../../store/auth/register/terms_and_conditions/actions/creators'
import { addToCart } from '../../utils/gtm/purchase'
import { getBorrowerAffiliate } from '../../store/marketing/affiliate/actions/getBorrowerAffiliate'
import { getInvestorAffiliate } from '../../store/marketing/affiliate/actions/getInvestorAffiliate'
import { getBrokerLoanApplication } from '../../store/broker/loanApplication/actions/getBrokerLoanApplication'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router'
import { postTermsAndCondtionAndPriceList } from '../../store/user/investor/termsAndCondtionAndPriceList/actions/postTermsAndCondtionAndPriceList'

interface ComponentProps {
  goal: string
}

interface Props
  extends WithTranslation,
    MapStateToProps,
    MapDispatchToProps,
    ComponentProps {}

const SignicatForm = (props: Props) => {
  const location = useLocation()
  const history = useHistory()
  const {
    t,
    data,
    goal,
    fetchTermsAndConditions,
    redirectToSignicat,
    isTermsAndConditionsAccepted,
    isTermsAndConditionAndPriceListAccepted,
    toggleTermsAndConditions,
    postTermsAndCondtionAndPriceList,
    getBorrowerAffiliate,
    getInvestorAffiliate,
    getBrokerLoanApplication,
  } = props
  const qs = queryString.parse(location.search)

  useEffect(() => {
    addToCart()
  }, [])

  useEffect(() => {
    getBorrowerAffiliate()
    getInvestorAffiliate()
    getBrokerLoanApplication()
  }, [getBorrowerAffiliate, getInvestorAffiliate, getBrokerLoanApplication])

  useEffect(
    () => {
      fetchTermsAndConditions()
    },
    // eslint-disable-next-line
    []
  )

  const toggle1stMarch2021TermsAndConditions = () => {
    history.push(`${location.pathname}/terms-and-conditions-and-price-list`)
  }

  return (
    <>
      <div className="d-flex terms-and-conditions align-items-center text-left">
        <div>
          <input
            className="terms-and-conditions__checkbox__color"
            type="checkbox"
            disabled={false}
            checked={isTermsAndConditionsAccepted}
            onChange={toggleTermsAndConditions}
          />
        </div>
        <div className="ml-3 accept-term">
          <Link to={`${location.pathname}/terms-and-conditions`}>
            {t('TERMS_AND_CONDITIONS_PRICE_LIST_PRIVACY_POLICY')}
          </Link>
          <div onClick={toggleTermsAndConditions}>
            {t(
              'TERMS_AND_CONDITIONS_PRICE_LIST_PRIVACY_POLICY_READ_AND_ACCEPTED'
            )}
          </div>
        </div>
      </div>
      <div className="d-flex terms-and-conditions align-items-center text-left hide">
        <div>
          <input
            className="terms-and-conditions__checkbox__color"
            type="checkbox"
            disabled={false}
            checked={isTermsAndConditionAndPriceListAccepted}
            onChange={postTermsAndCondtionAndPriceList}
          />
        </div>
        <div className="ml-3 pt-10 accept-term">
          <div className="text-left">
            {`${t('SIGN_UPCOMING_TEXT_TERMS_AND_CONDITIONS')}`}
            <Button
              onClick={toggle1stMarch2021TermsAndConditions}
              color="pep-link"
              className="link-h-20 height-terms text-left terms-and-conditions-btn"
            >
              <div>
                {`${t('SIGN_UPCOMING_TERMS_AND_CONDITIONS_AND_PRICE_LIST')}`}
                ,&nbsp;
              </div>
            </Button>
            {`${t('SIGN_UPCOMING_TEXT_TERMS_AND_CONDITIONS_MORE_INFO')}`}
          </div>
        </div>
      </div>
      {qs.error && qs.error === 'user-does-not-exists' && (
        <div className="position-relative">
          <small className="form-error-message">
            {t('USER_DOES_NOT_EXISTS')}
          </small>
        </div>
      )}
      {qs.error && qs.error === 'sca-auth-failed' && (
        <div className="error-message-item">
          <small className="color-red">{t('SCA_AUTH_FAILED')}</small>
        </div>
      )}
      <div className={'sca__wrapper d-flex'}>
        {data.map((methodName: string) => (
          <SignicatTile
            key={methodName}
            disabled={!isTermsAndConditionsAccepted}
            onClick={() => redirectToSignicat(methodName, goal)}
            methodName={methodName}
          />
        ))}
      </div>
    </>
  )
}

interface MapStateToProps {
  data: string[]
  isTermsAndConditionsAccepted: boolean
  isTermsAndConditionAndPriceListAccepted: boolean
}

interface MapDispatchToProps {
  fetchTermsAndConditions: () => void
  redirectToSignicat: (methodName: string, goal: string) => void
  toggleTermsAndConditions: () => void
  postTermsAndCondtionAndPriceList: () => void
  getBorrowerAffiliate: () => void
  getInvestorAffiliate: () => void
  getBrokerLoanApplication: () => void
}

const mapStateToProps = (state: State): MapStateToProps => ({
  data: state.auth.register.sca_methods.data,
  isTermsAndConditionsAccepted:
    state.auth.register.terms_and_conditions.is_accepted,
  isTermsAndConditionAndPriceListAccepted:
    state.user.investor.termsAndCondtionAndPriceList
      .isTermsAndConditionAndPriceListAccepted,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MapDispatchToProps => ({
  fetchTermsAndConditions: () => dispatch(fetchTermsAndConditions()),
  redirectToSignicat: (methodName: string, goal: string) =>
    dispatch(fetchSignicatAuthUrl(methodName, goal)),
  toggleTermsAndConditions: () =>
    dispatch(createToggleTermsAndConditionsAction()),
  postTermsAndCondtionAndPriceList: () =>
    dispatch(postTermsAndCondtionAndPriceList()),
  getBorrowerAffiliate: () => dispatch(getBorrowerAffiliate()),
  getInvestorAffiliate: () => dispatch(getInvestorAffiliate()),
  getBrokerLoanApplication: () => dispatch(getBrokerLoanApplication()),
})

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation(),
  connect<MapStateToProps, MapDispatchToProps, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(SignicatForm)
