import { Redirect, Route, Switch } from 'react-router'
import React from 'react'
import IdentityVerification from '../components/Auth/Register/IdentityVerification'
import TermsAndConditionsModal from '../components/Auth/TermsAndConditionsModal'
import TermsAndConditionsModal1stMarch2021 from '../components/Auth/TermsAndConditionsModalProduct21'
import ProvideDetails from '../components/Auth/Register/ProvideDetails'
import VerifyPhoneNumber from '../components/Auth/Register/VerifyPhoneNumber'
import Aml from '../components/Investor/Onboarding/Aml'
import SignContract from '../components/Investor/Onboarding/SignContract'
import ProvideDetailGuardRoute from './guards/onboarding/investor/ProvideDetailGuardRoute'
import VerifyPhoneNumberGuardRoute from './guards/onboarding/investor/VerifyPhoneNumberGuardRoute'
import LegacyGuardRoute from './guards/LegacyGuardRoute'
import AmlGuardRoute from './guards/onboarding/investor/AmlGuardRoute'
import SignContractGuardRoute from './guards/onboarding/investor/SignContractGuardRoute'
import IdentityVerificationGuardRoute from './guards/onboarding/investor/IdentityVerificationGuardRoute'

export default () => (
  <Switch>
    <Route exact path="/onboarding/investor">
      <Redirect
        to={{ pathname: '/onboarding/investor/identity-verification' }}
      />
    </Route>
    {/* @ts-ignore */}
    <LegacyGuardRoute path="/onboarding/investor/identity-verification">
      <IdentityVerificationGuardRoute>
        <IdentityVerificationView />
      </IdentityVerificationGuardRoute>
    </LegacyGuardRoute>
    {/* @ts-ignore */}
    <ProvideDetailGuardRoute path="/onboarding/investor/provide-details">
      <ProvideDetailsView />
    </ProvideDetailGuardRoute>
    {/* @ts-ignore */}
    <VerifyPhoneNumberGuardRoute path="/onboarding/investor/verify-phone-number">
      <VerifyPhoneNumber />
    </VerifyPhoneNumberGuardRoute>
    {/* @ts-ignore */}
    <AmlGuardRoute path="/onboarding/investor/aml">
      <Aml />
    </AmlGuardRoute>
    {/* @ts-ignore */}
    <SignContractGuardRoute path="/onboarding/investor/contract">
      <SignContractView />
    </SignContractGuardRoute>
  </Switch>
)

const IdentityVerificationView = () => (
  <>
    <IdentityVerification />
    <Switch>
      <Route path="/onboarding/investor/identity-verification/terms-and-conditions">
        <TermsAndConditionsModal />
      </Route>
      <Route path="/onboarding/investor/identity-verification/terms-and-conditions-and-price-list">
        <TermsAndConditionsModal1stMarch2021 />
      </Route>
    </Switch>
  </>
)

const ProvideDetailsView = () => (
  <>
    <ProvideDetails isRegister={false} />
    <Switch>
      <Route path="/onboarding/investor/provide-details/terms-and-conditions">
        <TermsAndConditionsModal />
      </Route>
      <Route path="/onboarding/investor/provide-details/terms-and-conditions-and-price-list">
        <TermsAndConditionsModal1stMarch2021 />
      </Route>
    </Switch>
  </>
)

const SignContractView = () => (
  <>
    <SignContract />
    <Switch>
      <Route path="/onboarding/investor/contract/terms-and-conditions">
        <TermsAndConditionsModal />
      </Route>
      <Route path="/onboarding/investor/contract/terms-and-conditions-and-price-list">
        <TermsAndConditionsModal1stMarch2021 />
      </Route>
    </Switch>
  </>
)
