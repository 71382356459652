/*
 *   TODO Fixme add missing banks
 */
export function accountName(param: string | undefined) {
  if (param) {
    const bankIdentifier = param.substring(4, 8)
    switch (bankIdentifier.substring(0, 1)) {
      case '1' || '2':
        return 'Nordea'
      case '5':
        return 'Pohjola Pankki '
      case '6':
        return 'Ålandsbanken (ÅAB)'
      default:
        break
    }
    switch (bankIdentifier.substring(0, 2)) {
      case '34':
        return 'Danske Bank'
      case '37':
        return 'DNB Bank ASA'
      case '31':
        return 'Handelsbanken'
      case '33':
        return 'Skandinaviska Enskilda Banken'
      case '39':
        return 'S-Pankki'
      case '36':
        return 'Tapiola Pankki'
      default:
        break
    }
    switch (bankIdentifier.substring(0, 3)) {
      case '711':
        return 'Calyon'
      case '713':
        return 'Citibank'
      case '405' || '497':
        return 'Aktia'
      case '470' || '479':
        return 'POP Pankki-ryhmä'
      case '400' ||
        '402' ||
        '403' ||
        '406' ||
        '407' ||
        '408' ||
        '410' ||
        '411' ||
        '412' ||
        '414' ||
        '415' ||
        '416' ||
        '417' ||
        '418' ||
        '419' ||
        '420' ||
        '421' ||
        '423' ||
        '424' ||
        '425' ||
        '426' ||
        '427' ||
        '428' ||
        '429' ||
        '430' ||
        '431' ||
        '432' ||
        '435' ||
        '436' ||
        '437' ||
        '438' ||
        '439' ||
        '440' ||
        '441' ||
        '442' ||
        '443' ||
        '444' ||
        '445' ||
        '446' ||
        '447' ||
        '449' ||
        '450' ||
        '451' ||
        '452' ||
        '454' ||
        '455' ||
        '456' ||
        '457' ||
        '458' ||
        '459' ||
        '460' ||
        '461' ||
        '462' ||
        '463' ||
        '464' ||
        '483' ||
        '484' ||
        '485' ||
        '485' ||
        '486' ||
        '487' ||
        '488' ||
        '489' ||
        '490' ||
        '491' ||
        '492' ||
        '493' ||
        '495' ||
        '496' ||
        '715':
        return 'Säästöpankkiryhmä'
      default:
        break
    }
  }
  return
}
