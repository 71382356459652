import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
  getOnFidoStatusFailureAction,
  getOnFidoStatusRequestAction,
  getOnFidoStatusSuccessAction,
} from './creators'
import InvestorAPI from '../../../../../api/user/InvestorAPI'
import { getUserProfile } from '../../../../user/profile/actions/getUserProfile'

export const getOnFidoStatus = (): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(getOnFidoStatusRequestAction())
  try {
    const investorAPI = new InvestorAPI()
    const response = await investorAPI.getOnFidoStatus()
    dispatch(getOnFidoStatusSuccessAction(response.data.data))
    if (
      response &&
      response.data &&
      response.data.data.verification_error_code === 0
    ) {
      dispatch(getUserProfile())
    }
  } catch (error) {
    dispatch(getOnFidoStatusFailureAction(error))
  }
}
