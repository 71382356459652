import { SET_BROKER_LOAN_APPLICATION_SUCCESS } from './actions/types'

export interface State {
  requested_amount: string
  requested_term: string
}

export default (state: State = initialState, action: any) => {
  if (action.type === SET_BROKER_LOAN_APPLICATION_SUCCESS) {
    return {
      ...state,
      requested_amount: action.requested_amount,
      requested_term: action.requested_term,
    }
  } else {
    return state
  }
}

export const initialState: State = {
  requested_amount: '',
  requested_term: '',
}
