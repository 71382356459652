import {
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
} from './types'
import Transaction from '../../../../../models/Legacy/Investor/Transaction'

export const createGetTransactionsRequestAction = () => ({
  type: GET_TRANSACTIONS_REQUEST,
})
export const createGetTransactionsSuccessAction = (data: Transaction[]) => ({
  data,
  type: GET_TRANSACTIONS_SUCCESS,
})
export const createGetTransactionsFailureAction = (error: any) => ({
  error,
  type: GET_TRANSACTIONS_FAILURE,
})
