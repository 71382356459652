import { combineReducers } from 'redux'
import contract, {
  initialState as contractInitialState,
  State as ContractState,
} from './contract'
import dashboard, {
  initialState as dashboardInitialState,
  State as DashboardState,
} from './dashboard'
import auto_invest, {
  initialState as autoInvestInitialState,
  State as AutoInvestState,
} from './auto_invest'
import aml, { initialState as amlInitialState, State as AmlState } from './aml'
import companyAml, {
  initialState as companyAmlInitialState,
  State as companyAmlState,
} from './companyAml'
import walkThru, {
  initialState as walkThruInitialState,
  State as walkThruState,
} from './walkThru'
import agreements, {
  initialState as agreementsInitialState,
  State as AgreementsState,
} from './agreements'
import companyAgreements, {
  initialState as companyAgreementsInitialState,
  State as CompanyAgreementsState,
} from './companyAgreements'
import termsAndCondtionAndPriceList, {
  initialState as termsAndCondtionAndPriceListInitialState,
  State as TermsAndCondtionAndPriceListState,
} from './termsAndCondtionAndPriceList'

export interface State {
  contract: ContractState
  dashboard: DashboardState
  aml: AmlState
  companyAml: companyAmlState
  walkThru: walkThruState
  auto_invest: AutoInvestState
  agreements: AgreementsState
  companyAgreements: CompanyAgreementsState
  termsAndCondtionAndPriceList: TermsAndCondtionAndPriceListState
}

export default combineReducers<State>({
  contract,
  dashboard,
  aml,
  companyAml,
  walkThru,
  auto_invest,
  agreements,
  companyAgreements,
  termsAndCondtionAndPriceList,
})

export const initialState: State = {
  contract: contractInitialState,
  dashboard: dashboardInitialState,
  aml: amlInitialState,
  companyAml: companyAmlInitialState,
  walkThru: walkThruInitialState,
  auto_invest: autoInvestInitialState,
  agreements: agreementsInitialState,
  companyAgreements: companyAgreementsInitialState,
  termsAndCondtionAndPriceList: termsAndCondtionAndPriceListInitialState,
}
