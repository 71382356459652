import { POST_TERMS_AND_CONDITION_AND_PRICE_LIST_SUCCESS } from './actions/types'
import { ACCEPT_TERMS_AND_CONDITION_AND_PRICE_LIST_SUCCESS } from './actions/types'

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_TERMS_AND_CONDITION_AND_PRICE_LIST_SUCCESS:
      return {
        ...initialState,
        isTermsAndConditionAndPriceListAccepted: !state.isTermsAndConditionAndPriceListAccepted,
      }
    case ACCEPT_TERMS_AND_CONDITION_AND_PRICE_LIST_SUCCESS:
      return {
        ...initialState,
        isTermsAndConditionAndPriceListAccepted: true,
      }
    default:
      return state
  }
}

export const initialState: State = {
  isTermsAndConditionAndPriceListAccepted: false,
}

export interface State {
  isTermsAndConditionAndPriceListAccepted: boolean
}
