import React from 'react'
import classNames from 'classnames'
import { THead, Tr, Th } from './Table'
import CalendarNext from './CalendarNext'
import CalendarPrev from './CalendarPrev'
import CalendarTitle from './CalendarTitle'

interface Props {
  showISOWeekNumbers?: boolean
  showWeekNumbers?: boolean
  locale?: any
  calendar: any
  nextDate?: Function
  prevDate?: Function
  showNext?: boolean
  showPrev?: boolean
  handleNext: Function
  handlePrev: Function
  showDropdowns?: boolean
  handleSelected?: any
}

export default class CalendarHeader extends React.Component<Props> {
  static defaultProps = {
    showISOWeekNumbers: false,
    showWeekNumbers: false,
    showNext: true,
    showPrev: true,
    nextDate: (calendar: any) => calendar.add(1, 'month'),
    prevDate: (calendar: any) => calendar.subtract(1, 'month'),
  }

  createNextProps = () => {
    const { showNext, calendar, nextDate, handleNext } = this.props
    // @ts-ignore
    const date = nextDate(calendar)
    return {
      handleNext,
      next: showNext,
      calendar: date,
    }
  }

  createPrevProps = () => {
    const { showPrev, calendar, prevDate, handlePrev } = this.props
    // @ts-ignore
    const date = prevDate(calendar)
    return {
      handlePrev,
      prev: showPrev,
      calendar: date,
    }
  }

  renderTitle = () => {
    const {
      showISOWeekNumbers,
      showWeekNumbers,
      calendar,
      locale,
      showDropdowns,
      handleSelected,
    } = this.props
    const colSpan = showISOWeekNumbers || showWeekNumbers ? 6 : 5
    const props = {
      colSpan,
      calendar,
      locale,
      showDropdowns,
      handleSelected,
    }
    // @ts-ignore
    return <CalendarTitle {...props} />
  }

  renderNext = () => {
    const props = this.createNextProps()
    return <CalendarNext {...props} />
  }

  renderPrev = () => {
    const props = this.createPrevProps()
    return <CalendarPrev {...props} />
  }

  renderWeeks = () => {
    const { showWeekNumbers, showISOWeekNumbers, locale } = this.props
    const { weekNames, weekLabel } = locale
    const showWeeks = showISOWeekNumbers || showWeekNumbers
    const weeks = showWeeks ? [weekLabel].concat(weekNames) : weekNames
    const WeekData = weeks.map((children: any, key: any) => {
      const className = classNames({
        week: !!(key === 0 && showWeeks),
      })
      const thProps = {
        children,
        className,
        key,
      }
      return <Th {...thProps} />
    })
    return <Tr>{WeekData}</Tr>
  }

  render() {
    return (
      <THead>
        <Tr>
          {this.renderPrev()}
          {this.renderTitle()}
          {this.renderNext()}
        </Tr>
        {this.renderWeeks()}
      </THead>
    )
  }
}
