import React from 'react'
import Spinner from './Spinner'

interface Props {
  isVisible?: boolean
}

const Indicator = (props: Props) => {
  return (
    <>
      {props.isVisible && (
        <div tabIndex={-1} className="indicator-container">
          <div className="">
            <div
              className="modal fade show d-block"
              role="dialog"
              tabIndex={-1}
            >
              <Spinner spinnerHeight={150} spinnerWidth={150} />
            </div>
            <div className="modal-backdrop fade show" />
          </div>
        </div>
      )}
    </>
  )
}

Indicator.defaultProps = {
  isVisible: false,
}

export default Indicator
