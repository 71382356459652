import React, { useState } from 'react'
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { State } from '../../../../store'
import { ThunkDispatch } from 'redux-thunk'
import updateLoanApplicationDueDate from '../../../../store/user/borrower/loan_application/actions/updateLoanApplicationDueDate'

interface Props extends WithTranslation, MappedState, MappedDispatch {}

const DateDropDown = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleIsOpen = () => setIsOpen(!isOpen)
  const { t, updateDueDate, dueDate } = props

  if (!dueDate) {
    return null
  }

  return (
    <div className="mb-3 d-inline-block">
      <ButtonDropdown
        isOpen={isOpen}
        toggle={toggleIsOpen}
        className="view-offer__dropdown"
      >
        <DropdownToggle caret size="lg" className="light">
          {t(dueDates[dueDate])}
        </DropdownToggle>
        <DropdownMenu>
          {Object.keys(dueDates).map((key) => (
            <DropdownItem
              key={dueDates[key]}
              onClick={() => updateDueDate(+key)}
            >
              {t(dueDates[key])}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  )
}

const dueDates = {
  1: '1ST_OF_MONTH',
  10: '10TH_OF_MONTH',
  15: '15TH_OF_MONTH',
  20: '20TH_OF_MONTH',
  25: '25TH_OF_MONTH',
  31: 'LAST_OF_MONTH',
}

interface MappedState {
  dueDate?: number | null
}

interface MappedDispatch {
  updateDueDate: (dueDate: number) => void
}

const mapStateToProps = (state: State): MappedState => ({
  dueDate: state.user.borrower.loan_application.data.due_date,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  updateDueDate: (dueDate: number) =>
    dispatch(updateLoanApplicationDueDate(dueDate)),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedState, MappedDispatch, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(DateDropDown)
