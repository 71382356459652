import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { State } from '../../../store'
import React from 'react'
import { capitalizeAndExtractFirstLetter } from '../../../utils'

interface Props extends WithTranslation, MappedState {}

const LegacyGreet = (props: Props) => {
  const { t, firstName } = props

  return (
    <section className="greet__wrapper">
      <h1 className="greet text-center">
        {t('HELLO')}{' '}
        {firstName && ` ${capitalizeAndExtractFirstLetter(firstName)}`}!
      </h1>
    </section>
  )
}

interface MappedState {
  firstName: string
}

const mapStateToProps = (state: State): MappedState => ({
  firstName: state.legacy.user.data.FirstName,
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect(mapStateToProps)
)

export default enhancer(LegacyGreet)
