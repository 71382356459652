import React from 'react'
import { Col, Row } from 'reactstrap'
import Info from './Info'
import { WithTranslation, useTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import {
  formatDate,
  formatMoney,
  setSpaceForReferenceNumber,
} from '../../../../utils'
import { ScrollElement } from 'utils/scroll'
import LoanStatusEnum from '../LoanStatus/LoanStatus.enum'

interface Props extends WithTranslation, MappedStateModel {}

const NextInstallment = (props: Props) => {
  const {
    referenceNumber,
    dueData,
    amount,
    isHidden,
    accountNumber,
    loanAccountState,
  } = props
  const { t } = useTranslation()

  if (isHidden || loanAccountState === LoanStatusEnum.DEBT_COLLECTION) {
    return null
  }

  const refNumber = referenceNumber ? referenceNumber.toString() : ''

  return (
    <>
      <ScrollElement name="next-installment" />
      <div className="next-installment">
        <Row>
          <Col>
            <h1 className="next-installment__header">
              {t('BORROWER_NEXT_INSTALLMENT')}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 8, offset: 2 }}>
            <div className="next-installment-info__wrapper">
              <Info title={t('BORROWER_RECEIVER')}>Fixura Ab Oy</Info>
              <Info title={t('BORROWER_ACCOUNT_NUMBER')}>{accountNumber}</Info>
              <Info title={t('BORROWER_REFERENCE_NUMBER')}>
                {setSpaceForReferenceNumber(refNumber)}
              </Info>
              <Info title={t('BORROWER_DASHBOARD_DUE_DATE_TITLE')}>
                {dueData && formatDate(dueData, 'YYYY-MM-DD')}
              </Info>
              <Info title={t('TRANSACTIONS_LIST_AMOUNT')}>
                {amount && formatMoney(amount)} €
              </Info>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default NextInstallment
