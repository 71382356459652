import {
  POST_CANCEL_WITHDRAW_FAILURE,
  POST_CANCEL_WITHDRAW_REQUEST,
  POST_CANCEL_WITHDRAW_SUCCESS,
  GET_WITHDRAW_STATUS_FAILURE,
  GET_WITHDRAW_STATUS_REQUEST,
  GET_WITHDRAW_STATUS_SUCCESS,
  POST_WITHDRAW_FAILURE,
  POST_WITHDRAW_REQUEST,
  POST_WITHDRAW_RESET,
  POST_WITHDRAW_SUCCESS,
  STORE_WITHDRAW_AMOUNT,
} from './actions/types'
import WithDrawStatusData from '../../../../../models/Investor/WithDrawStatus'

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_CANCEL_WITHDRAW_REQUEST:
      return { ...state, is_fetching: true }
    case POST_CANCEL_WITHDRAW_SUCCESS:
      return { ...initialState }
    case POST_CANCEL_WITHDRAW_FAILURE:
      return { ...initialState, error: action.error }
    case GET_WITHDRAW_STATUS_REQUEST:
      return { ...state, is_fetching: false }
    case GET_WITHDRAW_STATUS_SUCCESS:
      return {
        ...initialState,
        data: { ...action.data },
      }
    case GET_WITHDRAW_STATUS_FAILURE:
      return { ...initialState, error: action.error }
    case POST_WITHDRAW_REQUEST:
      return { ...state, is_fetching: true }
    //TODO checkme spikkedSpinner
    case POST_WITHDRAW_SUCCESS:
      return {
        ...initialState,
        is_successful: true,
        amount: action.amount,
        skippedSpinner: true,
      }
    case STORE_WITHDRAW_AMOUNT:
      return { ...initialState, amount: action.amount }
    case POST_WITHDRAW_FAILURE:
      return { ...initialState, error: action.error }
    case POST_WITHDRAW_RESET:
      return initialState
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  is_successful: false,
  amount: 0,
  error: null,
  data: {
    active: false,
    requestedAmount: 0,
    remainingAmountToSell: 0,
  },
  skippedSpinner: false,
}

export interface State {
  is_fetching: boolean
  is_successful: boolean
  amount: number
  error: any
  data: WithDrawStatusData
  skippedSpinner: boolean
}
