import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import Dates from '../../../../../../models/RangeDatepicker/Dates'
import {
  createGetInvestorTransactionsCsvFailureAction,
  createGetInvestorTransactionsCsvRequestAction,
  createGetInvestorTransactionsCsvSuccessAction,
} from './creators'
import InvestorAPI from '../../../../../../api/user/InvestorAPI'
import jsFileDownload from 'js-file-download'
import { AxiosResponse } from 'axios'
import { formatDate } from '../../../../../../utils'

const getDocument = (
  dates: Dates,
  type?: string,
  depositId?: string
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createGetInvestorTransactionsCsvRequestAction())
  try {
    const investor = new InvestorAPI()
    const response: AxiosResponse = await investor.getInvestorTransactionsFile(
      depositId || '',
      dates,
      type
    )
    const startDate = dates.startDate
      ? dates.startDate.toString()
      : 'transactions'
    const endDate = dates.endDate ? dates.endDate.toString() : ''
    jsFileDownload(
      response.data,
      `${formatDate(startDate)}-${formatDate(endDate)}.csv`,
      'application/csv'
    )
    dispatch(createGetInvestorTransactionsCsvSuccessAction())
  } catch (error) {
    dispatch(createGetInvestorTransactionsCsvFailureAction(error))
  }
}

export default getDocument
