import {
  GET_TOTAL_LOAN_AMT_LEFT_FAILURE,
  GET_TOTAL_LOAN_AMT_LEFT_REQUEST,
  GET_TOTAL_LOAN_AMT_LEFT_SUCCESS,
} from './types'

export const createGetTotalLoanAmtLeftRequestAction = () => ({
  type: GET_TOTAL_LOAN_AMT_LEFT_REQUEST,
})
export const createGetTotalLoanAmtLeftSuccessAction = (data) => ({
  data,
  type: GET_TOTAL_LOAN_AMT_LEFT_SUCCESS,
})
export const createGetTotalLoanAmtLeftFailureAction = (error: any) => ({
  error,
  type: GET_TOTAL_LOAN_AMT_LEFT_FAILURE,
})
