import {
  GET_INVESTOR_KPI_FAILURE,
  GET_INVESTOR_KPI_REQUEST,
  GET_INVESTOR_KPI_SUCCESS,
  STORE_DATES,
} from './types'
import InvestorKpi from '../../../../../../models/InvestorKpi'
import Dates from '../../../../../../models/RangeDatepicker/Dates'

export const createGetInvestorKpiRequestAction = () => ({
  type: GET_INVESTOR_KPI_REQUEST,
})
export const createGetInvestorKpiSuccessAction = (data: InvestorKpi) => ({
  data,
  type: GET_INVESTOR_KPI_SUCCESS,
})
export const createGetInvestorKpiFailureAction = (error: any) => ({
  error,
  type: GET_INVESTOR_KPI_FAILURE,
})

export const createStoreDatesAction = (dates: Dates) => ({
  dates,
  type: STORE_DATES,
})
