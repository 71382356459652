import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../store'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Col, Container, Row, Table } from 'reactstrap'
import TransactionRow from './TransactionRow'
import Indicator from '../../shared/Indicator'
import Transaction from '../../../models/Legacy/Investor/Transaction'
import fetchTransactions from '../../../store/legacy/investor/transactions/actions/fetchTransactions'
import { ScrollElement } from 'utils/scroll'
import LoadMore from '../../shared/LoadMore'
import { useParams } from 'react-router-dom'

interface Props
  extends WithTranslation,
    MappedStateModel,
    MappedDispatchModel {}

const Transactions = (props: Props) => {
  const { t, transactions, fetchTransactions, isFetching } = props
  const [numberOfLoadedRecords, setNumberOfLoadedRecords] = useState(16)
  const { userId } = useParams()

  useEffect(() => {
    if (userId) fetchTransactions(userId)
  }, [fetchTransactions, userId])

  const loadMore = () => {
    setNumberOfLoadedRecords(numberOfLoadedRecords + 10)
  }
  return (
    <div className="transactions-background">
      <ScrollElement name="transactions" />
      <Indicator isVisible={isFetching} />
      <Container>
        <Row>
          <Col>
            <h1 className="transactions-header">{t('TRANSACTIONS')}</h1>
          </Col>
        </Row>
        <Row>
          <Col md="8" className="m-auto">
            <Table borderless className="m-0">
              <thead>
                <tr className="header_transactions-small-mode">
                  <th className="table-item">{t('DATE')}</th>
                  <th className="table-item">{t('TYPE')}</th>
                  <th className="table-item">{t('RECEIVER')}</th>
                  <th className="table-item transactions-align-right">
                    {t('AMOUNT')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactions.map(
                  (transaction, index) =>
                    index < numberOfLoadedRecords && (
                      <TransactionRow
                        key={index}
                        date={transaction.Created}
                        receiver={getReceiver(transaction)}
                        amount={transaction.Amount}
                        type={t(transaction.Type)}
                      />
                    )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <LoadMore
          onClick={loadMore}
          isVisible={numberOfLoadedRecords < transactions.length}
        />
      </Container>
    </div>
  )
}

const getReceiver = (transaction: Transaction) => {
  if (transaction.From) {
    return transaction.From.Type
  }
  if (transaction.To) {
    return transaction.To.Type
  }
  return ''
}

interface MappedStateModel {
  transactions: Transaction[]
  isFetching: boolean
}

interface MappedDispatchModel {
  fetchTransactions: (legacyUserId: string) => void
}

const mapStateToProps = (state: State): MappedStateModel => ({
  transactions: state.legacy.investor.transactions.data,
  isFetching: state.legacy.investor.transactions.is_fetching,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  fetchTransactions: (legacyUserId) =>
    dispatch(fetchTransactions(legacyUserId)),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, MappedDispatchModel, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Transactions)
