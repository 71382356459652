import React from 'react'
import classNames from 'classnames'
import { Th } from './Table'

interface Props {
  next?: boolean
  calendar: any
  handleNext: Function
}

export default class CalendarNext extends React.Component<Props> {
  static defaultProps = {
    next: true,
  }

  handleNext(calendar: any) {
    const { handleNext } = this.props
    if (handleNext) {
      handleNext(calendar)
    }
  }

  render() {
    const { next, calendar } = this.props
    const className = classNames({ next, available: next })
    const onClick = calendar ? this.handleNext.bind(this, calendar) : () => {}
    const Span = next ? <span /> : null
    const nextProps = {
      className,
      onClick,
    }
    return <Th {...nextProps}>{Span}</Th>
  }
}
