import { combineReducers } from 'redux'
import profile, {
  initialState as profileInitialState,
  State as ProfileState,
} from './profile'
import investor, {
  initialState as investorInitialState,
  State as InvestorState,
} from './investor'
import borrower, {
  initialState as borrowerInitialState,
  State as BorrowerState,
} from './borrower'
import document, {
  initialState as documentInitialState,
  State as DocumentState,
} from './document'

export interface State {
  profile: ProfileState
  investor: InvestorState
  borrower: BorrowerState
  document: DocumentState
}

export default combineReducers<State>({
  profile,
  investor,
  borrower,
  document,
})

export const initialState: State = {
  profile: profileInitialState,
  investor: investorInitialState,
  borrower: borrowerInitialState,
  document: documentInitialState,
}
