import { Route, Switch } from 'react-router'
import React from 'react'

import TermsAndConditionsModal1stMarch2021 from '../components/Auth/TermsAndConditionsModalProduct21'
import SignCompnayContract from '../components/Investor/Onboarding/SignCompnayContract'
import SignCompanyContractGuardRoute from './guards/onboarding/investor/SignCompanyContractGuardRoute'
import CompanyAml from '../components/Investor/Onboarding/Aml/CompanyAml'
import CompanyAmlGuardRoute from './guards/onboarding/investor/CompanyAmlGuardRoute'

export default () => (
  <Switch>
    {/* @ts-ignore */}
    <CompanyAmlGuardRoute path="/onboarding/company/:companyId/investor/aml">
      <CompanyAml />
    </CompanyAmlGuardRoute>
    {/* @ts-ignore */}
    <SignCompanyContractGuardRoute path="/onboarding/company/:companyId/contract">
      <SignCompanyContractView />
    </SignCompanyContractGuardRoute>
  </Switch>
)

const SignCompanyContractView = () => (
  <>
    <SignCompnayContract />
    <Switch>
      <Route path="/onboarding/company/:companyId/contract/terms-and-conditions-and-price-list">
        <TermsAndConditionsModal1stMarch2021 />
      </Route>
    </Switch>
  </>
)
