import React from 'react'
import { State } from '../../../store'
import { ThunkDispatch } from 'redux-thunk'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Col, Row } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import Indicator from '../../shared/Indicator'
import moment from 'moment'
import 'moment/locale/sv'
import 'moment/locale/fi'
import 'moment/locale/de'

interface Props extends MappedState, MappedDispatch {}

const ActiveLoanAction = (props: Props) => {
  const {
    isHidden,
    isRejected,
    id,
    shouldRedirectToCCIS,
    tryAfterThisDate,
    isFetching,
  } = props

  const { t } = useTranslation()
  const browserHistory = useHistory()

  if (isHidden) {
    return null
  }

  return (
    <>
      <Indicator isVisible={isFetching} />
      <Row className="text-center pt-1 pb-5 offer-information">
        <Col>
          {!isRejected && shouldRedirectToCCIS ? (
            <Button
              className="hero"
              onClick={() => browserHistory.push('/dashboard/borrower/ccis')}
            >
              {t('CONTINUE_APPLICATION')}
            </Button>
          ) : (
            <>
              {!isRejected && (
                <Button
                  className="hero"
                  onClick={() => browserHistory.push(`/dashboard/borrower/kreditz`)}
                >
                  {t('CONTINUE_APPLICATION')}
                </Button>
              )}
              {isRejected && (
                <span>
                  {`${t(
                    'YOUR_LOAN_HAS_BEEN_REJECTED_PLEASE_TRY'
                  )} ${tryAfterThisDate} ${t('LATER')}`}
                </span>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  )
}

interface MappedState {
  isHidden: boolean
  isRejected: boolean
  isFetching: boolean
  shouldRedirectToCCIS: boolean
  id: number
  tryAfterThisDate: string
}

interface MappedDispatch {
}

const mapStateToProps = (state: State): MappedState => {
  const { data } = state.user.borrower.loan_application
  const date = state.user.borrower.loan_application.data.created_at
  const isFetching = state.user.borrower.loan_application.is_fetching

  const language =
    state.user.profile.data.language === 'se'
      ? 'sv'
      : state.user.profile.data.language

  const tryAfterThisDate = moment(date)
    .add(91, 'days')
    .locale(language)
    .format('ll')

  return {
    isHidden: data.id === 0,
    isRejected: !data.retry,
    shouldRedirectToCCIS: data.state === 'bs4',
    id: data.id,
    tryAfterThisDate: tryAfterThisDate,
    isFetching,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ActiveLoanAction)
