import React, { useState } from 'react'

interface Props {
  onChange: (value: string) => void
  hasError: boolean
  errorMessage?: string
}

const CodeInput = (props: Props) => {
  const { onChange, hasError, errorMessage } = props
  const otpTextInput: any[] = []
  const [fields, setFields] = useState(['', '', '', ''])

  const CodeInputs = [0, 1, 2, 3].map((i) => {
    return (
      <input
        autoFocus={!i ? true : false}
        value={fields[i]}
        key={i}
        ref={(ref) => (otpTextInput[i] = ref)}
        maxLength={1}
        size={1}
        type="text"
        inputMode="numeric"
        min="0"
        max="9"
        onChange={(e) => focusPrevious(e, i)}
        pattern="[0-9]{1}"
      />
    )
  })

  const focusPrevious = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    codeDigit = parseInt(e.target.value)
  ) => {
    if (codeDigit === 8) {
      e.preventDefault()
      if (index !== 0) {
        otpTextInput[index - 1].focus()
      }
      updateField(index, '')
    }
    if (codeDigit >= 0 && codeDigit <= 9) {
      updateField(index, e.target.value)
      if (index < otpTextInput.length - 1) {
        otpTextInput[index + 1].focus()
      }
    }
    if (codeDigit !== null) {
      updateField(index, e.target.value)
      if (!e.target.value) {
        otpTextInput[index].focus()
      }
    }
    if (index === 3 && codeDigit !== null) {
      otpTextInput[index].focus()
    }
  }

  const updateField = (index: number, value: string) => {
    const newFields = [...fields]
    newFields[index] = value
    setFields(newFields)

    onChange(newFields.join(''))
  }

  return (
    <>
      {CodeInputs}
      {hasError && (
        <small className="form-error-message left-35">{errorMessage}</small>
      )}
    </>
  )
}

CodeInput.defaultProps = {
  errorMessage: '',
}

export default CodeInput
