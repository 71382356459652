export const GET_ONFIDO_RETRY_REDIRECT_LINK_REQUEST =
  'user.investor.GET_ONFIDO_RETRY_REDIRECT_LINK_REQUEST'
export const GET_ONFIDO_RETRY_REDIRECT_LINK_SUCCESS =
  'user.investor.GET_ONFIDO_RETRY_REDIRECT_LINK_SUCCESS'
export const GET_ONFIDO_RETRY_REDIRECT_LINK_FAILURE =
  'user.investor.GET_ONFIDO_RETRY_REDIRECT_LINK_FAILURE'
export const GET_ONFIDO_STATUS_REQUEST =
  'user.investor.GET_ONFIDO_STATUS_REQUEST'
export const GET_ONFIDO_STATUS_SUCCESS =
  'user.investor.GET_ONFIDO_STATUS_SUCCESS'
export const GET_ONFIDO_STATUS_FAILURE =
  'user.investor.GET_ONFIDO_STATUS_FAILURE'
