import API from '../API'

export default class CompanyInvestorAPI extends API {
  public postCompanyAml(data, companyId) {
    const params = { ...companyAmlDefaultValues, ...data }

    return this.post(`api/company/${companyId}/aml`, params)
  }

  public postCompany(businessCode) {
    const params = { business_code: businessCode }

    return this.post(`api/company`, params)
  }
}

const companyAmlDefaultValues = {
  is_familiar_with_publicly_traded_stocks: false,
  is_familiar_with_bonds: false,
  is_familiar_with_capital_markets: false,
  is_familiar_with_derivatives: false,
  is_familiar_with_investment_funds: false,
  investment_funds_specification: '',
  is_familiar_with_investments_on_my_own: false,
  is_familiar_with_investment_advice: false,
  is_familiar_with_asset_management: false,
  is_familiar_with_electronic_trading: false,
  is_familiar_with_insurance_investments: false,
  insurance_investments_specification: '',
  business_sector: '',
  turnover: 0,
  profit: 0,
  balance_sheet_total: 0,
  origin_of_funds_is_extra_capital: false,
  origin_of_funds_is_asset_sale: false,
  origin_of_funds_is_acquisition: false,
  origin_of_funds_is_other: false,
  origin_of_funds_sepcification: '',
  goal_of_investment_is_generate_returns: false,
  goal_of_investment_is_savings: false,
  goal_of_investment_is_other: false,
  goal_of_investment_specification: '',
  annual_investment_count: '',
  annual_investment_amount: '',
  risk_profile: '',
}
