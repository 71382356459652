import {
  STORE_LOAN_FAILURE,
  STORE_LOAN_REQUEST,
  STORE_LOAN_SUCCESS,
} from './types'

export const createSignLoanContractRequestAction = () => ({
  type: STORE_LOAN_REQUEST,
})
export const createSignLoanContractSuccessAction = () => ({
  type: STORE_LOAN_SUCCESS,
})
export const createSignLoanContractFailureAction = (error: any) => ({
  error,
  type: STORE_LOAN_FAILURE,
})
