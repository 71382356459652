import { CheckBoxElement } from '../../../../../shared/CheckBoxGroup/Models'

const options: CheckBoxElement[] = [
  {
    id: 'is_employee',
    label: 'EMPLOYEE',
  },
  {
    id: 'is_entrepreneur',
    label: 'ENTREPRENEUR',
  },
  {
    id: 'is_student',
    label: 'STUDENT',
  },
  {
    id: 'is_retired',
    label: 'RETIRED',
  },
  {
    id: 'has_other_financial_situation',
    label: 'HAS_OTHER_FINANCIAL_SITUATION',
  },
]

export default options
