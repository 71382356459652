import React from 'react'
import { Col, Row } from 'reactstrap'
import Popup from 'reactjs-popup'

interface Props {
  title: string
  lg?: string
  xs?: string
  contentClassName?: string
  children: any
  src?: any
  postion?: any
  alt?: any
  info?: string
  className?: string
}

const LabeledValue = (props: Props) => {
  const {
    lg,
    xs,
    title,
    info,
    src,
    contentClassName,
    children,
    postion,
    className,
  } = props
  return (
    <Col lg={lg} xs={xs} className={className ? 'sm-pb-5' : ''}>
      <Row>
        <Col className="different-padding">
          <div
            className={className ? className : 'title-placement font-portfolio'}
          >
            {title}
            {src && info && (
              <>
                <Popup
                  trigger={
                    <img className="margin-portfolio" src={src} alt={title} />
                  }
                  on="hover"
                  position={postion ? postion : 'bottom left'}
                  closeOnDocumentClick
                >
                  <span className="info-popup">{info}</span>
                </Popup>
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={`${contentClassName} value-placement`}>
            {children}
          </div>
        </Col>
      </Row>
    </Col>
  )
}

LabeledValue.defaultProps = {
  lg: '12',
  xs: '12',
  contentClassName: 'font-25',
}

export default LabeledValue
