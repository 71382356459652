import React from 'react'

interface Props {
  title: string
}

const Info = (props: React.PropsWithChildren<Props>) => {
  const { title, children } = props

  return (
    <div className="next-installment-info">
      <div className="next-installment-info__title">{title}</div>
      <div className="next-installment-info__value">{children}</div>
    </div>
  )
}

export default Info
