import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form, FormGroup, Alert } from 'reactstrap'
import { LegacyLoginData } from '../../../models/Legacy/LegacyLoginData'
import FixuraInput from '../../shared/FixuraInput'
import Indicator from '../../shared/Indicator'
import { useTranslation } from 'react-i18next'
import { State } from '../../../store'
import { ThunkDispatch } from 'redux-thunk'
import { LoginWithUsernameAndPassword } from '../../../store/legacy/auth/actions/loginWithUsernameAndPassword'
import { connect } from 'react-redux'
import { unprocessableEntityErrorHandler } from '../../../utils'

interface Props extends MapStateToProps, MapDispatchToProps {}

const Login = (props: Props) => {
  const { isFetching, login, error, language } = props
  const { t } = useTranslation()

  const localUnprocessableEntityErrorHandler = unprocessableEntityErrorHandler.bind(
    undefined,
    ['username', 'password'],
    error
  )
  const [fields, setFields] = useState({
    username: '',
    password: '',
  })

  const [errors, setErrors] = useState(localUnprocessableEntityErrorHandler())
  useEffect(() => {
    setErrors(localUnprocessableEntityErrorHandler())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const localHandleChange = (
    field: string,
    e: React.FormEvent<HTMLInputElement>
  ) => handleChange(field, fields, setFields, e)
  const handleLoginByEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleValidation(fields, setErrors, t)) {
      login(fields)
    }
  }

  return (
    <div>
      <Indicator isVisible={isFetching} />
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <p className="login-method__title">{t('LOGIN_EMAIL_TITLE')}</p>
        </Col>
      </Row>
      <Form className="login-email__form" onSubmit={handleLoginByEmail}>
        <FormGroup>
          <Row>
            <Col lg={{ size: 6, offset: 3 }}>
              {error && (
                <Alert color="danger">
                  {t('USERNAME_OR_PASSWORD_INCORRECT')}
                </Alert>
              )}
              <FixuraInput
                className="w-100"
                type="text"
                placeholder={t('USERNAME')}
                onChange={localHandleChange.bind(undefined, 'username')}
                hasError={errors.hasOwnProperty('username')}
                errorMessage={
                  errors['username'] ||
                  (!!error && t('USERNAME_OR_PASSWORD_INCORRECT'))
                }
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col lg={{ size: 6, offset: 3 }}>
              <FixuraInput
                className="w-100"
                type="text"
                placeholder={t('PASSWORD')}
                onChange={localHandleChange.bind(undefined, 'password')}
                hasError={errors.hasOwnProperty('password')}
                errorMessage={errors['password']}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <a
                href={`https://old2.fixura.fi/${language}-fi/forgot-password/`}
              >
                {t('FORGOT_PASSWORD')}
              </a>
            </Col>
          </Row>
        </FormGroup>
        <Col>
          <Button type="submit" className="min-w-10">
            {t('CONTINUE')}
          </Button>
        </Col>
      </Form>
    </div>
  )
}

function handleChange(
  field,
  fields,
  setFields,
  e: React.FormEvent<HTMLInputElement>
) {
  fields[field] = e.currentTarget.value
  setFields(fields)
}

function handleValidation(fields, setErrors, t) {
  const errors = {}
  let formIsValid = true

  // Username
  if (!fields['username']) {
    formIsValid = false
    errors['username'] = t('CANNOT_BE_EMPTY')
  }

  // Password
  if (!fields['password']) {
    formIsValid = false
    errors['password'] = t('CANNOT_BE_EMPTY')
  }

  setErrors(errors)
  return formIsValid
}
interface MapStateToProps {
  isFetching: boolean
  error: any
  language: string
}

interface MapDispatchToProps {
  login: (data: LegacyLoginData) => void
}

const mapStateToProps = (state: State): MapStateToProps => ({
  isFetching: state.legacy.auth.is_fetching,
  error: state.legacy.auth.error,
  language:
    state.user.profile.data.language === 'se'
      ? 'sv'
      : state.user.profile.data.language,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MapDispatchToProps => ({
  login: (data: LegacyLoginData) =>
    dispatch(LoginWithUsernameAndPassword(data)),
})

export default connect<MapStateToProps, MapDispatchToProps, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(Login)
