import { compose } from 'redux'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { withTranslation } from 'react-i18next'
import MappedDispatchModel from './MappedDispatch.model'
import Presentation from './Presentation'
import { State } from '../../../../store'
import postWithdraw from '../../../../store/legacy/investor/withdraw/actions/postWithdraw'
import MappedStateModel from './MappedState.model'

const mapStateToProps = (state: State): MappedStateModel => {
  return {
    amount: state.legacy.investor.withdraw.amount,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  withdraw: (legacyUserId) =>
    dispatch((dispatch: ThunkDispatch<any, any, any>, getState: () => State) =>
      dispatch(
        postWithdraw(getState().legacy.investor.withdraw.amount, legacyUserId)
      )
    ),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, MappedDispatchModel, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Presentation)
