import moment from 'moment'
import { VALID_UP_TO__THREE_DAYS } from '../../../constants'

//Validate date from created date upto 3 days
export function isBetween(date) {
  let now = new Date()
  let current = moment(now).format('YYYY-MM-DD HH:mm:ss')
  let created = moment(date).format('YYYY-MM-DD HH:mm:ss')

  let validUntil = moment(date)
    .add(VALID_UP_TO__THREE_DAYS, 'day')
    .format('YYYY-MM-DD HH:mm:ss')

  let isBetween = current >= created && current <= validUntil

  return isBetween
}
