export const GET_PROFILE_REQUEST = 'user.profile.GET_USER_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'user.profile.GET_USER_PROFILE_SUCCESS'
export const GET_PROFILE_FAILURE = 'user.profile.GET_USER_PROFILE_FAILURE'

export const LOGOUT_REQUEST = 'user.profile.LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'user.profile.LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'user.profile.LOGOUT_FAILURE'

export const UPDATE_PROFILE_REQUEST = 'user.profile.UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'user.profile.UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'user.profile.UPDATE_PROFILE_FAILURE'
