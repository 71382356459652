import {
  GET_AGREEMENTS_REQUEST,
  GET_AGREEMENTS_SUCCESS,
  GET_AGREEMENTS_FAILURE,
  POST_SKIP_SIGNED_AGREEMENTS_SUCCESS,
} from './actions/types'
import { Agreements } from '../../../../models/Auth/ProfileData'
import {
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
} from '../../../user/profile/actions/types'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AGREEMENTS_REQUEST:
      return { ...state }
    case GET_AGREEMENTS_SUCCESS:
      return { ...initialState, data: { ...action.data } }
    case GET_AGREEMENTS_FAILURE:
      return { ...initialState }
    case POST_SKIP_SIGNED_AGREEMENTS_SUCCESS:
      return {
        ...initialState,
        has_skipped_signed_latest_agreements: 1,
      }
    case LOGOUT_SUCCESS:
    case LOGOUT_FAILURE:
      return initialState
    default:
      return state
  }
}

export const initialState: State = {
  has_skipped_signed_latest_agreements: 0,
  data: {
    has_signed_terms_and_conditions: false,
    has_signed_latest_terms_and_conditions: false,
    has_signed_investor_contract: false,
    has_signed_latest_investor_contract: false,
  },
}

export interface State {
  has_skipped_signed_latest_agreements: number
  data: Agreements
}
