import {
  LOCAL_STORAGE_IS_LEGACY,
  LOCAL_STORAGE_LEGACY_CAN_BE_ONBOARDED,
  LOCAL_STORAGE_LEGACY_ROLE,
  LOCAL_STORAGE_TOKEN_NAME,
  LOCAL_STORAGE_REDIRECT_PATH_AFTER_LOGIN,
  LOCAL_STORAGE_LEGACY_IS_FOREIGNER,
} from '../constants'
import Auth, { Role } from '../models/Legacy/Auth'

export function getFixuraToken() {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME)
}

export function removeFixuraToken() {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_NAME)
  removeIsLegacy()
  removeLegacyData()
}

export function setToken(
  token: string,
  isLegacy: boolean = false,
  legacyData?: Auth
) {
  localStorage.setItem(LOCAL_STORAGE_TOKEN_NAME, token)
  if (isLegacy && legacyData) {
    setIsLegacy()
    setLegacyData(legacyData)
  } else {
    removeIsLegacy()
    removeLegacyData()
  }
}

export function isLegacy(): boolean {
  return localStorage.getItem(LOCAL_STORAGE_IS_LEGACY) === 'true'
}

function setIsLegacy() {
  localStorage.setItem(LOCAL_STORAGE_IS_LEGACY, 'true')
}

function removeIsLegacy() {
  localStorage.removeItem(LOCAL_STORAGE_IS_LEGACY)
}

function setLegacyData(legacyData: Auth) {
  localStorage.setItem(
    LOCAL_STORAGE_LEGACY_ROLE,
    legacyData.role ? legacyData.role : ''
  )
  localStorage.setItem(
    LOCAL_STORAGE_LEGACY_CAN_BE_ONBOARDED,
    legacyData.can_be_onboarded ? 'true' : 'false'
  )
  localStorage.setItem(
    LOCAL_STORAGE_LEGACY_IS_FOREIGNER,
    legacyData.is_foreigner ? 'true' : 'false'
  )
}

export function getLegacyData(): Auth {
  return {
    token: '',
    role:
      localStorage[LOCAL_STORAGE_LEGACY_ROLE] === 'investor'
        ? Role.INVESTOR
        : Role.BORROWER,
    can_be_onboarded:
      localStorage[LOCAL_STORAGE_LEGACY_CAN_BE_ONBOARDED] === 'true',
    is_foreigner: localStorage[LOCAL_STORAGE_LEGACY_IS_FOREIGNER] === 'true',
  }
}

function removeLegacyData() {
  localStorage.removeItem(LOCAL_STORAGE_LEGACY_ROLE)
  localStorage.removeItem(LOCAL_STORAGE_LEGACY_IS_FOREIGNER)
  localStorage.removeItem(LOCAL_STORAGE_LEGACY_CAN_BE_ONBOARDED)
}

export function getRedirectPathAfterLogin() {
  return localStorage.getItem(LOCAL_STORAGE_REDIRECT_PATH_AFTER_LOGIN)
}

export function setRedirectPathAfterLogin(path: string) {
  localStorage.setItem(LOCAL_STORAGE_REDIRECT_PATH_AFTER_LOGIN, path)
}

export function removeRedirectPathAfterLogin() {
  localStorage.removeItem(LOCAL_STORAGE_REDIRECT_PATH_AFTER_LOGIN)
}
