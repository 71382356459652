import { CheckBoxElement } from '../../../../../shared/CheckBoxGroup/Models'

const options: CheckBoxElement[] = [
  {
    id: 'is_familiar_with_publicly_traded_stocks',
    label: 'PUBLICLY_TRADED_STOCKS',
  },
  {
    id: 'is_familiar_with_bonds',
    label: 'BONDS',
  },
  {
    id: 'is_familiar_with_money_market_instruments',
    label: 'CAPITAL_MARKETS',
  },
  {
    id: 'is_familiar_with_derivatives',
    label: 'DERIVATIVES',
  },
  {
    id: 'has_no_experience_in_investment_vehicles',
    label: 'HAS_NO_EXPERIENCE_IN_INVESTMENT_VEHICLES',
  },
  {
    id: 'is_familiar_with_investment_funds',
    label: 'INVESTMENT_FUNDS',
  },
]

export default options
