import API from '../API'
import RedirectData from 'models/Auth/Signicat/RedirectData'
import CallbackData from 'models/Auth/Signicat/CallbackData'

export default class Signicat extends API {
  public getRedirectUrl(data: RedirectData) {
    return this.post('api/auth/signicat/redirect', data)
  }

  public postCallbackData(data: CallbackData) {
    return this.post('api/auth/signicat/callback', data)
  }
}
