import React from 'react'
import { Col, Row } from 'reactstrap'

const Rows = (props: any) => {
  return (
    <Row>
      <Col>{props.children}</Col>
    </Row>
  )
}

export default Rows
