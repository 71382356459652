import { connect } from 'react-redux'
import { State } from '../../../../store'
import PrivateRoutePresentation from '../../PrivateRoutePresentation'

export default connect((state: State, props) => {
  // @ts-ignore
  const companyId = props.computedMatch.params.companyId
  const company = state.user.profile.data.companies.find(
    (company) => company.id === parseInt(companyId)
  )
  return {
    isAuthorized: company?.deposits.length === 0,
    pathname: `/onboarding/company/${companyId}/contract`,
  }
})(PrivateRoutePresentation)
