import React, { useState } from 'react'
import { Col, Row, Button } from 'reactstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import FixuraInput from 'components/shared/FixuraInput'

interface ComponentProps {
  setFields: (fields) => void
  fields: any
}

interface Props extends WithTranslation, ComponentProps {}

const AddBeneficialOwners = (props: Props) => {
  const { t, setFields, fields } = props

  const [inputList, setInputList] = useState([{ name: '', ssn: '' }])

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
    const listMap = inputList.map(function (item) {
      return (
        'name' +
        ': ' +
        item.name.toString() +
        ', ' +
        'ssn' +
        ': ' +
        item.ssn.toString()
      )
    })
    const beneficial_owner_list = listMap.toString()
    setFields({ ...fields, beneficial_owner_list })
  }

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    if (inputList.length >= 4) {
      return null
    }
    setInputList([...inputList, { name: '', ssn: '' }])
  }

  return (
    <div className=" mb-5">
      <Row className="mb-4">
        <Col>
          <div className="font-20-body">{t('ADD_BUSINESS_OWNERS')}</div>
        </Col>
      </Row>
      {inputList.map((bo, index) => {
        return (
          <Row className="mb-4" key={index}>
            <Col md="7" className="mb-4">
              <FixuraInput
                className="input-characteristics"
                name="name"
                placeholder={t('NAME')}
                defaultValue={bo.name}
                onChange={(e) => handleInputChange(e, index)}
              />
            </Col>
            <Col md="7" className="mb-4">
              <FixuraInput
                className="input-characteristics"
                name="ssn"
                placeholder={t('SOCIAL_SECURITY_NUMBER')}
                defaultValue={bo.ssn}
                onChange={(e) => handleInputChange(e, index)}
              />
            </Col>
            <Col md="7" className="mb-4">
              {inputList.length !== 1 && (
                <Button
                  className="m-2"
                  onClick={() => handleRemoveClick(index)}
                >
                  {t('REMOVE')}
                </Button>
              )}
              {inputList.length - 1 === index && (
                <Button onClick={handleAddClick}>{t('ADD')}</Button>
              )}
            </Col>
          </Row>
        )
      })}
    </div>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(AddBeneficialOwners)
