import React from 'react'
import { DropdownItem } from 'reactstrap'
import { Country } from './data'

interface Props {
  country: Country
  onClick: () => void
}

const CountryItem = (props: Props) => {
  const { country, onClick } = props

  return (
    <DropdownItem onClick={onClick} data-country-code={country.code}>
      <img src={country.img} className="flag__img" alt="flag" />
      <span>{country.name}</span>
    </DropdownItem>
  )
}

export default CountryItem
