import {
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_SUCCESS,
} from './actions/types'

export interface State {
  is_fetching: boolean
  error: any
}

export default (state: State = initialState, action: any) => {
  switch (action.type) {
    case GET_DOCUMENT_REQUEST:
      return { ...state, is_fetching: true }
    case GET_DOCUMENT_SUCCESS:
      return initialState
    case GET_DOCUMENT_FAILURE:
      return { ...initialState, error: action.error }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  error: null,
}
