import {
  FETCH_SIGNICAT_AUTH_URL_REQUEST_REQUEST,
  FETCH_SIGNICAT_AUTH_URL_REQUEST_SUCCESS,
  FETCH_SIGNICAT_AUTH_URL_REQUEST_FAILURE,
  POST_SIGNICAT_CALLBACK_DATA_REQUEST,
  POST_SIGNICAT_CALLBACK_DATA_SUCCESS,
  POST_SIGNICAT_CALLBACK_DATA_FAILURE,
} from './types'
import CallbackResponse from 'models/Auth/Signicat/CallbackResponse'

export const createFetchSignicatAuthUrlRequestAction = () => ({
  type: FETCH_SIGNICAT_AUTH_URL_REQUEST_REQUEST,
})
export const createFetchSignicatAuthUrlSuccessAction = (data: any) => ({
  data,
  type: FETCH_SIGNICAT_AUTH_URL_REQUEST_SUCCESS,
})
export const createFetchSignicatAuthUrlFailureAction = (error: any) => ({
  error,
  type: FETCH_SIGNICAT_AUTH_URL_REQUEST_FAILURE,
})

export const createPostSignicatCallbackDataRequestAction = () => ({
  type: POST_SIGNICAT_CALLBACK_DATA_REQUEST,
})
export const createPostSignicatCallbackDataSuccessAction = (
  data: CallbackResponse
) => ({
  data,
  type: POST_SIGNICAT_CALLBACK_DATA_SUCCESS,
})
export const createPostSignicatCallbackDataFailureAction = (error: any) => ({
  error,
  type: POST_SIGNICAT_CALLBACK_DATA_FAILURE,
})
