import {
  LOGIN_USER_PASS_FAILURE,
  LOGIN_USER_PASS_REQUEST,
  LOGIN_USER_PASS_SUCCESS,
} from './actions/types'
import {
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
} from '../../user/profile/actions/types'
import Auth from '../../../models/Legacy/Auth'

export default (state = initialState, action): State => {
  switch (action.type) {
    case LOGIN_USER_PASS_REQUEST:
      return { ...initialState, is_fetching: true }
    case LOGIN_USER_PASS_SUCCESS:
      return { ...initialState, is_logged_in: true, data: action.data }
    case LOGOUT_SUCCESS:
    case LOGOUT_FAILURE:
      return initialState
    case LOGIN_USER_PASS_FAILURE:
      return { ...initialState, error: action.error }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  is_logged_in: false,
  data: {
    token: undefined,
    role: undefined,
    can_be_onboarded: true,
    is_foreigner: false,
  },
  error: null,
}

export interface State {
  is_fetching: boolean
  is_logged_in: boolean
  data: Auth
  error: any
}
