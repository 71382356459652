import React from 'react'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../store'
import { Button, Container } from 'reactstrap'
import Indicator from 'components/shared/Indicator'
import Contract from 'components/shared/Contract'
import TermsAndConditions from './'
import { postSignTermsAndConditions } from 'store/auth/register/terms_and_conditions/actions/postSignTermsAndConditions'
import CustomerService from 'components/shared/CustomerService'

interface Props extends MappedState, MappedDispatch {}

const SignContract = (props: Props) => {
  const { isFetching, SignTermsAndConditions } = props
  const { t } = useTranslation()

  return (
    <Container>
      <div className="text-center pt-5">
        <h1 className="font-50 mb-4">{t('UPDATED_TERMS_AND_CONDITIONS')}</h1>
        <Indicator isVisible={isFetching} />
        <Contract>
          <TermsAndConditions />
        </Contract>
        <Button
          onClick={SignTermsAndConditions}
          type="submit"
          className="mt-5 mb-2"
        >
          {t('HAVE_READ_AND_ACCEPT')}
        </Button>
        <p>{t('DONT_ACCEPT_CHANGES')}</p>
      </div>
      <CustomerService />
    </Container>
  )
}

interface MappedState {
  isFetching: boolean
}

interface MappedDispatch {
  SignTermsAndConditions: () => void
}

const mapStateToProps = (state: State): MappedState => ({
  isFetching: state.auth.register.terms_and_conditions.is_fetching,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  SignTermsAndConditions: () => dispatch(postSignTermsAndConditions()),
})

const enhancer = compose(
  connect<MappedState, MappedDispatch, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(SignContract)
