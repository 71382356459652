import React from 'react'

const OfferInterestRateInformation = () => {
  return (
    <div className="text-center loan-interest-rate">
    </div>
  )
}

export default OfferInterestRateInformation
