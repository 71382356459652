import { CheckBoxElement } from '../../../../../shared/CheckBoxGroup/Models'

const options: CheckBoxElement[] = [
  {
    id: 'origin_of_invested_assets_is_salary',
    label: 'SALARY',
  },
  {
    id: 'origin_of_invested_assets_is_investments',
    label: 'INVESTMENTS',
  },
  {
    id: 'origin_of_invested_assets_is_inheritance',
    label: 'INHERITANCE',
  },
  {
    id: 'origin_of_invested_assets_is_other',
    label: 'OTHER',
  },
]

export default options
