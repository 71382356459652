import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import {
  createGetContractInvoiceFailureAction,
  createGetContractInvoiceRequestAction,
  createGetContractInvoiceSuccessAction,
} from './creators'
import Document from '../../../../api/user/Document'
import { AxiosResponse } from 'axios'
import jsFileDownload from 'js-file-download'

export const getDocument = (
  documentId: number
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createGetContractInvoiceRequestAction())

  try {
    const profile = new Document()
    // @ts-ignore
    const response: AxiosResponse = await profile.getDocument(documentId)
    jsFileDownload(response.data, 'Fixura-document.pdf', 'application/pdf')
    dispatch(createGetContractInvoiceSuccessAction())
  } catch (error) {
    dispatch(createGetContractInvoiceFailureAction(error))
  }
}
