import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import {
  createAispRetryFailureAction,
  createAispRetryRequestAction,
  createAispRetrySuccessAction,
} from './creators'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import { State } from '../../../../index'

const aispRetry = (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  dispatch(createAispRetryRequestAction())

  try {
    const borrowerApi = new BorrowerAPI()
    const response = await borrowerApi.aispRetry(
      getState().user.borrower.loan_application.data.id
    )
    dispatch(createAispRetrySuccessAction())
    window.location.href = response.data.data.redirect_url
  } catch (error) {
    dispatch(createAispRetryFailureAction(error))
  }
}

export default aispRetry
