import { connect } from 'react-redux'
import Presentation from './Presentation'
import MappedStateModel from './MappedState.model'
import { State } from '../../../../store'

const mapStateToProps = (state: State): MappedStateModel => {
  const { data } = state.user.borrower.loan
  return {
    loanAmount: data?.total_amount,
    loanAccountState: data?.loanAccountState,
  }
}

export default connect(mapStateToProps)(Presentation)
