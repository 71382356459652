import API from '../API'
import Dates from '../../models/RangeDatepicker/Dates'
import dayjs from 'dayjs'
export default class InvestorAPI extends API {
  public signInvestorContract(depositAccountId: string) {
    return this.post(`api/document/investor/${depositAccountId}/sign`, {})
  }

  public getInvestorContract(depositAccountId: string) {
    return this.get(`api/document/investor/${depositAccountId}`)
  }

  public getInvestorKpi(depositAccountId: string, dates: Dates) {
    const params = {
      from_date: dates.startDate
        ? dates.startDate.format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
      to_date: dates.endDate
        ? dates.endDate.format('YYYY-MM-DD')
        : dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    }

    return this.get(`api/investor/deposit/${depositAccountId}`, params)
  }

  public getInvestorTransactions(
    depositAccountId: string,
    dates: Dates,
    type?: string
  ) {
    const params = {
      type: type ? type : 'ALL',
      from_date: dates.startDate
        ? dates.startDate.format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
      to_date: dates.endDate
        ? dates.endDate.format('YYYY-MM-DD')
        : dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    }
    return this.get(
      `api/investor/deposit/${depositAccountId}/transaction`,
      params
    )
  }

  public getInvestorTransactionsFile(
    depositAccountId: string,
    dates: Dates,
    type?: string
  ) {
    const params = {
      type,
      from_date: dates.startDate
        ? dates.startDate.format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
      to_date: dates.endDate
        ? dates.endDate.format('YYYY-MM-DD')
        : dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    }

    return this.get(
      `api/investor/deposit/${depositAccountId}/transaction/download`,
      params,
      {},
      { responseType: 'arraybuffer' }
    )
  }

  public postAml(data, forceAmlFillIn: boolean) {
    const params = { ...amlDefaultValues, ...data }
    if (forceAmlFillIn) {
      return this.patch('api/aml-pep', params)
    }
    return this.post('api/aml-pep', params)
  }

  public getAml() {
    return this.get('api/aml-pep')
  }

  public getReferenceNumber() {
    return this.get('api/investor/funds/reference-number')
  }

  public postWithdrawNoAutosell(amount, depositId: string) {
    return this.post(`api/investor/deposit/${depositId}/withdraw`, {
      amount: parseFloat(amount),
    })
  }

  public postWithdraw(amount, depositId: string) {
    return this.post(`api/investor/deposit/${depositId}/auto-sell/withdraw`, {
      amount: parseFloat(amount),
    })
  }

  public cancelWithdraw(depositId: string) {
    return this.post(`api/investor/deposit/${depositId}/auto-sell/cancel`, {})
  }

  public getWithdrawStatus(depositId: string) {
    return this.get(`api/investor/deposit/${depositId}/auto-sell/status`)
  }

  public getAutoInvestStatus(depositId: string) {
    return this.get(`api/investor/deposit/${depositId}/auto-invest/status`)
  }

  public toggleAutoInvest(depositId: string) {
    return this.patch(
      `api/investor/deposit/${depositId}/auto-invest/toggle`,
      {}
    )
  }

  public getRetryRedirectLink(state: string) {
    return this.get(`api/auth/signicat/retry/onfido/${state}`, {})
  }

  public getOnFidoStatus() {
    return this.get(`/api/auth/signicat/onfido_status`, {})
  }
}

const amlDefaultValues = {
  is_familiar_with_publicly_traded_stocks: false,
  is_familiar_with_bonds: false,
  is_familiar_with_money_market_instruments: false,
  is_familiar_with_derivatives: false,
  is_familiar_with_investment_funds: false,
  investment_funds_specification: '',
  is_familiar_with_investments_on_my_own: false,
  is_familiar_with_investment_advice: false,
  is_familiar_with_asset_management: false,
  is_familiar_with_electronic_trading: false,
  is_familiar_with_insurance_investments: false,
  insurance_investments_specification: '',
  is_employee: false,
  is_entrepreneur: false,
  is_student: false,
  is_retired: false,
  profession: 'Test',
  monthly_gross_income_amount: '',
  total_assets_amount: '',
  origin_of_invested_assets_is_salary: false,
  origin_of_invested_assets_is_investments: false,
  origin_of_invested_assets_is_business_activities_or_acquisition: false,
  origin_of_invested_assets_is_inheritance: false,
  origin_of_invested_assets_is_other: false,
  origin_of_invested_assets_specification: '',
  goal_of_investment_is_generate_returns: false,
  goal_of_investment_is_savings: false,
  goal_of_investment_is_pension: false,
  goal_of_investment_is_other: false,
  goal_of_investment_specification: '',
  annual_investment_count: '',
  annual_investment_amount: '',
  risk_profile: '',
  is_pep: false,
  is_member_of_parliament: false,
  is_president: false,
  is_close_to_member_of_parliament: false,
  is_close_to_president: false,
  is_business_partner_with_member_of_parliament: false,
  is_business_partner_with_president: false,
  has_no_experience_in_investment_vehicles: false,
  has_no_experience_in_investment_services: false,
  has_other_financial_situation: false,
  financial_situation: '',
}
