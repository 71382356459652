import React from 'react'
import { compose } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { State } from '../../../store'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Col, Row, Container } from 'reactstrap'
import { isLegacy } from 'utils/auth'
import ic_bar_char_decrease from 'assets/images/ic_bar_char_decrease.svg'
import ic_bar_char_fixed from 'assets/images/ic_bar_char_fixed.svg'
import { formatMoney } from '../../../utils'
import finlandFlagRoundSmall from 'assets/images/flags/finland-flag-round-small.png'
import browserHistory from '../../../browserHistory'
import { Company } from 'models/Auth/ProfileData'
import PortfolioSettings from '../PortfolioSettings'

interface IProps extends MappedStateModel, WithTranslation {}

const CompanySwitch: React.FunctionComponent<IProps> = (props) => {
  const { availableInvestments, t, data, company } = props
  const history = useHistory()
  const location = useLocation()
  const { userId: legacyUserId } = useParams()
  const type = location.pathname.includes('fair') ? 'fair' : 'fixed'

  const mapNames = {
    fixed: t('FIXED_RATE_PORTFOLIO'),
    fair: t('FAIR_CREDIT_PORTFOLIO'),
  }

  return (
    <>
      {company ? (
        <div className="text-center p-title main-heading">
          <h1 className="add-funds-margin ">{`${company.name} ${t(
            'PORTFOLIOS'
          )}`}</h1>
        </div>
      ) : (
        <div className="text-center p-title main-heading">
          <h1 className="add-funds-margin ">{t('YOUR_PORTFOLIOS')}</h1>
        </div>
      )}
      {/* Zero value fix in process hide*/}
      <div className="text-center pb-30 hide">
        <span className="add-funds-margin color-red">
          {t('ZERO_VALUE_FIXING_IN_PROCESS')}
        </span>
      </div>
      {availableInvestments.map((investment) => {
        return (
          <Container
            className="add-funds__wrapper px-25"
            key={`${investment.type}-${investment.legacyUserId}`}
          >
            <Row className="center p-switch">
              <Col
                className="download-item switch-box"
                md={6}
                onClick={() => {
                  if (
                    investment.type !== type ||
                    investment.legacyUserId !== parseInt(legacyUserId)
                  ) {
                    if (investment.type === 'fair')
                      history.push(
                        `/dashboard/company/${company.id}/investor/fair-credit-portfolio`
                      )
                    if (investment.type === 'fixed' && !isLegacy()) {
                      history.push(
                        `/dashboard/company/fixed-rate-portfolio/${investment.legacyUserId}`
                      )
                    }
                    if (investment.type === 'fixed' && isLegacy()) {
                      history.push(
                        `/restricted/company/fixed-rate-portfolio/${investment.legacyUserId}`
                      )
                    }
                  }
                }}
              >
                <div className="download-item__wrapper pointer">
                  <div>
                    <strong className="download-item--title">
                      <img
                        className="img_bar_chart"
                        src={
                          investment.type === 'fair'
                            ? ic_bar_char_decrease
                            : ic_bar_char_fixed
                        }
                        alt={`${mapNames[investment.type]}`}
                      />
                      <span>{`${mapNames[investment.type]}${
                        investment.legacyUserId
                          ? ` (${investment.legacyUserId})`
                          : ''
                      }`}</span>
                    </strong>
                  </div>
                  <div className="sm-hide">
                    {investment.type === 'fair' && (
                      <span>{formatMoney(data.portfolio_value)} €</span>
                    )}
                  </div>
                  <div>
                    <img
                      className="mb-1_add_company h-2 sm-hide"
                      src={finlandFlagRoundSmall}
                      alt={`${mapNames[investment.type]}`}
                    />
                  </div>
                  {investment.type === 'fair' ? (
                    <PortfolioSettings />
                  ) : (
                    <div className="autoinvest badge badge__red">
                      <span>{t('INACTIVE')}</span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        )
      })}
    </>
  )
}

interface MappedStateModel {
  availableInvestments: Investment[]
  data: { portfolio_value: string }
  companies: Company[]
  company: any
}

interface Investment {
  type: 'fair' | 'fixed'
  legacyUserId?: number
}
const mapStateToProps = (state: State): MappedStateModel => {
  const availableInvestments: Investment[] = []
  const profileData = state.user.profile.data
  const { data } = state.user.investor.dashboard.kpi
  const url = browserHistory.location.pathname.split('/')
  const companyId = url[3]
  if (url.includes('/dashboard/company')) {
    // @ts-ignore
    return companyId
  }

  const companies = profileData.companies
  const company = companies.find(
    (company) => company.id === parseInt(companyId)
  )
  const legacyCompanies = company
    ? company.legacy_companies
    : state.legacy.user.legacy_companies

  if (
    profileData.deposits.length > 0 &&
    profileData.deposits[0].has_signed_contract
  ) {
    availableInvestments.push({ type: 'fair' })
  }

  legacyCompanies.forEach((legacyCompanies) => {
    availableInvestments.push({
      type: 'fixed',
      legacyUserId: legacyCompanies.legacy_user_id,
    })
  })

  return {
    availableInvestments,
    data,
    companies: state.user.profile.data.companies,
    company,
  }
}

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, any, any, any>(mapStateToProps)
)

export default enhancer(CompanySwitch)
