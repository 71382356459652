import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
  createSignTermsAndConditionsPreReleaseRequestAction,
  createSignTermsAndConditionsPreReleaseSuccessAction,
  createSignTermsAndConditionsPreReleaseFailureAction,
  createSetShouldAgreeWithTermsAndConditionsAction,
} from './creators'
import TermsAndConditionsAPI from '../../../../../api/general/TermsAndConditionsAPI'
import { getUserProfile } from 'store/user/profile/actions/getUserProfile'

export const postSignTermsAndConditionsPreRelease = (
  isInvestor?: boolean
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(createSignTermsAndConditionsPreReleaseRequestAction())
  try {
    const termsAndConditionAPI = new TermsAndConditionsAPI()
    await termsAndConditionAPI.signTermsAndConditionsPreRelease()
    dispatch(createSignTermsAndConditionsPreReleaseSuccessAction())
    dispatch(createSetShouldAgreeWithTermsAndConditionsAction(false))
    if (!isInvestor) {
      dispatch(getUserProfile('/dashboard'))
    }
  } catch (error) {
    dispatch(createSignTermsAndConditionsPreReleaseFailureAction(error))
  }
}
