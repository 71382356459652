import {
  createCancelWithdrawFailureAction,
  createCancelWithdrawRequestAction,
  createCancelWithdrawSuccessAction,
} from './creators'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import InvestorAPI from '../../../../../../api/user/InvestorAPI'
import getInvestorTransactions from '../../transactions/actions/getInvestorTransactions'
import getInvestorKpi from '../../kpi/actions/getInvestorKpi'
import { State } from '../../../../../index'

const cancelWithdraw = (depositId): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => State
) => {
  dispatch(createCancelWithdrawRequestAction())

  try {
    const investorAPI = new InvestorAPI()
    await investorAPI.cancelWithdraw(depositId)
    dispatch(createCancelWithdrawSuccessAction())

    const { transactions } = getState().user.investor.dashboard
    if (transactions.dates) {
      dispatch(
        getInvestorTransactions(
          transactions.dates,
          transactions.type,
          depositId
        )
      )
    }

    const { kpi } = getState().user.investor.dashboard
    if (kpi.dates) {
      dispatch(getInvestorKpi(kpi.dates, depositId))
    }
  } catch (error) {
    dispatch(createCancelWithdrawFailureAction(error))
  }
}

export default cancelWithdraw
