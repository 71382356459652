import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { State } from '../../store'
import { capitalizeFirstLetter } from '../../utils'

interface Props extends MappedState {}

const Name = (props: Props) => {
  const { firstName, lastName } = props

  return (
    <h1 className="mt-3">
      {capitalizeFirstLetter(firstName)} {capitalizeFirstLetter(lastName)}
    </h1>
  )
}

interface MappedState {
  firstName: string
  lastName: string
}

const mapStateToProps = (state: State): MappedState => ({
  firstName: state.user.profile.data.first_name,
  lastName: state.user.profile.data.last_name,
})

const enhancer = compose(connect<MappedState, any, any, any>(mapStateToProps))

export default enhancer(Name)
