import { connect } from 'react-redux'
import Presentation from './Presentation'
import { State } from '../../../../store'
import MappedStateModel from './MappedState.model'
import browserHistory from 'browserHistory'

const mapStateToProps = (state: State): MappedStateModel => {
  const { data } = state.legacy.borrower.loan
  const selectedLoanId = browserHistory.location.pathname.split('/').pop()
  if (selectedLoanId) {
    // @ts-ignore
    const loan = data.find((loan) => loan.Reference === selectedLoanId)
    if (loan) {
      const index = loan.LoanPlan.findIndex(
        (loanPlan) => loanPlan.Invoice && loanPlan.Invoice.InvoiceStatus === 60
      )
      if (index >= 0) {
        const invoice = loan.LoanPlan[index].Invoice
        return {
          referenceNumber: loan.Reference,
          dueData: invoice && invoice.DueDate,
          amount: invoice && invoice.TotalAmount,
          accountNumber: 'FI10 1789 3000 0054 79',
        }
      }
    }
  }
  return {
    isHidden: true,
  }
}

export default connect(mapStateToProps)(Presentation)
