import {
  GET_INVESTOR_TRANSACTIONS_CSV_FAILURE,
  GET_INVESTOR_TRANSACTIONS_CSV_REQUEST,
  GET_INVESTOR_TRANSACTIONS_CSV_SUCCESS,
  GET_INVESTOR_TRANSACTIONS_FAILURE,
  GET_INVESTOR_TRANSACTIONS_REQUEST,
  GET_INVESTOR_TRANSACTIONS_SUCCESS,
  STORE_DATES_AND_TYPE,
} from './actions/types'
import InvestorTransactions from '../../../../../models/InvestorTransactions'
import Dates from '../../../../../models/RangeDatepicker/Dates'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INVESTOR_TRANSACTIONS_REQUEST:
    case GET_INVESTOR_TRANSACTIONS_CSV_REQUEST:
      return { ...state, is_fetching: true }
    case GET_INVESTOR_TRANSACTIONS_CSV_SUCCESS:
      return { ...state, is_fetching: false }
    case GET_INVESTOR_TRANSACTIONS_SUCCESS:
      return { ...state, data: action.data, is_fetching: false, error: null }
    case GET_INVESTOR_TRANSACTIONS_FAILURE:
    case GET_INVESTOR_TRANSACTIONS_CSV_FAILURE:
      return { ...initialState, error: action.error }
    case STORE_DATES_AND_TYPE:
      return {
        ...state,
        dates: action.payload.dates,
        type: action.payload.type,
      }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  data: [],
  error: null,
  dates: undefined,
  type: undefined,
}

export interface State {
  is_fetching: boolean
  data: InvestorTransactions[]
  error: any
  dates?: Dates
  type?: string
}
