import {
  GET_BORROWER_LOAN_FAILURE,
  GET_BORROWER_LOAN_REQUEST,
  GET_BORROWER_LOAN_SUCCESS,
  ADD_LOAN,
} from './actions/types'
import Loan from '../../../../models/Borrower/Loan'
import LoanStatus from '../../../../models/Borrower/LoanStatus'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BORROWER_LOAN_REQUEST:
      return { ...state, is_fetching: true }

    case ADD_LOAN:
      const loansStatus: LoanStatus[] = action.data.map((loan) => {
        return {
          loan_id: loan.mambu_loan_id,
        }
      })
      return {
        ...state,
        loansStatus,
      }
    case GET_BORROWER_LOAN_SUCCESS:
      const updateLoansStatus: LoanStatus[] = state.loansStatus.map((loan) => {
        if (loan.loan_id === action.loanId) {
          const status = action.data.loanAccountState
          const loanLeft = action.data.remaining_debt
          return {
            ...loan,
            status,
            loanLeft,
          }
        }
        return loan
      })
      return {
        ...state,
        loansStatus: updateLoansStatus,
        is_fetching: false,
        data: action.data,
      }
    case GET_BORROWER_LOAN_FAILURE:
      return { ...state, error: action.error, is_fetching: false }

    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  data: undefined,
  error: null,
  loansStatus: [],
}

export interface State {
  is_fetching: boolean
  data?: Loan
  error: any
  loansStatus: LoanStatus[]
}
