import { connect } from 'react-redux'
import { State } from '../../store'
import PrivateRoutePresentation from './PrivateRoutePresentation'
import { getFixuraToken } from 'utils/auth'

/* TODO checkme */
export default connect((state: State) => {
  const { data } = state.user.profile
  const {
    should_agree_with_new_terms_and_conditions,
  } = state.auth.register.terms_and_conditions

  return {
    isAuthorized:
      (!!getFixuraToken() && data.id === 0) ||
      should_agree_with_new_terms_and_conditions,
    pathname: '/dashboard',
  }
})(PrivateRoutePresentation)
