import React, { useState, useEffect } from 'react'
import { Toast, ToastBody, Button, Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Steps from '../../components/shared/Steps'
import { store } from '../../store'
import { isBetween } from '../../components/shared/DateValidate/isBetween'
import { postWalkThru } from '../../store/user/investor/walkThru/actions/postWalkThru'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../store'
import ic_check_circle from 'assets/images/ic_check_circle.svg'
import { setSpaceForReferenceNumber } from '../../utils'
interface WalkThruProps {
  title?: string
  showToast: boolean
}

interface Props extends MappedDispatch, MappedState, WalkThruProps {}

const WalkThruInvestor = (props: Props) => {
  const {
    showToast,
    postWalkThruInvestor,
    isWalkThru,
    referenceNumber,
    accountNumber,
  } = props
  const [show, setShow] = useState(true)
  const [steps, setSteps] = useState(0)

  const { data } = store.getState().user.investor.dashboard.kpi
  const date = store.getState().user.profile.data.deposits[0]
    ? store.getState().user.profile.data.deposits[0].created_at
    : ''
  const portFolioValue = data.portfolio_value
  const { t } = useTranslation()

  useEffect(() => {
    isBetween(date)
  }, [date])

  //isHidden when user press skip or user's account older than three days or user's portfolio value is greater than zero
  if (parseInt(portFolioValue) !== 0 || !isBetween(date) || isWalkThru) {
    return null
  }

  const onNext = () => {
    setSteps(steps + 1)
  }

  const onBack = () => {
    setSteps(steps - 1)
  }

  const onReady = () => {
    setShow(false)
    postWalkThruInvestor()
  }

  const onSkip = () => {
    setShow(false)
    postWalkThruInvestor()
  }

  return (
    <div id="walk-thru-investor">
      {showToast && (
        <Toast isOpen={show} className="walk-thru__container">
          <ToastBody className="walk-thru__body">
            <Steps
              stepIndex={steps}
              steps={[
                t('WELCOME'),
                t('HOW_IT_WORKS'),
                t('MAKE_A_DEPOSIT'),
                t('READY'),
              ]}
            />
            {steps === 0 && (
              <>
                <h1 className="font-50 walk-thru__title step-big-title">
                  {t('WALK_THRU_INVESTOR_WELCOME')}
                </h1>
                <div className="walk-thru__detail">
                  <p>{t('WALK_THRU_INVESTOR_WELCOME_LINE1')}</p>
                  <p>{t('WALK_THRU_INVESTOR_WELCOME_LINE2')}</p>
                </div>
              </>
            )}
            {steps === 1 && (
              <>
                <h1 className="font-50 walk-thru__title step-big-title">
                  {t('HOW_IT_WORKS')}
                </h1>
                <div className="walk-thru__detail">
                  <p>{t('WALK_THRU_INVESTOR_HOW_IT_WORKS')}</p>
                </div>
                <div className="walk-thru__bullets">
                  <p className="walk-thru__bullets__title">
                    {t('WALK_THRU_INVESTOR_HOW_IT_WORKS_LINE1_TITLE')}
                  </p>
                  <p>{t('WALK_THRU_INVESTOR_HOW_IT_WORKS_LINE1')}</p>
                </div>
                <div className="walk-thru__bullets">
                  <p className="walk-thru__bullets__title">
                    {t('WALK_THRU_INVESTOR_HOW_IT_WORKS_LINE2_TITLE')}
                  </p>
                  <p>{t('WALK_THRU_INVESTOR_HOW_IT_WORKS_LINE2')}</p>
                </div>
                <div className="walk-thru__bullets">
                  <p className="walk-thru__bullets__title">
                    {t('WALK_THRU_INVESTOR_HOW_IT_WORKS_LINE3_TITLE')}
                  </p>
                  <p>{t('WALK_THRU_INVESTOR_HOW_IT_WORKS_LINE3')}</p>
                </div>
              </>
            )}
            {steps === 2 && (
              <>
                <h1 className="font-50 walk-thru__title step-big-title">
                  {t('MAKE_A_DEPOSIT')}
                </h1>
                <div className="walk-thru__detail">
                  <p>{t('WALK_THRU_INVESTOR_MAKE_A_DEPOSIT')}</p>
                </div>
                <div className="walk-thru__detail pb-2">
                  <p>{t('WALK_THRU_INVESTOR_MAKE_A_DEPOSIT_LINE1')}</p>
                </div>
                <>
                  <Row className="walk-thru__deposit-detail__main">
                    <Col lg="7" sm="6" xs="6">
                      <Row className="walk-thru__deposit-detail__icon">
                        <Col
                          lg="2"
                          sm="2"
                          xs="2"
                          className="walk-thru__deposit-detail__img"
                        >
                          <img
                            src={ic_check_circle}
                            alt={t('VERIFY_INFO_SECOND_LINE')}
                          />
                        </Col>
                        <Col lg="9" sm="9" xs="9" className="col-p">
                          <p className="walk-thru__deposit-detail__info">
                            {t('WALK_THRU_INVESTOR_MAKE_A_DEPOSIT_LINE2')}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="5" sm="6" xs="6">
                      <div className="walk-thru__deposit-bullets">
                        <p className="walk-thru__deposit-bullets__title">
                          {t('BORROWER_RECEIVER')}
                        </p>
                        <p>{t('FIXURA_AB_OY')}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="walk-thru__deposit-detail__main">
                    <Col lg="7" sm="6" xs="6">
                      <Row className="walk-thru__deposit-detail__icon">
                        <Col
                          lg="2"
                          sm="2"
                          xs="2"
                          className="walk-thru__deposit-detail__img"
                        >
                          <img
                            src={ic_check_circle}
                            alt={t('ACCOUNT_NUMBER')}
                          />
                        </Col>
                        <Col lg="9" sm="9" xs="9" className="col-p">
                          <p className="walk-thru__deposit-detail__info">
                            {t('WALK_THRU_INVESTOR_MAKE_A_DEPOSIT_LINE3')}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="5" sm="6" xs="6">
                      <div className="walk-thru__deposit-bullets">
                        <p className="walk-thru__deposit-bullets__title">
                          {t('ACCOUNT_NUMBER')}
                        </p>
                        <p>{accountNumber}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="walk-thru__deposit-detail__main">
                    <Col lg="7" sm="6" xs="6">
                      <Row className="walk-thru__deposit-detail__icon">
                        <Col
                          lg="2"
                          sm="2"
                          xs="2"
                          className="walk-thru__deposit-detail__img"
                        >
                          <img
                            src={ic_check_circle}
                            alt={t('REFERENCE_NUMBER')}
                          />
                        </Col>
                        <Col lg="9" sm="9" xs="9" className="col-p">
                          <p className="walk-thru__deposit-detail__info">
                            {t('WALK_THRU_INVESTOR_MAKE_A_DEPOSIT_LINE4')}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="5" sm="6" xs="6">
                      <div className="walk-thru__deposit-bullets">
                        <p className="walk-thru__deposit-bullets__title">
                          {t('REFERENCE_NUMBER')}
                        </p>
                        <p>{setSpaceForReferenceNumber(referenceNumber)}</p>
                      </div>
                    </Col>
                  </Row>
                </>
              </>
            )}
            {steps === 3 && (
              <>
                <h1 className="font-50 walk-thru__title step-big-title">
                  {t('WALK_THRU_INVESTOR_READY')}
                </h1>
                <div className="walk-thru__detail">
                  <p>{t('WALK_THRU_INVESTOR_READY_LINE1')}</p>
                  <p>{t('WALK_THRU_INVESTOR_READY_LINE2')}</p>
                  <p>{t('WALK_THRU_INVESTOR_READY_LINE3')}</p>
                </div>
              </>
            )}

            {steps === 0 ? (
              <Button className="walk-thru__btn" onClick={onNext}>
                {t('NEXT')}
              </Button>
            ) : steps === 3 ? (
              <>
                <Button className="walk-thru__btn" onClick={onBack}>
                  {t('BACK')}
                </Button>
                <Button className="walk-thru__btn" onClick={onReady}>
                  {t('READY')}
                </Button>
              </>
            ) : (
              <>
                <Button className="walk-thru__btn" onClick={onBack}>
                  {t('BACK')}
                </Button>
                <Button className="walk-thru__btn" onClick={onNext}>
                  {t('NEXT')}
                </Button>
              </>
            )}

            <div className="p-3">
              <Button onClick={onSkip} color="link">
                {t('SKIP_AND_CONTINUE')}
              </Button>
            </div>
          </ToastBody>
        </Toast>
      )}
      <div className="modal-backdrop fade show" />
    </div>
  )
}

interface MappedDispatch {
  postWalkThruInvestor: () => void
}

interface MappedState {
  isWalkThru: boolean
  referenceNumber?: string | number
  accountNumber: string
}

const mapStateToProps = (state: State): MappedState => ({
  isWalkThru: state.user.investor.walkThru.is_walk_thru,
  referenceNumber: state.user.investor.dashboard.kpi.data.reference_number,
  accountNumber: 'FI71 1318 3000 1080 67',
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  postWalkThruInvestor: () => dispatch(postWalkThru()),
})

export default connect(mapStateToProps, mapDispatchToProps)(WalkThruInvestor)
