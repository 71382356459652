import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
  createSignTermsAndConditionsRequestAction,
  createSignTermsAndConditionsSuccessAction,
  createSignTermsAndConditionsFailureAction,
  createSetShouldAgreeWithTermsAndConditionsAction,
} from './creators'
import TermsAndConditionsAPI from '../../../../../api/general/TermsAndConditionsAPI'
import { getUserProfile } from 'store/user/profile/actions/getUserProfile'

export const postSignTermsAndConditions = (
  isInvestor?: boolean
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(createSignTermsAndConditionsRequestAction())
  try {
    const termsAndConditionAPI = new TermsAndConditionsAPI()
    await termsAndConditionAPI.signTermsAndConditions()
    dispatch(createSignTermsAndConditionsSuccessAction())
    dispatch(createSetShouldAgreeWithTermsAndConditionsAction(false))
    if (!isInvestor) {
      dispatch(getUserProfile('/dashboard'))
    }
  } catch (error) {
    dispatch(createSignTermsAndConditionsFailureAction(error))
  }
}
