import React, { useEffect } from 'react'
import { State } from '../../../store'
import { ThunkDispatch } from 'redux-thunk'
import aispRetry from '../../../store/user/borrower/loan_application/actions/aispRetry'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import ActiveLoanAction from './ActiveLoanAction'
import { useHistory } from 'react-router'
import { isBetween } from '../../shared/DateValidate/isBetween'

interface Props extends MappedState, MappedDispatch {}

const ActiveLoanApplication = (props: Props) => {
  const { isHidden, amount, term, isOfferValid } = props
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    if (isOfferValid) {
      history.push('/dashboard/borrower/view-offer')
    }
  }, [history, isOfferValid])

  if (isHidden) {
    return null
  }

  return (
    <div className="view-offer container-fluid">
      <Row>
        <Col className="offer-information__col">
          <Row className="offer-information align-items-start justify-content-center pt-5">
            <div className="offer-information__box">
              <Col lg="6" md="6" className="text-center">
                <div className="offer-information__title">
                  {t('LOAN_AMOUNT')}
                </div>
                <div className="offer-information__value font-80 d-flex justify-content-center align-items-center">
                  {amount} €
                </div>
              </Col>
              <Col lg="6" md="6" className="text-center">
                <div className="offer-information__title">
                  {t('LOAN_TERM_IN_MONTHS')}
                </div>
                <div className="offer-information__value font-80 d-flex justify-content-center align-items-center">
                  {term}
                </div>
              </Col>
            </div>
          </Row>
          <ActiveLoanAction />
        </Col>
      </Row>
    </div>
  )
}

interface MappedState {
  isHidden: boolean
  amount: number
  term: number
  isOfferValid: boolean
}

interface MappedDispatch {
  aispRetry: () => void
}

const mapStateToProps = (state: State): MappedState => {
  const { data } = state.user.borrower.loan_application
  const date = state.user.borrower.loan_application.data.created_at

  /* Redirect to offer page if user has loan amount offer, state updated*/
  if (data.amount_offered) {
    const isOfferValid =
      isBetween(date) &&
      data.id !== 0 &&
      !data.approved &&
      data.amount_offered !== 0
    return {
      isOfferValid,
      isHidden: data.id === 0,
      amount: data.amount,
      term: data.terms,
    }
  }
  return {
    isOfferValid: false,
    isHidden: data.id === 0,
    amount: data.amount,
    term: data.terms,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  aispRetry: () => dispatch(aispRetry()),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ActiveLoanApplication)
