import { urlApplyLoanFI, urlApplyLoanEN, urlApplyLoanSV } from '../../constants'

export const setUrl = (language: string) => {
  switch (language) {
    case 'en':
      return urlApplyLoanEN
    case 'fi':
      return urlApplyLoanFI
    case 'se':
      return urlApplyLoanSV
    default:
      return urlApplyLoanEN
  }
}
