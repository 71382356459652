import React from 'react'
import { useLocation } from 'react-router'

interface Props {
  stepIndex: number
  steps: string[]
}

const Steps = (props: Props) => {
  const { steps, stepIndex } = props
  const location = useLocation()
  const isAml = location.pathname.includes('aml')

  return (
    <div className="steps-container">
      <ul className="steps-list">
        {steps.map((item, index) => {
          const stepDone = index <= stepIndex
          return (
            <li key={index} className={stepDone ? 'active' : ''}>
              {isAml ? (
                <span>
                  {item.split(' ').map((word) => (
                    <div key={word}>{word}</div>
                  ))}
                </span>
              ) : (
                <span>{item}</span>
              )}
              <span className="radial"></span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Steps
