import { ThunkAction } from 'redux-thunk'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'

const logKreditzEvent = (status: string, caseId: number, message: string): ThunkAction<any, any, any, any> => async () => {
  try {
    const borrowerAPI = new BorrowerAPI()
    await borrowerAPI.logKreditzEvent(status, caseId, message)
    // no need to handle error in this case
  } catch (error) {
    console.warn('Failed to log Kreditz event', error)
  }
}

export default logKreditzEvent
