import { compose } from 'redux'
import { connect } from 'react-redux'
import Presentation from './Presentation'
import fetchPortfolio from '../../../store/legacy/investor/kpi/actions/fetchPortfolio'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../store'
import { withTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel, { PortfolioData } from './MappedDispatch.model'

const mapStateToProps = (state: State): MappedStateModel => ({
  data: state.legacy.investor.kpi.data,
  isLegacy: true,
  isFetching: state.legacy.investor.kpi.is_fetching,
  error: state.legacy.investor.kpi.error,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  getInvestorPortfolio: (data: PortfolioData) =>
    dispatch(fetchPortfolio(data.legacyUserId)),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, MappedDispatchModel, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Presentation)
