import {
  GET_PORTFOLIO_FAILURE,
  GET_PORTFOLIO_REQUEST,
  GET_PORTFOLIO_SUCCESS,
} from './actions/types'
import Portfolio from '../../../../models/Legacy/Portfolio'

export default (state = initialState, action): State => {
  switch (action.type) {
    case GET_PORTFOLIO_REQUEST:
      return { ...state, is_fetching: true }
    case GET_PORTFOLIO_SUCCESS:
      return { ...initialState, data: action.data }
    case GET_PORTFOLIO_FAILURE:
      return { ...initialState, error: action.error }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  data: {
    StartTime: '',
    EndTime: '',
    Deposits: 0,
    Withdrawals: 0,
    Fees: 0,
    Investments: 0,
    RepaidCapital: 0,
    InterestPaid: 0,
    FreeCapital: 0,
    PortfolioValue: 0,
    ReturnAfterCreditLossesAndFees: 0,
    ActiveInvestments: 0,
    CreditLosses: 0,
    AvgROISinceStart: 0,
    PortfolioGrowth: 0,
    NrOfActiveInvestments: 0,
  },
  error: null,
}

export interface State {
  is_fetching: boolean
  data: Portfolio
  error: any
}
