import React from 'react'
import {Col, Row} from 'reactstrap'
import InvoiceTypeEnum from './InvoiceType.enum'
import {formatMoney} from '../../../../utils'

interface Props {
  className?: string
  title: string
  amount?: number
  percentage?: number | string
  invoiceStatus: InvoiceTypeEnum
}

const InstalmentMoreInfoRow = (props: Props) => {
  if (!props.amount && !props.percentage) {
    return null
  }

  return (
    <Row
      className={`payment-plan__dropdown ${getClassName(props.invoiceStatus)} ${
        props.className
      }`}
    >
      <Col xs="8">{props.title}</Col>

      {props.amount ? (
        <Col xs="4" lg="4" className="payment-plan__dropdown-amount">
          {formatMoney(props.amount)}€
        </Col>
      ) : (
        <Col xs="4" lg="4" className="payment-plan__dropdown-amount">
          {props.percentage}%
        </Col>
      )}
    </Row>
  )
}

function getClassName(invoiceStatus: InvoiceTypeEnum) {
  switch (invoiceStatus) {
    case InvoiceTypeEnum.READY_FOR_INVOICING:
      return 'up-coming'
    case InvoiceTypeEnum.PARTIALLY_PAID:
      return 'partially-paid'
    case InvoiceTypeEnum.PAID_LATE:
      return 'problem'
    case InvoiceTypeEnum.INVOICED:
      return 'current'
    case InvoiceTypeEnum.CANCELED:
      return 'canceled'
    default:
      return 'completed'
  }
}

export default InstalmentMoreInfoRow
