import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import BorrowerAPI from '../../../../../api/legacy/BorrowerAPI'
import {
  createLegacyInvoiceFailureAction,
  createLegacyInvoiceRequestAction,
  createLegacyInvoiceSuccessAction,
} from './creators'
import { AxiosResponse } from 'axios'
import { downloadBorrowerInvoice } from '../../../../../utils'

export default (
  id: number | string,
  legacyUserId?: number
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createLegacyInvoiceRequestAction())

  try {
    const borrowerAPI = new BorrowerAPI()
    const response: AxiosResponse = await borrowerAPI.getLegacyInvoice(
      id,
      legacyUserId
    )
    dispatch(createLegacyInvoiceSuccessAction())
    downloadBorrowerInvoice(response.data.data.Invoice)
  } catch (error) {
    dispatch(createLegacyInvoiceFailureAction(error))
  }
}
