import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { State } from '../../store'
import { withTranslation, WithTranslation } from 'react-i18next'
import ic_email from 'assets/images/ic_email.svg'

interface Props extends WithTranslation, MappedState {}

const Email = (props: Props) => {
  const { email, t } = props

  return (
    <div className="user-info mb-5">
      <div>
        <img src={ic_email} alt={t('USER_PHONE')} />
      </div>
      <div className="ml-3">{email}</div>
    </div>
  )
}

interface MappedState {
  email: string
}

const mapStateToProps = (state: State): MappedState => {
  return {
    email: state.user.profile.data.email,
  }
}

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedState, any, any, any>(mapStateToProps)
)

export default enhancer(Email)
