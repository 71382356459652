//TODO checkme return value
export function affiliateBorrowerUtils(
  name: string | string[] | null | undefined
) {
  switch (name) {
    case 'smart_response_main':
      return 'smartresponse'
    default:
      return 'adtraction'
  }
}

export function affiliateInvestorUtils(
  name: string | string[] | null | undefined
) {
  switch (name) {
    case 'smart_response_main':
      return 'smartresponse'
    default:
      return 'adtraction'
  }
}
