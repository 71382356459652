import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { State } from '../../../../store'
import { BankAccount } from '../../../../models/Auth/ProfileData'
import { ThunkDispatch } from 'redux-thunk'
import updateLoanApplicationBankAccount from '../../../../store/user/borrower/loan_application/actions/updateLoanApplicationBankAccount'

interface Props extends MappedState, MappedDispatch {}

const AccountNumberDropDown = (props: Props) => {
  const { bankAccounts, updateBankAccount, selectedBankAccount } = props
  const [isOpen, setIsOpen] = useState(false)

  if (!selectedBankAccount || !bankAccounts || bankAccounts.length === 0) {
    return null
  }

  return (
    <ButtonDropdown
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      className="view-offer__dropdown"
    >
      <DropdownToggle caret size="lg" className="light">
        <span className="account-name">{selectedBankAccount.IBAN}</span>
      </DropdownToggle>
      <DropdownMenu>
        {bankAccounts &&
          bankAccounts.map((account: BankAccount) => {
            return (
              <DropdownItem
                size="lg"
                className="light"
                onClick={() => updateBankAccount(account.id)}
                key={account.id}
              >
                <span className="account-name">{account.IBAN}</span>
              </DropdownItem>
            )
          })}
      </DropdownMenu>
    </ButtonDropdown>
  )
}

interface MappedState {
  bankAccounts?: BankAccount[]
  selectedBankAccount?: BankAccount
}

interface MappedDispatch {
  updateBankAccount: (bankAccountId: number) => void
}

const mapStateToProps = (state: State): MappedState => ({
  bankAccounts: state.user.borrower.loan_application.data.user?.bank_accounts,
  selectedBankAccount: state.user.borrower.loan_application.data.user?.bank_accounts.find(
    (bankAccount: BankAccount) => {
      return (
        bankAccount.id ===
        state.user.borrower.loan_application.data.bank_account_id
      )
    }
  ),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  updateBankAccount: (bankAccountId: number) =>
    dispatch(updateLoanApplicationBankAccount(bankAccountId)),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(AccountNumberDropDown)
