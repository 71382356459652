import React, { useEffect } from 'react'
import OfferInformation from './OfferInformation'
import TransferLoanButton from './TransferLoanButton'
import { connect } from 'react-redux'
import { State } from '../../../../store'
import { default as PaymentPlan } from '../../Dashboard/PaymentPlan/LoanOffer'
import Indicator from '../../../shared/Indicator'
import { useHistory } from 'react-router'
import Header from './Header'
import OfferInterestRateInformation from './OfferInterestRateInformation'
import updateLoanApplication from '../../../../store/user/borrower/loan_application/actions/updateLoanApplication'
import { ThunkDispatch } from 'redux-thunk'
import UpdateLoanOfferRequest from '../../../../models/Borrower/UpdateLoanOfferRequest'

interface Props extends MappedDispatch, MappedState {}

const ViewOffer = (props: Props) => {
  const {
    isFetching,
    shouldNavigateToCheck,
    updateLoanApplication,
    isPaymentPlanEmpty,
    UpdateLoanOfferRequest,
  } = props
  const history = useHistory()

  if (shouldNavigateToCheck) {
    history.push('/dashboard/borrower/loan-application')
  }

  /* if payment plan null call update loan */
  useEffect(() => {
    if (isPaymentPlanEmpty) {
      updateLoanApplication(UpdateLoanOfferRequest)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="view-offer">
      <Indicator isVisible={isFetching} />
      <Header />
      <OfferInterestRateInformation />
      <OfferInformation />
      <TransferLoanButton />
      <PaymentPlan />
    </div>
  )
}

interface MappedDispatch {
  updateLoanApplication: (updateLoan: UpdateLoanOfferRequest) => void
}
interface MappedState {
  isFetching: boolean
  shouldNavigateToCheck: boolean
  isPaymentPlanEmpty: boolean
  UpdateLoanOfferRequest: UpdateLoanOfferRequest
}

const mapStateToProps = (state: State): MappedState => {
  const { data } = state.user.borrower.loan_application

  const UpdateLoanOfferRequest = {
    due_date: 1,
    bank_account_id:
      state.user.profile.data.bank_accounts.length > 0
        ? state.user.profile.data.bank_accounts[0].id
        : 0,
  }
  return {
    isFetching: state.user.borrower.loan_application.is_fetching,
    shouldNavigateToCheck: data.id === 0,
    isPaymentPlanEmpty: data.payment_plan === null,
    UpdateLoanOfferRequest,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  updateLoanApplication: (updateLoan) =>
    dispatch(updateLoanApplication(updateLoan)),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ViewOffer)
