import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
  createSignContractFailureAction,
  createSignContractRequestAction,
  createSignContractSuccessAction,
} from './creators'
import InvestorAPI from '../../../../../api/user/InvestorAPI'
import { State } from '../../../../index'
import { getUserProfile } from '../../../profile/actions/getUserProfile'
import { investorSignDataLayer } from '../../../../../utils/gtm/investor'
import { postSkipSignedAgreements } from '../../../../../store/user/investor/agreements/actions/postSkipSignedAgreements'
import { postSkipCompanySignedAgreements } from '../../../../../store/user/investor/companyAgreements/actions/postSkipCompanySignedAgreements'

export const postSignContract = (
  depositId?: string,
  companyId?: number
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => State
) => {
  const depositAccountId = depositId
    ? depositId
    : getState().user.profile.data.deposits[0].mambu_deposit_id
  const legacyUsers = getState().user.profile.data.legacy_users

  dispatch(createSignContractRequestAction())
  try {
    const investorAPI = new InvestorAPI()
    await investorAPI.signInvestorContract(depositAccountId)
    dispatch(createSignContractSuccessAction())
    dispatch(postSkipSignedAgreements())
    dispatch(postSkipCompanySignedAgreements())
    investorSignDataLayer()
    if (companyId) {
      dispatch(
        getUserProfile(
          `/dashboard/company/${companyId}/investor/fair-credit-portfolio`
        )
      )
    } else if (
      legacyUsers.length > 0 &&
      legacyUsers[0].legacy_role === 'investor'
    ) {
      dispatch(
        getUserProfile('/dashboard/investor/fair-credit-portfolio?msg=welcome')
      )
    } else {
      dispatch(getUserProfile('/dashboard/investor/fair-credit-portfolio'))
    }
  } catch (error) {
    dispatch(createSignContractFailureAction(error))
  }
}
