import React from 'react'
import RadioButton from './RadioButton'
import { RadioElement } from './Models'
import Identical from '../Wrapper/Identical'
import { Col } from 'reactstrap'

interface Props {
  elements: RadioElement[]
  selectedElementId?: string
  onChange: (id: string) => void
  ElementWrapper?: React.ElementType
  errorMessage?: string
  hasError?: boolean
}

const RadioGroup = (props: Props): JSX.Element => {
  const { ElementWrapper, elements, selectedElementId, onChange } = props
  return (
    <>
      {elements.map((element) => (
        // @ts-ignore
        <ElementWrapper key={element.id}>
          <RadioButton
            data={element}
            isSelected={element.id === selectedElementId}
            onChange={() => onChange(element.id)}
          />
        </ElementWrapper>
      ))}
      {props.hasError && (
        <Col className="error-align">
          <small className="validation-error">{props.errorMessage}</small>
        </Col>
      )}
    </>
  )
}

RadioGroup.defaultProps = {
  ElementWrapper: Identical,
  errorMessage: '',
  hasError: false,
}

export default RadioGroup
