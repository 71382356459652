import { Col, Row, CustomInput } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../store'
import { connect } from 'react-redux'
import getAutoInvestStatus from '../../store/user/investor/auto_invest/actions/getAutoInvestStatus'
import toggleAutoInvestStatus from '../../store/user/investor/auto_invest/actions/toggleAutoInvestStatus'
import { useTranslation } from 'react-i18next'
import Indicator from '../shared/Indicator'
import ic_info_icon_black from 'assets/images/ic_info_icon_black.svg'
import browserHistory from '../../browserHistory'
import Popup from 'reactjs-popup'

interface ComponentProps {
  companyId?: number
  isSignRight: any
  isSignRightStatusPortfolioSettings?: boolean
}
interface Props extends MappedDispatch, MappedState, ComponentProps {}

const AutoInvest = (props: Props) => {
  const {
    isAutoInvestOn,
    toggleAutoInvest,
    getAutoInvestStatus,
    isFetching,
    depositId,
    companyId,
    isSignRight,
    isSignRightStatusPortfolioSettings,
    isAutoSellActive,
  } = props
  const { t } = useTranslation()
  const [isSignRightStatus, setIsSignRightStatus] = useState(true)

  useEffect(() => {
    const status = '2'
    if (
      companyId &&
      parseInt(isSignRight) !== parseInt(status) &&
      !isSignRightStatusPortfolioSettings
    ) {
      setIsSignRightStatus(false)
    } else {
      setIsSignRightStatus(true)
    }
  }, [isSignRight, companyId, isSignRightStatusPortfolioSettings])

  useEffect(() => {
    if (depositId) getAutoInvestStatus(depositId, companyId)
  }, [companyId, depositId, getAutoInvestStatus])

  //Visible all time except auto-sell in progress
  if (isAutoSellActive) {
    return null
  }

  const showAutoInvestStatus = () => {
    const url = browserHistory.location.pathname.split('/')
    /* Fixme logic */
    if (
      (!browserHistory.location.pathname.includes('portfolio') &&
        (url.length === 3 || url.length === 4)) ||
      browserHistory.location.pathname === '/dashboard'
    ) {
      return true
    }
  }

  const submitData = () => {
    if (!isAutoInvestOn) {
      toggleAutoInvest(depositId, companyId)
    } else {
      if (companyId) {
        browserHistory.push(
          `/dashboard/company/${companyId}/investor/fair-credit-portfolio/autoinvest`
        )
      } else {
        browserHistory.push(
          '/dashboard/investor/fair-credit-portfolio/autoinvest'
        )
      }
    }
  }

  return (
    <Row>
      <Indicator isVisible={isFetching} />
      {/* only for companies */}
      {companyId ? (
        isSignRightStatus ? (
          /* only for company dashboard widget */
          showAutoInvestStatus() ? (
            <div
              className={
                isAutoInvestOn
                  ? 'autoinvest badge badge__green'
                  : 'autoinvest badge badge__red'
              }
            >
              <span>{isAutoInvestOn ? t('ACTIVE') : t('INACTIVE')}</span>
            </div>
          ) : (
            <Col className="my-2" md={12}>
              <span>
                {t('AUTO_INVEST')}
                <Popup
                  trigger={
                    <img
                      className="ml-10 pb-6"
                      src={ic_info_icon_black}
                      alt={t('AUTO_INVEST')}
                    />
                  }
                  on="hover"
                  position="bottom left"
                  closeOnDocumentClick
                >
                  {isAutoInvestOn ? (
                    <span className="info-popup">{t('AUTO_INVEST_IS_ON')}</span>
                  ) : (
                    <span className="info-popup">
                      {t('AUTO_INVEST_IS_OFF')}
                    </span>
                  )}
                </Popup>
              </span>
              <CustomInput
                type="switch"
                id="autoinvest"
                name="autoinvest"
                label={isAutoInvestOn ? t('ACTIVE') : t('INACTIVE')}
                checked={isAutoInvestOn}
                className={
                  isAutoInvestOn
                    ? 'autoinvest green-color'
                    : 'autoinvest color-red'
                }
                onChange={submitData}
              />
            </Col>
          )
        ) : showAutoInvestStatus() ? (
          /* only for company dashboard widget and auto invest not available*/
          <div
            className={
              isAutoInvestOn
                ? 'autoinvest badge badge__green'
                : 'autoinvest badge badge__red'
            }
          >
            <span>{isAutoInvestOn ? t('ACTIVE') : t('INACTIVE')}</span>
          </div>
        ) : (
          /* only for company portfolio UI*/
          <>
            <Col className="my-2" md={12}>
              <div>
                {t('AUTO_INVEST')}{' '}
                <span
                  className={
                    isAutoInvestOn
                      ? 'autoinvest green-color'
                      : 'autoinvest color-red'
                  }
                >
                  {isAutoInvestOn ? t('ACTIVE') : t('INACTIVE')}
                </span>
              </div>
              <span>{`${t('AUTO_INVEST_NOT_AVAILABLE')} `}</span>
              <Popup
                trigger={
                  <img
                    className="ml-10"
                    src={ic_info_icon_black}
                    alt={t('AUTO_INVEST')}
                  />
                }
                on="hover"
                position="bottom left"
                closeOnDocumentClick
              >
                <span className="info-popup">
                  {t('NOT_ALLOW_TO_AUTO_INVEST_AND_WITH_DRAW')}
                </span>
              </Popup>
            </Col>
          </>
        )
      ) : showAutoInvestStatus() ? (
        /* only for private investors dashboard widget*/
        <div
          className={
            isAutoInvestOn
              ? 'autoinvest badge badge__green'
              : 'autoinvest badge badge__red'
          }
        >
          <span>{isAutoInvestOn ? t('ACTIVE') : t('INACTIVE')}</span>
        </div>
      ) : (
        /* only for private investors portfolio UI*/
        <Col className="my-2" md={12}>
          <span>
            {t('AUTO_INVEST')}
            <Popup
              trigger={
                <img
                  className="ml-10 pb-6"
                  src={ic_info_icon_black}
                  alt={t('AUTO_INVEST')}
                />
              }
              on="hover"
              position="bottom left"
              closeOnDocumentClick
            >
              {isAutoInvestOn ? (
                <span className="info-popup">{t('AUTO_INVEST_IS_ON')}</span>
              ) : (
                <span className="info-popup">{t('AUTO_INVEST_IS_OFF')}</span>
              )}
            </Popup>
          </span>
          <CustomInput
            type="switch"
            id="autoinvest"
            name="autoinvest"
            label={isAutoInvestOn ? t('ACTIVE') : t('INACTIVE')}
            checked={isAutoInvestOn}
            className={
              isAutoInvestOn ? 'autoinvest green-color' : 'autoinvest color-red'
            }
            onChange={submitData}
          />
        </Col>
      )}
    </Row>
  )
}

interface MappedState {
  isAutoInvestOn: boolean
  isFetching: boolean
  depositId: string
  isSignRight: any
  isAutoSellActive: boolean
}

interface MappedDispatch {
  getAutoInvestStatus: (depositId: string, companyId?: number) => void
  toggleAutoInvest: (depositId: string, companyId?: number) => void
}

const mapStateToProps = (state: State, props: ComponentProps): MappedState => {
  const auto_invest = state.user.investor.auto_invest.companiesAutoInvest
  const url = browserHistory.location.pathname.split('/')

  const companyId = url[3]
  if (url.includes('/dashboard/company')) {
    // @ts-ignore
    return companyId
  }

  const companyIdSelected = props.companyId ? props.companyId : companyId

  if (companyIdSelected) {
    const company = state.user.profile.data.companies.find(
      // @ts-ignore
      (company) => company.id === parseInt(companyIdSelected)
    )
    const data =
      auto_invest &&
      auto_invest.find(
        // @ts-ignore
        (company) => company.company_id === parseInt(companyIdSelected)
      )

    if (company && data) {
      const { is_fetching } = state.user.investor.auto_invest
      return {
        isAutoInvestOn: data.is_active,
        isFetching: is_fetching,
        depositId: company.deposits.length
          ? company.deposits[0].mambu_deposit_id
          : '',
        isSignRight: data.signing_right,
        isAutoSellActive: state.user.investor.dashboard.withdraw.data.active,
      }
    }
  }

  const { data, is_fetching } = state.user.investor.auto_invest
  return {
    isAutoInvestOn: data.is_active,
    isFetching: is_fetching,
    depositId: state.user.profile.data.deposits.length
      ? state.user.profile.data.deposits[0].mambu_deposit_id
      : '',
    isSignRight: data.signing_right,
    isAutoSellActive: state.user.investor.dashboard.withdraw.data.active,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  getAutoInvestStatus: (depositId: string, companyId) =>
    dispatch(getAutoInvestStatus(depositId, companyId)),
  toggleAutoInvest: (depositId: string, companyId) =>
    dispatch(toggleAutoInvestStatus(depositId, companyId)),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(AutoInvest)
