import {
  GET_BORROWER_LOAN_FAILURE,
  GET_BORROWER_LOAN_REQUEST,
  GET_BORROWER_LOAN_SUCCESS,
  ADD_LOAN,
} from './types'
import Loan from '../../../../../models/Borrower/Loan'
import { Company } from 'models/Auth/ProfileData'

export const createGetBorrowerLoanRequestAction = () => ({
  type: GET_BORROWER_LOAN_REQUEST,
})
export const createGetBorrowerLoanSuccessAction = (
  data: Loan,
  loanId: string
) => ({
  data,
  loanId,
  type: GET_BORROWER_LOAN_SUCCESS,
})
export const createGetBorrowerLoanFailureAction = (error: any) => ({
  error,
  type: GET_BORROWER_LOAN_FAILURE,
})

export const createAddLoanAction = (data: Company[]) => ({
  data,
  type: ADD_LOAN,
})
