import React, { useEffect } from 'react'
import { default as PortfolioSummary } from './PortfolioSummary/legacy'
import CustomerService from '../shared/CustomerService'
import StartInvesting from './StartInvesting'
import { default as CollectFunds } from './CollectFunds/legacy'
import { default as Transactions } from './Transactions/legacy'
import { default as WithdrawalSuccessful } from './WithdrawalSuccessful/legacy'
import { State } from '../../store'
import { compose } from 'redux'
import { connect } from 'react-redux'
import useHashLink from 'hooks/useHashLinks'
import { ThunkDispatch } from 'redux-thunk'
import getPersonalDetails from 'store/legacy/user/action/getPersonalDetails'
import { LegacyUser } from 'models/Auth/ProfileData'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router'

interface Props extends MappedState, MappedDispatch {
  isNormalUser: boolean
}
const Legacy = (props: Props) => {
  const {
    areFundsCollected,
    isFetching,
    getPersonalDetails,
    legacyUsers,
  } = props

  useHashLink(isFetching)
  const history = useHistory()
  const { hash } = useLocation()
  const { userId } = useParams()

  useEffect(() => {
    getPersonalDetails()
  }, [getPersonalDetails])

  useEffect(() => {
    if (legacyUsers.length && !userId) {
      history.replace(
        `/restricted/investor/fixed-rate-portfolio/${legacyUsers[0].legacy_user_id}${hash}`
      )
    }
  }, [history, userId, legacyUsers, hash])

  return (
    <div key={userId}>
      <StartInvesting />
      <PortfolioSummary />
      <Transactions />
      {areFundsCollected ? <WithdrawalSuccessful /> : <CollectFunds />}
      <CustomerService />
    </div>
  )
}

interface MappedState {
  areFundsCollected: boolean
  isFetching: boolean
  legacyUsers: LegacyUser[]
}

interface MappedDispatch {
  getPersonalDetails: () => {}
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  getPersonalDetails: () => dispatch(getPersonalDetails()),
})

const mapStateToProps = (state: State): MappedState => {
  const legacyInvestor = state.legacy.investor
  return {
    areFundsCollected: state.legacy.investor.withdraw.is_successful,
    legacyUsers: state.legacy.user.legacy_users,
    isFetching:
      legacyInvestor.kpi.is_fetching ||
      legacyInvestor.transactions.is_fetching ||
      legacyInvestor.withdraw.is_fetching,
  }
}

const enhancer = compose(
  connect<MappedState, MappedDispatch, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Legacy)
