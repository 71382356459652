import { CheckBoxElement } from '../../../../../shared/CheckBoxGroup/Models'

const options: CheckBoxElement[] = [
  {
    id: 'origin_of_funds_is_extra_capital',
    label: 'EXTRA_CAPITAL',
  },
  {
    id: 'origin_of_funds_is_asset_sale',
    label: 'ASSETS_SALE',
  },
  {
    id: 'origin_of_funds_is_acquisition',
    label: 'ACQUISITION',
  },
  {
    id: 'origin_of_funds_is_other',
    label: 'OTHER',
  },
]

export default options
