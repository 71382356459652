import { useLocation } from 'react-router'
import { useEffect, useRef } from 'react'
import { scroll } from '../utils/scroll'

const useHashLink: (isFetching: boolean) => void = (isFetching) => {
  const { hash, pathname } = useLocation()
  const watch = useRef(false)
  const IsScrolled = useRef(false)
  const pathName = useRef(pathname)

  useEffect(() => {
    // check if this is the first time
    if (!IsScrolled.current) {
      if (isFetching) {
        // start to watch when we have isFething equal to true
        watch.current = true
      } else {
        // check if isFething is changed from true to false
        if (watch.current) {
          scroll(hash.substring(1))
          IsScrolled.current = true
          watch.current = false
        }
      }
    }
  }, [hash, isFetching])

  useEffect(() => {
    if (pathName.current !== pathname) {
      pathName.current = pathname
      scroll(hash.substring(1))
      IsScrolled.current = false
    } else {
      scroll(hash.substring(1))
      IsScrolled.current = true
    }
  }, [hash, pathname])
}

export default useHashLink
