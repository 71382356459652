import {
  createPostSignicatCallbackDataFailureAction,
  createPostSignicatCallbackDataRequestAction,
  createPostSignicatCallbackDataSuccessAction,
} from './creators'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import Signicat from '../../../../../api/auth/Signicat'
import CallbackData from '../../../../../models/Auth/Signicat/CallbackData'
import browserHistory from '../../../../../browserHistory'
import { State } from '../../../../index'
import { setToken } from '../../../../../utils/auth'
import { getUserProfile } from '../../../../user/profile/actions/getUserProfile'

// fixme: Im a way to login! if the user already has account.
export default (data: CallbackData): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => State
) => {
  dispatch(createPostSignicatCallbackDataRequestAction())
  try {
    const signicat = new Signicat()
    const response: AxiosResponse = await signicat.postCallbackData(data)
    dispatch(createPostSignicatCallbackDataSuccessAction(response.data.data))

    // user is available in version 3.0 so we will redirect to dashboard
    if (response.data.data.token) {
      setToken(response.data.data.token)

      //only broker
      if (response.data.data.blaId) {
        dispatch(getUserProfile('/dashboard/borrower/loan-application'))
      } else {
        dispatch(getUserProfile('/dashboard'))
      }
      return
    }

    // different registration/onboarding scenarios
    if (
      getState().auth.register.signicat.callback_response.goal === 'investor'
    ) {
      browserHistory.push('/onboarding/investor/provide-details')
    } else if (
      getState().auth.register.signicat.callback_response.is_over_18 === false
    ) {
      browserHistory.push('/register/rejected')
    } else {
      browserHistory.push('/register/provide-details')
    }
  } catch (error) {
    dispatch(createPostSignicatCallbackDataFailureAction(error))
    if (error.response.status === 400) {
      browserHistory.push('/login/bank?error=user-does-not-exists')
    }
    if (error.response.status === 403) {
      // TODO: we need a way to access goal in failed requests
      browserHistory.push(
        '/onboarding/investor/identity-verification?error=sca-auth-failed'
      )
    }
  }
}
