import {
  GET_ONFIDO_RETRY_REDIRECT_LINK_REQUEST,
  GET_ONFIDO_RETRY_REDIRECT_LINK_SUCCESS,
  GET_ONFIDO_RETRY_REDIRECT_LINK_FAILURE,
  GET_ONFIDO_STATUS_REQUEST,
  GET_ONFIDO_STATUS_SUCCESS,
  GET_ONFIDO_STATUS_FAILURE,
} from './types'

export const getOnFidoRetryRedirectUrlRequestAction = () => ({
  type: GET_ONFIDO_RETRY_REDIRECT_LINK_REQUEST,
})
export const getOnFidoRetryRedirectUrlSuccessAction = (data: string) => ({
  data,
  type: GET_ONFIDO_RETRY_REDIRECT_LINK_SUCCESS,
})
export const getOnFidoRetryRedirectUrlFailureAction = (error: any) => ({
  error,
  type: GET_ONFIDO_RETRY_REDIRECT_LINK_FAILURE,
})

export const getOnFidoStatusRequestAction = () => ({
  type: GET_ONFIDO_STATUS_REQUEST,
})
export const getOnFidoStatusSuccessAction = (data: string) => ({
  data,
  type: GET_ONFIDO_STATUS_SUCCESS,
})
export const getOnFidoStatusFailureAction = (error: any) => ({
  error,
  type: GET_ONFIDO_STATUS_FAILURE,
})
