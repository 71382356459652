import API from '../API'
import EmailAndSmsForRegistrationRequest from '../../models/Auth/Sms/EmailAndSmsForRegistrationRequest'
import VerificationCodeRequest from '../../models/Auth/Sms/VerificationCodeRequest'

export default class SmsRegisterAPI extends API {
  public postSmsAndEmailData(data: EmailAndSmsForRegistrationRequest) {
    return this.post('api/auth/register', data)
  }

  public postForeignerSmsAndEmailData(data: EmailAndSmsForRegistrationRequest) {
    return this.post('api/auth/onboarding/investor/foreign', data)
  }

  public postVerificationCode(data: VerificationCodeRequest) {
    return this.post('api/auth/verify', data)
  }

  public postForeignerVerificationCode(data: VerificationCodeRequest) {
    return this.post('api/auth/onboarding/investor/foreign/verify', data)
  }
}
