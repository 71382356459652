import {
  LEGACY_INVOICE_FAILURE,
  LEGACY_INVOICE_REQUEST,
  LEGACY_INVOICE_SUCCESS,
  LEGACY_LOAN_FAILURE,
  LEGACY_LOAN_REQUEST,
  LEGACY_LOAN_SUCCESS,
} from './actions/types'
import { Loan } from '../../../../models/Legacy/Borrower/Loan'

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LEGACY_INVOICE_REQUEST:
      return { ...state, is_fetching: true }
    case LEGACY_INVOICE_SUCCESS:
      return { ...state, is_fetching: false, error: null }
    case LEGACY_INVOICE_FAILURE:
      return { ...state, is_fetching: false, error: action.error }
    case LEGACY_LOAN_REQUEST:
      return { ...initialState, is_fetching: true }
    case LEGACY_LOAN_SUCCESS:
      return {
        ...initialState,
        data: action.data,
      }
    case LEGACY_LOAN_FAILURE:
      return { ...initialState, error: action.error }
    default:
      return state
  }
}

export interface State {
  is_fetching: boolean
  data: Loan[]
  error: any
}

export const initialState: State = {
  is_fetching: false,
  data: [],
  error: null,
}
