import React from 'react'

const CCISConsent: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared t-and-c__v02">
      <p>
        Consumer Credit Inquiry System is a service offered by Suomen
        Asiakastieto Oy, by means of which credit grantors having made a mutual
        agreement disclose to each other information they have on a consumer on
        the basis of an assignment and consent from the consumer (credit
        applicant) for the purpose of processing a credit application. The
        information processed in and conveyed from the system concerns credits
        and their management, and it is not disclosed to outsiders without an
        obligation pertaining to law.
      </p>
      <p>
        If credit is granted, the information is stored for five years from the
        date when the credit has been due in total, unless there is an
        obligation prescribed in the law, which would require the information to
        be stored for a longer period. If no credit is granted, the information
        is deleted in its entirety after two years from the making of the credit
        application.
      </p>
      <p>
        The credit applicant has the right to inspect which information has been
        disclosed through the Consumer Credit Inquiry System, and to which
        parties. The inspection right is executed by Suomen Asiakastieto Oy.
      </p>
      <p>
        Additional information on the Consumer Credit Inquiry System maintained
        by Suomen Asiakastieto Oy and the companies in it:
        <a href="https://www.asiakastieto.fi/web/fi/positiivinen-tieto/positive-credit-information-in-consumer-credit-inquiry-system.html">
          &nbsp;asiakastieto.fi
        </a>
      </p>
      <p></p>
    </div>
  )
}

export default CCISConsent
