import { compose } from 'redux'
import { connect } from 'react-redux'
import Presentation from './Presentation'
import { State } from '../../../store'
import { withTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel from './MappedDispatch.model'
import React from 'react'

const mapStateToProps = (state: State): MappedStateModel => ({
  referenceNumber: state.user.investor.dashboard.kpi.data.reference_number,
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, MappedDispatchModel, any, any>(mapStateToProps)
)

export default enhancer(Presentation)
