import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import CompanyInvestmentExperience from './CompanyInvestmentExperience'
import CompanyFinancialSituation from './CompanyFinancialSituation'
import GoalOfInvestment from './GoalOfInvestment'
import BeneficialOwners from './BeneficialOwners'
import Steps from '../../../shared/Steps'
import { compose } from 'redux'
import FixuraInput from '../../../shared/FixuraInput'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../../store'
import postCompanyAml from '../../../../store/user/investor/companyAml/actions/postCompanyAml'
import { scroll, ScrollElement } from '../../../../utils/scroll'
import Indicator from '../../../shared/Indicator'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface Props extends MappedState, MappedDispatch {}

const CompanyAml = (props: Props) => {
  const [fields, setFields] = useState({})
  const [errors, setErrors] = useState({})
  const { t } = useTranslation()
  const { companyId } = useParams()

  const localHandleChange = (
    field: string,
    e: React.FormEvent<HTMLInputElement>
  ) => handleChange(field, fields, setFields, e)
  const { postCompanyAmlData, is_fetching } = props

  const submitData = () => {
    if (fields['IBAN']) {
      fields['IBAN'] = fields['IBAN'].split(' ').join('')
    }

    if (handleValidation(fields, setErrors, t)) {
      postCompanyAmlData(fields, companyId)
    }
  }

  return (
    <Container className="aml__wrapper">
      <Row className="step-top-pad">
        <Indicator isVisible={is_fetching} />
        <Steps
          stepIndex={3}
          steps={[
            t('IDENTITY_VERIFICATION'),
            t('INVESTOR_ON_BOARDING_STEP_PHONE'),
            t('INVESTOR_ON_BOARDING_STEP_AML'),
          ]}
        />
      </Row>
      <Row>
        <Col className="text-center">
          <h1 className="font-50">{t('JUST_FEW_QUESTIONS')}</h1>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col className="text-center">
          <ScrollElement name="IBAN" />
          <div className="font-20-body">{t('PROVIDE_BANK_ACCOUNT_NUMBER')}</div>
        </Col>
      </Row>
      <Row className="input-distance">
        <Col md="7" className="m-auto">
          <FixuraInput
            name="IBAN"
            className="input-characteristics"
            placeholder={t('BANK_ACCOUNT_NUMBER_(IBAN)')}
            onChange={localHandleChange.bind(undefined, 'IBAN')}
            errorMessage={errors['IBAN']}
            hasError={errors.hasOwnProperty('IBAN')}
          />
        </Col>
      </Row>
      <Row>
        <CompanyInvestmentExperience
          setFields={setFields}
          fields={fields}
          errors={errors}
        />
        <CompanyFinancialSituation
          setFields={setFields}
          fields={fields}
          errors={errors}
        />
        <GoalOfInvestment
          setFields={setFields}
          fields={fields}
          errors={errors}
        />
        <BeneficialOwners
          setFields={setFields}
          fields={fields}
          errors={errors}
        />
      </Row>
      <Row>
        <Col className="text-center">
          <div className="font-20-body">{t('THANKS_FOR_INFO')}</div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <div className="font-20-body">
            {t('CLICK_TO_SIGN_INVESTMENT_CONTRACT')}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Button className="continue-distance" onClick={submitData}>
            {t('CONTINUE_TO_AGREEMENT')}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

function handleChange(
  field,
  fields,
  setFields,
  e: React.FormEvent<HTMLInputElement>
) {
  fields[field] = e.currentTarget.value
  setFields(fields)
}

function handleValidation(fields, setErrors, t) {
  const errors = {}
  let formIsValid = true

  if (!fields['IBAN']) {
    scroll('IBAN')
    formIsValid = false
    errors['IBAN'] = t('CANNOT_BE_EMPTY')
  }

  if (
    isEmptyOrFalse(fields, 'is_familiar_with_publicly_traded_stocks') &&
    isEmptyOrFalse(fields, 'is_familiar_with_bonds') &&
    isEmptyOrFalse(fields, 'is_familiar_with_capital_markets') &&
    isEmptyOrFalse(fields, 'is_familiar_with_derivatives') &&
    isEmptyOrFalse(fields, 'is_familiar_with_investment_funds') &&
    isEmptyOrFalse(fields, 'has_no_experience_in_investment_vehicles')
  ) {
    if (isEmpty(fields, 'investment_funds_specification')) {
      if (formIsValid) {
        scroll('investment_funds_specification')
      }
      formIsValid = false
      errors['investment_funds_specification'] = t(
        'AT_LEAST_ONE_CHECKBOX_MUST_BE_CHECKED'
      )
    }
  }

  if (
    isEmptyOrFalse(fields, 'is_familiar_with_investments_on_my_own') &&
    isEmptyOrFalse(fields, 'is_familiar_with_investment_advice') &&
    isEmptyOrFalse(fields, 'is_familiar_with_asset_management') &&
    isEmptyOrFalse(fields, 'is_familiar_with_electronic_trading') &&
    isEmptyOrFalse(fields, 'is_familiar_with_insurance_investments')
  ) {
    if (isEmpty(fields, 'insurance_investments_specification')) {
      if (formIsValid) {
        scroll('insurance_investments_specification')
      }
      formIsValid = false
      errors['insurance_investments_specification'] = t(
        'AT_LEAST_ONE_CHECKBOX_MUST_BE_CHECKED'
      )
    }
  }

  if (isEmpty(fields, 'business_sector')) {
    if (formIsValid) {
      scroll('business_sector')
    }
    formIsValid = false
    errors['business_sector'] = t('CANNOT_BE_EMPTY')
  }

  if (isEmpty(fields, 'turnover')) {
    if (formIsValid) {
      scroll('turnover')
    }
    formIsValid = false
    errors['turnover'] = t('CANNOT_BE_EMPTY')
  }

  if (isEmpty(fields, 'profit')) {
    if (formIsValid) {
      scroll('profit')
    }
    formIsValid = false
    errors['profit'] = t('CANNOT_BE_EMPTY')
  }

  if (isEmpty(fields, 'balance_sheet_total')) {
    if (formIsValid) {
      scroll('balance_sheet_total')
    }
    formIsValid = false
    errors['balance_sheet_total'] = t('CANNOT_BE_EMPTY')
  }

  if (
    isEmptyOrFalse(fields, 'origin_of_funds_is_extra_capital') &&
    isEmptyOrFalse(fields, 'origin_of_funds_is_asset_sale') &&
    isEmptyOrFalse(fields, 'origin_of_funds_is_acquisition') &&
    isEmptyOrFalse(fields, 'origin_of_funds_is_other')
  ) {
    if (formIsValid) {
      scroll('investment_capital_origin')
    }
    formIsValid = false
    errors['investment_capital_origin'] = t(
      'AT_LEAST_ONE_CHECKBOX_MUST_BE_CHECKED'
    )
  }

  if (
    isEmptyOrFalse(fields, 'goal_of_investment_is_generate_returns') &&
    isEmptyOrFalse(fields, 'goal_of_investment_is_savings') &&
    isEmptyOrFalse(fields, 'goal_of_investment_is_other')
  ) {
    if (formIsValid) {
      scroll('purpose_of_investment')
    }
    formIsValid = false
    errors['purpose_of_investment'] = t('AT_LEAST_ONE_CHECKBOX_MUST_BE_CHECKED')
  }

  if (
    fields.hasOwnProperty('is_familiar_with_insurance_investments') &&
    fields['is_familiar_with_insurance_investments']
  ) {
    if (isEmpty(fields, 'insurance_investments_specification')) {
      if (formIsValid) {
        scroll('insurance_investments_specification')
      }
      formIsValid = false
      errors['insurance_investments_specification'] = t('CANNOT_BE_EMPTY')
    }
  }

  if (
    fields.hasOwnProperty('is_familiar_with_investment_funds') &&
    fields['is_familiar_with_investment_funds']
  ) {
    if (isEmpty(fields, 'investment_funds_specification')) {
      if (formIsValid) {
        scroll('investment_funds_specification')
      }
      formIsValid = false
      errors['investment_funds_specification'] = t('CANNOT_BE_EMPTY')
    }
  }

  if (
    fields.hasOwnProperty('origin_of_funds_is_other') &&
    fields['origin_of_funds_is_other']
  ) {
    if (isEmpty(fields, 'origin_of_funds_specification')) {
      if (formIsValid) {
        scroll('origin_of_funds_specification')
      }
      formIsValid = false
      errors['origin_of_funds_specification'] = t('CANNOT_BE_EMPTY')
    }
  }

  if (
    fields.hasOwnProperty('goal_of_investment_is_other') &&
    fields['goal_of_investment_is_other']
  ) {
    if (isEmpty(fields, 'goal_of_investment_specification')) {
      if (formIsValid) {
        scroll('goal_of_investment_specification')
      }
      formIsValid = false
      errors['goal_of_investment_specification'] = t('CANNOT_BE_EMPTY')
    }
  }

  if (isEmpty(fields, 'annual_investment_count')) {
    if (formIsValid) {
      scroll('annual_investment_count')
    }
    formIsValid = false
    errors['annual_investment_count'] = t('CANNOT_BE_EMPTY')
  }

  if (isEmpty(fields, 'annual_investment_amount')) {
    if (formIsValid) {
      scroll('annual_investment_amount')
    }
    formIsValid = false
    errors['annual_investment_amount'] = t('CANNOT_BE_EMPTY')
  }

  if (isEmpty(fields, 'risk_profile')) {
    if (formIsValid) {
      scroll('risk_profile')
    }
    formIsValid = false
    errors['risk_profile'] = t('CANNOT_BE_EMPTY')
  }

  if (
    !fields.hasOwnProperty('is_beneficial_owner') ||
    fields['is_beneficial_owner'] === null
  ) {
    formIsValid = false
    errors['is_beneficial_owner'] = t('CANNOT_BE_EMPTY')
  }

  setErrors(errors)

  return formIsValid
}

const isEmpty = (fields, field) =>
  !fields.hasOwnProperty(field) ||
  fields[field] === '' ||
  fields[field] === null
const isEmptyOrFalse = (fields, field) =>
  isEmpty(fields, field) || !fields[field]

interface MappedState {
  is_fetching: boolean
  error: any
}

interface MappedDispatch {
  postCompanyAmlData: (data, companyId) => void
}

const mapStateToProps = (state: State): MappedState => ({
  is_fetching: state.user.investor.aml.is_fetching,
  error: state.user.investor.aml.error,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  postCompanyAmlData: (data, companyId) =>
    dispatch(postCompanyAml(data, companyId)),
})

const enhancer = compose(
  connect<MappedState, MappedDispatch, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(CompanyAml)
