import {
  createPostEmailAndSMSFailureAction,
  createPostEmailAndSMSRequestAction,
  createPostEmailAndSMSSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import SmsRegisterAPI from '../../../../../api/auth/SmsRegisterAPI'
import { State } from '../../../../index'
import EmailAndSmsForRegistrationRequest from '../../../../../models/Auth/Sms/EmailAndSmsForRegistrationRequest'
import { getLegacyData } from 'utils/auth'

export default (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const { request_body } = getState().auth.register.sms
  const { state } = getState().auth.register.signicat.callback_response
  const { language } = getState().user.profile.data
  const isForeigner = getLegacyData().is_foreigner
  if (request_body && (state || isForeigner)) {
    const body: EmailAndSmsForRegistrationRequest = {
      email: request_body.email,
      mobile_phone: request_body.mobile_phone,
      is_checked_offers: request_body.is_checked_offers,
      state,
      language,
    }

    dispatch(createPostEmailAndSMSRequestAction(body))
    try {
      const smsApi = new SmsRegisterAPI()
      if (isForeigner) {
        await smsApi.postForeignerSmsAndEmailData(body)
      } else {
        await smsApi.postSmsAndEmailData(body)
      }
      dispatch(createPostEmailAndSMSSuccessAction())
    } catch (error) {
      dispatch(createPostEmailAndSMSFailureAction(error))
    }
  }
}
