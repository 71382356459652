import React from 'react'
import { Button } from 'reactstrap'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'

const options = [
  {
    label: 'ALL',
    value: undefined,
  },
  {
    label: 'WITHDRAWALS',
    value: 'WITHDRAWAL',
  },
  {
    label: 'INVESTMENTS',
    value: 'INVESTMENTS',
  },
  {
    label: 'ADMINISTRATION_FEE',
    value: 'ADMINISTRATION_FEE',
  },
  {
    label: 'INTERESTS',
    value: 'INTEREST_PAID',
  },
  {
    label: 'REPAID_CAPITAL',
    value: 'REPAID_CAPITAL',
  },
  {
    label: 'DEPOSITS',
    value: 'DEPOSIT',
  },
  {
    label: 'AUTOSELL_TRANSACTION',
    value: 'AUTOSELL_TRANSACTION',
  },
  {
    label: 'CREDIT_LOSS',
    value: 'CREDIT_LOSS',
  },
]

interface ComponentProps {
  selectedOption?: string
  onClick: (value?: string) => void
}

interface Props extends WithTranslation, ComponentProps {}

const Types = (props: Props) => {
  const { t, selectedOption, onClick } = props
  return (
    <>
      {options.map((option) => (
        <Button
          key={option.label}
          onClick={() => onClick(option.value)}
          className={
            option.value === selectedOption
              ? 'transactions-buttons'
              : 'light-grey transactions-buttons'
          }
        >
          {t(option.label)}
        </Button>
      ))}
    </>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(Types)
