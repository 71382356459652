import BackButton from '../../shared/BackButton'
import React from 'react'
import ActiveLoan from './ActiveLoan'
import Banks from './Banks'

const LoanApplication = () => {
  return (
    <>
      <BackButton />
      <ActiveLoan />
      <Banks />
    </>
  )
}

export default LoanApplication
