import {
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
} from './types'

export const createGetContractInvoiceRequestAction = () => ({
  type: GET_DOCUMENT_REQUEST,
})
export const createGetContractInvoiceSuccessAction = () => ({
  type: GET_DOCUMENT_SUCCESS,
})
export const createGetContractInvoiceFailureAction = (error: any) => ({
  error,
  type: GET_DOCUMENT_FAILURE,
})
