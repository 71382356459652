import React from 'react'
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import browserHistory from '../../../../browserHistory'
import { WithTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel from './MappedDispatch.model'

interface Props
  extends WithTranslation,
    MappedStateModel,
    MappedDispatchModel {}

const Presentation = (props: Props) => {
  const { t, depositId, toggleAutoInvest } = props

  const url = browserHistory.location.pathname.split('/')

  const companyId = url.includes('company') ? url[3] : ''
  const submitData = () => {
    // @ts-ignore
    toggleAutoInvest(depositId, companyId)
    browserHistory.goBack()
  }

  return (
    <Container>
      <Row>
        <Col xs="12">
          <Modal
            className="tc-modal"
            isOpen={true}
            toggle={browserHistory.goBack}
          >
            <ModalHeader className="p-0" toggle={browserHistory.goBack} />
            <ModalHeader className="center p-0">{t('AUTO_INVEST')}</ModalHeader>
            <ModalBody className="modal_modal-body_height">
              <div>
                <strong className="confirm-dialog-body-text">
                  {t('ARE_YOU_SURE_YOU_WANT_TO_TURN_OFF_AUTO_INVEST')}?
                </strong>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="min-w-10" onClick={submitData}>
                {t('YES')}
              </Button>
              <Button
                className="mt-2 link-bg-white"
                onClick={browserHistory.goBack}
              >
                {t('CLOSE')}
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </Container>
  )
}

export default Presentation
