import { connect } from 'react-redux'
import { State } from '../../store'
import PrivateRoutePresentation from './PrivateRoutePresentation'

export default connect((state: State) => {
  const {
    should_agree_with_new_terms_and_conditions,
  } = state.auth.register.terms_and_conditions

  return {
    isAuthorized: !should_agree_with_new_terms_and_conditions,
    pathname: '/terms-and-conditions',
  }
})(PrivateRoutePresentation)
