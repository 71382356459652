import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { setInvestorAffiliateTrackingCode } from './creators'
import { affiliateInvestorTrackingCode } from '../../../../utils/affiliate/investor/affiliateTracking'

/* TODO Unit Test */
export const getInvestorAffiliate = (): ThunkAction<
  any,
  any,
  any,
  any
> => async (dispatch: Dispatch) => {
  const data = affiliateInvestorTrackingCode()
  const investor_unique_code = data?.unique_code
  const investor_affiliate_name = data?.utmCampaign
  dispatch(
    setInvestorAffiliateTrackingCode(
      investor_unique_code,
      investor_affiliate_name
    )
  )
}
