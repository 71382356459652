import { compose } from 'redux'
import { connect } from 'react-redux'
import Presentation from './Presentation'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../store'
import { withTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel from './MappedDispatch.model'
import { createStoreWithdrawAmountAction } from '../../../store/legacy/investor/withdraw/actions/creators'
import { withRouter } from 'react-router'
import postSignCompanyRight from '../../../store/user/investor/auto_invest/actions/postSignCompanyRight'
import cancelWithdraw from '../../../store/user/investor/dashboard/withdraw/actions/cancelWithdraw'
import getWithdrawStatus from '../../../store/user/investor/dashboard/withdraw/actions/getWithdrawStatus'

const mapStateToProps = (state: State): MappedStateModel => ({
  data: state.legacy.investor.kpi.data,
  is_fetching: state.legacy.investor.withdraw.is_fetching,
  error: state.legacy.investor.withdraw.error,
  isHidden:
    state.user.profile.data.deposits.length > 0 &&
    state.user.profile.data.deposits[0].has_signed_contract,
  depositId:
    state.user.profile.data.deposits.length > 0
      ? state.user.profile.data.deposits[0].mambu_deposit_id
      : '',
  status: state.legacy.investor.withdraw.data,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  withdraw: (amount: number) =>
    dispatch(createStoreWithdrawAmountAction(amount)),
  cancelWithdraw: (depositId: string) => dispatch(cancelWithdraw(depositId)),
  postSignCompany: (code: string, companyId: number) =>
    dispatch(postSignCompanyRight(code, companyId)),
  getWithdrawStatus: (depositId: string) =>
    dispatch(getWithdrawStatus(depositId)),
})

const enhancer = compose<React.FunctionComponent>(
  withRouter,
  withTranslation(),
  connect<MappedStateModel, MappedDispatchModel, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Presentation)
