import {
  POST_FOREIGN_INVESTOR_PROVIDE_DETAILS_FAILURE,
  POST_FOREIGN_INVESTOR_PROVIDE_DETAILS_REQUEST,
  POST_FOREIGN_INVESTOR_PROVIDE_DETAILS_SUCCESS,
} from './actions/types'
import PostForeignInvestorProvideDetails from '../../../../models/Auth/ForeignInvestorProfileData'

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_FOREIGN_INVESTOR_PROVIDE_DETAILS_REQUEST:
      return {
        ...state,
        is_fetching: true,
        request_body: action.requestBody,
        error: null,
      }
    case POST_FOREIGN_INVESTOR_PROVIDE_DETAILS_SUCCESS:
      return { ...state, is_fetching: false, error: null, state: action.state }
    case POST_FOREIGN_INVESTOR_PROVIDE_DETAILS_FAILURE:
      return { ...state, is_fetching: false, error: action.error }
    default:
      return state
  }
}

export const initialState: State = {
  request_body: undefined,
  is_fetching: false,
  error: null,
}

export interface State {
  request_body?: PostForeignInvestorProvideDetails
  is_fetching: boolean
  error: any
  state?: string
}
