import * as React from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import Olark from './Olark/Olark'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import mail_icon from 'assets/images/mail_icon.png'
import chat_icon from 'assets/images/chat_icon.png'
import helpdesk_icon from 'assets/images/helpdesk_icon.png'
import { useLocation } from 'react-router'

interface Props extends WithTranslation {}

const CustomerService = (props: Props) => {
  const { t } = props
  const location = useLocation()

  const isInvestor =
    location.pathname.includes('investor') ||
    location.pathname.includes('company')

  const isBorrower =
    location.pathname.includes('/dashboard/borrower') &&
    !location.pathname.includes('loan')

  return (
    <div
      className={
        isInvestor || isBorrower
          ? 'dashboard-customer-service'
          : 'customer-service'
      }
    >
      <Container>
        <Row
          className={isInvestor || isBorrower ? 'customer-service-header' : ''}
        >
          <Col>
            <h3 className="text-center top-title">{t('CONTACT_US')}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="text-center">{t('CUSTOMER_SERVICE')}</h1>
          </Col>
        </Row>
        <Row
          className={isInvestor || isBorrower ? 'customer-service-body' : ''}
        >
          <Col lg={{ size: 4 }} sm={{ size: 12 }} className="text-center">
            <img src={mail_icon} className="service-img" alt="fixura email" />
            <h2 className="service-title">info@fixura.com</h2>
            <a href="mailto:info@fixura.com">
              <Button className="link-bg-white">{t('SEND_US_EMAIL')}</Button>
            </a>
          </Col>
          <Col lg={{ size: 4 }} sm={{ size: 12 }} className="text-center">
            <img src={chat_icon} className="service-img" alt="fixura chat" />
            <h2 className="service-title">{t('CHAT')}</h2>
            <Olark />
          </Col>
          <Col lg={{ size: 4 }} sm={{ size: 12 }} className="text-center">
            <img
              src={helpdesk_icon}
              className="service-img"
              alt="fixura phone number"
            />
            {isInvestor ? (
              <>
                <h2 className="service-title">+358 6 361 2112</h2>
                <a href="tel:+358 6 361 2112">
                  <Button className="link-bg-white">{t('MAKE_A_CALL')}</Button>
                </a>
              </>
            ) : (
              <>
                <h2 className="service-title">+358 6 361 2111</h2>
                <a href="tel:+358 6 361 2111">
                  <Button className="link-bg-white">{t('MAKE_A_CALL')}</Button>
                </a>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const enhancer = compose<React.FunctionComponent>(withTranslation())

export default enhancer(CustomerService)
