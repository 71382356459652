import React, { useEffect } from 'react'
import { Col, Container, Row, Label } from 'reactstrap'
import Origin from './Origin'
import ProfessionalSituation from './ProfessionalSituation'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import FixuraInput from '../../../../shared/FixuraInput'
import { ScrollElement } from '../../../../../utils/scroll'

interface ComponentProps {
  setFields: (fields) => void
  fields: any
  errors: any
}

interface Props extends WithTranslation, ComponentProps {}

let hideFieldOtherFinSituation = true
let hideFieldOtherOriginAsset = true

const FinancialSituation = (props: Props) => {
  const { t, setFields, fields, errors } = props
  const setHideFieldFinSituation = (value) =>
    (hideFieldOtherFinSituation = value)
  const setHideFieldOtherOriginAsset = (value) =>
    (hideFieldOtherOriginAsset = value)

  const handleChange = (
    fieldName: string,
    e: React.FormEvent<HTMLInputElement>
  ) => {
    passFields(fieldName, e.currentTarget.value)
  }
  const passFields = (fieldName: string, value: string) => {
    const newFields = { ...fields }
    newFields[fieldName] = value
    setFields(newFields)
  }

  useEffect(() => {
    hideFieldOtherFinSituation = !fields['has_other_financial_situation']
    /*  TODO: fixme
       hideFieldOtherOriginAsset = !fields[
      'origin_of_invested_assets_is_inheritance'
    ]*/
  }, [fields])

  return (
    <Container className="aml__wrapper">
      <Row className="mt-5 mb-5">
        <Col className="text-center" lg={{ size: 8, offset: 2 }}>
          <ScrollElement name="profession" />
          <div className="font-28">{t('FINANCIAL_SITUATION')}</div>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 8, offset: 2 }}>
          <ProfessionalSituation
            setFields={setFields}
            fields={fields}
            errors={errors}
            hideField={setHideFieldFinSituation}
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md="7" className="mb-4" lg={{ size: 8, offset: 2 }}>
          <FixuraInput
            className={
              hideFieldOtherFinSituation
                ? 'hide-field'
                : 'input-characteristics'
            }
            placeholder={t('FINANCIAL_SITUATION')}
            onChange={handleChange.bind(undefined, 'financial_situation')}
            hasError={errors.hasOwnProperty('financial_situation')}
            errorMessage={errors['financial_situation']}
            defaultValue={fields['financial_situation']}
          />
        </Col>
        <Col md="7" className="mb-4" lg={{ size: 8, offset: 2 }}>
          {fields['profession'] && <Label>{t('PROFESSION')}</Label>}
          <FixuraInput
            className="input-characteristics"
            placeholder={t('PROFESSION')}
            onChange={handleChange.bind(undefined, 'profession')}
            hasError={errors.hasOwnProperty('profession')}
            errorMessage={errors['profession']}
            defaultValue={fields['profession']}
          />
        </Col>
        <Col md="7" className="mb-4" lg={{ size: 8, offset: 2 }}>
          <ScrollElement name="monthly_gross_income_amount" />
          {fields['monthly_gross_income_amount'] && (
            <Label>{t('RECURRING_INCOME')}</Label>
          )}
          <FixuraInput
            className="input-characteristics"
            placeholder={t('RECURRING_INCOME')}
            type="number"
            onChange={handleChange.bind(
              undefined,
              'monthly_gross_income_amount'
            )}
            hasError={errors.hasOwnProperty('monthly_gross_income_amount')}
            errorMessage={errors['monthly_gross_income_amount']}
            defaultValue={fields['monthly_gross_income_amount']}
          />
        </Col>
        <ScrollElement name="total_assets_amount" />
        <Col md="7" className="mb-4" lg={{ size: 8, offset: 2 }}>
          {fields['total_assets_amount'] && <Label>{t('TOTAL_ASSETS')}</Label>}
          <FixuraInput
            className="input-characteristics"
            placeholder={t('TOTAL_ASSETS')}
            type="number"
            onChange={handleChange.bind(undefined, 'total_assets_amount')}
            hasError={errors.hasOwnProperty('total_assets_amount')}
            errorMessage={errors['total_assets_amount']}
            defaultValue={fields['total_assets_amount']}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 8, offset: 2 }}>
          <ScrollElement name="origin_of_invested_assets_specification" />
          <Origin
            setFields={setFields}
            fields={fields}
            errors={errors}
            hideField={setHideFieldOtherOriginAsset}
          />
        </Col>
      </Row>
      <Row className="big-distance-bot">
        <Col md="7" lg={{ size: 8, offset: 2 }}>
          <FixuraInput
            className={
              hideFieldOtherOriginAsset ? 'hide-field' : 'input-characteristics'
            }
            placeholder={t('FROM_WHERE')}
            onChange={handleChange.bind(
              undefined,
              'origin_of_invested_assets_specification'
            )}
            hasError={errors.hasOwnProperty(
              'origin_of_invested_assets_specification'
            )}
            errorMessage={errors['origin_of_invested_assets_specification']}
            defaultValue={fields['origin_of_invested_assets_specification']}
          />
        </Col>
      </Row>
    </Container>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(FinancialSituation)
