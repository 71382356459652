import queryString from 'query-string'
import { affiliateInvestorUtils } from '../affiliateSourceUtils'

// affiliate tracking get using redirect_path
export const affiliateTracking = () => {
  const url = localStorage.redirect_path_after_login || ''
  const qs = queryString.parse(url.split('?')[1]) || ''
  if (localStorage && url.includes('/onboarding/investor')) {
    return qs
  }
}

// unique code
export const affiliateInvestorTrackingCode = () => {
  const data = affiliateTracking()
  if (data) {
    const utm = {
      //default name adtraction checkinng at_gd
      utmCampaign: data.at_gd
        ? affiliateInvestorUtils(data.at_gd)
        : affiliateInvestorUtils(data.utm_campaign),
      utmSource: data.utm_source,
      utmMedium: data.utm_medium,
      unique_code: data.at_gd ? data.at_gd : data.r,
    }
    return utm
  }
}
