import React, { useEffect, useState } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { WithTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel from './MappedDispatch.model'
import VersionSwitch from '../VersionSwitch'
import CompanySwitch from '../CompanySwitch'
import { isLegacy } from 'utils/auth'
import browserHistory from 'browserHistory'
import Dates from '../../../models/RangeDatepicker/Dates'
import dayjs from 'dayjs'
import { useLocation } from 'react-router'
import { FINNISH_NATIONALITY } from '../../../constants'
import WalkThruInvestor from '../../../utils/walkThru/walkThruInvestor'
import Indicator from '../../shared/Indicator'
interface Props
  extends WithTranslation,
    MappedStateModel,
    MappedDispatchModel {}

const Presentation = (props: Props) => {
  const {
    t,
    isOnFidoUser,
    nationality,
    depositId,
    getInvestorPortfolio,
    startDate,
    numberOfDepositAccounts,
    companies,
    isFetching,
  } = props

  const [date] = useState<Dates>({
    startDate,
    endDate: dayjs(),
  })
  const location = useLocation()
  const isCompany = location && location.pathname.includes('company')
  const isDashboard = browserHistory.location.pathname === '/dashboard'

  useEffect(
    () => {
      if (depositId && numberOfDepositAccounts) {
        getInvestorPortfolio({ date }, depositId)
      }
    },
    // eslint-disable-next-line
    [numberOfDepositAccounts]
  )

  //for foreigner investor's
  const isCompanyExist = companies && companies.length > 0

  return (
    <div>
      <Indicator isVisible={isFetching} />
      <WalkThruInvestor title="walk-thru-investor" showToast={true} />
      <div
        className={
          isCompany || nationality !== FINNISH_NATIONALITY
            ? 'company-portfolio-list'
            : 'portfolio-list'
        }
      >
        <>
          {!isCompany && <VersionSwitch />}
          {(nationality === FINNISH_NATIONALITY || isCompanyExist) &&
            !isLegacy() &&
            (isOnFidoUser === 0 || isCompanyExist) &&
            isCompany && <CompanySwitch />}
        </>
      </div>
      {nationality === FINNISH_NATIONALITY &&
        !isLegacy() &&
        isOnFidoUser === 0 &&
        !isCompany &&
        !isDashboard && (
          <div className="fixura_loans mt-20 hide">
            <div className="text-center">
              <h1 className="pt-45">{t('FIXURA_LOANS')}</h1>
              <p className="p-10">{t('FIXURA_LOANS_DETAILS')}</p>
              <Row className="row-w-90">
                <Col className="text-center take-loan__btn">
                  <Button
                    onClick={() =>
                      browserHistory.push(
                        '/dashboard/borrower/loan-application'
                      )
                    }
                  >
                    {t('APPLY_FOR_A_LOAN')}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        )}
    </div>
  )
}

export default Presentation
