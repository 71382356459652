import {
  POST_AML_REQUEST,
  POST_AML_SUCCESS,
  POST_AML_FAILURE,
  GET_AML_REQUEST,
  GET_AML_SUCCESS,
  GET_AML_FAILURE,
} from './types'

export const createAmlRequestAction = () => ({ type: POST_AML_REQUEST })
export const createAmlSuccessAction = () => ({ type: POST_AML_SUCCESS })
export const createAmlFailureAction = (error: any) => ({
  error,
  type: POST_AML_FAILURE,
})

export const getAmlRequestAction = () => ({ type: GET_AML_REQUEST })
export const getAmlSuccessAction = (data: any) => ({
  data,
  type: GET_AML_SUCCESS,
})
export const getAmlFailureAction = (error: any) => ({
  error,
  type: GET_AML_FAILURE,
})
