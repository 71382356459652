import {
  createLoginWithSmsFailureAction,
  createLoginWithSmsRequestAction,
  createLoginWithSmsSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import SmsLoginAPI from '../../../../../api/auth/SmsLoginAPI'
import { State } from '../../../../index'
import { SendMobilePhoneToAuthenticateViaSmsData } from '../../../../../models/Auth/Sms/SendMobilePhoneToAuthenticateViaSmsRequest'

export default (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const body: SendMobilePhoneToAuthenticateViaSmsData = {
    mobile_phone: getState().auth.login.sms.mobile_phone,
    blaId: getState().broker.loanApplication.blaId,
  }
  dispatch(createLoginWithSmsRequestAction())
  try {
    const smsApi = new SmsLoginAPI()
    await smsApi.sendMobilePhoneToAuthenticateViaSms(body)
    dispatch(createLoginWithSmsSuccessAction(body.mobile_phone))
  } catch (error) {
    dispatch(createLoginWithSmsFailureAction(error))
  }
}
