import { compose } from 'redux'
import { connect } from 'react-redux'
import Presentation from './Presentation'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../store'
import { withTranslation } from 'react-i18next'
import getInvestorKpi from '../../../store/user/investor/dashboard/kpi/actions/getInvestorKpi'
import fetchPortfolio from '../../../store/legacy/investor/kpi/actions/fetchPortfolio'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel, { PortfolioData } from './MappedDispatch.model'
import dayjs from 'dayjs'
import browserHistory from 'browserHistory'
import { Company } from 'models/Auth/ProfileData'

const mapStateToProps = (state: State, props): MappedStateModel => {
  const companies = state.user.profile.data.companies
  const companyId = browserHistory.location.pathname.startsWith(
    '/dashboard/company'
  )
    ? browserHistory.location.pathname.split('/')[3]
    : undefined

  const company: Company | undefined = companyId
    ? companies.find((company) => company.id === parseInt(companyId))
    : undefined
  const { data } = state.user.investor.dashboard.kpi
  const deposits = companyId
    ? company?.deposits || []
    : state.user.profile.data.deposits
  const { is_fetching } = state.user.investor.dashboard.kpi

  return {
    firstName: state.user.profile.data.first_name,
    isOnFidoUser: state.user.profile.data.is_foreigner,
    nationality: state.user.profile.data.nationality,
    startDate:
      deposits.length > 0
        ? dayjs(deposits[0].created_at, 'YYYY-MM-DD HH-mm-ss').subtract(
            1,
            'day'
          )
        : dayjs().subtract(2, 'month'),
    numberOfDepositAccounts: deposits.length,
    data: {
      FreeCapital: data.free_capital,
      Deposits: data.deposits,
      Withdrawals: data.withdrawals,
      Fees: data.fees_paid,
      Investments: data.nr_active_investments,
      RepaidCapital: data.repaid_capital,
      InterestPaid: data.interest_paid,
      ActiveInvestments: data.active_investments,
      PortfolioValue: data.portfolio_value,
      ReturnAfterCreditLossesAndFees: 0,
      CreditLosses: 0,
      AvgROISinceStart: 0,
      PortfolioGrowth: 0,
      NrOfActiveInvestments: data.nr_active_investments,
    },
    depositId: deposits.length ? deposits[0].mambu_deposit_id : undefined,
    legacyUserId: state.user.profile.data.legacy_users.length
      ? state.user.profile.data.legacy_users[0].legacy_user_id
      : undefined,
    isLegacy: false,
    companies: state.user.profile.data.companies,
    isFetching: is_fetching,
    agreements: state.user.investor.agreements.data,
    has_skipped_signed_latest_agreements:
      state.user.investor.agreements.has_skipped_signed_latest_agreements,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  getLegacyInvestorPortfolio: (legacyUserId) =>
    dispatch(fetchPortfolio(legacyUserId)),
  getInvestorPortfolio: (data: PortfolioData, depositId) =>
    dispatch(getInvestorKpi(data.date, depositId)),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, MappedDispatchModel, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Presentation)
