import { Route } from 'react-router'
import React from 'react'
import AispCallback from '../components/Borrower/AISP/AispCallback'
import GatherInformation from '../components/Borrower/AISP/GatherInformation'
import LoanApplication from '../components/Borrower/LoanApplication/index'

export default () => (
  <div id="container">
    <Route exact path="/aisp" component={AispCallback} />
    <Route path="/kreditz/success" component={GatherInformation} />
    <Route path="/kreditz/failure" component={LoanApplication} />
  </div>
)
