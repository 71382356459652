import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Alert } from 'reactstrap'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Indicator from '../../../shared/Indicator'
import Steps from '../Steps'
import CodeInput from '../../CodeInput'
import { State } from '../../../../store'
import browserHistory from '../../../../browserHistory'
import { ThunkDispatch } from 'redux-thunk'
import postVerificationCode from '../../../../store/auth/register/sms/actions/postVerificationCode'
import repostEmailAndSms from '../../../../store/auth/register/sms/actions/repostEmailAndSms'
import { checkoutStep2 } from '../../../../utils/gtm/purchase'
import { setSpaceForPhoneNumber, toMinuteSecond } from '../../../../utils'
import { RESENT_SMS_TIME } from '../../../../constants'
import { useHistory } from 'react-router'

interface Props extends WithTranslation, MappedState, MappedDispatch {}

const VerifyPhoneNumber = (props: Props) => {
  const {
    t,
    mobilePhone,
    resendVerificationCode,
    postVerificationCode,
    isFetching,
    error,
  } = props

  const history = useHistory()
  const [fields, setFields] = useState({})
  const [timeLeft, setTimeLeft] = useState(RESENT_SMS_TIME)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    checkoutStep2()
  }, [])

  useEffect(() => {
    if (timeLeft > 0) {
      const timeoutID = setTimeout(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)
      return () => {
        clearTimeout(timeoutID)
      }
    } else {
      history.push(`/login/sms/code`)
    }
  }, [history, timeLeft])

  function resendVerifyCode() {
    resendVerificationCode()
    setTimeLeft(RESENT_SMS_TIME)
  }

  const submitData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleValidation(fields, setErrors, t)) {
      postVerificationCode(fields['code'])
    }
  }

  return (
    <section id="container" className="container borrower-onboarding">
      <Indicator isVisible={isFetching} />
      <Steps currentStepIndex={1} />
      <div className="code">
        <h1 className="font-50 step-big-title">{t('SMS_VERIFICATION_CODE')}</h1>
        {mobilePhone && (
          <p className="text-center mb-5">
            {t('CODE_WAS_SENT_TO')} {setSpaceForPhoneNumber(mobilePhone)}{' '}
            {t('SMS_VERIFICATION_DIDNT_GET_CODE', {
              time: timeLeft > 0 && toMinuteSecond(timeLeft),
            })}
            {'.'}
          </p>
        )}
        <Form className="verification-code__form" onSubmit={submitData}>
          {error && <Alert color="danger">{error}</Alert>}
          <Col className="d-flex justify-content-center align-items-center flex-row">
            <CodeInput
              onChange={(code) => setFields({ code })}
              hasError={errors.hasOwnProperty('code')}
              errorMessage={errors['code']}
            />
          </Col>
          <Col className="d-flex justify-content-center align-items-center flex-column">
            <Button type="submit" className="my-4 min-w-10">
              {t('CONTINUE')}
            </Button>
            <Button
              color="link"
              onClick={resendVerifyCode}
              disabled={timeLeft > 0}
            >
              {t('DIDNOT_GET_CODE')}
            </Button>
            <Button color="link" onClick={browserHistory.goBack}>
              {t('SMS_VERIFICATION_EDIT_PHONE_NUMBER')}
            </Button>
          </Col>
        </Form>
      </div>
    </section>
  )
}

function handleValidation(fields, setErrors, t) {
  const errors = {}
  let formIsValid = true

  if (!fields.hasOwnProperty('code')) {
    formIsValid = false
    errors['code'] = t('CANNOT_BE_EMPTY')
  } else {
    const re = /^[0-9]{4}$/im
    if (!re.test(fields['code'].toLowerCase())) {
      formIsValid = false
      errors['code'] = t('CANNOT_BE_EMPTY')
    }
  }

  setErrors(errors)

  return formIsValid
}

interface MappedState {
  mobilePhone: string | undefined
  isFetching: boolean
  error: string
}

interface MappedDispatch {
  postVerificationCode: (code: string) => void
  resendVerificationCode: () => void
}

const mapStateToProps = (state: State): MappedState => {
  const { request_body } = state.auth.register.sms
  return {
    isFetching: state.auth.register.sms.is_fetching,
    mobilePhone: request_body && request_body.mobile_phone,
    error: state.auth.register.sms.error,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  postVerificationCode: (code: string) => dispatch(postVerificationCode(code)),
  resendVerificationCode: () => dispatch(repostEmailAndSms()),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedState, MappedDispatch, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(VerifyPhoneNumber)
