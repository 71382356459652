import {
  LOGIN_WITH_SMS_FAILURE,
  LOGIN_WITH_SMS_REQUEST,
  LOGIN_WITH_SMS_SUCCESS,
  VERIFY_PHONE_NUMBER_FAILURE,
  VERIFY_PHONE_NUMBER_REQUEST,
  VERIFY_PHONE_NUMBER_SUCCESS,
} from './types'

export const createLoginWithSmsRequestAction = () => ({
  type: LOGIN_WITH_SMS_REQUEST,
})
export const createLoginWithSmsSuccessAction = (mobilePhone: string) => ({
  mobilePhone,
  type: LOGIN_WITH_SMS_SUCCESS,
})
export const createLoginWithSmsFailureAction = (error: any) => ({
  error,
  type: LOGIN_WITH_SMS_FAILURE,
})

export const createVerifyPhoneNumberRequestAction = () => ({
  type: VERIFY_PHONE_NUMBER_REQUEST,
})
export const createVerifyPhoneNumberSuccessAction = (blaId: string) => ({
  blaId,
  type: VERIFY_PHONE_NUMBER_SUCCESS,
})
export const createVerifyPhoneNumberFailureAction = (error: any) => ({
  error,
  type: VERIFY_PHONE_NUMBER_FAILURE,
})
