import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Vehicles from './Vehicles'
import Services from './Services'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'

interface ComponentProps {
  setFields: (fields) => void
  fields: any
  errors: any
}

interface Props extends WithTranslation, ComponentProps {}

const CompanyInvestmentExperience = (props: Props) => {
  const { t, setFields, fields, errors } = props
  const handleChange = (
    fieldName: string,
    e: React.FormEvent<HTMLInputElement>
  ) => {
    passFields(fieldName, e.currentTarget.value)
  }
  const passFields = (fieldName: string, value: string) => {
    const newFields = { ...fields }
    newFields[fieldName] = value

    setFields(newFields)
  }

  return (
    <Container className="aml__wrapper">
      <Row className="mt-5 mb-5">
        <Col className="text-center">
          <div className="font-20-body">
            {t('INVESTMENT_EXPERIENCE_AND_KNOWLEDGE')}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Vehicles
            setFields={setFields}
            fields={fields}
            errors={errors}
            handleChange={handleChange}
          />
          <Services
            setFields={setFields}
            fields={fields}
            errors={errors}
            handleChange={handleChange}
          />
        </Col>
      </Row>
    </Container>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(CompanyInvestmentExperience)
