import {
  createPostSmsVerificationFailureAction,
  createPostSmsVerificationRequestAction,
  createPostSmsVerificationSuccessAction,
} from './creators'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import SmsRegisterAPI from '../../../../../api/auth/SmsRegisterAPI'
import { State } from '../../../../index'
import VerificationCodeRequest from '../../../../../models/Auth/Sms/VerificationCodeRequest'
import { setToken, getLegacyData } from '../../../../../utils/auth'
import { getUserProfile } from '../../../../user/profile/actions/getUserProfile'

export default (code: string): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => State
) => {
  const { request_body } = getState().auth.register.sms
  const { state } = getState().auth.register.signicat.callback_response
  const { state: smsState } = getState().auth.register.sms
  const isForeigner = getLegacyData().is_foreigner

  if (request_body && request_body.mobile_phone && (state || isForeigner)) {
    const body: VerificationCodeRequest = {
      code,
      mobile_phone: request_body.mobile_phone,
      state: isForeigner ? smsState : state,
    }

    dispatch(createPostSmsVerificationRequestAction())
    try {
      const smsApi = new SmsRegisterAPI()
      const response: AxiosResponse = isForeigner
        ? await smsApi.postForeignerVerificationCode(body)
        : await smsApi.postVerificationCode(body)

      dispatch(createPostSmsVerificationSuccessAction(response.data.data.blaId))

      setToken(response.data.data.token)

      if (
        getState().auth.register.signicat.callback_response.goal ===
          'investor' ||
        isForeigner
      ) {
        dispatch(getUserProfile('/onboarding/investor/aml'))
      } else if (response.data.data.blaId) {
        dispatch(getUserProfile('/dashboard/borrower/loan-application'))
      } else {
        dispatch(getUserProfile('/dashboard'))
      }
    } catch (error) {
      dispatch(
        createPostSmsVerificationFailureAction(
          error && error.response && error.response.data.message
        )
      )
    }
  }
}
