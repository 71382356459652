import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
  createGetCheckLoanApplicationStatusKreditzFailureAction,
  createGetCheckLoanApplicationStatusKreditzRequestAction,
  createGetCheckLoanApplicationStatusKreditzSuccessAction,
  createResetRedirectUrlAction,
} from './creators'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import browserHistory from '../../../../../browserHistory'
import { checkoutStep3 } from '../../../../../utils/gtm/checkout'
import updateLoanApplication from './updateLoanApplication'
import LoanApplication from '../../../../../models/Borrower/LoanApplication'

const checkLoanStatusKreditz = (
  loanApplicationId: number
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(createGetCheckLoanApplicationStatusKreditzRequestAction())

  try {
    const borrowerApi = new BorrowerAPI()
    const pollResponse = await borrowerApi.pollStatusKreditz(loanApplicationId)

    if (pollResponse.data.status === 'SCORING_NOT_MAIN_ACCOUNT') {
      dispatch(createResetRedirectUrlAction())
      browserHistory.push('/dashboard/borrower/kreditz?retry=true')
      return
    }

    if (pollResponse.data.status === 'SCORING_ERROR') {
      throw new Error('Scoring status polling error')
    }

    if (pollResponse.data.status !== 'SCORING_READY') {
      dispatch(
        createGetCheckLoanApplicationStatusKreditzSuccessAction('pending')
      )
      return
    }

    const resultResponse = await borrowerApi.getResultKreditz(loanApplicationId)
    if (resultResponse.data.approved) {
      const data: LoanApplication = resultResponse.data.loanApplication
      dispatch(
        createGetCheckLoanApplicationStatusKreditzSuccessAction('success', data)
      )

      //google tag
      checkoutStep3()

      // from checkLoanStatus -- needed too?
      if (
        data.user &&
        data.user.bank_accounts.length > 0 &&
        !data.due_date &&
        !data.bank_account_id
      ) {
        dispatch(
          updateLoanApplication({
            due_date: 1,
            bank_account_id: data.user.bank_accounts[0].id,
          })
        )
      }

      browserHistory.push('/dashboard/borrower/view-offer')
    } else {
      dispatch(
        createGetCheckLoanApplicationStatusKreditzSuccessAction('rejected')
      )
      browserHistory.push('/dashboard/borrower/loan-rejected')
    }
  } catch (error) {
    dispatch(createGetCheckLoanApplicationStatusKreditzFailureAction(error))
  }
}

export default checkLoanStatusKreditz
