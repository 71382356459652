import { NavItem, NavLink } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { State } from '../../store'
import { ThunkDispatch } from 'redux-thunk'
import logoutUser from '../../store/user/profile/actions/logoutUser'
import { connect } from 'react-redux'
import ic_account_circle_black from 'assets/images/ic_account_circle_black.svg'
import { Link } from 'react-router-dom'
import { getFixuraToken } from 'utils/auth'
import NavMenu from './NavMenu'
import ic_lock_icon_white from 'assets/images/ic_lock_icon_white.svg'
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Col,
} from 'reactstrap'
import { countries } from '../../constants'
import updateUserProfile from '../../store/user/profile/actions/updateUserProfile'
import { Investor } from 'utils/isInvestor'
import { Broker } from 'utils/broker'

interface Props extends MappedState, MappedDispatch {}

const NavLinks = (props: Props) => {
  const { isLoggedIn, logoutUser, showProfile, language } = props
  const { t } = useTranslation()

  const { i18n } = useTranslation()
  const [isPhoneDropDownOped, setIsPhoneDropDownOped] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(
    getCountryByLocale(language)
  )
  let isBroker = Broker()
  let isBorrower = !Investor()
  useEffect(
    () => {
      const country = getCountryByLocale(language)
      if (country) {
        setSelectedCountry(country)
        i18n.changeLanguage(country.locale)
      }
    },
    // eslint-disable-next-line
    [language]
  )
  function getCountryByLocale(locale: string) {
    if (!locale) return { img: '', name: '', locale: '' }
    const country = countries.find(
      (country) => country.locale === locale.toLowerCase()
    )
    return country ? country : countries[0]
  }

  function changeSelectedCountryCode(e: any) {
    const selectedCountryLocale =
      e.target.getAttribute('data-locale') ||
      e.target.parentNode.getAttribute('data-locale')
    const country = getCountryByLocale(selectedCountryLocale)
    if (country) {
      setSelectedCountry(country)
      props.changeUserLanguage(country.locale)
    }
  }

  function toggleDropDown() {
    setIsPhoneDropDownOped(!isPhoneDropDownOped)
  }

  if (isLoggedIn || !!getFixuraToken()) {
    return (
      <>
        <NavMenu />
        {showProfile && (
          <NavItem>
            <Link to="/dashboard/profile" className="nav-link nav-name">
              {t('PROFILE')}
              <img
                className="ml-2"
                src={ic_account_circle_black}
                alt={t('PROFILE')}
              />
            </Link>
          </NavItem>
        )}
        <NavItem>
          <NavLink
            className="nav-white-btn"
            href=""
            onClick={(e) => {
              e.preventDefault()
              logoutUser()
            }}
          >
            {t('LOGOUT')}
            <img className="ml-2" src={ic_lock_icon_white} alt={t('PROFILE')} />
          </NavLink>
        </NavItem>
        <Row>
          <Col className="language-nav">
            <div className="language-switch">
              <ButtonDropdown
                className="language-switch__drop-down"
                isOpen={isPhoneDropDownOped}
                toggle={toggleDropDown}
              >
                <DropdownToggle
                  caret
                  className="language-switch__caret lang-btn"
                >
                  <img
                    src={selectedCountry.img}
                    className="language-switch__img"
                    alt=""
                  />
                  <span className="country-title">{selectedCountry.name}</span>
                </DropdownToggle>
                <DropdownMenu className="language-switch__dropdown-menu">
                  {countries.map((country) => {
                    return (
                      <DropdownItem
                        className="language-switch__dropdown-item"
                        onClick={changeSelectedCountryCode}
                        data-locale={country.locale}
                        data-country-name={country.name}
                        key={country.locale}
                      >
                        <img
                          src={country.img}
                          className="language-switch__flag-img"
                          alt={t('FLAG_IMAGE')}
                        />
                        <span>{country.name}</span>
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          </Col>
        </Row>
      </>
    )
  }
  return (
    <>
      {isBorrower && (
        <NavItem>
          <Link to="/register" className="nav-link nav-white-btn">
            {t('REGISTER')}
          </Link>
        </NavItem>
      )}
      {!isBroker && (
        <NavItem>
          <Link to="/login" className="nav-link nav-white-btn">
            {t('LOGIN')}
          </Link>
        </NavItem>
      )}
      {/* TODO Fixme for small device: not close auto */}
      <Row>
        <Col className="language-nav">
          <div className="language-switch">
            <ButtonDropdown
              className="language-switch__drop-down"
              isOpen={isPhoneDropDownOped}
              toggle={toggleDropDown}
            >
              <DropdownToggle caret className="language-switch__caret lang-btn">
                <img
                  src={selectedCountry.img}
                  className="language-switch__img"
                  alt=""
                />
                <span className="country-title">{selectedCountry.name}</span>
              </DropdownToggle>
              <DropdownMenu className="language-switch__dropdown-menu">
                {countries.map((country) => {
                  return (
                    <DropdownItem
                      className="language-switch__dropdown-item"
                      onClick={changeSelectedCountryCode}
                      data-locale={country.locale}
                      data-country-name={country.name}
                      key={country.locale}
                    >
                      <img
                        src={country.img}
                        className="language-switch__flag-img"
                        alt={t('FLAG_IMAGE')}
                      />
                      <span>{country.name}</span>
                    </DropdownItem>
                  )
                })}
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        </Col>
      </Row>
    </>
  )
}

interface MappedState {
  isLoggedIn: boolean
  showProfile: boolean
  isFetchingProfile: boolean
  language: string
}

interface MappedDispatch {
  logoutUser: () => void
  changeUserLanguage: (language: string) => void
}

const mapStateToProps = (state: State): MappedState => ({
  isLoggedIn: state.legacy.auth.is_logged_in || !!state.user.profile.data.id,
  showProfile: !!state.user.profile.data.id,
  isFetchingProfile: state.user.profile.is_fetching,
  language: state.user.profile.data.language,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  logoutUser: () => dispatch(logoutUser()),
  changeUserLanguage: (language: string) =>
    dispatch(updateUserProfile({ language })),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavLinks)
