enum OnFidoTypeEnum {
  SUCCESS = 'SUCCESS',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  PROCESSING_SECOND_TIME = 'PROCESSING_SECOND_TIME',
  REJECTED_SECOND_TIME = 'REJECTED_SECOND_TIME',
  PROCESSING_THIRD_TIME = 'PROCESSING_THIRD_TIME',
  REJECTED_THIRD_TIME = 'REJECTED_THIRD_TIME',
}

export default OnFidoTypeEnum
