import React, { ChangeEvent } from 'react'
import { CheckBoxElement } from './Models'
import { uniqueString } from '../../../utils'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'

interface ComponentProps {
  data: CheckBoxElement
  isSelected: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

interface Props extends WithTranslation, ComponentProps {}

const CheckBox = (props: Props) => {
  const id = uniqueString(10) + props.data.id
  return (
    <div className="check-box">
      <input
        id={id}
        onChange={props.onChange}
        type="checkbox"
        checked={props.isSelected}
      />
      <label htmlFor={id}>{props.t(props.data.label)}</label>
    </div>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(CheckBox)
