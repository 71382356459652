import React, { useEffect } from 'react'
import { Button } from 'reactstrap'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import browserHistory from '../../browserHistory'
import CustomerService from '../../components/shared/CustomerService'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import { getRetryRedirectLink } from '../../store/user/investor/onFido/actions/getRetryRedirectLink'
import getStateFromOnFidoRetryRedirectUrl from '../../utils/onFido'
interface ComponentProps {
  state: string
  getOnFidoRetryRedirectUrl: (state) => void
}

interface Props extends WithTranslation, ComponentProps, MapDispatchToProps {}

const OnFidoRedirect = (props: Props) => {
  const { t, getOnFidoRetryRedirectUrl } = props
  const state = getStateFromOnFidoRetryRedirectUrl()

  useEffect(() => {
    if (state) getOnFidoRetryRedirectUrl(state)
  }, [getOnFidoRetryRedirectUrl, state])

  return (
    <div className="loan-rejection__wrapper">
      <aside className="on-fido-message__container">
        <h1 className="loan__main-title">{t('ON_FIDO_REDIRECTING_TEXT')}</h1>
        <p className="loan-rejection__description"></p>
        <Button
          className="hero light loan-back-to-home__btn"
          color="secondary"
          onClick={() => browserHistory.push('/login')}
        >
          {t('BACK_TO_HOME')}
        </Button>
      </aside>

      <CustomerService />
    </div>
  )
}

interface MapDispatchToProps {
  getOnFidoRetryRedirectUrl: (state) => void
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MapDispatchToProps => ({
  getOnFidoRetryRedirectUrl: (state) => dispatch(getRetryRedirectLink(state)),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MapDispatchToProps, any>(null, mapDispatchToProps)
)

export default enhancer(OnFidoRedirect)
