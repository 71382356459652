export const GET_AUTO_INVEST_STATUS_REQUEST =
  'user.investor.auto_invest.GET_AUTO_INVEST_STATUS_REQUEST'
export const GET_AUTO_INVEST_STATUS_SUCCESS =
  'user.investor.auto_invest.GET_AUTO_INVEST_STATUS_SUCCESS'
export const GET_AUTO_INVEST_STATUS_FAILURE =
  'user.investor.auto_invest.GET_AUTO_INVEST_STATUS_FAILURE'

export const TOGGLE_AUTO_INVEST_STATUS_REQUEST =
  'user.investor.auto_invest.TOGGLE_AUTO_INVEST_STATUS_REQUEST'
export const TOGGLE_AUTO_INVEST_STATUS_SUCCESS =
  'user.investor.auto_invest.TOGGLE_AUTO_INVEST_STATUS_SUCCESS'
export const TOGGLE_AUTO_INVEST_STATUS_FAILURE =
  'user.investor.auto_invest.TOGGLE_AUTO_INVEST_STATUS_FAILURE'
export const ADD_COMPANY_INVESTOR = 'company.investor.ADD_COMPANY_INVESTOR'
export const GET_COMPANY_AUTO_INVEST_STATUS_SUCCESS =
  'company.investor.GET_COMPANY_AUTO_INVEST_STATUS_SUCCESS'
export const TOGGLE_COMPANY_AUTO_INVEST_STATUS_SUCCESS =
  'company.investor.TOGGLE_COMPANY_AUTO_INVEST_STATUS_SUCCESS'

export const GET_COMPANY_SIGN_RIGHT_STATUS_REQUEST =
  'user.investor.auto_invest.GET_AUTO_INVEST_STATUS_REQUEST'
export const GET_COMPANY_SIGN_RIGHT_STATUS_SUCCESS =
  'user.investor.auto_invest.GET_COMPANY_SIGN_RIGHT_STATUS_SUCCESS'
export const GET_COMPANY_SIGN_RIGHT_STATUS_FAILURE =
  'user.investor.auto_invest.GET_COMPANY_SIGN_RIGHT_STATUS_FAILURE'
