import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import browserHistory from '../../browserHistory'
import { compose, Dispatch } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
/* import PriceList from './PriceList'*/
/* import PrivacyPolicy from '../Auth/PrivacyPolicy' */
import { connect } from 'react-redux'
import { createAcceptTermsAndConditionsAction } from '../../store/auth/register/terms_and_conditions/actions/creators'
import Fixura2023MarketingLink from './MarketingLink/Fixura2023MarketingLink'

interface Props extends WithTranslation, MappedDispatch {}

const TermsAndConditionsModal = (props: Props) => {
  const { t, acceptTermsAndConditions } = props

  return (
    <Modal
      isOpen
      centered
      className="custom-class-tc-2023"
      toggle={browserHistory.goBack}
    >
      <ModalHeader toggle={browserHistory.goBack}>
        {t('TERMS_AND_CONDITIONS_PRICE_LIST_PRIVACY_POLICY_READ_AND_ACCEPTED')}
      </ModalHeader>
      <ModalBody>
        {/* T & C for 01 May 2023 */}
        <Fixura2023MarketingLink />
        {/*<TermsAndConditions23 /> */}
        {/*  <PriceList /> */}
        {/*  <PrivacyPolicy /> */}
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button
          onClick={() => {
            acceptTermsAndConditions()
            browserHistory.goBack()
          }}
        >
          {t('ACCEPT')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

interface MappedDispatch {
  acceptTermsAndConditions: () => void
}

const mapDispatchToProps = (dispatch: Dispatch): MappedDispatch => ({
  acceptTermsAndConditions: () =>
    dispatch(createAcceptTermsAndConditionsAction()),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<any, MappedDispatch>(null, mapDispatchToProps)
)

export default enhancer(TermsAndConditionsModal)
