import React, { useState } from 'react'
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Alert,
} from 'reactstrap'
import { WithTranslation, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import FixuraInput from 'components/shared/FixuraInput'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import postCompany from 'store/user/investor/companyAml/actions/postCompany'
import { State } from 'store'
import Indicator from 'components/shared/Indicator'
import { useParams } from 'react-router-dom'
import { checkBusinessCode } from 'utils/regex'

interface Props extends WithTranslation, MappedDispatch, MappedState {}

const AddCompanyModal = (props: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [businessCode, setBusinessCode] = useState('')
  const { postCompany, isFetching, error } = props
  const { companyId } = useParams()

  const check = () => {
    if (companyId) {
      history.push(`/onboarding/company/${companyId}/investor/aml`)
    } else {
      postCompany(businessCode)
    }
  }

  const toggle = () => {
    if (companyId) {
      history.push(`/onboarding/company/${companyId}/investor/aml`)
    } else {
      history.goBack()
    }
  }

  const isBusinessCodeCorrect = checkBusinessCode(businessCode)

  return (
    <Container>
      <Row>
        <Col xs="12">
          <Modal className="tc-modal" isOpen={true} toggle={toggle}>
            <ModalHeader className="p-0" toggle={toggle} />
            <ModalHeader className="center p-0">
              {companyId ? t('VERIFICATION_SUCCESSFUL') : t('ADD_COMPANY')}
            </ModalHeader>
            {!companyId && (
              <ModalBody>
                <Indicator isVisible={isFetching} />
                <p>{t('ADD_COMPANY_MODAL_TEXT')}</p>
                {error && <Alert color="danger">{error}</Alert>}
                <FixuraInput
                  className="input-characteristics"
                  placeholder={t('BUSINESS_CODE')}
                  onChange={(e) => {
                    setBusinessCode(e.currentTarget.value)
                  }}
                />
              </ModalBody>
            )}
            {companyId && (
              <ModalBody>
                <p>{t('ADD_COMPANY_MODAL_SUCCESS_TEXT')}</p>
              </ModalBody>
            )}
            <ModalFooter>
              <Button
                disabled={
                  (!businessCode && !companyId) ||
                  (!companyId && !isBusinessCodeCorrect)
                }
                onClick={check}
                className="min-w-10"
              >
                {companyId ? t('CONTINUE') : t('SUBMIT')}
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </Container>
  )
}

interface MappedDispatch {
  postCompany: (businessCode: string) => void
}

interface MappedState {
  isFetching: boolean
  error: string
}
const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  postCompany: (businessCode) => dispatch(postCompany(businessCode)),
})

const mapStateToProps = (state: State): MappedState => ({
  isFetching: state.user.investor.companyAml.is_fetching,
  error: state.user.investor.companyAml.error,
})

export default connect(mapStateToProps, mapDispatchToProps)(AddCompanyModal)
