import { CheckBoxElement } from '../../../../../shared/CheckBoxGroup/Models'

const options: CheckBoxElement[] = [
  {
    id: 'is_familiar_with_investments_on_my_own',
    label: 'INVESTMENTS_ON_MY_OWN',
  },
  {
    id: 'is_familiar_with_investment_advice',
    label: 'INVESTMENT_ADVICE',
  },
  {
    id: 'is_familiar_with_asset_management',
    label: 'ASSET_MANAGEMENT',
  },
  {
    id: 'is_familiar_with_electronic_trading',
    label: 'ELECTRONIC_TRADING',
  },
  {
    id: 'is_familiar_with_insurance_investments',
    label: 'INSURANCE_INVESTMENTS_PLEASE_SPECIFY_WHICH',
  },
]

export default options
