export const FETCH_SIGNICAT_AUTH_URL_REQUEST_REQUEST =
  'auth.register.signicat.FETCH_SIGNICAT_AUTH_URL_REQUEST_REQUEST'
export const FETCH_SIGNICAT_AUTH_URL_REQUEST_SUCCESS =
  'auth.register.signicat.FETCH_SIGNICAT_AUTH_URL_REQUEST_SUCCESS'
export const FETCH_SIGNICAT_AUTH_URL_REQUEST_FAILURE =
  'auth.register.signicat.FETCH_SIGNICAT_AUTH_URL_REQUEST_FAILURE'

export const POST_SIGNICAT_CALLBACK_DATA_REQUEST =
  'auth.register.signicat.POST_SIGNICAT_CALLBACK_DATA_REQUEST'
export const POST_SIGNICAT_CALLBACK_DATA_SUCCESS =
  'auth.register.signicat.POST_SIGNICAT_CALLBACK_DATA_SUCCESS'
export const POST_SIGNICAT_CALLBACK_DATA_FAILURE =
  'auth.register.signicat.POST_SIGNICAT_CALLBACK_DATA_FAILURE'
