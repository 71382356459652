import { combineReducers } from 'redux'
import affiliate, {
  initialState as affiliateInitialState,
  State as AffiliateState,
} from './affiliate'

export interface State {
  affiliate: AffiliateState
}

export default combineReducers<State>({
  affiliate,
})

export const initialState: State = {
  affiliate: affiliateInitialState,
}
