// fixme: This methods are harcoded now becuase they where hardcoded in the backend
//  (and no api is now provided from backend side) to fix this it needs to create an end piont in the backend.
export default (state = initalState) => {
  return state
}

export const initalState: State = {
  data: [
    'fi-aktia',
    'fi-alandsbanken',
    'fi-danskebank',
    'fi-handelsbanken',
    'fi-nordea',
    'fi-omasp',
    'fi-op',
    'fi-pop',
    'fi-saastopankki',
    'fi-spankki',
    'fi-telia',
  ],
}

export interface State {
  data: string[]
}
