import React, { useEffect } from 'react'
import { State } from '../../../store'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import fetchBanksList from '../../../store/user/borrower/loan_application/actions/fetchBanksList'
import Indicator from '../../shared/Indicator'
import { Col, Row } from 'reactstrap'
import Bank from '../../../models/Borrower/Bank'
import createBankSession from '../../../store/user/borrower/loan_application/actions/createBankSession'
import { useTranslation } from 'react-i18next'

interface Props extends MappedState, MappedDispatch {}

const Banks = (props: Props) => {
  const { banks, fetchBanksList, redirectToBank, isFetching } = props

  const { t } = useTranslation()

  useEffect(() => {
    fetchBanksList()
  }, [fetchBanksList])
  return (
    <Row className="my-4">
      <Col xs={{ offset: 1, size: 10 }}>
        <h1 className="text-center">
          {t('CHOOSE_ONE_OF_THE_FOLLOWING_BANKS')}
        </h1>
        <Indicator isVisible={isFetching} />
        <Row>
          {banks.map((bank) => (
            <Col xs="6" sm="4" md="3">
              <button
                className="btn btn-link"
                onClick={() => redirectToBank(bank.id)}
              >
                {bank.bankDisplayName}
              </button>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

interface MappedState {
  isFetching: boolean
  banks: Bank[]
}

interface MappedDispatch {
  fetchBanksList: () => void
  redirectToBank: (id: string) => void
}

const mapStateToProps = (state: State): MappedState => ({
  banks: state.user.borrower.loan_application.banks,
  isFetching: state.user.borrower.loan_application.is_fetching,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  fetchBanksList: () => dispatch(fetchBanksList()),
  redirectToBank: (id: string) => dispatch(createBankSession(id)),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(Banks)
