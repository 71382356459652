import {
  GET_PERSONAL_DETAILS_FAILURE,
  GET_PERSONAL_DETAILS_REQUEST,
  GET_PERSONAL_DETAILS_SUCCESS,
} from './types'
import { LegacyUser } from 'models/Auth/ProfileData'

export const createGetPersonalDetailsRequestAction = () => ({
  type: GET_PERSONAL_DETAILS_REQUEST,
})
export const createGetPersonalDetailsSuccessAction = (
  data: any,
  legacy_users: LegacyUser[]
) => ({
  data,
  legacy_users,
  type: GET_PERSONAL_DETAILS_SUCCESS,
})
export const createGetPersonalDetailsFailureAction = (error: any) => ({
  error,
  type: GET_PERSONAL_DETAILS_FAILURE,
})
