import React from 'react'
import { Col, Container, Row, Label } from 'reactstrap'
import Origin from './Origin'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import FixuraInput from '../../../../shared/FixuraInput'
import { ScrollElement } from '../../../../../utils/scroll'

interface ComponentProps {
  setFields: (fields) => void
  fields: any
  errors: any
}

interface Props extends WithTranslation, ComponentProps {}

const CompanyFinancialSituation = (props: Props) => {
  const { t, setFields, fields, errors } = props
  const handleChange = (
    fieldName: string,
    e: React.FormEvent<HTMLInputElement>
  ) => {
    passFields(fieldName, e.currentTarget.value)
  }
  const passFields = (fieldName: string, value: string) => {
    const newFields = { ...fields }
    newFields[fieldName] = value

    setFields(newFields)
  }

  return (
    <Container className="aml__wrapper">
      <Row className="mt-5 mb-5">
        <Col className="text-center">
          <ScrollElement name="profession" />
          <div className="font-20-body">{t('FINANCIAL_SITUATION')}</div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={{ size: 8, offset: 2 }}>
          <div className="font-20-body">
            {t('COMPANY_PROFESSIONAL_SITUATION')}
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md="7" className="mb-4" lg={{ size: 8, offset: 2 }}>
          {fields['business_sector'] && <Label>{t('BUSINESS_SECTOR')}</Label>}
          <FixuraInput
            className="input-characteristics"
            placeholder={t('BUSINESS_SECTOR')}
            onChange={handleChange.bind(undefined, 'business_sector')}
            hasError={errors.hasOwnProperty('business_sector')}
            errorMessage={errors['business_sector']}
          />
        </Col>
        <Col md="7" className="mb-4" lg={{ size: 8, offset: 2 }}>
          {fields['turnover'] && <Label>{t('TURNOVER')}</Label>}
          <FixuraInput
            className="input-characteristics"
            placeholder={t('TURNOVER')}
            onChange={handleChange.bind(undefined, 'turnover')}
            hasError={errors.hasOwnProperty('turnover')}
            errorMessage={errors['turnover']}
          />
        </Col>
        <Col md="7" className="mb-4" lg={{ size: 8, offset: 2 }}>
          <ScrollElement name="profit" />
          {fields['profit'] && <Label>{t('PROFIT')}</Label>}
          <FixuraInput
            className="input-characteristics"
            placeholder={t('PROFIT')}
            type="number"
            onChange={handleChange.bind(undefined, 'profit')}
            hasError={errors.hasOwnProperty('profit')}
            errorMessage={errors['profit']}
          />
        </Col>
        <ScrollElement name="balance_sheet_total" />
        <Col md="7" className="mb-4" lg={{ size: 8, offset: 2 }}>
          {fields['balance_sheet_total'] && (
            <Label>{t('BALANCE_SHEET_TOTAL')}</Label>
          )}
          <FixuraInput
            className="input-characteristics"
            placeholder={t('BALANCE_SHEET_TOTAL')}
            type="number"
            onChange={handleChange.bind(undefined, 'balance_sheet_total')}
            hasError={errors.hasOwnProperty('balance_sheet_total')}
            errorMessage={errors['balance_sheet_total']}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 8, offset: 2 }}>
          <ScrollElement name="origin_of_funds_specification" />
          <Origin setFields={setFields} fields={fields} errors={errors} />
        </Col>
      </Row>
      <Row className="big-distance-bot">
        <Col md="7" lg={{ size: 8, offset: 2 }}>
          <FixuraInput
            className="input-characteristics"
            placeholder={t('FROM_WHERE')}
            onChange={handleChange.bind(
              undefined,
              'origin_of_funds_specification'
            )}
            hasError={errors.hasOwnProperty('origin_of_funds_specification')}
            errorMessage={errors['origin_of_funds_specification']}
          />
        </Col>
      </Row>
    </Container>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(CompanyFinancialSituation)
