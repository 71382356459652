import React from 'react'
import { TBody, Tr } from './Table'
import { cellMapper } from './Cell'
import { dates, unitType } from './utils'

interface Props {
  startDate?: any
  endDate?: any
  minDate?: any
  maxDate?: any
  showISOWeekNumbers?: boolean
  showWeekNumbers?: boolean
  calendar?: any
  range?: any
  onDayClick?: () => void
  onDayMouseEnter?: () => void
}

export default class CalendarBody extends React.Component<Props> {
  renderCells = () => {
    const {
      startDate,
      endDate,
      range,
      minDate,
      maxDate,
      showWeekNumbers,
      showISOWeekNumbers,
      calendar,
      onDayClick,
      onDayMouseEnter,
    } = this.props
    const startOfMonth = calendar.startOf('month')
    const startOfWeek = startOfMonth.startOf('week')
    const endOfMonth = calendar.endOf('month')
    const endOfWeek = endOfMonth.endOf('week')
    const data = []
    let weekstart = startOfWeek.clone()
    for (; weekstart <= endOfWeek; ) {
      // @ts-ignore
      data.push(weekstart)
      weekstart = weekstart.add(1, 'day')
    }
    if (data.length < 42) {
      let fill = data.length
      for (; fill < 42; fill += 1) {
        // @ts-ignore
        data.push(weekstart)
        weekstart = weekstart.add(1, 'day')
      }
    }
    const resolvedData = []
    for (let cxi = 0; cxi < 6; cxi += 1) {
      const rows = []
      const rxi = cxi * 7
      const rxiDay = data[rxi]
      let week = null
      if (showISOWeekNumbers || showWeekNumbers) {
        if (showISOWeekNumbers) {
          // @ts-ignore
          week = dates.isoWeek(rxiDay.toDate())
        } else {
          // @ts-ignore
          week = rxiDay.week()
        }

        rows.push({
          // @ts-ignore
          week,
          // @ts-ignore
          unitType: unitType.WEEK,
          // @ts-ignore
          key: 0,
        })
      }

      const initLength = rows.length
      for (let cxj = 0; cxj < 7; cxj += 1) {
        const key = rxi + cxj
        const day = data[key]
        const props = {
          minDate,
          maxDate,
          day,
          calendar,
          startDate,
          range,
          endDate,
          onDayMouseEnter,
          onDayClick,
          unitType: unitType.DAY,
          key: key + initLength,
        }
        // @ts-ignore
        rows.push(props)
      }
      // @ts-ignore
      resolvedData.push(rows)
    }
    return (
      <>
        {resolvedData.map((rows, rowKey) => {
          // @ts-ignore
          const cell = rows.map((props) => {
            const Component = cellMapper[props.unitType]
            // @ts-ignore
            return <Component {...props} />
          })
          return <Tr key={rowKey}>{cell}</Tr>
        })}
      </>
    )
  }

  render() {
    return <TBody>{this.renderCells()}</TBody>
  }
}
