import pageTitleUtils from './pageTitleUtils'

declare global {
  interface Window {
    dataLayer: any
  }
}

export const virtualPageView: (pathname) => void = (pathname) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'virtualPageView',
    pageTitle: pageTitleUtils(pathname),
    pagePath: pathname,
  })
}
