export const SING_CONTRACT_REQUEST =
  'user.investor.contract.SIGN_CONTRACT_REQUEST'
export const SING_CONTRACT_SUCCESS =
  'user.investor.contract.SIGN_CONTRACT_SUCCESS'
export const SING_CONTRACT_FAILURE =
  'user.investor.contract.SIGN_CONTRACT_FAILURE'

export const GET_CONTRACT_REQUEST =
  'user.investor.contract.GET_CONTRACT_REQUEST'
export const GET_CONTRACT_SUCCESS =
  'user.investor.contract.GET_CONTRACT_SUCCESS'
export const GET_CONTRACT_FAILURE =
  'user.investor.contract.GET_CONTRACT_FAILURE'
