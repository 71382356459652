import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import browserHistory from '../../../../../browserHistory'
import {
  createSignLoanContractFailureAction,
  createSignLoanContractRequestAction,
  createSignLoanContractSuccessAction,
} from './creators'

const postStoreLoan = (
  loanApplicationId: number
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createSignLoanContractRequestAction())

  try {
    const borrowerAPI = new BorrowerAPI()
    await borrowerAPI.storeLoan(loanApplicationId)
    dispatch(createSignLoanContractSuccessAction())
    browserHistory.push('/dashboard/borrower/signed')
  } catch (error) {
    dispatch(createSignLoanContractFailureAction(error))
  }
}

export default postStoreLoan
