import API from '../API'

export default class TermsAndConditionsAPI extends API {
  public getTermsAndConditions() {
    return this.get('api/document/latest/terms_and_conditions')
  }
  public signTermsAndConditions() {
    return this.post('api/document/terms-and-conditions/sign', {})
  }
  public signTermsAndConditionsPreRelease() {
    return this.post('api/document/terms-and-conditions/pre-release/sign', {})
  }
  public getTermsAndConditionsAndContractStatus() {
    return this.get('api/document/tac-investor-contract/status', {})
  }
  public getCompanyTermsAndConditionsAndContractStatus(companyId: number) {
    return this.get(`api/document/tac-company-contract/${companyId}/status`, {})
  }
  public signCompanyTermsAndConditions(companyId: number) {
    return this.post(
      `api/document/company/${companyId}/terms-and-conditions/sign`,
      {}
    )
  }
}
