import {
  createPostEmailAndSMSFailureAction,
  createPostEmailAndSMSRequestAction,
  createPostEmailAndSMSSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import SmsRegisterAPI from '../../../../../api/auth/SmsRegisterAPI'
import { State } from '../../../../index'
import EmailAndSmsForRegistrationRequest from '../../../../../models/Auth/Sms/EmailAndSmsForRegistrationRequest'
import browserHistory from '../../../../../browserHistory'
import { getLegacyData } from 'utils/auth'

export default (data): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const { state } = getState().auth.register.signicat.callback_response
  const { language } = getState().user.profile.data
  const isForeigner = getLegacyData().is_foreigner

  if (isForeigner || state) {
    const body: EmailAndSmsForRegistrationRequest = {
      email: data.email,
      mobile_phone: data.mobile_phone,
      is_checked_offers: data.is_checked_offers,
      state,
      language,
    }

    dispatch(createPostEmailAndSMSRequestAction(body))
    try {
      const smsApi = new SmsRegisterAPI()
      const response: AxiosResponse = isForeigner
        ? await smsApi.postForeignerSmsAndEmailData(body)
        : await smsApi.postSmsAndEmailData(body)
      dispatch(createPostEmailAndSMSSuccessAction(response.data.data.state))
      if (response.data.data.goal === 'investor' || isForeigner) {
        browserHistory.push('/onboarding/investor/verify-phone-number')
      } else {
        browserHistory.push('/register/verify-phone-number')
      }
    } catch (error) {
      dispatch(createPostEmailAndSMSFailureAction(error))
    }
  }
}
