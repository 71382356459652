import React from 'react'
import { State } from '../../../store'
import { connect } from 'react-redux'
import En from './En'
import Fi from './Fi'
import Se from './Se'

interface IMappedState {
  language: string
}
const components = {
  en: En,
  fi: Fi,
  se: Se,
}
const CCISConsent: React.FunctionComponent<IMappedState> = (props) => {
  const { language } = props
  const Presentation = components[language]
  return <Presentation />
}

const mapStateToProps = (state: State): IMappedState => ({
  language: state.user.profile.data.language,
})

export default connect(mapStateToProps)(CCISConsent)
