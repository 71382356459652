import {
  POST_EMAIL_AND_SMS_FAILURE,
  POST_EMAIL_AND_SMS_REQUEST,
  POST_EMAIL_AND_SMS_SUCCESS,
  POST_SMS_VERIFICATION_CODE_FAILURE,
  POST_SMS_VERIFICATION_CODE_REQUEST,
  POST_SMS_VERIFICATION_CODE_SUCCESS,
} from './types'
import EmailAndSmsForRegistrationRequest from '../../../../../models/Auth/Sms/EmailAndSmsForRegistrationRequest'

export const createPostEmailAndSMSRequestAction = (
  requestBody: EmailAndSmsForRegistrationRequest
) => ({
  requestBody,
  type: POST_EMAIL_AND_SMS_REQUEST,
})
export const createPostEmailAndSMSSuccessAction = (state?: string) => ({
  state,
  type: POST_EMAIL_AND_SMS_SUCCESS,
})
export const createPostEmailAndSMSFailureAction = (error: any) => ({
  error,
  type: POST_EMAIL_AND_SMS_FAILURE,
})

export const createPostSmsVerificationRequestAction = () => ({
  type: POST_SMS_VERIFICATION_CODE_REQUEST,
})
export const createPostSmsVerificationSuccessAction = (blaId: string) => ({
  blaId,
  type: POST_SMS_VERIFICATION_CODE_SUCCESS,
})
export const createPostSmsVerificationFailureAction = (error: any) => ({
  error,
  type: POST_SMS_VERIFICATION_CODE_FAILURE,
})
