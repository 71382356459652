import React from 'react'
import FixuraLogo from '../../shared/FixuraLogo'

const TermsAndConditions: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared t-and-c__v02">
      <FixuraLogo size="large" color={'dark'} />
      <br />
      <h3>FIXURA AB OY – YLEISET EHDOT</h3>
      <h3>Voimassa 01.02.2020 alkaen</h3>
      <p>
        Näitä Yleisiä Ehtoja sovelletaan kaikkiin 01.02.2020 lähtien tehtyihin
        Sijoittajasopimuksiin ja Lainasopimuksiin. Ennen 01.02.2020 tehtyihin
        Lainoihin sovelletaan aiempia, 01.03.2019 päivitettyjä Yleisiä Ehtoja,
        jotka löytyvät Fixuran verkkopalvelusta.
      </p>

      <h3>1. MÄÄRITELMÄT</h3>
      <table className="definitions">
        <tbody>
          <tr>
            <td>
              <b>Autoinvest</b>
            </td>
            <td>
              Fixuran tarjoaman järjestelmän automaattinen sijoitustoiminto.
            </td>
          </tr>
          <tr>
            <td>
              <b>Autosell</b>
            </td>
            <td>
              Fixuran tarjoaman järjestelmän automaattinen myyntitoiminto.
            </td>
          </tr>
          <tr>
            <td>
              <b>Fixura</b>
            </td>
            <td>Fixura Ab Oy (Y-tunnus 2246639-7)</td>
          </tr>
          <tr>
            <td>
              <b>Fixura-tili</b>
            </td>
            <td>
              Käyttäjälle tehty erillinen tili Fixuran palveluun, jonka kautta
              käyttäjä voi sijoittaa tai lainata varoja.
            </td>
          </tr>
          <tr>
            <td>
              <b>Korko</b>
            </td>
            <td>Lainasummasta perittävä korko.</td>
          </tr>
          <tr>
            <td>
              <b>Laina</b>
            </td>
            <td>
              Sijoittajan lainanottajalle lainasopimuksen nojalla maksama summa.
            </td>
          </tr>
          <tr>
            <td>
              <b>Lainanottaja</b>
            </td>
            <td>
              Palvelun käyttäjä, joka on lähettänyt lainahakemuksen ja/tai on
              tehnyt lainasopimuksen lainanottajana.
            </td>
          </tr>
          <tr>
            <td>
              <b>Lainasopimus</b>
            </td>
            <td>
              Lainanottajan ja Sijoittajan välille solmittu sopimus, jonka
              nojalla sovitaan mm. lainattavasta pääomasta, korosta ja
              maksusuunnitelmasta.
            </td>
          </tr>
          <tr>
            <td>
              <b>Pankkipäivä</b>
            </td>
            <td>
              Mikä tahansa arkipäivä, lukuun ottamatta lauantaita, sunnuntaita
              tai yleisiä pyhä- tai vapaapäiviä.
            </td>
          </tr>
          <tr>
            <td>
              <b>Sijoitus</b>
            </td>
            <td>
              Sijoittajan sijoittama summa Lainanottajien haettavaksi pääomaksi.
            </td>
          </tr>
          <tr>
            <td>
              <b>Sijoittaja</b>
            </td>
            <td>
              Palvelun käyttäjä, joka on hyväksynyt Fixuran Sijoittajasopimuksen
              ja tallettanut varoja Fixura-tilille lainattavaksi
              Lainanottajille.
            </td>
          </tr>
          <tr>
            <td>
              <b>Sijoittajasopimus</b>
            </td>
            <td>
              Sijoittajan ja Fixuran välille tehty sopimus, jossa sovitaan
              sijoitusyhteistyöstä ja jonka nojalla Fixura välittää
              lainahakemuksia Sijoittajille, hoitaa yhteydenpidon Lainanottajiin
              sekä huolehtii rahaliikenteestä.
            </td>
          </tr>
          <tr>
            <td>
              <b>Yleiset Ehdot</b>
            </td>
            <td>
              Ajantasaiset Lainasopimuksiin ja Sijoittajasopimuksiin
              sovellettavat yleiset ehdot, jotka ovat saatavilla
              verkkopalvelusta.
            </td>
          </tr>
          <tr>
            <td>
              <b>Hinnasto</b>
            </td>
            <td>
              Verkkopalvelussa saatavilla oleva ajantasainen hinnasto, jonka
              mukaisesti Fixura veloittaa maksuja Sijoittajilta ja
              Lainanottajilta.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <h3>2. JOHDANTO</h3>
      <p>
        Fixura on yritys, jonka liikeidea ja toimintamalli on toimia linkkinä ja
        saattaa yhteen lainanhakijat ja ne, jotka haluavat lainata varojaan
        toisille. Fixura toimii joustavana markkinapaikkana tällaisille
        toimijoille ja osana tätä toimintaa välittää Lainoja Sijoittajilta
        Lainanottajille. Fixuran Sijoittajilta ja Lainanottajilta näistä
        palveluista veloitettavat maksut löytyvät Hinnastosta, joka on
        erottamaton osa näitä Yleisiä Ehtoja. Rekisteröitymällä käyttäjäksi
        verkkopalvelussa käyttäjä hyväksyy nämä Yleiset Ehdot häntä sitoviksi.
        Nämä Yleiset Ehdot ovat osa Lainanottajan velkasuhteen ehtoja
        Sijoittajan ja Lainanottajan tekemän erillisen Lainasopimuksen
        mukaisesti. Mikäli nämä Yleiset Ehdot ovat ristiriidassa sen kanssa,
        mitä on nimenomaisesti sovittu Sijoittajan ja Lainanottajan välisessä
        Lainasopimuksessa, Lainasopimuksen ehdot ovat ensisijaisia. Nämä Yleiset
        Ehdot ovat myös osa Sijoittajan ja Fixuran välistä Sijoittajasopimusta
        ja ne hyväksytään osapuolia sitoviksi Sijoittajasopimuksesta ilmenevällä
        tavalla. Mikäli nämä Yleiset Ehdot ovat ristiriidassa sen kanssa mitä on
        nimenomaisesti sovittu Sijoittajasopimuksessa, Sijoittajan ja Fixuran
        välillä, Sijoittajasopimuksen ehdot ovat ensisijaisia.
      </p>
      <p>
        Fixura korostaa, ettei se ole Sijoittajan ja Lainanottajan välisen
        lainasuhteen sopimusosapuoli, vaan Fixura toimii ainoastaan näiden
        välisenä luotonvälittäjänä ja ettei Fixura tarjoa sijoituspalvelulain
        mukaisia sijoituspalveluita. Fixura Ab Oy on rekisteröity Etelä-Suomen
        Aluehallintoviraston luotonantaja- ja vertaislainanvälittäjärekisteriin.
      </p>

      <h3>3. LAINANOTTAJAN EHDOT</h3>
      <h3>Mahdollisuus nostaa lainaa</h3>
      <p>Fixuran välittämää Lainaa voi nostaa henkilö:</p>
      <ul>
        <li>joka on täysivaltainen ja on vähintään 21-vuotias;</li>
        <li>joka on asunut vakituisesti Suomessa vähintään kaksi vuotta;</li>
        <li>jolla on säännölliset tulot;</li>
        <li>
          joka on luovuttanut Fixuralle tarvittavat tiedot luottokelpoisuuden
          arviointia varten;
        </li>
        <li>
          jolla ei ole julkisia maksuhäiriöitä tai maksamattomia erääntyneitä
          Lainoja; ja
        </li>
        <li>
          joka ei ole hallituksen jäsenenä yrityksessä, jolle on rekisteröity
          maksuhäiriöitä.
        </li>
      </ul>
      <p>
        Ennen lainan myöntämistä Fixura tarkistaa Lainanottajan antamat tiedot
        Lainanottajan antaman suostumuksen ja henkilötietolain mukaisesti.
        Lainanottaja on itse vastuussa antamiensa tietojen oikeellisuudesta ja
        totuudenmukaisuudesta. Lainanottajan on myös ilmoitettava mahdollisista
        osoitteen- tai nimenmuutoksista viipymättä Fixuralle. Sijoittaja tai
        Fixura eivät vastaa hakemustiedoissa ja/tai Lainasopimuksessa
        ilmenevistä virheistä tai niistä aiheutuvista viivästyksistä taikka
        muista seuraamuksista. Mikäli Lainanottaja ei täytä
        ilmoitusvelvollisuuttaan, on Fixuralla oikeus vaatia Lainanottajaa
        korvaamaan selvittelystä aiheutuneet kohtuulliset kulut.
      </p>
      <p>
        Fixuran välittämien Lainojen myöntämisen arviointiin käytetään osittain
        myös kolmansilta osapuolilta saatuja ja kolmansien osapuolien
        analysoimia tietoja. Lainanottaja hyväksyy, että Fixura selvittää
        Lainanottajan luottokelpoisuuden ulkopuolisten toimijoiden tarjoamien
        palveluiden avulla. Tarvittavat luottotiedot hankitaan Bisnode Finland
        Oy:n tai Suomen Asiakastieto Oy:n kautta. Lainanottaja hyväksyy, että
        annetut tiedot tarkastetaan ja säilytetään asiakassuhteen ajan sekä sen
        päättymisen jälkeen tietosuojaselosteen mukaisesti. Fixura pidättää
        itselleen oikeuden estää henkilöä hakemasta ja nostamasta lainaa sekä
        hylätä jo tehdyn lainahakemuksen.
      </p>

      <p>
        Kun Lainanottaja on hyväksynyt saamansa Lainan, voi Lainanottaja jättää
        uuden lainahakemuksen kuuden (6) kuukauden kuluttua, edellyttäen, että
        edellisen Lainan kuusi (6) ensimmäistä lyhennystä Korkoineen on
        kokonaisuudessaan maksettu. Lainanottajalla voi olla yhteensä enintään
        20 000,00 euroa lainattua pääomaa. Mikäli haettu lainasumma ei tule
        kokonaan rahoitetuksi, voivat Sijoittajat myöntää Lainanottajalle
        haettua Lainaa pienemmän Lainan. Näin ollen Lainanottaja ei ole taattu
        saamaan kokonaisuudessaan hakemaansa Lainaa, eivätkä Sijoittajat ole
        velvollisia tarjoamaan Lainaa Lainanottajan hakemalla summalla.
        Lainalaskurissa lainaa haettaessa ilmoitettavat tiedot ovat aina
        ainoastaan alustava arvio haettavasta Lainasta. Lainan todelliset tiedot
        kuten määrä, Korko, maksuaika ja maksuerän suuruus selviävät
        lainatarjouksesta, johon Fixura kehottaa Lainanottajaa aina tutustumaan
        huolellisesti.
      </p>

      <h3>Lainanottajan maksut</h3>
      <p>
        Lainanottaja sitoutuu maksamaan Lainan takaisin Lainasopimuksessa
        mainituin ehdoin kuukausittain. Kuukausittainen maksuerä koostuu Lainan
        lyhennyksestä, korosta ja lainanhoitomaksusta sekä mahdollisista
        käyttäjän valitsemista lisäpalveluista johtuvista maksuista. Laina-aika
        voi olla korkeintaan 84 kuukautta. Lainan nimelliskorko voi olla
        korkeintaan 10,00 % ja alimmillaan 2,90 %. Lainanhoitomaksu on 0,01 %
        päivässä Lainan alkuperäisestä lainapääomasta laskettuna.
        Lainanhoitomaksu veloitetaan lainasopimuksen mukaisesti 0,30 %
        kuukausittain tai 3,65 % vuosittain. Lainanhoitomaksut voivat kuitenkin
        olla yhteensä enintään 150,00 euroa vuodessa. Mahdolliset lisäpalvelut
        veloitetaan kulloinkin voimassa olevan Hinnaston mukaisesti. Kaikki
        Fixuran veloittamat kulut ovat arvonlisäverottomia, koska toiminta ei
        ole arvonlisäverovelvollista. Fixura ei ole vastuussa myöhässä
        tapahtuvista maksuista tai saamatta jääneistä maksuista, jos maksuja ei
        ole maksettu lainasopimuksen mukaisesti.
      </p>
      <p>
        Lainanottajalla on oikeus maksaa Lainansa kokonaan takaisin etuajassa.
        Ennenaikaisen takaisinmaksun yhteydessä Lainanottajan on maksettava:
      </p>
      <ul>
        <li>jäljellä oleva pääoma;</li>
        <li>kertynyt mutta maksamaton Korko;</li>
        <li>kertyneet mutta maksamattomat lainanhoitomaksut; ja</li>
        <li>
          Lainasopimuksessa yksilöidyt lainan perustamisesta aiheutuneet kulut.
        </li>
      </ul>
      <p>
        Lainanottajan ei tarvitse maksaa lainanhoitomaksuja eikä Korkoa jäljellä
        olevalta laina-ajalta.
      </p>
      <br />
      <p>
        Mikäli Lainanottajan loppusuoritus ylittää Lainan jäljellä olevan summan
        enintään viidellä (5) eurolla, Fixuralla on oikeus olla palauttamatta
        liikasuoritusta. Viittä (5) euroa suuremman liikasuorituksen
        palauttamisesta Fixura veloittaa viiden (5) euron käsittelymaksun.
        Lainasuhteen keskeiset ehdot (kuten lainan Korko, todellinen vuosikorko,
        kulut ja takaisinmaksuaika) vahvistetaan erikseen Lainanottajan ja
        Sijoittajan välisessä Lainasopimuksessa. Lainanottajalla on lainmukainen
        oikeus maksuajan pidennykseen, esimerkiksi työttömyyden tai
        sairastumisen vuoksi. Tarvittaessa Fixura voi myös siirtää eräpäivää tai
        muuttaa alkuperäistä maksusuunnitelmaa. Maksusuunnitelman muutokset
        päivitetään verkkopalveluun. Sopimuksen mukainen Korko veloitetaan
        edelleen jäljellä olevasta Lainasta.
      </p>
      <h3>Ennen 01.02.2020 myönnetyt Lainat</h3>
      <p>
        Ennen 01.02.2020 myönnettyihin Lainoihin sovelletaan 01.03.2019
        päivitettyjä Yleisiä Ehtoja, jotka löytyvät kokonaisuudessaan Fixuran
        verkkopalvelusta.
      </p>
      <h3>Viivästyneet maksut, lainojen erääntyminen ja myynti</h3>
      <p>
        Fixura painottaa, että on tärkeää maksaa Lainan kuukausierät ajoissa.
        Mikäli Lainanottaja ei maksa kuukausittaista maksueräänsä, lähetetään
        hänelle 14 päivän kuluttua eräpäivästä kirjallinen maksumuistutus ja
        laskuun lisätään viiden (5) euron muistutusmaksu. Mikäli maksuerä on 14
        päivän kuluttua edelleen maksamatta, lähetetään Lainanottajalle toinen
        kirjallinen maksumuistutus ja laskuun lisätään toinen muistutusmaksu.
      </p>
      <p>
        Jos maksun suorittaminen viivästyy, Fixura voi vaatia Lainanottajaa
        maksamaan viivästyskorkoa, joka on määritetty Lainasopimuksessa. Jos
        viivästyskorko on lainan Korkoa alempi, viivästyskorko on laina-aikana,
        sekä Lainan erääntyessä kokonaisuudessaan 180 vuorokauden ajan siitä,
        kuin Laina on kokonaisuudessaan erääntynyt, kuitenkin vähintään yhtä
        suuri, kuin Lainasopimuksen mukainen Korko. Viivästyskorko lasketaan
        jokaiselta päivältä eräpäivän ylittäneestä summasta eräpäivästä lähtien
        koko summan suorittamiseen saakka.
      </p>
      <p>
        Mikäli maksamattomien laskujen arvo on yhteensä 5,00 % tai enemmän
        Lainan alkuperäisestä pääomasta, erääntyy Laina kokonaan ja Fixura
        lähettää Lainanottajalle tästä irtisanomisilmoituksen, jonka jälkeen
        Laina voidaan siirtää perintään alla kuvatun mukaisesti. Jatkuva
        maksamatta jättäminen voi johtaa oikeustoimenpiteisiin ja mahdollisesti
        aiheuttaa maksuhäiriömerkinnän luottotietoihin, jos asia siirretään
        ulosottoon.
      </p>
      <p>
        Erääntynyt Laina voidaan myydä kolmannelle osapuolelle. Fixuralla on
        oikeus välittää Lainan saatavat ja Lainanottajan tiedot perintään sekä
        ilmoittaa luottotietolain edellytysten täyttyessä luottotietorekisteriin
        maksulaiminlyönnin, joka on kestänyt yli 60 päivää. Perintään
        lähetettyihin asioihin lisätään perintätoimiston maksut sekä
        viivästyskorko. Erääntyneet Lainat voidaan siirtää perintätoimiston
        käsiteltäväksi tai myydä kolmansille osapuolille. Fixura ilmoittaa
        Lainanottajalle Lainasopimuksen siirtymisestä kolmannelle osapuolelle.
      </p>
      <p>
        Perinnän kautta Lainanottajalta saadut maksut kohdistetaan ensin Lainan
        Korkoihin ja erääntyneeseen pääomaan, sen jälkeen perintä- ja muihin
        kuluihin sekä mahdollisesti näihin liittyviin viivästyskorkoihin. Lainan
        lyhennykset ja korot kuuluvat Sijoittajalle, muut kulut
        perintätoimistolle ja Fixuralle.
        <br />
        <br />
        Kaikki maksujen erääntymiseen liittyvät muutokset ja sopimukset on
        tehtävä etukäteen kirjallisesti.
      </p>
      <h3>Laskutus</h3>
      <p>
        Laskun eräpäivä sovitaan Lainasopimuksessa. Mikäli eräpäivä ei ole
        Pankkipäivä, käytetään eräpäivää seuraavaa mahdollista Pankkipäivää.
        Laskutus tapahtuu sähköpostilla, ellei Lainanottaja ole sopinut Fixuran
        asiakastuen kanssa muusta laskutustavasta. Fixura voi lähettää laskun
        myös tekstiviestillä. Vastaavaa sovelletaan maksumuistutuksiin ja
        perintäilmoituksiin. Jos laskutus tapahtuu muulla laskutustavalla,
        lisätään Fixuran kulloinkin soveltama laskutuslisä.
      </p>
      <h3>4. SIJOITTAJAN EHDOT</h3>
      <h3>Riski</h3>
      <p>
        Fixura painottaa, että Sijoittajan on syytä huomioida, että jokaisessa
        Sijoituksessa on riski pääoman menettämisestä osittain tai kokonaan.
        Vertaislainoissa sijoittamisen keskeiset riskit muodostuvat
        Lainanottajan mahdollisesta maksukyvyttömyydestä, mikä voi johtaa
        siihen, ettei Lainanottaja kykene maksamaan Lainaa osittain tai kokonaan
        takaisin, sekä toteutuneen tuoton poikkeamisesta Sijoittajan odottamasta
        tuotosta. Sijoittajan tulee huomioida, että mm. Lainan ennenaikainen
        takaisinmaksu johtaa siihen, että tuotto jää odotettua pienemmäksi.
      </p>
      <p>
        Fixura pyrkii tarjoamaan Sijoittajille joustavan markkinapaikan, jossa
        Sijoitukset ovat jälkimarkkinakelpoisia, mutta Fixura ei voi taata
        jälkimarkkinan toimivuutta tai sen tehokkuutta. Näin ollen Sijoittaja ei
        välttämättä voi luovuttaa Sijoitukseen liittyviä oikeuksiaan ja
        velvollisuuksiaan edelleen toisille Sijoittajille. Sijoittaja ei
        välttämättä voi realisoida Lainoihin tekemiään Sijoituksia haluamanaan
        ajankohtana ja Sijoitusten toteutuva maturiteetti saattaa poiketa
        merkittävästi siitä, mitä Sijoittaja on sijoitusta tehdessään olettanut.
        Sijoittajan tulee edelleen huomioida, että lainojen historiallinen
        tuotto ei ole tae tuotosta tulevaisuudessa. Hajauttaminen on paras tapa
        pienentää riskejä ja luottotappioita.
      </p>
      <p>
        Ennen Lainanottajan lainahakemuksen hyväksymistä, Fixura tarkastaa
        kolmansien osapuolten ylläpitämät luottotietorekisterit, asiakkaan
        pankkitilin transaktiot sekä Fixuran asiakas- ja luottotietorekisterin
        Lainanottajan maksukyvyn varmistamiseksi. Fixura pyrkii lisäksi
        rajoittamaan Sijoittajan riskiä luottoluokittelemalla Lainanottajat,
        epäämällä Lainan hakemisen sellaisilta henkilöiltä, joilla on
        luottohäiriöitä tai epäluotettavia luottotietoja sekä siirtämällä
        perintään tai myymällä erääntyneet Lainat kolmansille osapuolille.
        Fixura tarkistaa Lainanottajan luottotiedot Bisnode Finland Oy:n tai
        Suomen Asiakastieto Oy:n kautta, mutta Fixura ei voi taata saatujen
        tietojen paikkansapitävyyttä. Jos Lainanottaja ei maksa kuukausittaisia
        lainan eriä muistutuksista huolimatta, Laina siirretään perintätoimiston
        hoidettavaksi tai myydään kokonaisuudessaan kolmannelle osapuolelle.
        Fixura myy Lainan kolmannelle osapuolelle myyntihetkellä voimassa olevan
        Hinnaston mukaiseen hintaan. Fixuralla on myös oikeus ostaa Laina
        itselleen vastaavaan hintaan.
      </p>
      <p>
        Lainanottajalla on lainmukainen oikeus maksuajan pidennykseen
        esimerkiksi työttömyyden tai sairastumisen seurauksena. Fixura voi myös
        siirtää eräpäivää tai muuttaa alkuperäistä maksusuunnitelmaa. Sopimuksen
        mukainen Korko veloitetaan edelleen jäljellä olevasta lainasta. Lisäksi
        Lainanottajalla on oikeus maksaa Lainansa takaisin etuajassa, jolloin
        erääntymättömistä eristä ei veloiteta Korkoa.
      </p>

      <h3>Sijoittajan maksut</h3>
      <p>
        Fixura veloittaa Sijoittajalta maksuja kulloinkin voimassa olevan
        Hinnaston mukaisesti.
      </p>
      <p>
        Sijoittaja on itse vastuussa omista Sijoituksiin liittyvistä
        veroseuraamuksista sekä vero- ja muista viranomaisilmoituksista, kuten
        esimerkiksi korkotulojen, luottotappioiden ja kulujen ilmoittamisesta
        verottajalle sekä mahdollisten pääomaverojen maksamisesta. Suomalaisten
        yksityishenkilöiden osalta Fixura ilmoittaa verottajalle tiedot
        korkotuloista ja luottotappioista, mutta Sijoittaja on velvollinen
        tarkistamaan ilmoitettujen tietojen paikkansapitävyyden.
      </p>

      <h3>Saatujen maksujen kohdistus sekä erääntyneiden saatavien myynti</h3>

      <p>
        Fixuralla on oikeus harkintansa mukaan siirtää erääntynyt lainasaatava
        perintään tai myydä se markkinahintaan kolmannelle osapuolelle kaikkine
        oikeuksineen ja velvollisuuksineen. Velkojen myyntihinta kolmannelle
        osapuolelle ilmenee aina kulloinkin voimassa olevasta Hinnastosta.
      </p>
      <p>
        Siltä osin kuin Sijoittajan saatava on siirretty perintään, kohdistetaan
        Lainanottajan maksut Lainanottajan ja Sijoittajan välisessä
        velkasuhteessa voimassa olevan lainsäädännön mukaisesti siten, että
        maksut kohdistetaan ensin Lainan erääntyneisiin Korkoihin ja
        erääntyneeseen pääomaan ja vasta sen jälkeen perintä- ja muihin kuluihin
        sekä näihin liittyviin mahdollisiin viivästyskorkoihin. Edellä
        mainitusta huolimatta perintätoimisto vähentää automaattisesti syntyneet
        perintäkulut Lainanottajan maksun kautta saaduista varoista kullekin
        Sijoittajalle tehtävän tilityksen yhteydessä ja suhteessa Sijoittajaan,
        ennen kuin varat tilitetään Sijoittajan Fixura-tilille.
      </p>
      <p>
        Siltä osin kuin Sijoittajan saatava on myyty kolmannelle osapuolelle,
        kohdistetaan Sijoittajalle kuuluvat myyntitulot velkasaatavan pääomaan.
        Tällöin myynnistä saadut Sijoittajalle kuuluvat tulot tilitetään
        Sijoittajalle kolmen (3) Pankkipäivän kuluessa siitä, kun Fixura on
        vastaanottanut myyntitulot. Lainanottajalla ei ole oikeutta siirtää tai
        myydä lainaa kolmannelle osapuolelle.
      </p>

      <h3>Automaattinen sijoitustoiminto (Autoinvest)</h3>

      <p>
        Autoinvest-toiminnon avulla järjestelmä toteuttaa Sijoituksia
        Sijoittajan puolesta. Riskien hajauttamiseksi järjestelmä pyrkii
        jakamaan Sijoitukset useampiin Lainoihin. Autoinvest-toiminto hajauttaa
        Sijoittajien vapaan pääoman siten, että enintään 3,00 % suuruinen osuus,
        kuitenkin aina vähintään 20,00 euroa, Sijoittajan vapaasta pääomasta
        sijoitetaan yhteen Lainaan. Sijoitukset yksittäiseen Lainaan vaihtelevat
        20,00 eurosta korkeintaan 1 000,00 euroon. Sijoitukset yksittäisiin
        jälkimarkkinoilta ostettuihin Lainoihin voivat kuitenkin olla alle 20,00
        euroa. Koska yksittäiseen Lainaan sijoitettava määrä on enintään 1
        000,00 euroa, voi yksittäistapauksessa syntyä tilanne, jossa yksi
        Sijoittaja yksin sijoittaa 1 000,00 euron Lainaan, elleivät muut
        Sijoittajat ole antaneet tarjousta ko. Lainasta.
      </p>
      <p>
        Fixura painottaa, ettei Autoinvest toiminto aktiivisesti etsi
        yksittäiselle Sijoittajalle Sijoittajan sijoitusprofiiliin sopivia tai
        paremmin tuottavia sijoituskohteita taikka auta kohteiden analysoinnissa
        taikka hyödyntämisessä. Kyse ei myöskään ole sijoitusneuvonnasta taikka
        -palvelusta eikä Fixura ole miltään osin vastuussa Autoinvest-toiminnon
        kautta toteutuvien Sijoitusten kannattavuudesta.
      </p>

      <h3>Sijoitusten automaattinen myyntitoiminto (Autosell)</h3>
      <p>
        Autosell-toiminto toimii Sijoittajan jälkimarkkinatyökaluna, jonka
        avulla järjestelmä myy Sijoituksia Sijoittajan puolesta. Mikäli
        Sijoittaja nostaa Fixura-tililtään varoja enemmän kuin vapaan pääoman
        verran, Autosell-toiminto aktivoituu automaattisesti ja pyrkii myymään
        erotusta vastaavan summan Sijoittajan sijoituksista muille
        Fixura-sijoittajille. Kun Autosell-toiminto on myynyt sijoituksia,
        Sijoittaja voi halutessaan nostaa vapaan pääoman pankkitililleen tai
        jatkaa sijoittamista Autoinvest-toiminnolla. Autosell-toiminnon ollessa
        aktiivinen, Autoinvest-toiminto ei luo samanaikaisesti uusia Sijoituksia
        Sijoittajan salkkuun. Autosell-toiminto voi myydä Sijoittajan
        Sijoituksia joko kokonaan tai osittain.
      </p>

      <h3>Yritykset ja yhteisöt</h3>
      <p>
        Fixuran kautta sijoittavien yritysten ja yhteisöjen on varmistettava,
        että Sijoitukset ja sijoitusratkaisut noudattavat kulloinkin voimassa
        olevaa osakeyhtiölakia sekä muuta soveltuvaa lainsäädäntöä.
      </p>

      <h3>Rahansiirtojen toteuttamisen aikataulu</h3>
      <p>
        Fixura pyrkii toteuttamaan Sijoittajan rahansiirtopyynnön Fixura-tililtä
        Sijoittajan omalle pankkitilille yhden (1) Pankkipäivän, mutta
        viimeistään rahansiirtopyyntöä seuraavan kolmen (3) Pankkipäivän
        kuluessa. Sijoittajan tulee tehdä kaikki rahansiirtopyynnöt Fixuralle
        kirjallisesti ja tunnistautuneena verkkopalvelun kautta omassa
        profiilissaan. Fixuran sisäisen prosessin lisäksi varojen siirtymisen
        aikatauluun vaikuttaa pankkien välisen maksuliikenteen säännöt.
        Fixuralla on aina oikeus kieltäytyä rahansiirtopyynnön toteuttamisesta,
        mikäli siihen on lakiin tai viranomaismääräykseen perustuva syy.
      </p>
      <p>
        Mikäli rahansiirtopyyntö vaatii Autosell-toiminnon avulla toteutettavaa
        sijoitusten myymistä toisille Sijoittajille, rahansiirtopyyntö voidaan
        lähettää Fixuralle vasta myyntien toteutumisen jälkeen. Mikäli
        Sijoitusten myyntitavoite täyttyy ainoastaan osittain, voi Sijoittaja
        keskeyttää Sijoitusten myynnin, pyytää kerättyjen varojen siirtämistä
        pankkitililleen tai jatkaa varojen uudelleensijoittamista verkkopalvelun
        kautta. Mikäli Sijoitusten myyntitavoite täyttyy kokonaan, voi
        Sijoittaja tehdä rahansiirtopyynnön omalle pankkitililleen tai jatkaa
        varojen uudelleensijoittamista verkkopalvelun kautta.
      </p>

      <h3>Ennakonpidätys</h3>
      <p>
        Yksityishenkilöille suoritettavista koroista Fixura voi tarvittaessa
        toimittaa ennakonpidätyksen. Verohallinnolle tilitettävä ennakonpidätys
        on määrältään kulloinkin voimassa olevassa laissa ja asetuksessa
        pääomatuloista toimitettavan ennakonpidätyksen suuruinen. Ennakonpidätys
        toimitetaan sentin tarkkuudella pyöristämällä laskettu määrä lähimpään
        senttiin. Fixura ei maksa Korkoa ennakonpidätykselle.
      </p>

      <h3>5. LAINATUOTTEET</h3>
      <h3>Laskevan koron laina</h3>
      <p>
        Fixuran laskevan koron lainatuotteen maksusuunnitelmassa käytettävä
        nimelliskorko on 2,90 – 9,90 %. Maksusuunnitelmassa käytettävä vuotuinen
        nimelliskorko alkaa Lainasopimuksessa sovitun suuruisena, ja alenee
        kuukausittain 0,10 prosenttiyksikköä, mikäli Lainanottaja maksaa
        kulloisenkin kuukausittaisen maksueränsä ajoissa maksusuunnitelman
        mukaisesti ennen maksumuistutuksen lähettämistä. Vuotuinen nimelliskorko
        on kuitenkin aina vähintään 2,90 %.
      </p>
      <p>
        Mikäli Lainanottaja ei maksa maksuerää ennen Fixuran lähettämää
        maksumuistutusta, Lainanottaja ei ole oikeutettu Koron alenemiseen
        seuraavissa maksuerissä ja korkotaso pysyy sovitulla tai edellä olevan
        mukaisesti saavutetulla tasolla. Mikäli Lainanottaja maksaa kaikki
        kuukausittaiset maksuerät myöhässä eli Fixuran lähettämien
        maksumuistutusten jälkeen, ei Korko alene lainkaan, vaan
        Lainasopimuksessa sovittua nimelliskorkoa sovelletaan sellaisenaan.
      </p>
      <p>
        Alkuperäinen maksusuunnitelma ja sen kuukausittainen maksuerä on
        laskettu olettaen, että Lainanottaja maksaa kaikki kuukausittaiset erät
        ajoissa eräpäivään mennessä. Mikäli Lainanottaja maksaa kuukausittaisia
        maksueriä myöhässä, korkotaso ei laske alkuperäisen maksusuunnitelman
        mukaisesti, jolloin velkapääoma lyhenee alkuperäistä maksusuunnitelmaa
        hitaammin. Tällöin maksusuunnitelman viimeiseen erään sisällytetään
        jäljelle jäänyt maksamaton velkapääoma. Lainanottaja voi halutessaan
        saada uuden maksusuunnitelman jäljelle jääneelle maksamattomalle
        velkapääomalle. Fixura toimittaa Lainanottajalle lyhennystaulukon sitä
        pyydettäessä, josta ilmenee suoritettavat maksut, maksukaudet sekä
        -ehdot.
      </p>

      <h3>6. YHTEYDENOTOT SIJOITTAJAN JA LAINANOTTAJAN VÄLILLÄ</h3>
      <p>
        Fixura allekirjoittaa Lainasopimukset Sijoittajan puolesta, laskuttaa
        aiheutuneet saatavat, välittää tarvittaessa saatavat edelleen perintään
        tai ulosottoon ja myy tarvittaessa saatavat kolmannelle osapuolelle.
        Velkasuhde on kuitenkin kaikissa tilanteissa Sijoittajan ja
        Lainanottajan välillä. Fixura hoitaa tästä huolimatta kaiken
        yhteydenpidon Lainanottajan ja Sijoittajan välillä. Sijoittaja ja
        Lainanottaja eivät saa olla suoraan yhteydessä toisiinsa ilman Fixuran
        suostumusta, ellei toisin ole kirjallisesti sovittu.
      </p>

      <h3>7. HENKILÖTIETOJEN KÄSITTELY JA LUOTTOTARKASTUS</h3>
      <p>
        Fixura käsittelee henkilötietoja sen tietosuojaselosteen
        <a href="https://www.fixura.com/tietosuojaseloste/">
          https://www.fixura.com/tietosuojaseloste/
        </a>
        mukaisesti, mm. seuraaviin tarkoituksiin:
      </p>
      <ul>
        <li>
          henkilötietojen käsittely, jossa pyrkimyksenä on Fixuran
          velvollisuuksien hoitaminen Lainanottajaa ja Sijoittajaa kohtaan;
        </li>
        <li>
          saatujen ja käsiteltyjen henkilötietojen käyttäminen yhteistyössä
          viranomaisten ja yhteistyökumppanien kanssa;
        </li>
        <li>
          tietojen käyttäminen omassa markkinoinnissa sekä yhteistyökumppanien
          tuotteiden ja palveluiden markkinoinnissa;
        </li>
        <li>
          laskutuksen ja reskontran antamiseen kolmannen osapuolen
          hoidettavaksi;
        </li>
        <li>
          henkilön luottokelpoisuuden ja luottoluokituksen tarkastamiseen
          käyttämällä esimerkiksi luottotietopalveluja.
        </li>
      </ul>
      <p>
        Fixura ei voi taata luottoluokitukseen käytettävien tietojen
        paikkansapitävyyttä. Fixura käyttää asiakasviestinnässään ensisijaisesti
        asiakkaan antamaa sähköpostiosoitetta ja puhelinnumeroa. Asiakas on
        velvollinen huolehtimaan sähköpostin, puhelinnumeron ja muiden
        yhteystietojensa ajantasaisuudesta sekä ilmoittamaan muutoksista
        välittömästi Fixuralle.
      </p>

      <h3>8. VASTUUNRAJOITUS</h3>
      <p>
        Fixura ei vastaa mistään suorista, epäsuorista, välittömistä tai
        välillisistä vahingoista, joita Lainanottajalle tai Sijoittajalle voi
        aiheutua. Fixura ei näin ollen vastaa esimerkiksi saamatta jääneestä
        voitosta, tuotosta, maineen, liikearvon tai tiedon menetyksestä,
        korvaavien tuotteiden tai palveluiden hankintakustannuksista tai muista
        aineettomista taikka välillisistä vahingoista.
      </p>

      <h3>9. MUUT EHDOT</h3>
      <p>
        Fixura pidättää itsellään oikeuden muuttaa näitä Yleisiä Ehtoja.
        Muutoksista ilmoitetaan niitä koskeville osapuolille kirjallisesti ennen
        muutoksen voimaantuloa. Jos Lainasopimus taikka Sijoittajasopimus ja
        nämä Yleiset Ehdot ovat keskenään ristiriidassa, Lainasopimusta tai
        Sijoittajasopimusta noudatetaan ensisijaisena.
      </p>

      <h3>10. YLIVOIMAINEN ESTE</h3>
      <p>
        Fixura, Sijoittaja tai Lainanottaja ei vastaa vahingoista, jotka
        aiheutuvat ylivoimaisesta esteestä. Vastuuta ei ylivoimaisena esteenä
        pidettävällä perusteella synny, jos osapuoli voi osoittaa, että sille
        kuuluvan velvoitteen täyttämisen on estänyt sellainen epätavallinen tai
        ennalta-arvaamaton syy, johon se ei ole voinut vaikuttaa ja jonka
        seurauksia se ei kaikkea huolellisuutta noudattaen olisi voinut välttää;
        kuten esimerkiksi sota, luonnontapahtuma, tulipalo, yleisen
        tietoliikenteen keskeytyminen, työselkkaus, viranomaisten toimenpide,
        valuuttarajoitus, kriisiajan velvoite ja/tai kaupankäynnin rajoitus.
      </p>

      <h3>11. SOVELLETTAVA LAKI JA RIIDANRATKAISU</h3>
      <p>
        Lainanottajan ja Sijoittajan välisessä velkasuhteessa sekä yhteistyössä
        Sijoittajan ja Fixuran välillä sovelletaan Suomen lakia, lukuun
        ottamatta sen lainvalintasäännöksiä.
      </p>
      <p>
        Kaikki Lainanottajan, Sijoittajan ja Fixuran väliset erimielisyydet,
        jotka johtuvat näistä Yleisistä Ehdoista ja/tai Lainasopimuksesta
        ratkaistaan lähtökohtaisesti neuvottelemalla. Lainanottajalla on oikeus
        lisäksi kääntyä toimivaltaisten viranomaisten puoleen ja saattaa asia
        kuluttajariitalautakunnan ratkaistavaksi. Jos osapuolet eivät pysty
        ratkaisemaan erimielisyyksiään kohtuullisessa ajassa vilpittömässä
        mielessä käytävien neuvotteluiden avulla, erimielisyydet ratkaistaan
        Pohjanmaan käräjäoikeudessa ensimmäisenä oikeusasteena.
      </p>
    </div>
  )
}

export default TermsAndConditions
