import React from 'react'
import { WithTranslation, withTranslation, useTranslation } from 'react-i18next'
import browserHistory from '../../browserHistory'
import { Col, Container, Row } from 'reactstrap'
import { compose } from 'redux'
import ic_back_circle from 'assets/images/ic_back_circle.svg'

interface Props extends WithTranslation {}

const BackButton: React.FunctionComponent<Props> = ({ children }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Row>
        <Col className="my-3" md={6}>
          <img
            onClick={browserHistory.goBack}
            color="link"
            src={ic_back_circle}
            alt={t('BACK')}
            className="text-left pointer"
          />
        </Col>
        {children && (
          <Col className="text-right my-3" md={6}>
            {children}
          </Col>
        )}
      </Row>
    </Container>
  )
}

const enhancer = compose<React.FunctionComponent>(withTranslation())

export default enhancer(BackButton)
