export const POST_WITHDRAW_REQUEST =
  'user.investor.dashboard.withdraw.POST_WITHDRAW_REQUEST'
export const POST_WITHDRAW_SUCCESS =
  'user.investor.dashboard.withdraw.POST_WITHDRAW_SUCCESS'
export const POST_WITHDRAW_FAILURE =
  'user.investor.dashboard.withdraw.POST_WITHDRAW_FAILURE'
export const POST_WITHDRAW_RESET =
  'user.investor.dashboard.withdraw.POST_WITHDRAW_RESET'
export const STORE_WITHDRAW_AMOUNT =
  'user.investor.dashboard.withdraw.STORE_WITHDRAW_AMOUNT'
export const POST_CANCEL_WITHDRAW_REQUEST =
  'user.investor.dashboard.withdraw.POST_CANCEL_WITHDRAW_REQUEST'
export const POST_CANCEL_WITHDRAW_SUCCESS =
  'user.investor.dashboard.withdraw.POST_CANCEL_WITHDRAW_SUCCESS'
export const POST_CANCEL_WITHDRAW_FAILURE =
  'user.investor.dashboard.withdraw.POST_CANCEL_WITHDRAW_FAILURE'
export const GET_WITHDRAW_STATUS_REQUEST =
  'user.investor.dashboard.withdraw.GET_WITHDRAW_STATUS_REQUEST'
export const GET_WITHDRAW_STATUS_SUCCESS =
  'user.investor.dashboard.withdraw.GET_WITHDRAW_STATUS_SUCCESS'
export const GET_WITHDRAW_STATUS_FAILURE =
  'user.investor.dashboard.withdraw.GET_WITHDRAW_STATUS_FAILURE'
