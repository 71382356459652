export const POST_COMPANY_AML_REQUEST =
  'user.investor.aml.POST_COMPANY_AML_REQUEST'
export const POST_COMPANY_AML_SUCCESS =
  'user.investor.aml.POST_COMPANY_AML_SUCCESS'
export const POST_COMPANY_AML_FAILURE =
  'user.investor.aml.POST_COMPANY_AML_FAILURE'

export const POST_COMPANY_REQUEST = 'user.investor.aml.POST_COMPANY_REQUEST'
export const POST_COMPANY_SUCCESS = 'user.investor.aml.POST_COMPANY_SUCCESS'
export const POST_COMPANY_FAILURE = 'user.investor.aml.POST_COMPANY_FAILURE'
