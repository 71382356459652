import { compose } from 'redux'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { withTranslation } from 'react-i18next'
import MappedDispatchModel from './MappedDispatch.model'
import MappedStateModel from './MappedState.model'
import postWithdraw from '../../../../store/user/investor/dashboard/withdraw/actions/postWithdraw'
import { State } from '../../../../store'
import Presentation from './Presentation'
import { Company } from 'models/Auth/ProfileData'
import browserHistory from 'browserHistory'

const mapStateToProps = (state: State): MappedStateModel => {
  const companyId = browserHistory.location.pathname.split('/')[3]
  const companies = state.user.profile.data.companies
  const company: Company | undefined = companyId
    ? companies.find((company) => company.id === parseInt(companyId))
    : undefined
  if (company) {
    return {
      bankAccounts: company.bank_accounts,
      amount: state.user.investor.dashboard.withdraw.amount,
      depositId:
        company.deposits.length > 0 ? company.deposits[0].mambu_deposit_id : '',
      data: state.user.investor.dashboard.kpi.data,
    }
  }
  return {
    bankAccounts: state.user.profile.data.bank_accounts,
    amount: state.user.investor.dashboard.withdraw.amount,
    depositId:
      state.user.profile.data.deposits.length > 0
        ? state.user.profile.data.deposits[0].mambu_deposit_id
        : '',
    data: state.user.investor.dashboard.kpi.data,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  withdraw: (depositId: string) =>
    dispatch((dispatch: ThunkDispatch<any, any, any>, getState: () => State) =>
      dispatch(
        postWithdraw(
          getState().user.investor.dashboard.withdraw.amount,
          depositId
        )
      )
    ),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, MappedDispatchModel, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Presentation)
