import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { createPostTermsAndCondtionAndPriceListSuccessAction } from './creators'

export const postTermsAndCondtionAndPriceList = (): ThunkAction<
  any,
  any,
  any,
  any
> => async (dispatch: Dispatch) => {
  dispatch(createPostTermsAndCondtionAndPriceListSuccessAction())
}
