import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
  createGetCheckLoanApplicationStatusFailureAction,
  createGetCheckLoanApplicationStatusRequestAction,
  createGetCheckLoanApplicationStatusSuccessAction,
} from './creators'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import browserHistory from '../../../../../browserHistory'
import updateLoanApplication from './updateLoanApplication'
import { checkoutStep3 } from '../../../../../utils/gtm/checkout'

const checkLoanStatus = (
  loanApplicationId: number
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(createGetCheckLoanApplicationStatusRequestAction())

  try {
    const borrowerApi = new BorrowerAPI()
    const response = await borrowerApi.checkLoanStatus(loanApplicationId)
    const { data } = response.data
    dispatch(
      createGetCheckLoanApplicationStatusSuccessAction(response.data.data)
    )
    //google tag
    checkoutStep3()
    if (
      data.user &&
      data.user.bank_accounts.length > 0 &&
      !data.due_date &&
      !data.bank_account_id
    ) {
      dispatch(
        updateLoanApplication({
          due_date: 1,
          bank_account_id: response.data.data.user.bank_accounts[0].id,
        })
      )
    }

    browserHistory.push('/dashboard/borrower/view-offer')
  } catch (error) {
    dispatch(createGetCheckLoanApplicationStatusFailureAction(error))
    if (error.response && error.response.status === 400) {
      if (error.response.data.retry) {
        browserHistory.push('/dashboard/borrower/aisp-rejected')
      } else {
        browserHistory.push('/dashboard/borrower/loan-rejected')
      }
    }
  }
}

export default checkLoanStatus
