import updateLoanApplication from './updateLoanApplication'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../../index'

export default (dueDate: number) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => State
) => {
  const { bank_account_id } = getState().user.borrower.loan_application.data
  if (bank_account_id) {
    dispatch(
      updateLoanApplication({
        bank_account_id,
        due_date: dueDate,
      })
    )
  }
}
