import {
  LEGACY_INVOICE_FAILURE,
  LEGACY_INVOICE_REQUEST,
  LEGACY_INVOICE_SUCCESS,
  LEGACY_LOAN_FAILURE,
  LEGACY_LOAN_REQUEST,
  LEGACY_LOAN_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from './types'
import Loan from '../../../../../models/Loan'
import { Loan as LegacyLoan } from '../../../../../models/Legacy/Borrower/Loan'

export const createUpdateRequestAction = (data: Loan) => ({
  data,
  type: UPDATE_REQUEST,
})
export const createUpdateSuccessAction = (data: Loan) => ({
  data,
  type: UPDATE_SUCCESS,
})
export const createUpdateFailureAction = (error: any) => ({
  error,
  type: UPDATE_FAILURE,
})

export const createLegacyLoanRequestAction = () => ({
  type: LEGACY_LOAN_REQUEST,
})
export const createLegacyLoanSuccessAction = (data: LegacyLoan[]) => ({
  data,
  type: LEGACY_LOAN_SUCCESS,
})
export const createLegacyLoanFailureAction = (error: any) => ({
  error,
  type: LEGACY_LOAN_FAILURE,
})

export const createLegacyInvoiceRequestAction = () => ({
  type: LEGACY_INVOICE_REQUEST,
})
export const createLegacyInvoiceSuccessAction = () => ({
  type: LEGACY_INVOICE_SUCCESS,
})
export const createLegacyInvoiceFailureAction = (error: any) => ({
  error,
  type: LEGACY_INVOICE_FAILURE,
})
