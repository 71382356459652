import {
  createUpdateCCISRequestAction,
  createUpdateCCISSuccessAction,
  createUpdateCCISFailureAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import { TINK_LINK_URL } from '../../../../../constants'
import browserHistory from '../../../../../browserHistory'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import { State } from '../../../../index'

const updateCCIS = (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const loanApplicationId = getState().user.borrower.loan_application.data.id
  const isCheckedCcis = getState().user.borrower.loan_application
    .is_checked_ccis

  dispatch(createUpdateCCISRequestAction())

  try {
    const borrowerAPI = new BorrowerAPI()
    const response: AxiosResponse = await borrowerAPI.updateIsCheckedCCIS(
      loanApplicationId,
      isCheckedCcis
    )
    if (response.data.data?.loan_application.service === 'nordigen-tink') {
      window.location.href = TINK_LINK_URL
    }
    if (response.data.data?.rejected) {
      browserHistory.push('/dashboard/borrower/loan-rejected')
    } else {
      dispatch(
          createUpdateCCISSuccessAction(response.data.data.loan_application, response.data.data.redirect_url)
      )
      browserHistory.push('/dashboard/borrower/kreditz')
    }
  } catch (error) {
    dispatch(createUpdateCCISFailureAction(error))
  }
}

export default updateCCIS
