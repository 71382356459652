import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { State } from '../../../../store'
import { formatMoney } from '../../../../utils'
import LoanStatusEnum from '../LoanStatus/LoanStatus.enum'

interface IProps {
  legacyLoanId?: number
  loanMambuId?: string
}
interface Props extends MappedState, IProps {}

const LoanLeft = (props: Props) => {
  const { amount, isHidden, loanAccountState } = props

  if (isHidden || loanAccountState === LoanStatusEnum.DEBT_COLLECTION) {
    return null
  }

  return amount ? (
    <div className="sm-hide"> {`${formatMoney(amount)} €`}</div>
  ) : null
}

interface MappedState {
  amount?: number
  isHidden?: boolean
  loanAccountState?: string
}

const mapStateToProps = (
  state: State,
  { legacyLoanId, loanMambuId }: IProps
): MappedState => {
  const data = state.user.borrower.loan.loansStatus
  const legacyData = state.legacy.borrower.loan.data

  if (legacyLoanId) {
    const loan = legacyData.find((loan) => loan.Reference === legacyLoanId)
    if (loan) {
      return {
        amount: loan.RemainingDebt,
        loanAccountState: loan.loanAccountState,
      }
    }
  }
  if (data) {
    // @ts-ignore
    const loan = data.find((loan) => loan.loan_id === loanMambuId)
    if (loan) {
      return {
        amount: loan.loanLeft,
        loanAccountState: loan.status,
      }
    }
  }

  return {
    isHidden: true,
  }
}

const enhancer = compose(connect<MappedState, any, any, any>(mapStateToProps))

export default enhancer(LoanLeft)
