import { SET_BORROWER_AFFILIATE_CODE_AND_NAME } from './actions/types'
import { SET_INVESTOR_AFFILIATE_CODE_AND_NAME } from './actions/types'

export interface State {
  borrower_affiliate_name: string
  borrower_unique_code: string
  investor_affiliate_name: string
  investor_unique_code: string
}

export default (state: State = initialState, action: any) => {
  switch (action.type) {
    case SET_BORROWER_AFFILIATE_CODE_AND_NAME:
      return {
        ...state,
        borrower_unique_code: action.unique_code,
        borrower_affiliate_name: action.affiliate_name,
      }
    case SET_INVESTOR_AFFILIATE_CODE_AND_NAME:
      return {
        ...state,
        investor_unique_code: action.unique_code,
        investor_affiliate_name: action.affiliate_name,
      }
    default:
      return state
  }
}

export const initialState: State = {
  borrower_affiliate_name: '',
  borrower_unique_code: '',
  investor_affiliate_name: '',
  investor_unique_code: '',
}
