import { ThunkDispatch } from 'redux-thunk'
import {
  createVerifyPhoneNumberFailureAction,
  createVerifyPhoneNumberRequestAction,
  createVerifyPhoneNumberSuccessAction,
} from './creators'
import SmsLoginAPI from '../../../../../api/auth/SmsLoginAPI'
import { State } from '../../../../index'
import { VerifyPhoneNumber } from '../../../../../models/Auth/Sms/VerifyPhoneNumber'
import { getUserProfile } from '../../../../user/profile/actions/getUserProfile'
import { setToken } from '../../../../../utils/auth'

export default (code: string) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => State
) => {
  const { language } = getState().user.profile.data
  const body: VerifyPhoneNumber = {
    code,
    mobile_phone: getState().auth.login.sms.mobile_phone,
    affiliate_name: getState().marketing.affiliate.borrower_affiliate_name
      ? getState().marketing.affiliate.borrower_affiliate_name
      : getState().marketing.affiliate.investor_affiliate_name,
    unique_affiliate_id: getState().marketing.affiliate.borrower_unique_code
      ? getState().marketing.affiliate.borrower_unique_code
      : getState().marketing.affiliate.investor_unique_code,
    language,
  }
  dispatch(createVerifyPhoneNumberRequestAction())

  try {
    const smsLogin = new SmsLoginAPI()
    const response = await smsLogin.verifyPhoneNumber(body)
    dispatch(createVerifyPhoneNumberSuccessAction(response.data.data.blaId))
    setToken(response.data.data.token)

    if (response.data.data.blaId) {
      dispatch(getUserProfile('/dashboard/borrower/loan-application'))
    } else {
      dispatch(getUserProfile('/dashboard'))
    }
  } catch (error) {
    dispatch(
      createVerifyPhoneNumberFailureAction(
        error &&
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.code
      )
    )
  }
}
