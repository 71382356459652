import API from '../API'

export default class DocumentApi extends API {
  public getDocument(documentId: string) {
    return this.get(
      `api/document/download/${documentId}`,
      {},
      {},
      { responseType: 'arraybuffer' }
    )
  }
  public getCompanyDocument(documentId: number) {
    return this.get(
      `api/document/company/download/${documentId}`,
      {},
      {},
      { responseType: 'arraybuffer' }
    )
  }
}
