import { connect } from 'react-redux'
import Presentation from './Presentation'
import { State } from '../../../../store'
import MappedStateModel from './MappedState.model'
import InvoiceTypeEnum from './InvoiceType.enum'
import Invoice from '../../../../models/Legacy/Borrower/Invoice'
import { formatDate } from '../../../../utils'
import getLegacyInvoice from '../../../../store/legacy/borrower/loan/actions/getLegacyInvoice'
import MappedDispatchModel from './MappedDispatch.model'
import browserHistory from 'browserHistory'

const mapStateToProps = (state: State): MappedStateModel => {
  const { data } = state.legacy.borrower.loan
  const selectedLoanId = browserHistory.location.pathname.split('/').pop()

  if (selectedLoanId) {
    // @ts-ignore
    const loan = data.find((loan) => loan.Reference === selectedLoanId)
    return {
      data:
        loan &&
        loan.LoanPlan.map((loanPlan) => ({
          legacyUserId: loan.legacy_user_id,
          dueDate: formatDate(loanPlan.DueDate, 'YYYY-MM-DD'),
          totalAmount: loanPlan.TotalAmount,
          invoiceStatus: getInvoiceStatus(loanPlan.Invoice),
          invoiceId: getInvoiceId(loanPlan.Invoice),
          interestRate: loan.InterestRate,
          invoiceTotalAmount: loanPlan.Invoice?.TotalAmount,
          invoicePaidAmount: loanPlan.Invoice?.PaidAmount,
          interest: loanPlan.InterestAmount,
          fees: loanPlan.TotalFeeAmount,
          principal: loanPlan.DebtAmount,
        })),
    }
  }
  return { isHidden: true }
}

const mapDispatchToProps = (dispatch): MappedDispatchModel => ({
  downloadInvoice: (invoiceId, legacyUserId) =>
    dispatch(getLegacyInvoice(invoiceId, legacyUserId)),
})

const getInvoiceStatus = (invoice?: Invoice): InvoiceTypeEnum => {
  if (invoice) {
    switch (invoice.InvoiceStatus) {
      case 40:
        return InvoiceTypeEnum.READY_FOR_INVOICING
      case 60:
        return InvoiceTypeEnum.INVOICED
      case 80:
        return InvoiceTypeEnum.PARTIALLY_PAID
      case 100:
        return InvoiceTypeEnum.PAID
      case 120:
        return InvoiceTypeEnum.OVERPAID
      case 140:
        return InvoiceTypeEnum.CANCELED
    }
  }
  return InvoiceTypeEnum.READY_FOR_INVOICING
}

const getInvoiceId = (invoice?: Invoice) => {
  if (invoice) {
    return invoice.InvoiceNumber
  }
  return undefined
}

export default connect<MappedStateModel, MappedDispatchModel, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(Presentation)
