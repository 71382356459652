import {
  createGetBorrowerLoanFailureAction,
  createGetBorrowerLoanRequestAction,
  createGetBorrowerLoanSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import { State } from '../../../../index'

const getBorrowerLoan = (id: number): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const { loans } = getState().user.profile.data
  if (loans.length === 0) {
    return
  }
  const loanId = loans.find((loan) => loan.id === id)?.mambu_loan_id
  if (loanId) {
    dispatch(createGetBorrowerLoanRequestAction())
    try {
      const borrowerAPI = new BorrowerAPI()
      const response = await borrowerAPI.getBorrowerLoan(loanId)
      dispatch(createGetBorrowerLoanSuccessAction(response.data.data, loanId))
    } catch (error) {
      dispatch(createGetBorrowerLoanFailureAction(error))
    }
  }
}

export default getBorrowerLoan
