import React from 'react'
import classNames from 'classnames'
import { dayjs } from './utils'
import { Table } from './Table'
import CalendarBody from './CalendarBody'
import CalendarHead from './CalendarHead'
import PickerUI from './PickerUI'
import { Dayjs } from 'dayjs'

interface Props {
  opens: string
  position?: string
  name?: string
}

class LinkedCalendar extends React.Component<Props> {
  state = {
    leftCalendar: dayjs().subtract(1, 'month'),
    rightCalendar: dayjs(),
  }

  static defaultProps = {
    position: 'left',
  }
  createProps = () => {
    const { leftCalendar, rightCalendar } = this.state
    const leftState = Object.assign({}, this.props, { calendar: leftCalendar })
    const rightState = Object.assign({}, this.props, {
      calendar: rightCalendar,
    })
    // tslint:disable-next-line:no-this-assignment
    const { handlePrev, handleNext } = this
    return {
      leftProps: {
        handlePrev,
        handleNext: () => {},
        handleSelected: handlePrev,
        showNext: false,
        ...leftState,
      },
      rightProps: {
        handleNext,
        showPrev: false,
        handlePrev: () => {},
        handleSelected: handleNext,
        ...rightState,
      },
    }
  }

  handlePrev = (leftCalendar: any) => {
    this.setState({
      leftCalendar,
      rightCalendar: leftCalendar.add(1, 'month'),
    })
  }

  handleNext = (rightCalendar: any) => {
    this.setState({
      rightCalendar,
      leftCalendar: rightCalendar.subtract(1, 'month'),
    })
  }

  renderTable = () => {
    const props = this.createProps()
    const { leftProps, rightProps } = props
    const className = classNames({
      'drp-calendar': true,
      left: true,
    })

    return [
      <div className={className} key={0}>
        <div className="calendar-table">
          <Table className="table-condensed">
            <CalendarHead {...leftProps} />
            <CalendarBody {...leftProps} />
          </Table>
        </div>
      </div>,
      <div className={className} key={1}>
        <div className="calendar-table">
          <Table className="table-condensed">
            <CalendarHead {...rightProps} />
            <CalendarBody {...rightProps} />
          </Table>
        </div>
      </div>,
    ]
  }

  render() {
    const { opens, children } = this.props
    const className = classNames({
      [`opens${opens}`]: true,
      'daterangepicker ltr show-calendar': true,
    })
    return (
      <div
        className={className}
        style={{
          left: 'auto',
          display: 'block',
        }}
      >
        {this.renderTable()}
        {children}
      </div>
    )
  }
}

interface UIProps {
  onDatesChange: (date: any) => void
  showDropdowns: boolean
  opens: 'left' | 'right'
  isVisible: boolean
  initialDate: {
    startDate?: Dayjs
    endDate?: Dayjs
  }
  name?: string
}

export default class LinkedCalendarUI extends React.Component<UIProps> {
  render() {
    const uiProps: any = { ...this.props, component: LinkedCalendar }
    return <PickerUI {...uiProps} />
  }
}
