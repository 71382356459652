import {
  POST_COMPANY_REQUEST,
  POST_COMPANY_SUCCESS,
  POST_COMPANY_FAILURE,
  POST_COMPANY_AML_REQUEST,
  POST_COMPANY_AML_SUCCESS,
  POST_COMPANY_AML_FAILURE,
} from './types'

export const createCompanyRequestAction = () => ({
  type: POST_COMPANY_REQUEST,
})
export const createCompanySuccessAction = () => ({
  type: POST_COMPANY_SUCCESS,
})
export const createCompanyFailureAction = (error: any) => ({
  error,
  type: POST_COMPANY_FAILURE,
})
export const createCompanyAmlRequestAction = () => ({
  type: POST_COMPANY_AML_REQUEST,
})
export const createCompanyAmlSuccessAction = () => ({
  type: POST_COMPANY_AML_SUCCESS,
})
export const createCompanyAmlFailureAction = (error: any) => ({
  error,
  type: POST_COMPANY_AML_FAILURE,
})
