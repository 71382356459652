import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'

interface Props {
  isVisible?: boolean
  onClick?: () => void
}

const LoadMore = (props: Props) => {
  const { isVisible, onClick } = props
  const { t } = useTranslation()

  if (!isVisible) {
    return null
  }

  return (
    <Row>
      <Col className="text-center my-4">
        <Button
          className="load-more_btn more d-inline-block"
          color="link"
          onClick={onClick}
        >
          {t('LOAD_MORE')}
        </Button>
      </Col>
    </Row>
  )
}

export default LoadMore
