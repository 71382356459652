import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Presentation from './Presentation'
import updateUserProfile from '../../store/user/profile/actions/updateUserProfile'
import { ThunkDispatch } from 'redux-thunk'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel from './MappedDispatch.model'
import { State } from '../../store'
import React from 'react'

const mapStateToProps = (state: State): MappedStateModel => ({
  language: state.user.profile.data.language,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  changeUserLanguage: (language: string) =>
    dispatch(updateUserProfile({ language })),
})

const enhancer = compose<React.FunctionComponent>(
  withRouter,
  withTranslation(),
  connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)
)

export default enhancer(Presentation)
