import {
  POST_EMAIL_AND_SMS_FAILURE,
  POST_EMAIL_AND_SMS_REQUEST,
  POST_EMAIL_AND_SMS_SUCCESS,
  POST_SMS_VERIFICATION_CODE_FAILURE,
  POST_SMS_VERIFICATION_CODE_REQUEST,
  POST_SMS_VERIFICATION_CODE_SUCCESS,
} from './actions/types'
import EmailAndSmsForRegistrationRequest from '../../../../models/Auth/Sms/EmailAndSmsForRegistrationRequest'

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_EMAIL_AND_SMS_REQUEST:
      return {
        ...state,
        is_fetching: true,
        request_body: action.requestBody,
        error: null,
      }
    case POST_SMS_VERIFICATION_CODE_REQUEST:
      return { ...state, is_fetching: true, error: null }
    case POST_EMAIL_AND_SMS_SUCCESS:
    case POST_SMS_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        is_fetching: false,
        error: null,
        state: action.state,
        blaId: action.blaId,
      }
    case POST_EMAIL_AND_SMS_FAILURE:
      return { ...state, is_fetching: false, error: action.error }
    case POST_SMS_VERIFICATION_CODE_FAILURE:
      return { ...state, is_fetching: false, error: action.error }
    default:
      return state
  }
}

export const initialState: State = {
  request_body: undefined,
  is_fetching: false,
  error: null,
}

export interface State {
  request_body?: EmailAndSmsForRegistrationRequest
  is_fetching: boolean
  error: any
  state?: string
  blaId?: string
}
