import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import CCISConsent from '../../Auth/CCISConsent'
import { connect } from 'react-redux'
import { State } from '../../../store'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'
import { createToggleIsCheckedCCISAction } from '../../../store/user/borrower/loan_application/actions/creators'

interface Props extends MappedState, MappedDispatch {}

const CCISModal = (props: Props) => {
  const { isCheckedCCIS, toggleIsCheckedCCIS } = props
  const { t } = useTranslation()
  const history = useHistory()

  const onClickAccept = () => {
    if (!isCheckedCCIS) {
      toggleIsCheckedCCIS()
    }
    history.goBack()
  }

  return (
    <Modal isOpen centered className="custom-class" toggle={history.goBack}>
      <ModalHeader toggle={history.goBack}>
        {t('CCIS_CONSENT_HEADLINE')}
      </ModalHeader>
      <ModalBody>
        <CCISConsent />
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button onClick={onClickAccept}>{t('ACCEPT')}</Button>
      </ModalFooter>
    </Modal>
  )
}

interface MappedState {
  isCheckedCCIS?: boolean
}

interface MappedDispatch {
  toggleIsCheckedCCIS: () => void
}

const mapStateToProps = (state: State): MappedState => ({
  isCheckedCCIS: state.user.borrower.loan_application.is_checked_ccis,
})

const mapDispatchToProps = (dispatch: Dispatch): MappedDispatch => ({
  toggleIsCheckedCCIS: () => dispatch(createToggleIsCheckedCCISAction()),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(CCISModal)
