import React from 'react'

interface InputProps {
  type?: string
  name?: string
  placeholder?: string
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
  hasError?: boolean
  errorMessage?: string
  onBlur?: () => {}
  id?: string
  className?: string
  onPaste?: (e: React.FormEvent<HTMLInputElement>) => void
  defaultValue?: string
}

const FixuraInput = (props: InputProps) => {
  return (
    <>
      <input
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        id={props.id}
        className={props.className}
        onPaste={props.onPaste}
        defaultValue={props.defaultValue}
      />
      {props.hasError && (
        <small className="form-error-message">{props.errorMessage}</small>
      )}
    </>
  )
}

FixuraInput.defaultProps = {
  type: 'text',
  name: '',
  placeholder: '',
  onChange: () => {},
  hasError: false,
  errorMessage: '',
  onBlur: () => {},
  id: '',
  className: '',
  onPaste: () => {},
  defaultValue: '',
}

export default FixuraInput
