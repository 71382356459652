import React from 'react'
import { Col, Row } from 'reactstrap'
import options from './options'
import RadioGroup from '../../../../../shared/RadioGroup'
import Rows from '../../../../../shared/Wrapper/Rows'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { ScrollElement } from '../../../../../../utils/scroll'

interface ComponentProps {
  setFields: (fields) => void
  fields: any
  errors: any
}

interface Props extends WithTranslation, ComponentProps {}

const AnnualInvestEstimation = (props: Props) => {
  const { t, setFields, fields, errors } = props
  const passFields = (id: string) => {
    setFields({
      ...fields,
      annual_investment_amount: id,
    })
  }
  return (
    <div className=" mb-5">
      <Row className="mb-4">
        <Col>
          <ScrollElement name="annual_investment_amount" />
          <div className="font-20-body">
            {t('ANNUAL_INVESTMENT_ESTIMATION')}
          </div>
        </Col>
      </Row>
      <RadioGroup
        elements={options}
        selectedElementId={
          fields.hasOwnProperty('annual_investment_amount') &&
          fields['annual_investment_amount']
        }
        onChange={passFields}
        ElementWrapper={Rows}
        hasError={errors.hasOwnProperty('annual_investment_amount')}
        errorMessage={errors['annual_investment_amount']}
      />
    </div>
  )
}

const enhancer = compose<React.FunctionComponent<ComponentProps>>(
  withTranslation()
)

export default enhancer(AnnualInvestEstimation)
