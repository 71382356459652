import { ThunkAction } from 'redux-thunk'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import { Action, Dispatch } from 'redux'
import {
  createPostTinkCodeRequestAction,
  createPostTinkCodeSuccessAction,
  createPostTinkCodeFailureAction,
} from './creators'
import { State } from '../../../../index'
import browserHistory from '../../../../../browserHistory'

const postTinkCode = (
  code: string
): ThunkAction<void, State, void, Action> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const activeLoanApplicationId = getState().user.borrower.loan_application.data
    .id

  if (activeLoanApplicationId === 0) {
    return
  }

  dispatch(createPostTinkCodeRequestAction())

  try {
    const borrowerAPI = new BorrowerAPI()
    await borrowerAPI.postTinkCode(activeLoanApplicationId, code)
    dispatch(createPostTinkCodeSuccessAction())
    browserHistory.push(
      `/dashboard/borrower/aisp?id=${activeLoanApplicationId}`
    )
  } catch (error) {
    dispatch(createPostTinkCodeFailureAction(error))
    browserHistory.push('/dashboard/borrower')
  }
}

export default postTinkCode
