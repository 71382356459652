import { combineReducers } from 'redux'
import loan_contract, {
  initialState as loanContractInitialState,
  State as LoanContractState,
} from './loan_contract'
import loan_calculator, {
  initialState as loanCalculatorInitialState,
  State as LoanCalculatorState,
} from './loan_calculator'
import loan_application, {
  initialState as loanApplicationInitialState,
  State as LoanApplicationState,
} from './loan_application'
import loan, {
  initialState as loanInitialState,
  State as LoanState,
} from './loan'
import store_loan, {
  initialState as storeLoanInitialState,
  State as StoreLoanState,
} from './store_loan'
import totalLoanAmtLeft, {
  initialState as totalLoanAmtLeftInitialState,
  State as TotalLoanAmtLeftState,
} from './totalLoanAmtLeft'

export interface State {
  loan: LoanState
  loan_calculator: LoanCalculatorState
  loan_application: LoanApplicationState
  loan_contract: LoanContractState
  store_loan: StoreLoanState
  totalLoanAmtLeft: TotalLoanAmtLeftState
}

export default combineReducers<State>({
  loan,
  loan_contract,
  loan_calculator,
  loan_application,
  store_loan,
  totalLoanAmtLeft,
})

export const initialState: State = {
  loan: loanInitialState,
  loan_calculator: loanCalculatorInitialState,
  loan_contract: loanContractInitialState,
  loan_application: loanApplicationInitialState,
  store_loan: storeLoanInitialState,
  totalLoanAmtLeft: totalLoanAmtLeftInitialState,
}
