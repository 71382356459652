import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Spinner from '../../shared/Spinner'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import { State } from '../../../store'
import checkLoanStatusKreditz from '../../../store/user/borrower/loan_application/actions/checkLoanStatusKreditz'
import LoanApplication from '../../../models/Borrower/LoanApplication'
import getActiveLoanApplication from '../../../store/user/borrower/loan_application/actions/getActiveLoanApplication'

interface Props extends MappedDispatch, MappedState {}

const GatherInformation = (props: Props) => {
  const { loanApplication, getActiveLoanApplication, checkLoanStatus } = props
  const { t } = useTranslation()
  const [counter, setCounter] = useState(0)

  useEffect(
    () => {
      if (!loanApplication?.id) {
        getActiveLoanApplication()
        return
      }

      checkLoanStatus(loanApplication.id)
    },
    [loanApplication?.id, counter]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter => counter + 1)  // will cause rerender and rerun the previous effect
    }, 10000)

    // component will unmount
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, []);

  return (
    <div className="m-5 p-5">
      <Spinner spinnerHeight={150} spinnerWidth={150} />
      <h1 className="text-center mt-5">{t('PROCESSING_THE_APPLICATION')}</h1>
      <h6 className="text-center pb-5">{t('DO_NOT_REFRESH')}.</h6>
    </div>
  )
}

interface MappedState {
  loanApplication: LoanApplication
  retry: boolean
}

interface MappedDispatch {
  getActiveLoanApplication: () => void
  checkLoanStatus: (loanApplicationId: number) => void
}

const mapStateToProps = (state: State): MappedState => ({
  loanApplication: state.user.borrower.loan_application.data,
  retry: !!state.user.borrower.loan_application.data.retry,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  getActiveLoanApplication: () => dispatch(getActiveLoanApplication()),
  checkLoanStatus: (loanApplicationId: number) =>
    dispatch(checkLoanStatusKreditz(loanApplicationId)),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(GatherInformation)
