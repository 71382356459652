import { connect } from 'react-redux'
import { State } from '../../store'
import { getFixuraToken, isLegacy } from '../../utils/auth'
import PrivateRoutePresentation from './PrivateRoutePresentation'

export default connect((state: State) => {
  const { auth } = state.legacy
  return {
    isAuthorized: auth.is_logged_in || (!!getFixuraToken() && isLegacy()),
    pathname: '/login',
  }
})(PrivateRoutePresentation)
