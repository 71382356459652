import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import { WithTranslation } from 'react-i18next'
import MappedDispatchModel from './MappedDispatch.model'
import MappedStateModel from './MappedState.model'
import getAutoInvestStatus from '../../../store/user/investor/auto_invest/actions/getAutoInvestStatus'
import ic_bar_char_decrease from 'assets/images/ic_bar_char_decrease.svg'
import ic_bar_char_fixed from 'assets/images/ic_bar_char_fixed.svg'
import finlandFlagRoundSmall from 'assets/images/flags/finland-flag-round-small.png'
import Indicator from '../../shared/Indicator'
import AutoInvest from '../../Profile/Autoinvest'
import { useParams } from 'react-router-dom'
import browserHistory from '../../../browserHistory'
import { useLocation } from 'react-router-dom'

interface Props
  extends WithTranslation,
    MappedStateModel,
    MappedDispatchModel {}

const PortfolioSettings = (props: Props) => {
  const { t, isFetching, depositId, isSignRight } = props

  const [isSignRightStatus, setIsSignRightStatus] = useState(true)
  const { companyId } = useParams()
  const location = useLocation()

  const type = location.pathname.includes('fair')
    ? 'fair'
    : location.pathname.includes('fixed')
    ? 'fixed'
    : ''

  useEffect(() => {
    const status = '2'
    if (companyId && parseInt(isSignRight) !== parseInt(status)) {
      setIsSignRightStatus(false)
    } else {
      setIsSignRightStatus(true)
    }
  }, [isSignRight, companyId])

  useEffect(() => {
    if (depositId) getAutoInvestStatus(depositId, companyId)
  }, [companyId, depositId])

  const showAutoInvestStatus = () => {
    const url = browserHistory.location.pathname.split('/')
    /* Fixme logic */
    if (
      (!browserHistory.location.pathname.includes('portfolio') &&
        (url.length === 3 || url.length === 4)) ||
      browserHistory.location.pathname === '/dashboard'
    ) {
      return true
    }
  }

  return (
    <>
      {showAutoInvestStatus() ? (
        <div className="next-installment">
          <div className="next-installment-info ml-10">
            <AutoInvest
              companyId={companyId}
              isSignRight={isSignRight}
              isSignRightStatusPortfolioSettings={isSignRightStatus}
            />
          </div>
        </div>
      ) : (
        <div className="next-installment">
          <>
            <Indicator isVisible={isFetching} />
            {(type === 'fair' || type === 'fixed') && (
              <Row>
                <Col>
                  <h1 className="next-installment__header">{t('PORTFOLIO')}</h1>
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                <div className="next-installment-info__wrapper ml-10">
                  {(type === 'fair' || type === 'fixed') && (
                    <>
                      <div className="next-installment-info">
                        <img
                          className="img_bar_chart"
                          src={
                            type === 'fair'
                              ? ic_bar_char_decrease
                              : type === 'fixed'
                              ? ic_bar_char_fixed
                              : ''
                          }
                          alt="chart_icon"
                        />
                        {type === 'fair' ? (
                          <span>{t('VARIABLE_INTEREST_LOANS')}</span>
                        ) : type === 'fixed' ? (
                          <span>{t('LOAN_WITH_FIXED_RATE')}</span>
                        ) : null}
                      </div>
                      <div className="next-installment-info">
                        <img
                          className="mb-1_add_company h-2 px-10"
                          src={finlandFlagRoundSmall}
                          alt="flag"
                        />
                        <span>{t('FINLAND')}</span>
                      </div>
                    </>
                  )}
                  {type === 'fair' && (
                    <div className="next-installment-info ml-10">
                      <AutoInvest
                        companyId={companyId}
                        isSignRight={isSignRight}
                        isSignRightStatusPortfolioSettings={isSignRightStatus}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </>
        </div>
      )}
    </>
  )
}

export default PortfolioSettings
