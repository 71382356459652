import 'bootstrap/dist/css/bootstrap.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { Router } from 'react-router'
import browserHistory from './browserHistory'
import { Provider } from 'react-redux'
import { store } from './store'
import './assets/styles/custom.scss'
import Views from './Views'
import { getFixuraToken, getLegacyData, isLegacy } from './utils/auth'
import { getUserProfile } from './store/user/profile/actions/getUserProfile'
import { createLoginWithUserPassSuccessAction } from './store/legacy/auth/actions/creators'

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Router history={browserHistory}>
        <Views />
      </Router>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
)

if (getFixuraToken() !== null) {
  if (isLegacy()) {
    store.dispatch(createLoginWithUserPassSuccessAction(getLegacyData()))
  } else {
    // @ts-ignore
    store.dispatch(getUserProfile())
  }
}
