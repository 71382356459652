import { combineReducers } from 'redux'
import loan, {
  State as LoanState,
  initialState as loanInitialState,
} from './loan'

export interface State {
  loan: LoanState
}

export default combineReducers<State>({
  loan,
})

export const initialState = {
  loan: loanInitialState,
}
