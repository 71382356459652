import {
  createLogoutFailureAction,
  createLogoutRequestAction,
  createLogoutSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import LogoutAPI from '../../../../api/auth/LogoutAPI'
import browserHistory from '../../../../browserHistory'
import { removeFixuraToken } from '../../../../utils/auth'
import { createSetShouldAgreeWithTermsAndConditionsAction } from '../../../auth/register/terms_and_conditions/actions/creators'

export default (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch
) => {
  dispatch(createLogoutRequestAction())
  const logoutAPI = new LogoutAPI()

  try {
    await logoutAPI.logoutUser()
    removeFixuraToken()
    dispatch(createSetShouldAgreeWithTermsAndConditionsAction(false))
    browserHistory.replace('/')
    dispatch(createLogoutSuccessAction())
  } catch (error) {
    removeFixuraToken()
    dispatch(createLogoutFailureAction(error))
    browserHistory.replace('/')
  }
}
