import {
  createAmlFailureAction,
  createAmlRequestAction,
  createAmlSuccessAction,
} from './creators'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import InvestorAPI from '../../../../../api/user/InvestorAPI'
import { getUserProfile } from '../../../profile/actions/getUserProfile'

const postAML = (
  data,
  forceAmlFillIn
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(createAmlRequestAction())

  try {
    const investorAPI = new InvestorAPI()
    await investorAPI.postAml(data, forceAmlFillIn)
    dispatch(createAmlSuccessAction())
    dispatch(getUserProfile('/onboarding/investor/contract'))
  } catch (error) {
    dispatch(createAmlFailureAction(error))
  }
}

export default postAML
