import { Col, Row } from 'reactstrap'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import LoadMoreLoadLess from '../../../shared/LoadMoreLoadLess'
import { InstalmentModel } from './Instalment.model'
import Instalment from './Instalment'
import MappedDispatchModel from './MappedDispatch.model'
import { ScrollElement } from 'utils/scroll'
import LoanStatusEnum from '../LoanStatus/LoanStatus.enum'

interface Props extends MappedStateModel, MappedDispatchModel {}

const PaymentPlan = (props: Props) => {
  const [allIsLoaded, setAllIsLoaded] = useState(false)
  const {
    data,
    isHidden,
    downloadInvoice,
    wrapperClass,
    loanAccountState,
  } = props

  const { t } = useTranslation()
  if (isHidden || loanAccountState === LoanStatusEnum.DEBT_COLLECTION) {
    return null
  }

  return (
    <>
      <ScrollElement name="payment-plan" />
      <aside className={`payment-plan ${wrapperClass}`}>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} className="text-center">
            <h1 className="payment-plan__title">{t('PAYMENT_PLAN')}</h1>
          </Col>
        </Row>
        {data &&
          data.map((instalment: InstalmentModel, index: number) => {
            if (allIsLoaded || index < 10) {
              return (
                <Instalment
                  key={instalment.dueDate}
                  legacyUserId={instalment.legacyUserId}
                  dueDate={instalment.dueDate}
                  interestRate={instalment.interestRate}
                  invoiceId={instalment.invoiceId}
                  invoiceStatus={instalment.invoiceStatus}
                  downloadInvoice={downloadInvoice}
                  totalAmount={instalment.totalAmount}
                  invoiceTotalAmount={instalment.invoiceTotalAmount}
                  invoicePaidAmount={instalment.invoicePaidAmount}
                  interest={instalment.interest}
                  fee={instalment.fee}
                  principal={instalment.principal}
                  discount={instalment.discount}
                />
              )
            }
            return null
          })}
        <LoadMoreLoadLess
          isVisible={data && data.length > 10}
          toggle={() => setAllIsLoaded(!allIsLoaded)}
          allIsLoaded={allIsLoaded}
        />
      </aside>
    </>
  )
}

export default PaymentPlan
