import {
  createGetPersonalDetailsRequestAction,
  createGetPersonalDetailsSuccessAction,
  createGetPersonalDetailsFailureAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import GeneralAPI from '../../../../api/legacy/GeneralAPI'
import { AxiosResponse } from 'axios'

const getPersonalDetails = (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch
) => {
  dispatch(createGetPersonalDetailsRequestAction())

  try {
    const generalAPI = new GeneralAPI()
    const response: AxiosResponse = await generalAPI.getProfileDetails()
    dispatch(
      createGetPersonalDetailsSuccessAction(
        response.data.data.token,
        response.data.data.legacy_users
      )
    )
  } catch (error) {
    dispatch(createGetPersonalDetailsFailureAction(error))
  }
}

export default getPersonalDetails
