import React from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import {
  urlTermsAndConditionsEN,
  urlTermsAndConditionsFI,
  urlTermsAndConditionsSV,
  urlPrivacyPolicyEN,
  urlPrivacyPolicyFI,
  urlPrivacyPolicySV,
  urlPriceListtEN,
  urlPriceListtFI,
  urlPriceListtSV,
} from '../../../constants'

const Fixura2023MarketingLink: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const language = store.getState().user.profile.data.language

  const setUrlTermsAndConditions = (language: string) => {
    switch (language) {
      case 'en':
        return urlTermsAndConditionsEN
      case 'fi':
        return urlTermsAndConditionsFI
      case 'se':
        return urlTermsAndConditionsSV
      default:
        return urlTermsAndConditionsEN
    }
  }

  const setUrlPrivacyPolicy = (language: string) => {
    switch (language) {
      case 'en':
        return urlPrivacyPolicyEN
      case 'fi':
        return urlPrivacyPolicyFI
      case 'se':
        return urlPrivacyPolicySV
      default:
        return urlPrivacyPolicyEN
    }
  }

  const setUrlPriceList = (language: string) => {
    switch (language) {
      case 'en':
        return urlPriceListtEN
      case 'fi':
        return urlPriceListtFI
      case 'se':
        return urlPriceListtSV
      default:
        return urlPriceListtEN
    }
  }

  return (
    <div>
      <div className="login-link d-flex text-left marketing">
        <a
          href={setUrlTermsAndConditions(language)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('TERMS_AND_CONDITIONS')}
        </a>
      </div>
      <div className="login-link d-flex text-left marketing">
        <a
          href={setUrlPrivacyPolicy(language)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('FOOTER_PRIVACY_POLICY')}
        </a>
      </div>
      <div className="login-link d-flex text-left marketing">
        <a
          href={setUrlPriceList(language)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('PRICE_LIST')}
        </a>
      </div>
    </div>
  )
}

export default Fixura2023MarketingLink
