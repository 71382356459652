import { combineReducers } from 'redux'
import investor, {
  State as InvestorState,
  initialState as investorInitialState,
} from './investor'
import auth, {
  State as AuthState,
  initialState as authInitialState,
} from './auth'
import borrower, {
  State as BorrowerState,
  initialState as borrowerInitialState,
} from './borrower'
import user, {
  State as UserState,
  initialState as userInitialState,
} from './user'

export interface State {
  investor: InvestorState
  auth: AuthState
  borrower: BorrowerState
  user: UserState
}

export default combineReducers<State>({
  investor,
  auth,
  borrower,
  user,
})

export const initialState: State = {
  investor: investorInitialState,
  auth: authInitialState,
  borrower: borrowerInitialState,
  user: userInitialState,
}
