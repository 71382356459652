import { connect } from 'react-redux'
import { State } from '../../../store'
import PrivateRoutePresentation from '../PrivateRoutePresentation'

export default connect((state: State) => {
  const { data } = state.user.borrower.loan_application

  return {
    isAuthorized: data.id !== 0,
    pathname: '/dashboard/borrower',
  }
})(PrivateRoutePresentation)
