import {
  GET_AGREEMENTS_REQUEST,
  GET_AGREEMENTS_SUCCESS,
  GET_AGREEMENTS_FAILURE,
  POST_SKIP_SIGNED_AGREEMENTS_SUCCESS,
} from './types'
import { Agreements } from '../../../../../models/Auth/ProfileData'

export const createAgreementsRequestAction = () => ({
  type: GET_AGREEMENTS_REQUEST,
})
export const createAgreementsSuccessAction = (data: Agreements) => ({
  data,
  type: GET_AGREEMENTS_SUCCESS,
})
export const createAgreementsFailureAction = (error: any) => ({
  error,
  type: GET_AGREEMENTS_FAILURE,
})
export const createPostSkipSignedAgreementsSuccessAction = () => ({
  type: POST_SKIP_SIGNED_AGREEMENTS_SUCCESS,
})
