import { store } from '../store'
import OnFidoTypeEnum from './OnFidoType.enum'

export const statusCheckOnFido = (status: number) => {
  switch (status) {
    case 0:
      return OnFidoTypeEnum.SUCCESS
    case 1:
      return OnFidoTypeEnum.PROCESSING
    case 2:
      return OnFidoTypeEnum.REJECTED
    case 3:
      return OnFidoTypeEnum.PROCESSING_SECOND_TIME
    case 4:
      return OnFidoTypeEnum.REJECTED_SECOND_TIME
    case 5:
      return OnFidoTypeEnum.PROCESSING_THIRD_TIME
    case 6:
      return OnFidoTypeEnum.REJECTED_THIRD_TIME
    default:
      return OnFidoTypeEnum.PROCESSING
  }
}

export const isOnFidoUserRejected = () => {
  const isOnFidoUser = store.getState().user.profile.data.is_foreigner
  const status = store.getState().user.profile.data.verification_error_code
  const isOnFidoUserStatus = statusCheckOnFido(status)
  const isOnFidoRejected =
    isOnFidoUserStatus === 'REJECTED' ||
    isOnFidoUserStatus === 'REJECTED_SECOND_TIME' ||
    isOnFidoUserStatus === 'REJECTED_THIRD_TIME'

  if (isOnFidoUser === 1 && isOnFidoRejected) {
    return true
  } else {
    return false
  }
}

export const isOnFidoUserVerified = () => {
  const isOnFidoUser = store.getState().user.profile.data.is_foreigner
  const status = store.getState().user.profile.data.verification_error_code
  const isOnFidoUserStatus = statusCheckOnFido(status)
  const isOnFidoVerified =
    isOnFidoUserStatus === 'PROCESSING' ||
    isOnFidoUserStatus === 'PROCESSING_SECOND_TIME' ||
    isOnFidoUserStatus === 'PROCESSING_THIRD_TIME'

  if (isOnFidoUser === 1 && isOnFidoVerified) {
    return true
  } else {
    return false
  }
}
