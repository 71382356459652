import {
  POST_AML_REQUEST,
  POST_AML_SUCCESS,
  POST_AML_FAILURE,
  GET_AML_SUCCESS,
  GET_AML_FAILURE,
  GET_AML_REQUEST,
} from './actions/types'
import Aml from '../../../../models/aml'

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_AML_REQUEST:
      return { ...state, is_fetching: true }
    case POST_AML_SUCCESS:
      return { ...initialState, is_successful: true }
    case POST_AML_FAILURE:
      return { ...state, error: action.error, is_fetching: false }
    case GET_AML_REQUEST:
      return { ...state, is_fetching: true }
    case GET_AML_SUCCESS:
      return {
        ...initialState,
        data: action.data,
        is_successful: true,
        is_fetching: false,
      }
    case GET_AML_FAILURE:
      return { ...initialState, is_fetching: false }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  is_successful: false,
  error: null,
  data: {
    is_familiar_with_publicly_traded_stocks: false,
    is_familiar_with_bonds: false,
    is_familiar_with_money_market_instruments: false,
    is_familiar_with_derivatives: false,
    is_familiar_with_investment_funds: false,
    investment_funds_specification: '',
    is_familiar_with_investments_on_my_own: false,
    is_familiar_with_investment_advice: false,
    is_familiar_with_asset_management: false,
    is_familiar_with_electronic_trading: false,
    is_familiar_with_insurance_investments: false,
    insurance_investments_specification: '',
    is_employee: false,
    is_entrepreneur: false,
    is_student: false,
    is_retired: false,
    profession: '',
    monthly_gross_income_amount: '',
    total_assets_amount: '',
    origin_of_invested_assets_is_salary: false,
    origin_of_invested_assets_is_investments: false,
    origin_of_invested_assets_is_business_activities_or_acquisition: false,
    origin_of_invested_assets_is_inheritance: false,
    origin_of_invested_assets_is_other: false,
    origin_of_invested_assets_specification: '',
    goal_of_investment_is_generate_returns: false,
    goal_of_investment_is_savings: false,
    goal_of_investment_is_pension: false,
    goal_of_investment_is_other: false,
    goal_of_investment_specification: '',
    annual_investment_count: '',
    annual_investment_amount: '',
    risk_profile: '',
    is_pep: false,
    is_member_of_parliament: false,
    is_president: false,
    is_close_to_member_of_parliament: false,
    is_close_to_president: false,
    is_business_partner_with_member_of_parliament: false,
    is_business_partner_with_president: false,
    has_no_experience_in_investment_vehicles: false,
    has_no_experience_in_investment_services: false,
    has_other_financial_situation: false,
    financial_situation: '',
  },
}

export interface State {
  is_fetching: boolean
  is_successful: boolean
  error: any
  data: Aml
}
