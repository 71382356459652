import { createLogoutSuccessAction } from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import browserHistory from '../../../../browserHistory'
import { removeFixuraToken } from '../../../../utils/auth'

export default (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch
) => {
  dispatch(createLogoutSuccessAction())
  removeFixuraToken()
  browserHistory.replace('/')
}
