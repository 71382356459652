import React from 'react'
import { State } from '../../../store'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Steps from './Steps'
import Indicator from '../../shared/Indicator'
import SignicatForm from '../SignicatForm'
import LinkForeignInvestor from './ForeignInvestor/LinkForeignInvestor'
import { Investor } from 'utils/isInvestor'
import { SHOW_INVESTOR_REGISTER_LINK } from '../../../constants'

interface Props extends WithTranslation {
  fetchTermsAndConditions: () => void
  redirectToSignicat: (methodName: string, goal: string) => void
  isFetching: boolean
  location: Location
}

const IdentityVerification = (props: Props) => {
  const { t, isFetching, location } = props
  /* isServiceBreak false (display none) */
  const isServiceBreak = false
  let isInvestor = Investor()
  let showLinkForeignInvestor = SHOW_INVESTOR_REGISTER_LINK

  let goal = 'register'
  if (location.pathname.startsWith('/onboarding/investor')) {
    goal = 'investor'
  }

  return (
    <section id="container" className="container borrower-onboarding min-h-72">
      <Indicator isVisible={isFetching} />
      <Steps currentStepIndex={0} />
      <div className="bank">
        <h1 className="font-50 step-big-title">
          {t('CHOOSE_A_VERIFICATION_METHOD')}
        </h1>
        {isServiceBreak && (
          <div className="center-text pt-10 px-15 pb-30">
            <p className="color-red">
              {t('ASIAKASTEITO_SERVICES_NOT_AVAILABLE')}
            </p>
          </div>
        )}
        <SignicatForm {...{ goal }} />
        {isInvestor && showLinkForeignInvestor && <LinkForeignInvestor />}
      </div>
    </section>
  )
}
interface MapStateToProps {
  isFetching: boolean
}

const mapStateToProps = (state: State) => ({
  isFetching:
    state.auth.register.terms_and_conditions.is_fetching ||
    state.auth.register.signicat.is_fetching,
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  withRouter,
  connect<MapStateToProps, any, any, any>(mapStateToProps)
)

export default enhancer(IdentityVerification)
