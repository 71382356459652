import React from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { WithTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel from './MappedDispatch.model'
import { formatMoney } from '../../../utils'
interface Props
  extends WithTranslation,
    MappedStateModel,
    MappedDispatchModel {}

const WithdrawalSuccessful = (props: Props) => {
  const { t, amount, reset } = props

  return (
    <div className="back-color">
      <Container>
        <Row>
          <Col className="text-center">
            <div className="m-auto">
              <div className="withdrawal-successful">
                {formatMoney(amount)} €
              </div>
            </div>
            <div className="funds-collected-message font-40">
              {t('FUNDS_ARE_WITHDRAWN')}
            </div>
            <div className="funds-collected-message mb-5">
              {t('FUNDS_WILL_BE_TRANSFERRED_TO_ACCOUNT_WITHIN_2_DAYS')}
            </div>
            <Button
              className="light-grey withdraw-successful-done"
              onClick={reset}
            >
              {t('DONE')}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WithdrawalSuccessful
