import {
  AISP_RETRY_FAILURE,
  AISP_RETRY_REQUEST,
  AISP_RETRY_SUCCESS,
  GET_ACTIVE_LOANS_FAILURE,
  GET_ACTIVE_LOANS_REQUEST,
  GET_ACTIVE_LOANS_SUCCESS,
  GET_CHECK_LOAN_APPLICATION_STATUS_FAILURE,
  GET_CHECK_LOAN_APPLICATION_STATUS_REQUEST,
  GET_CHECK_LOAN_APPLICATION_STATUS_SUCCESS,
  GET_CHECK_LOAN_APPLICATION_STATUS_KREDITZ_FAILURE,
  GET_CHECK_LOAN_APPLICATION_STATUS_KREDITZ_REQUEST,
  GET_CHECK_LOAN_APPLICATION_STATUS_KREDITZ_SUCCESS,
  STORE_LOAN_APPLICATION_FAILURE,
  STORE_LOAN_APPLICATION_REQUEST,
  STORE_LOAN_APPLICATION_SUCCESS,
  UPDATE_LOAN_APPLICATION_REQUEST,
  UPDATE_LOAN_APPLICATION_SUCCESS,
  UPDATE_LOAN_APPLICATION_FAILURE,
  FETCH_BANKS_REQUEST,
  FETCH_BANKS_SUCCESS,
  FETCH_BANKS_FAILURE,
  CREATE_BANK_SESSION_REQUEST,
  CREATE_BANK_SESSION_SUCCESS,
  CREATE_BANK_SESSION_FAILURE,
  TOGGLE_IS_CHECKED_CCIS,
  UPDATE_IS_CHECKED_CCIS_REQUEST,
  UPDATE_IS_CHECKED_CCIS_SUCCESS,
  UPDATE_IS_CHECKED_CCIS_FAILURE,
  UPDATE_REDIRECT_URL_REQUEST,
  UPDATE_REDIRECT_URL_SUCCESS,
  UPDATE_REDIRECT_URL_FAILURE,
  FETCH_BROKER_URL_REQUEST,
  FETCH_BROKER_URL_SUCCESS,
  FETCH_BROKER_URL_FAILURE,
  PATCH_BROKER_URL_REQUEST,
  PATCH_BROKER_URL_SUCCESS,
  PATCH_BROKER_URL_FAILURE,
  RESET_REDIRECT_URL
} from './actions/types'
import LoanApplication from '../../../../models/Borrower/LoanApplication'
import Bank from '../../../../models/Borrower/Bank'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_LOANS_REQUEST:
    case GET_CHECK_LOAN_APPLICATION_STATUS_REQUEST:
    case AISP_RETRY_REQUEST:
    case STORE_LOAN_APPLICATION_REQUEST:
    case UPDATE_LOAN_APPLICATION_REQUEST:
    case FETCH_BANKS_REQUEST:
    case FETCH_BROKER_URL_REQUEST:
    case PATCH_BROKER_URL_REQUEST:
    case CREATE_BANK_SESSION_REQUEST:
    case UPDATE_IS_CHECKED_CCIS_REQUEST:
    case UPDATE_REDIRECT_URL_REQUEST:
    case GET_CHECK_LOAN_APPLICATION_STATUS_KREDITZ_REQUEST:
      return { ...state, is_fetching: true }

    case AISP_RETRY_SUCCESS:
    case CREATE_BANK_SESSION_SUCCESS:
    case PATCH_BROKER_URL_SUCCESS:
      return {
        ...state,
        is_fetching: false,
      }

    case RESET_REDIRECT_URL:
      return { ...state, redirect_url: '' }

    case UPDATE_IS_CHECKED_CCIS_SUCCESS:
    case UPDATE_REDIRECT_URL_SUCCESS:
      return {
        ...state,
        redirect_url: action.redirect_url,
        is_fetching: false
      }

    case STORE_LOAN_APPLICATION_SUCCESS:
      return {
        ...state,
        is_fetching: false,
        data: action.data,
      }

    case FETCH_BANKS_SUCCESS:
      return {
        ...state,
        is_fetching: false,
        banks: action.data,
      }

    case GET_CHECK_LOAN_APPLICATION_STATUS_SUCCESS:
    case GET_ACTIVE_LOANS_SUCCESS:
    case UPDATE_LOAN_APPLICATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
        is_fetching: false,
      }

    case GET_CHECK_LOAN_APPLICATION_STATUS_KREDITZ_SUCCESS:
      return {
        ...state,
        kreditz_pending: !action.isReady,
        is_fetching: false,
        data: {
          ...state.data,
          ...action.data
        }
      }

    case GET_CHECK_LOAN_APPLICATION_STATUS_FAILURE:
      if (action.error.response.data.retry) {
        return {
          ...state,
          data: {
            ...state.data,
            retry: action.error.response.data.retry,
          },
          is_fetching: false,
        }
      } else {
        return {
          ...state,
          data: {
            ...state.data,
          },
          is_fetching: false,
        }
      }
    case GET_ACTIVE_LOANS_FAILURE:
    case AISP_RETRY_FAILURE:
    case STORE_LOAN_APPLICATION_FAILURE:
    case UPDATE_LOAN_APPLICATION_FAILURE:
    case FETCH_BANKS_FAILURE:
    case CREATE_BANK_SESSION_FAILURE:
    case UPDATE_IS_CHECKED_CCIS_FAILURE:
    case UPDATE_REDIRECT_URL_FAILURE:
    case FETCH_BROKER_URL_FAILURE:
    case PATCH_BROKER_URL_FAILURE:
    case GET_CHECK_LOAN_APPLICATION_STATUS_KREDITZ_FAILURE:
      return { ...state, error: action.error, is_fetching: false }

    case TOGGLE_IS_CHECKED_CCIS:
      return { ...state, is_checked_ccis: !state.is_checked_ccis }

    //TODO CHECKME
    case FETCH_BROKER_URL_SUCCESS:
      return {
        ...state,
        is_fetching: false,
        broker_url: action.data.url,
        broker_name: action.data.name
      }

    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  redirect_url: '',
  is_checked_ccis: false,
  rejected: false,
  kreditz_check: null,
  data: {
    id: 0,
    user_id: 0,
    amount: 0,
    terms: 0,
    amount_offered: null,
    terms_offered: null,
    installment_offered: null,
    monthly_fee: null,
    total_installment_offered: null,
    average_nominal_interest: null,
    average_effective_annual_interest: null,
    payment_plan: [],
    approved: false,
    due_date: null,
    bank_account_id: null,
    user: undefined,
    created_at: '',
    updated_at: '',
    retry: false,
    link: undefined,
    first_year_total_fee: 0,
    state: undefined,
  },
  banks: [],
  broker_url: '',
  broker_name: '',
  error: null,
}

export interface State {
  is_fetching: boolean
  redirect_url: string
  rejected: boolean
  is_checked_ccis: boolean
  kreditz_check: null | 'pending' | 'success' | 'rejected'
  data: LoanApplication
  banks: Bank[]
  broker_url: string
  broker_name: string,
  error: any
}
