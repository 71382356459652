import React, { useState } from 'react'
import {
  ButtonDropdown,
  Col,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
} from 'reactstrap'
// import { validatePhoneNumber } from '../../utils'
import CountryItem from './CountryItem'
import { countries, Country } from './data'
import { useTranslation } from 'react-i18next'

interface Props {
  onChange: (fullNumber: string, country: string) => void
  hasError?: boolean
  errorMessage?: string
  onBlur?: (string) => void
  autoFocus?: boolean
}

const PhoneInput = (props: Props) => {
  const { hasError, errorMessage, onChange, onBlur } = props
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedCountry, setSelectedCountry] = useState(countries[0])
  const { t } = useTranslation()

  function handlePhoneNumberChanged(e: React.FormEvent<HTMLInputElement>) {
    setPhoneNumber(e.currentTarget.value)
    onChange(
      `+${selectedCountry.code}${+e.currentTarget.value}`,
      selectedCountry.iso_code
    )
  }

  function toggleDropDown() {
    setIsDropDownOpen(!isDropDownOpen)
  }

  function changeSelectedCountry(country: Country) {
    if (country) {
      setSelectedCountry(country)
      onChange(`+${country.code}${phoneNumber}`, country.iso_code)
    }
  }

  return (
    <FormGroup className="phone-input">
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <div className="phone-input-col">
            <ButtonDropdown
              className="flag__dropdown"
              isOpen={isDropDownOpen}
              toggle={toggleDropDown}
            >
              <DropdownToggle caret className="flag__caret">
                <img src={selectedCountry.img} className="flag__img" alt="" />
              </DropdownToggle>
              <DropdownMenu>
                <div className="phone-input_search">
                  <Input
                    onChange={(e) => {
                      setSearchTerm(e.target.value)
                    }}
                    placeholder={t('SEARCH_COUNTRIES')}
                    value={searchTerm}
                  />
                </div>
                {countries
                  .filter((country) => {
                    if (searchTerm === '') return true
                    return (
                      country.name
                        .toLowerCase()
                        .indexOf(searchTerm.toLowerCase()) === 0
                    )
                  })
                  .map((country) => (
                    <CountryItem
                      onClick={() => changeSelectedCountry(country)}
                      country={country}
                      key={country.name}
                    />
                  ))}
              </DropdownMenu>
            </ButtonDropdown>
            <span className="country-code">+{selectedCountry.code}</span>
            <div className="phone-input_tel">
              <Input
                autoFocus={!!props.autoFocus}
                type="tel"
                value={phoneNumber}
                onChange={handlePhoneNumberChanged}
                onBlur={onBlur}
                bsSize="sm"
              />
            </div>
            {hasError && (
              <small className="form-error-message">{errorMessage}</small>
            )}
          </div>
        </Col>
      </Row>
    </FormGroup>
  )
}

PhoneInput.defaultProps = {
  hasError: false,
  errorMessage: '',
}

export default PhoneInput
