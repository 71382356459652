import React from 'react'
import User from 'models/Legacy/User'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'

interface IProps {
  data: User
}

const LegacyPersonalData: React.FunctionComponent<IProps> = ({ data }) => {
  const { t } = useTranslation()
  const { Address } = data
  const dataArray = [
    [t('SSN'), data.SSN],
    [t('GENDER'), data.Gender],
    [t('FIRST_NAME'), data.FirstName],
    [t('LAST_NAME'), data.LastName],
    [
      t('ADDRESS'),
      `${Address.PostCode}, ${Address.Address}, ${Address.City}, ${Address.Country}`,
    ],
    [t('NATIONALITY'), data.Nationality],
  ]

  return (
    <>
      {dataArray.map((item) => {
        return item[1] ? (
          <Row key={item[0]} className="mb-3">
            <Col md={6} className="font-weight-bold">
              {item[0]}
            </Col>
            <Col md={6}>{item[1]}</Col>
          </Row>
        ) : null
      })}

      <div className="text-secondary mb-4" role="alert">
        {t('LEGACY_DATA_INCORRECT')}
      </div>
    </>
  )
}

export default LegacyPersonalData
