import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { createPostSkipSignedAgreementsSuccessAction } from './creators'

export const postSkipSignedAgreements = (): ThunkAction<
  any,
  any,
  any,
  any
> => async (dispatch: Dispatch) => {
  dispatch(createPostSkipSignedAgreementsSuccessAction())
}
