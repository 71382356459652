import React, { useState } from 'react'
import { Toast, ToastHeader } from 'reactstrap'

interface Props {
  title?: string
  showToast: boolean
  text?: string
  className?: string
}

const ToastView = (props: Props) => {
  const { showToast, text, className } = props
  const [show, setShow] = useState(true)

  const toggle = () => setShow(!show)

  return (
    <div>
      {showToast && (
        <Toast isOpen={show}>
          <ToastHeader className={className} toggle={toggle}>
            <h5>{text}</h5>
          </ToastHeader>
        </Toast>
      )}
    </div>
  )
}

export default ToastView
