import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import {
  createLoginWithSmsFailureAction,
  createLoginWithSmsRequestAction,
  createLoginWithSmsSuccessAction,
} from './creators'
import SmsLoginAPI from '../../../../../api/auth/SmsLoginAPI'
import browserHistory from '../../../../../browserHistory'
import { State } from '../../../../index'
import { SendMobilePhoneToAuthenticateViaSmsData } from '../../../../../models/Auth/Sms/SendMobilePhoneToAuthenticateViaSmsRequest'

export const loginWithSms = (
  mobilePhone: string
): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  dispatch(createLoginWithSmsRequestAction())
  const body: SendMobilePhoneToAuthenticateViaSmsData = {
    mobile_phone: mobilePhone,
    blaId: getState().broker.loanApplication.blaId,
  }
  try {
    const smsLogin = new SmsLoginAPI()
    await smsLogin.sendMobilePhoneToAuthenticateViaSms(body)
    dispatch(createLoginWithSmsSuccessAction(mobilePhone))
    browserHistory.push('/login/sms/verify')
  } catch (error) {
    if (error.response.status === 403) {
      browserHistory.push('/login/bank')
    }
    dispatch(createLoginWithSmsFailureAction(error))
  }
}
