import { compose } from 'redux'
import { connect } from 'react-redux'
import Presentation from './Presentation'
import { State } from '../../../../store'
import { withTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import React from 'react'

const mapStateToProps = (state: State): MappedStateModel => {
  const { data } = state.user.borrower.loan
  if (data) {
    return {
      remainingDebt: data.remaining_debt,
      loanAccountState: data.loanAccountState,
      interestRate: data.interest_rate,
      monthlyPayment: data.monthly_payment,
      loanAmount: data.total_amount,
      dueDate: data.due_day_of_month,
    }
  }
  return {
    isHidden: true,
  }
}

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, any, any, any>(mapStateToProps)
)

export default enhancer(Presentation)
