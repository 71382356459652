import { CheckBoxElement } from '../../../../../shared/CheckBoxGroup/Models'

const options: CheckBoxElement[] = [
  {
    id: 'is_familiar_with_publicly_traded_stocks',
    label: 'PUBLICLY_TRADED_STOCKS',
  },
  {
    id: 'is_familiar_with_bonds',
    label: 'BONDS',
  },
  {
    id: 'is_familiar_with_capital_markets',
    label: 'CAPITAL_MARKETS',
  },
  {
    id: 'is_familiar_with_derivatives',
    label: 'DERIVATIVES',
  },
  {
    id: 'is_familiar_with_investment_funds',
    label: 'INVESTMENT_FUNDS',
  },
]

export default options
