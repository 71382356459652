import API from '../API'
import PostForeignInvestorProvideDetails from '../../models/Auth/ForeignInvestorProfileData'

export default class OnFidoRegisterAPI extends API {
  public postForeignInvestorProvideDetailsData(
    data: PostForeignInvestorProvideDetails
  ) {
    return this.post('api/auth/signicat/redirect/onfido', data)
  }
}
