import React from 'react'

interface TrProps {
  className?: string
  onClick?: () => void
  style?: any
}

export class Tr extends React.Component<TrProps> {
  render() {
    const { className, onClick, style, children } = this.props
    return (
      <tr style={style} onClick={onClick} className={className}>
        {children}
      </tr>
    )
  }
}

interface ThProps {
  className?: string
  onClick?: () => void
  style?: any
  colSpan?: number
  onMouseDown?: () => void
}

export class Th extends React.Component<ThProps> {
  render() {
    const {
      className,
      onClick,
      style,
      children,
      colSpan,
      onMouseDown,
    } = this.props
    return (
      <th
        style={style}
        onClick={onClick}
        colSpan={colSpan}
        onMouseDown={onMouseDown}
        className={className}
      >
        {children}
      </th>
    )
  }
}

interface TBodyProps {
  className?: string
  style?: any
}

export class TBody extends React.Component<TBodyProps> {
  render() {
    const { className, style, children } = this.props
    return (
      <tbody style={style} className={className}>
        {children}
      </tbody>
    )
  }
}

interface TdProps {
  className?: string
  style?: any
}

export class Td extends React.Component<TdProps> {
  render() {
    const { className, style, children } = this.props
    return (
      <td style={style} className={className}>
        {children}
      </td>
    )
  }
}

interface THeadProps {
  className?: string
  style?: any
}

export class THead extends React.Component<THeadProps> {
  render() {
    const { className, style, children } = this.props
    return (
      <thead style={style} className={className}>
        {children}
      </thead>
    )
  }
}

interface TableProps {
  className?: string
  style?: any
}

export class Table extends React.Component<TableProps> {
  render() {
    const { className, style, children } = this.props
    return (
      <table style={style} className={className}>
        {children}
      </table>
    )
  }
}
