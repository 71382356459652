export const UPDATE_REQUEST = '[borrower][loan] UPDATE_REQUEST'
export const UPDATE_FAILURE = '[borrower][loan] UPDATE_FAILURE'
export const UPDATE_SUCCESS = '[borrower][loan] UPDATE_SUCCESS'

export const LEGACY_LOAN_REQUEST = 'legacy.borrower.loan.LEGACY_LOAN_REQUEST'
export const LEGACY_LOAN_SUCCESS = 'legacy.borrower.loan.LEGACY_LOAN_SUCCESS'
export const LEGACY_LOAN_FAILURE = 'legacy.borrower.loan.LEGACY_LOAN_FAILURE'

export const LEGACY_INVOICE_REQUEST =
  'legacy.borrower.loan.LEGACY_INVOICE_REQUEST'
export const LEGACY_INVOICE_SUCCESS =
  'legacy.borrower.loan.LEGACY_INVOICE_SUCCESS'
export const LEGACY_INVOICE_FAILURE =
  'legacy.borrower.loan.LEGACY_INVOICE_FAILURE'
