import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { setBorrowerAffiliateTrackingCode } from './creators'
import { affiliateBorrowerTrackingCode } from '../../../../utils/affiliate/borrower/affiliateTracking'

/* TODO Unit Test */
export const getBorrowerAffiliate = (): ThunkAction<
  any,
  any,
  any,
  any
> => async (dispatch: Dispatch) => {
  const data = affiliateBorrowerTrackingCode()
  const borrower_unique_code = data?.unique_code
  const borrower_affiliate_name = data?.utmCampaign
  dispatch(
    setBorrowerAffiliateTrackingCode(
      borrower_unique_code,
      borrower_affiliate_name
    )
  )
}
