import {
  GET_AUTO_INVEST_STATUS_FAILURE,
  GET_AUTO_INVEST_STATUS_REQUEST,
  GET_AUTO_INVEST_STATUS_SUCCESS,
  TOGGLE_AUTO_INVEST_STATUS_FAILURE,
  TOGGLE_AUTO_INVEST_STATUS_REQUEST,
  TOGGLE_AUTO_INVEST_STATUS_SUCCESS,
  ADD_COMPANY_INVESTOR,
  GET_COMPANY_AUTO_INVEST_STATUS_SUCCESS,
  TOGGLE_COMPANY_AUTO_INVEST_STATUS_SUCCESS,
  GET_COMPANY_SIGN_RIGHT_STATUS_REQUEST,
  GET_COMPANY_SIGN_RIGHT_STATUS_SUCCESS,
  GET_COMPANY_SIGN_RIGHT_STATUS_FAILURE,
} from './types'
import AutoInvest from '../../../../../models/Investor/AutoInvest'
import { Company } from 'models/Auth/ProfileData'

export const createGetAutoInvestStatusRequestAction = () => ({
  type: GET_AUTO_INVEST_STATUS_REQUEST,
})
export const createGetAutoInvestStatusSuccessAction = (data: AutoInvest) => ({
  data,
  type: GET_AUTO_INVEST_STATUS_SUCCESS,
})
export const createGetCompanyAutoInvestStatusSuccessAction = (
  data: AutoInvest,
  companyId: number
) => ({
  data,
  companyId,
  type: GET_COMPANY_AUTO_INVEST_STATUS_SUCCESS,
})
export const createGetAutoInvestStatusFailureAction = (error: any) => ({
  error,
  type: GET_AUTO_INVEST_STATUS_FAILURE,
})

export const createToggleAutoInvestStatusRequestAction = () => ({
  type: TOGGLE_AUTO_INVEST_STATUS_REQUEST,
})
export const createToggleAutoInvestSuccessAction = (data: AutoInvest) => ({
  data,
  type: TOGGLE_AUTO_INVEST_STATUS_SUCCESS,
})
export const createCompanyToggleAutoInvestSuccessAction = (
  data: AutoInvest,
  companyId: number
) => ({
  data,
  companyId,
  type: TOGGLE_COMPANY_AUTO_INVEST_STATUS_SUCCESS,
})
export const createToggleAutoInvestFailureAction = (error: any) => ({
  error,
  type: TOGGLE_AUTO_INVEST_STATUS_FAILURE,
})
export const createAddCompanyInvestorAction = (data: Company[]) => ({
  data,
  type: ADD_COMPANY_INVESTOR,
})

export const createGetCompanySignRightStatusRequestAction = () => ({
  type: GET_COMPANY_SIGN_RIGHT_STATUS_REQUEST,
})
export const createGetCompanySignRightStatusSuccessAction = (
  data: AutoInvest,
  companyId: number
) => ({
  data,
  companyId,
  type: GET_COMPANY_SIGN_RIGHT_STATUS_SUCCESS,
})
export const createGetCompanySignRightStatusFailureAction = (error: any) => ({
  error,
  type: GET_COMPANY_SIGN_RIGHT_STATUS_FAILURE,
})
