import { connect } from 'react-redux'
import Presentation from './Presentation'
import MappedStateModel from './MappedState.model'
import { State } from '../../../../store'

const mapStateToProps = (state: State): MappedStateModel => {
  const { data } = state.user.borrower.loan
  if (data?.next_instalment) {
    return {
      referenceNumber: data.reference_number,
      dueData: data.next_instalment.due_date,
      amount: data.next_instalment.total_due_amount,
      accountNumber: 'FI71 1318 3000 1080 67',
      loanAccountState: data.loanAccountState,
    }
  }
  return { isHidden: true }
}

export default connect(mapStateToProps)(Presentation)
