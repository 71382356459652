import React from 'react'

const CCISConsent: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared t-and-c__v02">
      <p>
        Förfrågningssystemet för konsumentkrediter är en tjänst som erbjuds av
        Suomen Asiakastieto Oy, genom vilken kreditgivare som ingått avtal med
        varandra på uppdrag av en konsument (lånesökande) och med dennes
        samtycke kan lämna ut uppgifter om konsumenten till varandra för
        behandling av en kreditansökan. De uppgifter som behandlas och förmedlas
        i systemet berör krediter och skötseln av dem, och överlåts inte till
        utomstående om inte lagen kräver detta.
      </p>
      <p>
        Om kredit beviljas sparas uppgifterna i fem år efter att krediten i sin
        helhet har förfallit till betalning, om det inte föreligger en
        lagstadgad skyldighet att spara dem längre. Om kredit inte beviljas
        raderas uppgifterna inom två år efter att kreditansökan lämnats in.
      </p>
      <p>
        Den kreditsökande har rätt att granska vilka uppgifter som har lämnats
        ut från förfrågningssystemet för konsumentkrediter och till vilka
        mottagare. Granskningsrätten tillämpas av Suomen Asiakastieto Oy.
      </p>
      <p>
        Mer information om Suomen Asiakastieto Oy:s förfrågningssystem för
        konsumentkrediter och de bolag som hör till det:
        <a href="https://www.asiakastieto.fi/web/fi/positiivinen-tieto/positiva-kreditupplysningar-i-forfragningssystemet-for-konsumentkredit.html">
          &nbsp;asiakastieto.fi
        </a>
      </p>
      <p></p>
    </div>
  )
}

export default CCISConsent
