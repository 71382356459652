declare global {
  interface Window {
    dataLayer: any
  }
}

// google tag manager investor signup
export const investorSignDataLayer: () => void = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'investor_event',
    category: 'signup',
  })
}
