import React from 'react'
import { useTranslation } from 'react-i18next'

const Header = () => {
  const { t } = useTranslation()

  return (
    <h1 className="font-50 text-center your-loan-offer__header p-5">
      {t('YOUR_LOAN_OFFER')}
    </h1>
  )
}

export default Header
