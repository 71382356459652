import { connect } from 'react-redux'
import Presentation from './Presentation'
import { State } from '../../../../store'
import MappedStateModel from './MappedState.model'
import InvoiceTypeEnum from './InvoiceType.enum'
import { formatDate } from '../../../../utils'

const mapStateToProps = (state: State): MappedStateModel => {
  const { data } = state.user.borrower.loan_application
  if (data.payment_plan && data.payment_plan.length > 0) {
    return {
      data: data.payment_plan.map((loanPlan) => ({
        dueDate: formatDate(loanPlan.dueDate, 'YYYY-MM-DD'),
        totalAmount: loanPlan.totalDue,
        invoiceStatus: InvoiceTypeEnum.READY_FOR_INVOICING,
        interestRate: loanPlan.interest,
        fee: Number(loanPlan.fees),
        principal: Number(loanPlan.principalAmount),
        interest: Number(loanPlan.interestAmount),
      })),
      wrapperClass: 'took-loan',
    }
  }
  return { isHidden: true }
}

export default connect<MappedStateModel, any, any, any>(mapStateToProps)(
  Presentation
)
