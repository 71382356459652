import finlandFlagRoundSmall from 'assets/images/flags/finland-flag-round-small.png'

export interface Country {
  name: string
  iso_code: string
  code: string
  img?: string
}

export const countries: Country[] = [
  { name: 'Finland', code: '358', iso_code: 'FI', img: finlandFlagRoundSmall },
  { name: 'Belgium', code: '32', iso_code: 'BE' },
  { name: 'Austria', code: '43', iso_code: 'AT' },
  { name: 'Portugal', code: '351', iso_code: 'PT' },
  { name: 'Spain', code: '34', iso_code: 'ES' },
  { name: 'Cyprus', code: '357', iso_code: 'CY' },
  { name: 'Estonia', code: '372', iso_code: 'EE' },
  { name: 'France', code: '33', iso_code: 'FR' },
  { name: 'Germany', code: '49', iso_code: 'DE' },
  { name: 'Greece', code: '30', iso_code: 'GR' },
  { name: 'Ireland', code: '353', iso_code: 'IE' },
  { name: 'Italy', code: '39', iso_code: 'IT' },
  { name: 'Latvia', code: '371', iso_code: 'LV' },
  { name: 'Lithuania', code: '370', iso_code: 'LT' },
  { name: 'Luxembourg', code: '352', iso_code: 'LU' },
  { name: 'Malta', code: '356', iso_code: 'MT' },
  { name: 'Monaco', code: '377', iso_code: 'MC' },
  { name: 'Netherlands', code: '31', iso_code: 'NL' },
  { name: 'San Marino', code: '378', iso_code: 'SM' },
  { name: 'Slovakia', code: '421', iso_code: 'SK' },
  { name: 'Slovenia', code: '386', iso_code: 'SI' },
  { name: 'Bulgaria', code: '359', iso_code: 'BG' },
  { name: 'Czech Republic', code: '420', iso_code: 'CZ' },
  { name: 'Denmark', code: '45', iso_code: 'DK' },
  { name: 'Gibraltar', code: '350', iso_code: 'GI' },
  { name: 'Hungary', code: '36', iso_code: 'HU' },
  { name: 'Iceland', code: '354', iso_code: 'IS' },
  { name: 'Liechtenstein', code: '423', iso_code: 'LI' },
  { name: 'Norway', code: '47', iso_code: 'NO' },
  { name: 'Poland', code: '48', iso_code: 'PL' },
  { name: 'Romania', code: '40', iso_code: 'RO' },
  { name: 'Sweden', code: '46', iso_code: 'SE' },
  { name: 'Switzerland', code: '41', iso_code: 'CH' },
  { name: 'United Kingdom', code: '44', iso_code: 'GB' },
].sort(function (a, b) {
  var nameA = a.name.toUpperCase()
  var nameB = b.name.toUpperCase()
  if (a.iso_code === 'FI') return -1
  if (b.iso_code === 'FI') return 1
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
})

countries.map(async (country) => {
  const { default: module } = await import(
    `assets/images/flags/${country.iso_code.toLowerCase()}.svg`
  )
  country.img = !country.img ? module : country.img
  return country
})
