import { CheckBoxElement } from '../../../../../shared/CheckBoxGroup/Models'

const options: CheckBoxElement[] = [
  {
    id: 'low',
    label: 'LOW',
  },
  {
    id: 'medium',
    label: 'MEDIUM',
  },
  {
    id: 'high',
    label: 'HIGH',
  },
]

export default options
